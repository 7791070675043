import FusePageSimple from '@fuse/core/FusePageSimple';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHoldFiles } from 'app/store/docbase/admin/systemSlice';
import { getUserList } from 'app/store/docbase/admin/accountSlice';
import ContentHeader from '../component/ContentHeader';
import HoldFilesTable from './HoldFilesTable';

function HoldFiles() {
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(true);
	const pageLayout = React.useRef(null);

	const { usersObj } = useSelector(({ docbase }) => docbase.admin.account);

	const [searchObj, setSearchObj] = React.useState({
		userID: ''
	});

	useEffect(() => {
		doSearch(true);
	}, [searchObj]);

	function doSearch(showLoading) {
		setLoading(showLoading);
		if (Object.keys(usersObj).length <= 0) {
			dispatch(getUserList()).then(() => {
				dispatch(getHoldFiles({ userID: searchObj.userID })).then(() => {
					setLoading(false);
				});
			});
		} else {
			dispatch(getHoldFiles({ userID: searchObj.userID })).then(() => {
				setLoading(false);
			});
		}
	}

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<HoldFilesTable
					loading={loading}
					searchObj={searchObj}
					setLoading={setLoading}
					fnSetSearchObj={(name, value) => setSearchObj({ ...searchObj, [name]: value })}
					fnDoSearch={showLoading => doSearch(showLoading)}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default HoldFiles;
