import { TableCell, TableHead, TableRow, Icon, Paper, Input, InputAdornment, IconButton } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .file_types': {
			width: 105
		},
		'& .log_types': {
			width: 50
		}
	}
}));

function LogsTableHead(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();

	const headerCols = [
		{
			id: 'log_types',
			align: 'center',
			className: 'log_types',
			disablePadding: false,
			label: '',
			isSearch: false,
			sort: false
		},
		{
			id: 'file_types',
			align: 'center',
			className: 'file_types',
			disablePadding: false,
			label: '',
			isSearch: false,
			sort: false
		},
		{
			id: 'date',
			align: 'left',
			className: 'left table_col_date',
			disablePadding: false,
			label: t('DATE'),
			isSearch: false,
			sort: false
		},
		{
			id: 'logs_contents',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('INSTANT_LOGS_CONTENTS'),
			isSearch: false,
			sort: false
		}
	];

	return (
		<TableHead>
			<TableRow className={classes.tr}>
				{headerCols.map(row => {
					return (
						<TableCell
							className={clsx(row.className)}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={false}
						>
							<span className="align-middle">{row.label}</span>
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default LogsTableHead;
