import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Paper, Input, Button } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getApprovalTakeoutManageTypes, setApprovalTakeoutManageTypes } from 'app/store/docbase/admin/systemSlice';
import { getDirectOrgList } from 'app/store/docbase/admin/folderSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import FuseUtils from '@fuse/utils/FuseUtils';
import OrgTreeView from '../component/OrgTreeView';

const useStyles = makeStyles(theme => ({
	root: { marginRight: 30, borderRadius: 6 },
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 228}px`,
		padding: '0 20px'
	},
	icon: {
		width: 20,
		height: 20
	},
	btn01: {
		marginRight: 10
	}
}));

let isFirst = true;
function ExportApprovalTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const [loading, setLoading] = useState(true);

	const [searchText, setSearchText] = React.useState('');
	const [orgsChildren, setOrgsChildren] = React.useState({});
	const [folders, setFolders] = React.useState([]);
	const [expandedItems, setExpandedItems] = useState([]);
	const [data, setData] = useState({});

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 228}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		if (folders.length > 0) filterSearch(searchText);
		return () => {
			filterSearch.cancel();
		};
	}, [searchText]);

	useEffect(() => {
		isFirst = false;
		setLoading(true);
		getData();
		return () => {
			isFirst = true;
			setSearchText('');
		};
	}, [dispatch]);

	useEffect(() => {
		setOrgTree();
	}, [orgsChildren]);

	const filterSearch = useCallback(
		debounce(val => {
			try {
				if (val.length > 0) {
					setData({
						0: { children: FuseUtils.filterFoldersNameByString(folders, val) }
					});
					setExpandedItems([0]);
				} else {
					setOrgTree();
				}
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		}, 500)
	);
	if (loading) {
		return <FuseLoading />;
	}

	async function getData() {
		const takeoutManageList = await getApprovalTakeoutManageTypes();
		const takeoutManageObj = {};

		takeoutManageList.forEach(tm => {
			takeoutManageObj[tm.fid] = tm.manage_type;
		});

		const respOrgs = await getDirectOrgList();
		const orgChildren = {};
		const expanded = [];

		Object.keys(respOrgs.orgChildren).forEach(key => {
			if (respOrgs.orgChildren[key]) {
				const array = respOrgs.orgChildren[key].children.map(child => {
					child.manage_type = takeoutManageObj[child.fid];
					return child;
				});

				orgChildren[key] = { children: array };
			}
			expanded.push(Number(key));
		});

		setFolders(
			respOrgs.orgs.map(org => {
				org.manage_type = takeoutManageObj[org.fid];
				return org;
			})
		);
		setOrgsChildren(orgChildren);
		setLoading(false);
	}

	function setOrgTree() {
		const expanded = [];
		console.log(orgsChildren);
		Object.keys(orgsChildren).forEach(key => {
			expanded.push(Number(key));
		});
		setData(orgsChildren);
		setExpandedItems(expanded);
		setLoading(false);
	}

	function handleExpandClick() {
		if (expandedItems.length === 0) {
			const tempObj = { ...orgsChildren };
			const expanded = [];
			Object.keys(orgsChildren).forEach(key => {
				if (orgsChildren[key]) {
					const array = [...orgsChildren[key].children];
					tempObj[key] = { children: array };
				}
				expanded.push(Number(key));
			});
			setExpandedItems(expanded);
		} else {
			setExpandedItems([]);
		}
	}

	function doAction(fid, type) {
		setLoading(true);
		const params = folders.map(f => {
			if (f.fid === fid) {
				return { ...f, manage_type: type };
			}
			return f;
		});

		setApprovalTakeoutManageTypes(params).then(respResult => {
			if (respResult) {
				dispatch(showMessage({ message: t('TAKEOUT_APPROVE_MESSAGE_1') }));
				getData();
			}
		});
	}

	function doActionEx(manageType) {
		setLoading(true);
		const params = folders.map(f => {
			return { fid: f.fid, manage_type: manageType };
		});

		setApprovalTakeoutManageTypes(params).then(respResult => {
			if (respResult) {
				dispatch(showMessage({ message: t('TAKEOUT_APPROVE_MESSAGE_1') }));
				getData();
			}
		});
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className={clsx('items-center w-full flex table-search-wrapper')}>
					<div className="">
						<div className="input-item">
							<Input
								placeholder={t('SEARCH')}
								className="search-input"
								disableUnderline
								fullWidth
								value={searchText}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => setSearchText(ev.target.value)}
							/>
						</div>
					</div>
					<div className="total-cnt-info">
						<span className="pipe">|</span>
						<span>
							{t('TOTAL')} <span className="count">{folders.length}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="btn-group">
						<Button onClick={e => handleExpandClick()}>
							{expandedItems.length === 0 ? t('EXPAND_ALL') : t('COLLAPSE_ALL')}
						</Button>
						<Button
							variant="contained"
							color="default"
							className={classes.btn01}
							type="button"
							onClick={e => doActionEx(0)}
						>
							{t('TAKEOUT_APPROVE_MANAGE_UNLOCK_ALL')}
						</Button>
						<Button variant="contained" color="secondary" className="" type="button" onClick={e => doActionEx(1)}>
							{t('TAKEOUT_APPROVE_MANAGE_LOCK_ALL')}
						</Button>
					</div>
				</div>

				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<div>
						<OrgTreeView
							className="full"
							childrenObj={data}
							expandedItems={expandedItems}
							fnOnClickAction={(e, node) => {
								doAction(node.fid, node.manage_type === 0 ? 1 : 0);
							}}
							fnOnClickHandler={(e, node) => {
								props.fnSetSelectedFid(node.fid);
								props.fnSetPath(node.disp_path.substring(node.disp_path.indexOf('/', 1)));
							}}
						/>
					</div>
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

export default ExportApprovalTable;
