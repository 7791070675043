import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import commonUtils from 'app/utils/CommonUtils';
import wcAvi from './icon/wc_avi.svg';
import wcDoc from './icon/wc_doc.svg';
import wcEtc from './icon/wc_etc.svg';
import wcJpg from './icon/wc_jpg.svg';
import wcPdf from './icon/wc_pdf.svg';
import wcPng from './icon/wc_png.svg';
import wcPpt from './icon/wc_ppt.svg';
import wcPsd from './icon/wc_psd.svg';
import wcTxt from './icon/wc_txt.svg';
import wcXls from './icon/wc_xls.svg';
import wcZip from './icon/wc_zip.svg';
import wcLock from './icon/wc_lock.svg';
import wcUnlock from './icon/wc_unlock.svg';

const useStyles = makeStyles({
	fileIcon: {
		width: 16,
		height: 16,
		margin: '0 auto',
		'&.huge': {
			width: 64,
			height: 64
		},
		'&.large': {
			width: 35,
			height: 35
		}
	},
	fileListIcon: {
		width: '35px',
		height: '35px',
		margin: '0 auto'
	}
});

function FileIcon(props) {
	const classes = useStyles();
	const ext = commonUtils.splitLastDelimiterString(props.targetName, '.').toLowerCase();
	let img;
	if (props.secureDocInfo && props.secureDocInfo.isSecureDoc) {
		if (props.secureDocInfo.accessible) {
			img = (
				<img
					className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
					src={wcUnlock}
					alt="file-icon"
				/>
			);
		} else {
			img = (
				<img
					className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
					src={wcLock}
					alt="file-icon"
				/>
			);
		}
	} else {
		switch (ext) {
			case 'avi':
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcAvi}
						alt="file-icon"
					/>
				);
				break;
			case 'doc':
			case 'docx':
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcDoc}
						alt="file-icon"
					/>
				);
				break;
			case 'jpg':
			case 'jpeg':
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcJpg}
						alt="file-icon"
					/>
				);
				break;
			case 'pdf':
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcPdf}
						alt="file-icon"
					/>
				);
				break;
			case 'png':
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcPng}
						alt="file-icon"
					/>
				);
				break;
			case 'ppt':
			case 'pptx':
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcPpt}
						alt="file-icon"
					/>
				);
				break;
			case 'psd':
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcPsd}
						alt="file-icon"
					/>
				);
				break;
			case 'txt':
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcTxt}
						alt="file-icon"
					/>
				);
				break;
			case 'xls':
			case 'xlsx':
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcXls}
						alt="file-icon"
					/>
				);
				break;
			case 'zip':
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcZip}
						alt="file-icon"
					/>
				);
				break;
			default:
				img = (
					<img
						className={clsx(props.className, props.isFileList ? classes.fileListIcon : classes.fileIcon)}
						src={wcEtc}
						alt="file-icon"
					/>
				);
		}
	}

	return <div className={props.className}>{img}</div>;
}

export default FileIcon;
