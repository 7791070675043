import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from '../../utils/HttpClient';
import securityUtils from '../../utils/SecurityUtils';
import { secureRequest } from '../../store/docbase/commonAction';

export const initialSetup = () => async dispatch => {
	// return dispatch(initialSetupSuccess({ product_registered: true, administrator_setup: true }));
	httpClient.sendPost('/api/initialSetup', {}).then(data => {
		if (!data.result) {
			httpClient.printError(data);
			return dispatch(initialSetupSuccess({ product_registered: true, administrator_setup: true }));
		}

		return dispatch(
			initialSetupSuccess({
				product_registered: data.product_registered,
				administrator_setup: data.administrator_setup
			})
		);
	});
};

export const getProductInfo = createAsyncThunk('setting/getProductKeyInfo', async () => {
	const respData = await httpClient.sendPost('/api/getProductKeyInfo', {});
	if (!respData.result) {
		return {
			validProductKey: false,
			expireAt: '2021-01-18 23:55:58',
			userLicenses: 0,
			supportAccessControl: false,
			supportDataleakControl: false,
			supportSavingControl: false,
			expireMaintenanceAt: '2021-01-18 23:55:58',
			settledExpireMaintenance: false
		};
	}
	return {
		validProductKey: respData.valid_product_key,
		expireAt: respData.expire_at,
		userLicenses: respData.user_licenses,
		supportAccessControl: respData.support_access_control,
		supportDataleakControl: respData.support_dataleak_control,
		supportSavingControl: respData.support_saving_control,
		expireMaintenanceAt: respData.expire_maintenance_at,
		settledExpireMaintenance: respData.settled_expire_maintenance
	};
});

/**
 * PreOrder ProductKey 사용전 생성 API
 * @param {*} year
 * @param {*} month
 * @param {*} day
 * @param {*} licenses
 * @returns
 */
export const registerProduct = (year, month, day, licenses) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/registerProduct', {
					expire_year: year,
					expire_month: month,
					expire_day: day,
					user_licenses: licenses,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};
/**
 * PreOrder Productkey 생성 API
 * @param {*} uuid
 * @returns
 */
export const registerPreorderedProduct = uuid => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/registerPreorderedProduct', {
					uuid: securityUtils.encryptAES(aes, uuid, 'utf8', 'hex'),
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

const initialState = {
	administrator_setup: true,
	product_registered: true,
	productInfo: {
		validProductKey: true,
		expireAt: '2021-01-18 23:55:58',
		userLicenses: 0
	}
};

const initialSlice = createSlice({
	name: 'initial',
	initialState,
	reducers: {
		initialSetupSuccess: (state, action) => {
			state.product_registered = action.payload.product_registered;
			state.administrator_setup = action.payload.administrator_setup;
		},
		setAdminSetup: (state, action) => {
			state.administrator_setup = action.payload;
		},
		setProductKey: (state, action) => {
			state.product_registed = action.payload;
		}
	},
	extraReducers: {
		[getProductInfo.fulfilled]: (state, action) => {
			state.productInfo.validProductKey = action.payload.validProductKey;
			state.productInfo.expireAt = action.payload.expireAt;
			state.productInfo.userLicenses = action.payload.userLicenses;
			state.productInfo.supportAccessControl = action.payload.supportAccessControl;
			state.productInfo.supportDataleakControl = action.payload.supportDataleakControl;
			state.productInfo.supportSavingControl = action.payload.supportSavingControl;
			state.productInfo.expireMaintenanceAt = action.payload.expireMaintenanceAt;
			state.productInfo.settledExpireMaintenance = action.payload.settledExpireMaintenance;
		}
	}
});

export const { initialSetupSuccess, setAdminSetup, setProductKey } = initialSlice.actions;

export default initialSlice.reducer;
