/* eslint-disable no-await-in-loop */
import React, { useCallback } from 'react';
import _ from '@lodash';
import { debounce } from 'lodash';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	TextField,
	Checkbox,
	Typography,
	IconButton,
	Tabs,
	Tab,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Person, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { getAllOrgFolderList, getFolderPermList } from 'app/store/docbase/admin/folderSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { updateUser, getUserList } from 'app/store/docbase/admin/accountSlice';
import PermIcon from '../icon/PermIcon';
import OrgTreeView from './OrgTreeView';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		'& .MuiPaper-root': {
			padding: 0,
			width: '100%',
			maxWidth: 673,
			background: '#f2f3f5',
			'&>div': { padding: 30 }
		}
	},
	contentWrapper: {
		height: 400,
		background: '#fff'
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		padding: 0,
		height: 60,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		'& .MuiTypography-root': {
			fontSize: 16,
			fontWeight: 'normal'
		}
	},
	head: {
		paddingBottom: '0px !important',
		'& .search-wrapper': {
			background: '#ffffff',
			borderRadius: 6,
			padding: '15px 30px',
			display: 'flex',
			'& .input-item': { width: 273 },
			'&>button': { marginLeft: 10 }
		}
	},
	body: {
		paddingTop: '10px !important',
		'& .type-tabs': {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			'& .active_bar': {
				background: '#fff'
			},
			'& .list-tab': {
				borderRadius: '6px 6px 0 0',
				minWidth: 118,
				height: 50,
				padding: 0,
				color: '#192141'
			},
			'& .list-tab.Mui-selected': {
				background: '#ffffff',
				color: '#00cfe2'
			}
		},
		'& .result-list': {
			fontSize: 14,
			boxShadow: 'none',
			marginTop: '-3px',
			'& .cell-check': {
				width: 60
			},
			'& .MuiTableBody-root': { background: '#ffffff' },
			'& .cell-name': {
				'&>div': {
					display: 'flex',
					alignItems: 'center'
				},
				'&>.etc': { position: 'absolute', right: 0, top: 2 }
			},
			'& .MuiIconButton-label': {
				width: 10,
				height: 10
			},
			'& .obj-name>span': {
				marginLeft: 12
			}
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 40,
		'& .MuiButton-root': {
			width: 172,
			height: 54
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

let isClicked = false;

export default function DLPGroupPermDialog(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const formRef = React.useRef(null);
	const { t } = useTranslation('docbase');

	const { users } = useSelector(({ docbase }) => docbase.admin.account);
	const { orgsChildren, orgFolders } = useSelector(({ docbase }) => docbase.admin.folder);
	const [searchVal, setSearchVal] = React.useState('');
	const [dataList, setDataList] = React.useState(users);
	const [orgsData, setOrgsData] = React.useState({});
	const [orgsExpanded, setOrgsExpanded] = React.useState([]);

	const [checkedList, setCheckedList] = React.useState({});
	const [allCheck, setAllCheck] = React.useState(false);
	const [tabValue, setTabValue] = React.useState(0);
	const [sortObj, setSortObj] = React.useState({
		direction: 'asc'
	});

	React.useEffect(() => {
		if (props.open) {
			dispatch(getAllOrgFolderList()).then(() => {
				dispatch(getUserList());
			});

			filterDataList('');
		} else {
			setDataList([]);
			setSearchVal('');
			setCheckedList({});
			setOrgTree();
		}
	}, [props.open]);

	React.useEffect(() => {
		setSearchVal('');
		filterDataList('');
		setAllCheck(false);
	}, [tabValue, users]);

	React.useEffect(() => {
		setOrgTree();
	}, [orgsChildren]);

	React.useEffect(() => {
		filterDataList(searchVal);
		return () => {
			filterDataList.cancel();
		};
	}, [searchVal]);

	function setOrgTree() {
		const expanded = [];
		Object.keys(orgsChildren).forEach(key => {
			expanded.push(Number(key));
		});
		setOrgsData(orgsChildren);
		setOrgsExpanded(expanded);
	}

	const filterDataList = useCallback(
		debounce(val => {
			const data = [];
			const checks = {};
			if (tabValue === 0) {
				if (val.length > 0) {
					orgFolders.forEach(org => {
						if (org.name.toLowerCase().includes(val.toLowerCase())) {
							data.push(org);
							checks[org.fid] = false;
						}
					});

					setOrgsData({ 0: { children: data } });
					setOrgsExpanded([]);
				} else {
					setOrgTree();
				}
			} else {
				//	조직폴더 사용자
				users.forEach(u => {
					//	이미 권한이 있는 사용자 제거
					if (!props.permsObj[u.user_id]) {
						if (val === '' || u.name.indexOf(val) >= 0 || u.user_id.indexOf(val) >= 0) {
							data.push({ id: u.user_id, name: u.name, path: '' });
							checks[u.user_id] = false;
						}
					}
				});
			}
			setCheckedList(checks);
			setDataList(data);
		}, 100)
	);

	function handleCheckBox(id) {
		setAllCheck(false);
		setCheckedList({
			...checkedList,
			[id]: !checkedList[id]
		});
	}

	function handleCheckAllBox() {
		const checkedObj = {};
		dataList.forEach(item => {
			checkedObj[item.id] = !allCheck;
		});
		setAllCheck(!allCheck);
		setCheckedList(checkedObj);

		if (tabValue === 0) {
			const tempObj = { ...orgsChildren };
			const expanded = [];
			Object.keys(orgsChildren).forEach(key => {
				if (orgsChildren[key]) {
					const array = orgsChildren[key].children.map(child => {
						const obj = { ...child };
						obj.checked = !allCheck;
						return obj;
					});
					tempObj[key] = { children: array };
				}
				expanded.push(Number(key));
			});
			setOrgsData(tempObj);
			setOrgsExpanded(expanded);
		}
	}

	async function setPerm() {
		if (!isClicked) {
			isClicked = true;

			const selects = Object.keys(checkedList);
			if (tabValue === 0) {
				const userList = {};
				for (let i = 0; i < selects.length; i++) {
					if (checkedList[selects[i]]) {
						const list = await getFolderPermList(selects[i]);
						list.forEach(item => {
							userList[item.user_id] = item;
						});
					}
				}
				props.fnSetPerm(Object.keys(userList));
			} else if (selects.length > 0) {
				const userList = [];
				for (let i = 0; i < selects.length; i++) {
					if (checkedList[selects[i]]) {
						userList.push(selects[i]);
					}
				}
				props.fnSetPerm(userList);
			}
			isClicked = false;
		}
	}

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog">
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('PERM_FOLDER_TITLE')}
				</DialogTitle>

				<DialogActions className={classes.head}>
					<div className="search-wrapper">
						<div className="input-item">
							<TextField
								className=""
								type="text"
								placeholder={t('SEARCH_KEYWORD')}
								name="search_val"
								fullWidth
								value={searchVal}
								variant="outlined"
								size="small"
								onChange={e => setSearchVal(e.target.value)}
							/>
						</div>
						<Button
							variant="contained"
							className="default"
							type="button"
							fullWidth
							onClick={() => handleCheckAllBox()}
						>
							{t('CHECK_ALL')}
						</Button>
						<Button
							variant="contained"
							color="secondary"
							className=""
							type="button"
							fullWidth
							onClick={() => setPerm()}
						>
							{t('ADD')}
						</Button>
					</div>
				</DialogActions>

				<DialogContent className={classes.body}>
					<div>
						<div className="type-tabs">
							<Tabs
								value={tabValue}
								onChange={(ev, val) => {
									setTabValue(val);
								}}
								indicatorColor="secondary"
								textColor="inherit"
								variant="scrollable"
								scrollButtons="off"
								classes={{ indicator: 'active_bar' }}
							>
								<Tab className="list-tab" disableRipple label={t('ORG_FOLDER')} />
								<Tab className="list-tab" disableRipple label={t('USER')} />
							</Tabs>
						</div>
						<div className="result-list">
							<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')}>
								{tabValue === 0 ? (
									<div>
										<OrgTreeView
											className="full"
											childrenObj={orgsData}
											expandedItems={orgsExpanded}
											checkbox
											fnOnChangeChecked={(fid, pid, checked) => {
												const temp = { ...orgsData };
												const targetArrays = orgsData[pid].children.map(child => {
													const obj = { ...child };
													if (obj.fid === fid) {
														obj.checked = checked;
													}
													return obj;
												});
												setOrgsData({ ...orgsData, [pid]: { children: targetArrays } });
												handleCheckBox(fid);
											}}
											fnOnClickHandler={(e, node) => {}}
										/>
									</div>
								) : (
									<Table stickyHeader className="w-full min-w-full">
										<TableHead>
											<TableRow>
												<TableCell padding="none" className="cell-check" align="center">
													<Checkbox
														color="secondary"
														size="small"
														indeterminate={
															!allCheck && Object.values(checkedList).filter(obj => obj).length > 0
														}
														checked={allCheck}
														onChange={e => handleCheckAllBox()}
													/>
												</TableCell>
												<TableCell padding="none" className="left" align="left">
													<TableSortLabel
														active
														direction={sortObj.direction}
														onClick={e => {
															setSortObj({
																direction: sortObj.direction === 'asc' ? 'desc' : 'asc'
															});
														}}
													>
														{t('USER')}
													</TableSortLabel>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{_.orderBy(
												dataList,
												[
													d => {
														return d.name;
													}
												],
												[sortObj.direction]
											).map((data, idx) => {
												return (
													<TableRow
														className="cursor-pointer relative"
														hover
														role="checkbox"
														tabIndex={-1}
														key={data.id}
														onClick={event => handleCheckBox(data.id)}
													>
														<TableCell className="" padding="none" align="center">
															<Checkbox
																color="secondary"
																size="small"
																checked={checkedList[data.id]}
																tabIndex={-1}
																disableRipple
																name="checked"
																onChange={e => {
																	handleCheckBox(data.id);
																}}
															/>
														</TableCell>
														<TableCell className="left cell-name" component="td" scope="row">
															<div className="obj-name">
																<PermIcon type="person" className="small" />
																<span>{`${data.name}(${data.id})`}</span>
															</div>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								)}
							</FuseScrollbars>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
