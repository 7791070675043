import React from 'react';
import Formsy from 'formsy-react';
import { TextFieldFormsy, SelectFormsy, CheckboxFormsy } from '@fuse/core/formsy';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MenuItem, Button, Dialog, DialogContent, DialogTitle, Backdrop, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { getUserList, addUser } from 'app/store/docbase/admin/accountSlice';
import commonUtils from 'app/utils/CommonUtils';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 604
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6,
			padding: 40
		},
		'&>.content>.row': {
			display: 'flex',
			alignItems: 'center',
			margin: '15px 0'
		},
		'&>.content>.row:first-child': {
			marginTop: 0
		},
		'&>.content>.row:last-child': {
			marginBottom: 0
		},
		'&>.content>.row>.title': {
			width: 160,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.title>span:before': {
			color: '#00cfe2',
			content: '"*"'
		},
		'&>.content>.row>.input-area': {
			width: '100%',
			maxWidth: 382,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.input-area>.hint': {
			color: '#9f9f9f',
			fontSize: '1.2rem'
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 40,
		'& .MuiButton-root': {
			width: 172,
			height: 54
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	},
	datePicker: {
		'& .MuiOutlinedInput-notchedOutline': { display: 'none' },
		'& input': {
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 6,
			width: 185,
			textAlign: 'center',
			padding: '9.5px 0'
		},
		'& .MuiOutlinedInput-root.Mui-focused input': {
			boxShadow: '0 0 0 2px #000 inset'
		},
		'& .MuiButtonBase-root': {
			background: '#eceff4',
			borderRadius: 6,
			border: '1px solid #d9dee5',
			padding: 7
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	}
}));

export default function CreateUserDialog(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');
	const sysConfigure = useSelector(({ docbase }) => docbase.admin.setting.sysConfigure);
	const [formValid, setFormValid] = React.useState({});
	const [openBackdrop, setOpenBackdrop] = React.useState(false);
	const [expireAt, setExpireAt] = React.useState(null);
	const [useExpireAt, setUseExpireAt] = React.useState(false);

	const formRef = React.useRef(null);

	function handleSubmit(values) {
		const validationErrors = {};
		let isValid = true;
		if (!values.userID || values.userID.trim().length <= 0) {
			validationErrors.userID = t('USER_ERROR_MESSAGE_3');
			isValid = false;
		} else if (/[\\/:*?\\"<>|]/.test(values.userID.trim())) {
			validationErrors.userID = t('PATTERN_ERROR_MESSAGE');
			isValid = false;
		}
		if (!values.name || values.name.trim().length <= 0) {
			validationErrors.name = t('USER_ERROR_MESSAGE_4');
			isValid = false;
		}
		if (!values.password || values.password.length <= 0) {
			validationErrors.password = t('USER_ERROR_MESSAGE_5');
			isValid = false;
		} else if (!values.passwordConfirm || values.passwordConfirm.length <= 0) {
			validationErrors.passwordConfirm = t('USER_ERROR_MESSAGE_6');
			isValid = false;
		} else if (values.password !== values.passwordConfirm) {
			validationErrors.passwordConfirm = t('USER_ERROR_MESSAGE_7');
			isValid = false;
		} else {
			validationErrors.passwordConfirm = '';
		}

		if (sysConfigure.OSR_support && values.local_account === '-') {
			dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_20') }));
			isValid = false;
		}

		if (useExpireAt && expireAt === null) {
			dispatch(showErrorMessage({ message: t('EXPIRE_AT_ERROR_MESSAGE_1') }));
			isValid = false;
		}

		setFormValid(validationErrors);
		if (isValid && !openBackdrop) {
			setOpenBackdrop(true);
			addUser(
				values.userID,
				values.password,
				values.name,
				2,
				values.access_level,
				values.email,
				values.local_account ? values.local_account : 'true',
				values.allow_multiple_logins && values.allow_multiple_logins === '1' ? 0 : values.allow_multiple_logins,
				useExpireAt ? commonUtils.getDateString(expireAt) : ''
			)
				.then(data => {
					dispatch(showMessage({ message: t('USER_MESSAGE_1') }));
					dispatch(getUserList());
					props.fnHandleClose();
				})
				.catch(err => {
					if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
						dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_8') }));
					} else if (err.error === commonTypes.SERVER_ERROR_NOT_ENOUGH_USER_LICENSES) {
						dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_10') }));
					} else if (err.error === commonTypes.SERVER_ERROR_INVALID_PRODUCTKEY) {
						dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_13') }));
					}
				})
				.finally(() => {
					setOpenBackdrop(false);
				});
		}
	}

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<Backdrop className={classes.backdrop} open={openBackdrop}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<DialogTitle className={classes.title} id="form-dialog-title">
					{t('USER_ADD')}
				</DialogTitle>
				<Formsy
					name="registerForm"
					className="flex flex-col justify-center w-full"
					onSubmit={values => handleSubmit(values)}
					validationErrors={formValid}
					ref={formRef}
				>
					<DialogContent className={classes.body}>
						<div className="content">
							<div className="row">
								<div className="title">
									<span>{t('ID')}</span>
								</div>
								<div className="input-area">
									<TextFieldFormsy
										className=""
										placeholder={t('ENTER_ID')}
										type="text"
										name="userID"
										variant="outlined"
										validations={{
											idCheck: (values, value) => {
												if (value) {
													if (value.trim().length > 30) {
														setFormValid({ userID: t('USER_ERROR_MESSAGE_9') });
													} else if (/[\\/:*?\\"<>|]/.test(value.trim())) {
														setFormValid({ userID: t('PATTERN_ERROR_MESSAGE') });
													} else {
														setFormValid({ userID: '' });
													}
												} else {
													setFormValid({ userID: '' });
												}
												return true;
											}
										}}
										autoFocus
										required
										fullWidth
										size="small"
									/>
								</div>
							</div>

							<div className="row">
								<div className="title">
									<span>{t('NAME')}</span>
								</div>
								<div className="input-area">
									<TextFieldFormsy
										className=""
										placeholder={t('ENTER_NAME')}
										type="name"
										name="name"
										variant="outlined"
										validations={{
											check: (values, value) => {
												if (value) {
													if (value.trim().length > 30) {
														return t('USER_ERROR_MESSAGE_11');
													}
												}
												return true;
											}
										}}
										required
										fullWidth
										size="small"
									/>
								</div>
							</div>

							<div className="row">
								<div className="title">
									<span>{t('EMAIL')}</span>
								</div>
								<div className="input-area">
									<TextFieldFormsy
										className=""
										placeholder={t('ENTER_EMAIL')}
										type="email"
										name="email"
										variant="outlined"
										fullWidth
										size="small"
									/>
								</div>
							</div>

							<div className="row">
								<div className="title">
									<span>{t('PASSWORD')}</span>
								</div>
								<div className="input-area">
									<TextFieldFormsy
										className=""
										placeholder={t('ENTER_PASSWORD')}
										type="password"
										name="password"
										variant="outlined"
										showError
										required
										fullWidth
										size="small"
									/>
								</div>
							</div>

							<div className="row">
								<div className="title">
									<span>{t('PASSWORD_CONFIRM')}</span>
								</div>
								<div className="input-area">
									<TextFieldFormsy
										className=""
										placeholder={t('ENTER_PASSWORD_CONF')}
										type="password"
										name="passwordConfirm"
										variant="outlined"
										required
										fullWidth
										size="small"
									/>
								</div>
							</div>

							<div className="row">
								<div className="title">
									<span>{t('ACCESS_LEVEL')}</span>
								</div>
								<div className="input-area">
									<SelectFormsy
										className="w-full"
										name="access_level"
										label=""
										value="10000"
										variant="outlined"
										size="small"
									>
										<MenuItem value="10000">-</MenuItem>
										<MenuItem value="1">1</MenuItem>
										<MenuItem value="2">2</MenuItem>
										<MenuItem value="3">3</MenuItem>
										<MenuItem value="4">4</MenuItem>
										<MenuItem value="5">5</MenuItem>
									</SelectFormsy>
								</div>
							</div>

							{sysConfigure.OSR_support ? (
								<div className="row">
									<div className="title">
										<span>{t('LOCAL_ACCOUNT')}</span>
									</div>
									<div className="input-area">
										<SelectFormsy
											className="w-full"
											name="local_account"
											label=""
											value="-"
											variant="outlined"
											size="small"
										>
											<MenuItem value="-">-</MenuItem>
											<MenuItem value="true">{t('YES')}</MenuItem>
											<MenuItem value="false">{t('NO')}</MenuItem>
										</SelectFormsy>
										<p className="hint">{t('LOCAL_ACCOUNT_HELP')}</p>
									</div>
								</div>
							) : (
								<></>
							)}
							{sysConfigure.menu_support_type === 2 ? (
								<></>
							) : (
								<>
									<div className="row">
										<div className="title">
											<span>{t('MULTIPLE_LOGINS')}</span>
										</div>
										<div className="input-area">
											<SelectFormsy
												className="w-full"
												name="allow_multiple_logins"
												label=""
												value="0"
												variant="outlined"
												size="small"
											>
												<MenuItem value={0}>{t('UNUSED')}</MenuItem>
												<MenuItem value="2">2</MenuItem>
												<MenuItem value="3">3</MenuItem>
												<MenuItem value="4">4</MenuItem>
												<MenuItem value="5">5</MenuItem>
											</SelectFormsy>
										</div>
									</div>
									<div className="row">
										<div className="title">
											<span>{t('EXPIRE_AT')}</span>
										</div>
										<div className="input-area flex" style={{ gab: 1, alignItems: 'center' }}>
											<CheckboxFormsy
												name="use-expire"
												value={useExpireAt}
												onChange={e => setUseExpireAt(e.target.checked)}
												style={{ '&>.MuiFormControlLabel-root': { marginLeft: '-5px' } }}
											/>
											<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
												<KeyboardDatePicker
													className={classes.datePicker}
													fullWidth
													disabled={!useExpireAt}
													disableToolbar
													invalidDateMessage=""
													autoOk
													size="small"
													variant="inline"
													inputVariant="outlined"
													format="yyyy/MM/dd"
													id="start_date"
													maxDate={new Date('2999-12-31')}
													value={expireAt}
													onChange={d => {
														// eslint-disable-next-line no-restricted-globals
														if (!isNaN(d.getTime())) {
															if (expireAt === null || expireAt.getTime() !== d.getTime())
																setExpireAt(d);
														}
													}}
													KeyboardButtonProps={{
														'aria-label': 'change date start'
													}}
												/>
											</MuiPickersUtilsProvider>
										</div>
									</div>
								</>
							)}

							<div className={classes.buttonWrapper}>
								<Button
									onClick={props.fnHandleClose}
									className="default"
									variant="contained"
									aria-label="cancel"
									type="button"
								>
									{t('CANCEL')}
								</Button>
								<Button variant="contained" color="secondary" className="" aria-label="Register" type="submit">
									{t('ADD')}
								</Button>
							</div>
						</div>
					</DialogContent>
				</Formsy>
			</Dialog>
		</div>
	);
}
