import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import SetupInfoTable from './SetupInfoTable';
import ContentHeader from '../component/ContentHeader';

function SetupInfoPage() {
	const pageLayout = React.useRef(null);
	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<SetupInfoTable fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()} />}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default SetupInfoPage;
