import StatisticLog from './StatisticLog';

const StatisticLogConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/logs',
			component: StatisticLog
		}
	]
};

export default StatisticLogConfig;
