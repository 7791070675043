import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
	Button,
	Grid,
	Paper,
	Checkbox,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	TextField,
	Tooltip
} from '@material-ui/core';
import CustomIcon from 'app/main/icon/CustomIcon';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		height: '100%',
		padding: 0,
		alignItems: 'center'
	},
	listWrapper: {
		'flex-grow': 0,
		'max-width': 714,
		'flex-basis': '45%',
		margin: 0,
		'box-sizing': 'border-box',
		padding: 0
	},
	paper: {
		height: '100%',
		overflow: 'auto',
		padding: 0,
		borderRadius: 6,
		'&>.title': {
			fontSize: 14,
			color: '#000000',
			textAlign: 'center',
			padding: '15px 0',
			cursor: 'default'
		},
		'&>.insert-wrapper': {
			display: 'flex',
			padding: '20px 30px'
		},
		'&>.insert-wrapper>.input': {
			width: '100%',
			maxWidth: 504,
			marginRight: 10
		}
	},
	parentList: {
		padding: 0,
		'& .icon': {
			minWidth: 16,
			paddingLeft: 0,
			paddingRight: 10
		},
		'& .MuiListItem-root': {
			display: 'flex',
			alignItems: 'center',
			height: 45,
			'& .MuiTypography-body1': {
				fontSize: 13,
				fontFamily: 'S-CoreDream-Regu'
			}
		},
		'& .MuiListItem-button:hover': {
			background: 'rgb(0 0 0 / 40%)'
		}
	},
	orgRow: {
		background: '#353b48',
		borderBottom: '1px solid #8b95a1',
		color: '#ffffff',
		'& .MuiCheckbox-root': {
			color: '#ffffff'
		},
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#ffffff'
		},
		'& .MuiCheckbox-colorSecondary>.MuiIconButton-label': {
			width: 16,
			height: 16,
			background: 'initial'
		}
	},
	userRow: {
		color: '#000000',
		paddingLeft: 40,
		borderBottom: '1px dotted #e0e0e0',
		'& .MuiCheckbox-root': {
			color: '#353b48'
		},
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#353b48'
		},
		'& .MuiCheckbox-colorSecondary>.MuiIconButton-label': {
			width: 16,
			height: 16,
			background: 'initial'
		},
		'&:last-child': {
			borderBottom: 0
		}
	},
	listItemBorder: {
		'border-top': '1px solid #ccc'
	},
	listItemOrgBorder: {
		'border-bottom': '1px dashed #ccc'
	},
	IndentationIcon: { width: '20px', height: '42px', padding: '10px 0px' },
	IndentationWrapper: {
		'min-width': '20px',
		'padding-left': '20px'
	},
	checkedListItem: {
		background: '#c3cdd6',
		border: '1px solid #fff'
	},

	listButtonWrapper: {
		'flex-grow': 0,
		'max-width': 40,
		'flex-basis': '10%',
		margin: '0 40px',
		'box-sizing': 'border-box',
		'& .MuiButton-root': {
			color: '#333d4b',
			minWidth: 40,
			padding: 0,
			width: 40,
			background: '#eceff4',
			border: '1px solid #d9dee5'
		}
	},
	button: {
		marginBottom: 10
	}
}));

function intersection(a, b) {
	return a.filter(value => b.indexOf(value) !== -1);
}

export default function TransferGrid(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');
	const refList = React.useRef();

	const [searchLeftVal, setSearchLeftVal] = React.useState('');
	const [searchRightVal, setSearchRightVal] = React.useState('');
	const [searchLeftTempVal, setSearchLeftTempVal] = React.useState('');
	const [searchRightTempVal, setSearchRightTempVal] = React.useState('');
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const [checkList, setCheckList] = React.useState([]);
	const [checkOrgs, setCheckOrgs] = React.useState([]);

	const [leftOrgObj, setLeftOrgObj] = React.useState({ orderList: [], orgObj: {} });
	const [rightOrgObj, setRightOrgObj] = React.useState({ orderList: [], orgObj: {} });
	window.addEventListener('resize', () => setInnerHeight(window.innerHeight));

	React.useEffect(() => {
		const orderLeftFids = [];
		const orderRightFids = [];
		const leftObj = {};
		const rightObj = {};

		props.leftList.forEach(item => {
			if (item.isOrg) {
				orderLeftFids.push(item.fid);
				leftObj[item.fid] = { open: true };
			}
		});
		props.rightList.forEach(item => {
			if (item.isOrg) {
				orderRightFids.push(item.fid);
				rightObj[item.fid] = { open: true };
			}
		});

		if (props.leftObj.none) {
			leftObj.none = { open: true };
		}
		if (props.rightObj.none) {
			rightObj.none = { open: true };
		}
		setLeftOrgObj({ orderList: orderLeftFids, orgObj: leftObj });
		setRightOrgObj({ orderList: orderRightFids, orgObj: rightObj });
	}, [props.leftObj, props.rightObj]);

	const leftChecked = intersection(
		checkList,
		props.leftList.map(obj => obj.user_id)
	);
	const rightChecked = intersection(
		checkList,
		props.rightList.map(obj => obj.user_id)
	);

	const initChecklist = () => {
		setCheckList([]);
		setCheckOrgs([]);
	};

	const searchLeftOrgs = [];
	const searchRightOrgs = [];
	const searchLeftObj = {};
	const searchRightObj = {};

	const drawSearchList = (obj, isLeft) => {
		const items = isLeft ? obj.leftList : obj.rightList;

		return (
			<>
				{items.map((item, idx) => {
					if (isLeft) {
						if (searchLeftVal !== '') {
							if (
								(item.user_id.indexOf(searchLeftVal) === -1 && item.name.indexOf(searchLeftVal) === -1) ||
								searchLeftObj[item.user_id]
							) {
								const searchIndex = searchLeftOrgs.indexOf(item.fid);
								if (searchIndex === -1) {
									if (item.isOrg) {
										searchLeftOrgs.splice(searchIndex, 1);
									}
									return '';
								}
							}
							searchLeftObj[item.user_id] = true;
							if (item.isOrg) {
								searchLeftOrgs.push(item.fid);
							}
						}
					} else if (searchRightVal !== '') {
						if (
							(item.user_id.indexOf(searchRightVal) === -1 && item.name.indexOf(searchRightVal) === -1) ||
							searchRightObj[item.user_id]
						) {
							const searchIndex = searchRightOrgs.indexOf(item.fid);
							if (searchIndex === -1) {
								if (item.isOrg) {
									searchRightOrgs.splice(searchIndex, 1);
								}
								return '';
							}
						}
						searchRightObj[item.user_id] = true;
						if (item.isOrg) {
							searchRightOrgs.push(item.fid);
						}
					}

					const labelId = `transfer-list-item-${idx}-label`;
					if (item.isOrg) {
						return (
							<ListItem
								className={clsx(classes.orgRow)}
								role="listitem"
								button
								onClick={e => {
									const temps = [...checkList];
									if (item.isOrg) {
										const curIndex = checkOrgs.findIndex(
											el => el.fid === item.fid && el.position === item.position
										);

										if (curIndex === -1) {
											setCheckOrgs([...checkOrgs, { fid: item.fid, position: item.position }]);
											items.forEach(i => {
												if (
													item.fid === i.fid &&
													i.user_id !== '' &&
													checkList.indexOf(i.user_id) === -1
												) {
													temps.push(i.user_id);
												}
											});
											setCheckList(temps);
										} else {
											let index;
											items.forEach(i => {
												if (item.fid === i.fid && i.user_id !== '') {
													index = temps.indexOf(i.user_id);
													if (index !== -1) {
														temps.splice(index, 1);
													}
												}
											});
											setCheckList(temps);
											const tempOrgs = [...checkOrgs];
											tempOrgs.splice(curIndex, 1);
											setCheckOrgs(tempOrgs);
										}
									} else {
										const curIndex = checkList.indexOf(item.user_id);

										if (curIndex === -1) {
											setCheckList([...checkList, item.user_id]);
										} else {
											temps.splice(curIndex, 1);
											setCheckList(temps);
										}
									}
								}}
							>
								<Checkbox
									edge="start"
									checked={
										checkOrgs.findIndex(el => el.fid === item.fid && el.position === item.position) !== -1
									}
									tabIndex={-1}
									disableRipple
									onClick={e => {
										e.stopPropagation();
										const temps = [...checkList];
										if (item.isOrg) {
											const curIndex = checkOrgs.findIndex(
												el => el.fid === item.fid && el.position === item.position
											);

											if (curIndex === -1) {
												setCheckOrgs([...checkOrgs, { fid: item.fid, position: item.position }]);
												items.forEach(i => {
													if (
														item.fid === i.fid &&
														i.user_id !== '' &&
														checkList.indexOf(i.user_id) === -1
													) {
														temps.push(i.user_id);
													}
												});
												setCheckList(temps);
											} else {
												let index;
												items.forEach(i => {
													if (item.fid === i.fid && i.user_id !== '') {
														index = temps.indexOf(i.user_id);
														if (index !== -1) {
															temps.splice(index, 1);
														}
													}
												});
												setCheckList(temps);
												const tempOrgs = [...checkOrgs];
												tempOrgs.splice(curIndex, 1);
												setCheckOrgs(tempOrgs);
											}
										} else {
											const curIndex = checkList.indexOf(item.user_id);

											if (curIndex === -1) {
												setCheckList([...checkList, item.user_id]);
											} else {
												temps.splice(curIndex, 1);
												setCheckList(temps);
											}
										}
									}}
								/>
								<ListItemIcon className="icon">
									<CustomIcon className="small" type="org-folder" fill="#ffffff" />
								</ListItemIcon>
								<ListItemText
									id={labelId}
									primary={`${item.name.substring(item.name.substring(1).indexOf('/') + 1)}`}
								/>
							</ListItem>
						);
					}
					return (
						<ListItem
							className={clsx(classes.userRow)}
							role="listitem"
							button
							onClick={e => {
								const temps = [...checkList];
								const curIndex = checkList.indexOf(item.user_id);

								if (curIndex === -1) {
									setCheckList([...checkList, item.user_id]);
								} else {
									temps.splice(curIndex, 1);
									setCheckList(temps);
								}
							}}
						>
							<Checkbox
								edge="start"
								checked={checkList.indexOf(item.user_id) !== -1}
								tabIndex={-1}
								disableRipple
								onClick={e => {
									const temps = [...checkList];
									const curIndex = checkList.indexOf(item.user_id);

									if (curIndex === -1) {
										setCheckList([...checkList, item.user_id]);
									} else {
										temps.splice(curIndex, 1);
										setCheckList(temps);
									}
								}}
							/>
							<ListItemIcon className="icon">
								<CustomIcon className="small" type="person" fill="#333d4b" />
							</ListItemIcon>
							<ListItemText id={labelId} primary={`${item.name}`} />
						</ListItem>
					);
				})}
			</>
		);
	};

	const drawList = isLeft => {
		const orderFids = isLeft ? leftOrgObj.orderList : rightOrgObj.orderList;
		const orgObj = isLeft ? leftOrgObj.orgObj : rightOrgObj.orgObj;
		const itemObj = isLeft ? props.leftObj : props.rightObj;

		let orgRows = <></>;
		let noneRows = <></>;
		if (orderFids.length > 0) {
			orgRows = orderFids.map(fid => {
				if (itemObj[fid]) {
					return (
						<>
							<ListItem
								className={clsx(classes.orgRow)}
								role="listitem"
								button
								onClick={e => {
									if (isLeft) {
										setLeftOrgObj({
											...leftOrgObj,
											orgObj: { ...leftOrgObj.orgObj, [fid]: { open: !leftOrgObj.orgObj[fid].open } }
										});
									} else {
										setRightOrgObj({
											...rightOrgObj,
											orgObj: { ...rightOrgObj.orgObj, [fid]: { open: !rightOrgObj.orgObj[fid].open } }
										});
									}
								}}
							>
								<Checkbox
									edge="start"
									checked={
										checkOrgs.findIndex(el => el.fid === fid && el.position === itemObj[fid][0].position) !==
										-1
									}
									tabIndex={-1}
									disableRipple
									onClick={e => {
										e.stopPropagation();
										const temps = [...checkList];
										const curIndex = checkOrgs.findIndex(
											el => el.fid === fid && el.position === itemObj[fid][0].position
										);

										if (curIndex === -1) {
											setCheckOrgs([...checkOrgs, { fid, position: itemObj[fid][0].position }]);
											itemObj[fid].forEach((i, idx) => {
												if (
													idx > 0 &&
													fid === i.fid &&
													i.user_id !== '' &&
													checkList.indexOf(i.user_id) === -1
												) {
													temps.push(i.user_id);
												}
											});
											setCheckList(temps);
										} else {
											let index;
											itemObj[fid].forEach((i, idx) => {
												if (idx > 0 && fid === i.fid && i.user_id !== '') {
													index = temps.indexOf(i.user_id);
													if (index !== -1) {
														temps.splice(index, 1);
													}
												}
											});
											setCheckList(temps);
											const tempOrgs = [...checkOrgs];
											tempOrgs.splice(curIndex, 1);
											setCheckOrgs(tempOrgs);
										}
									}}
								/>
								<ListItemIcon className="icon">
									<CustomIcon className="small" type="org-folder" fill="#ffffff" />
								</ListItemIcon>
								<ListItemText
									id={itemObj[fid][0].fid}
									primary={`${itemObj[fid][0].name.substring(
										itemObj[fid][0].name.substring(1).indexOf('/') + 1
									)}`}
								/>
							</ListItem>

							<Collapse in={orgObj[fid].open} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									{itemObj[fid].map((item, idx) => {
										if (idx === 0) {
											return <></>;
										}

										return (
											<ListItem
												className={clsx(classes.userRow)}
												role="listitem"
												button
												onClick={e => {
													const temps = [...checkList];
													const curIndex = checkList.indexOf(item.user_id);

													if (curIndex === -1) {
														setCheckList([...checkList, item.user_id]);
													} else {
														temps.splice(curIndex, 1);
														setCheckList(temps);
													}
												}}
											>
												<Checkbox
													edge="start"
													checked={checkList.indexOf(item.user_id) !== -1}
													tabIndex={-1}
													disableRipple
													onClick={e => {
														const temps = [...checkList];
														const curIndex = checkList.indexOf(item.user_id);

														if (curIndex === -1) {
															setCheckList([...checkList, item.user_id]);
														} else {
															temps.splice(curIndex, 1);
															setCheckList(temps);
														}
													}}
												/>
												<ListItemIcon className="icon">
													<CustomIcon className="small" type="person" fill="#333d4b" />
												</ListItemIcon>
												<ListItemText id={item.fid} primary={`${item.name}`} />
											</ListItem>
										);
									})}
								</List>
							</Collapse>
						</>
					);
				}
				return <></>;
			});
		}

		if (orgObj.none !== undefined && itemObj.none.length > 0) {
			noneRows = (
				<>
					<ListItem
						className={clsx(classes.orgRow)}
						role="listitem"
						button
						onClick={e => {
							if (isLeft) {
								setLeftOrgObj({
									...leftOrgObj,
									orgObj: { ...leftOrgObj.orgObj, none: { open: !leftOrgObj.orgObj.none.open } }
								});
							} else {
								setRightOrgObj({
									...rightOrgObj,
									orgObj: { ...rightOrgObj.orgObj, none: { open: !rightOrgObj.orgObj.none.open } }
								});
							}
						}}
					>
						<Checkbox
							edge="start"
							checked={
								checkOrgs.findIndex(el => el.fid === 'none' && el.position === (isLeft ? 'left' : 'right')) !== -1
							}
							tabIndex={-1}
							disableRipple
							onClick={e => {
								e.stopPropagation();
								const temps = [...checkList];
								const curIndex = checkOrgs.findIndex(el =>
									el.fid === 'none' && el.position === isLeft ? 'left' : 'right'
								);

								if (curIndex === -1) {
									setCheckOrgs([...checkOrgs, { fid: 'none', position: isLeft ? 'left' : 'right' }]);
									itemObj.none.forEach(i => {
										if (i.user_id !== '' && checkList.indexOf(i.user_id) === -1) {
											temps.push(i.user_id);
										}
									});
									setCheckList(temps);
								} else {
									let index;
									itemObj.none.forEach(i => {
										if (i.user_id !== '') {
											index = temps.indexOf(i.user_id);
											if (index !== -1) {
												temps.splice(index, 1);
											}
										}
									});
									setCheckList(temps);
									const tempOrgs = [...checkOrgs];
									tempOrgs.splice(curIndex, 1);
									setCheckOrgs(tempOrgs);
								}
							}}
						/>
						<ListItemIcon className="icon">
							<CustomIcon className="small" type="org-folder" fill="#ffffff" />
						</ListItemIcon>
						<ListItemText id="none" primary="무소속" />
					</ListItem>

					<Collapse in={orgObj.none.open} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							{itemObj.none.map((item, idx) => {
								return (
									<ListItem
										className={clsx(classes.userRow)}
										role="listitem"
										button
										onClick={e => {
											const temps = [...checkList];
											const curIndex = checkList.indexOf(item.user_id);

											if (curIndex === -1) {
												setCheckList([...checkList, item.user_id]);
											} else {
												temps.splice(curIndex, 1);
												setCheckList(temps);
											}
										}}
									>
										<Checkbox
											edge="start"
											checked={checkList.indexOf(item.user_id) !== -1}
											tabIndex={-1}
											disableRipple
											onClick={e => {
												e.stopPropagation();
												const temps = [...checkList];
												const curIndex = checkList.indexOf(item.user_id);

												if (curIndex === -1) {
													setCheckList([...checkList, item.user_id]);
												} else {
													temps.splice(curIndex, 1);
													setCheckList(temps);
												}
											}}
										/>
										<ListItemIcon className="icon">
											<CustomIcon className="small" type="person" fill="#333d4b" />
										</ListItemIcon>
										<ListItemText
											id={item.fid}
											primary={`${
												item.isOrg
													? item.name.substring(item.name.substring(1).indexOf('/') + 1)
													: item.name
											}`}
										/>
									</ListItem>
								);
							})}
						</List>
					</Collapse>
				</>
			);
		}
		return (
			<>
				{orgRows}
				{noneRows}
			</>
		);
	};

	const customList = (propsObj, isLeft) => {
		let title = '';
		switch (props.tabType) {
			case 0:
				title = isLeft ? t('COLLECT_USED') : t('COLLECT_NOT_USE');
				break;
			case 1:
				title = isLeft ? t('COLLECT_USED_REMOVE_SOURCE') : t('COLLECT_NOT_USED_REMOVE_SOURCE');
				break;
			case 2:
				title = isLeft ? t('COLLECT_USED_FULL_SCAN') : t('COLLECT_NOT_USED_FULL_SCAN');
				break;
			default:
				title = '';
		}

		return (
			<Paper className={classes.paper}>
				<div className="title">{title}</div>
				<Divider />
				<div className="insert-wrapper">
					<div className="input">
						<TextField
							type="text"
							placeholder={t('SEARCH_KEYWORD')}
							name="search-val"
							value={isLeft ? searchLeftTempVal : searchRightTempVal}
							variant="outlined"
							size="small"
							onChange={e =>
								isLeft ? setSearchLeftTempVal(e.target.value) : setSearchRightTempVal(e.target.value)
							}
							fullWidth
						/>
					</div>
					<div>
						<Button
							variant="contained"
							color="secondary"
							className=""
							aria-label="ignore-add"
							size="small"
							type="button"
							onClick={e => {
								if (isLeft) {
									setSearchLeftVal(searchLeftTempVal);
								} else {
									setSearchRightVal(searchRightTempVal);
								}
							}}
						>
							{t('SEARCH')}
						</Button>
					</div>
				</div>
				<List
					className={classes.parentList}
					component="div"
					role="list"
					ref={refList}
					style={{ height: `${innerHeight - 318}px`, 'overflow-y': 'auto' }}
				>
					{(isLeft && searchLeftVal.trim().length > 0) || (!isLeft && searchRightVal.trim().length > 0)
						? drawSearchList(propsObj, isLeft)
						: drawList(isLeft)}
				</List>
			</Paper>
		);
	};

	return (
		<div className={classes.root}>
			<div className={classes.listWrapper}>{customList(props, true)}</div>
			<div className={classes.listButtonWrapper}>
				<Grid container direction="column" alignItems="center">
					<Tooltip title={t('COLLECT_ALL_UNAPPLIED')} arrow placement="top">
						<Button
							variant="outlined"
							size="small"
							className={classes.button}
							onClick={() => {
								props.fnDoCollectPolicy(
									props.leftList.map(obj => obj.user_id),
									false
								);
								initChecklist();
							}}
							disabled={props.leftList.findIndex(el => !el.isOrg) === -1}
							aria-label="move all right"
						>
							≫
						</Button>
					</Tooltip>
					<Tooltip title={t('UNAPPLIED')} arrow placement="left">
						<Button
							variant="outlined"
							size="small"
							className={classes.button}
							onClick={e => {
								props.fnDoCollectPolicy(leftChecked, false);
								initChecklist();
							}}
							disabled={leftChecked.length === 0}
							aria-label="move selected right"
						>
							&gt;
						</Button>
					</Tooltip>
					<Tooltip title={t('APPLY')} arrow placement="right">
						<Button
							variant="outlined"
							size="small"
							className={classes.button}
							onClick={e => {
								props.fnDoCollectPolicy(rightChecked, true);
								initChecklist();
							}}
							disabled={rightChecked.length === 0}
							aria-label="move selected left"
						>
							&lt;
						</Button>
					</Tooltip>
					<Tooltip title={t('COLLECT_ALL_APPLY')} arrow placement="bottom">
						<Button
							variant="outlined"
							size="small"
							className={classes.button}
							onClick={e => {
								props.fnDoCollectPolicy(
									props.rightList.map(obj => obj.user_id),
									true
								);
								initChecklist();
							}}
							disabled={props.rightList.findIndex(el => !el.isOrg) === -1}
							aria-label="move all left"
						>
							≪
						</Button>
					</Tooltip>
				</Grid>
			</div>
			<div className={classes.listWrapper}>{customList(props, false)}</div>
		</div>
	);
}
