import React from 'react';
import { debounce } from 'lodash';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import _ from '@lodash';
import clsx from 'clsx';
import commonUtils from 'app/utils/CommonUtils';
import FuseUtils from '@fuse/utils';
import {
	Button,
	Paper,
	Input,
	Tooltip,
	IconButton,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel
} from '@material-ui/core';
import CustomIcon from 'app/main/icon/CustomIcon';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .MuiButton-root': {
			width: 80
		},
		'& .datetime': {
			width: 125
		}
	},
	contentWrapper: {
		height: `${window.innerHeight - 274}px`
	}
}));

export default function FullScanComponent(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');
	const refList = React.useRef();
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const [order, setOrder] = React.useState({
		id: 'id',
		direction: 'asc'
	});
	const [data, setData] = React.useState([]);
	const [searchText, setSearchText] = React.useState('');

	React.useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 274}px`;
		}

		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {
		filterSearch(searchText);
	}, [props.userPolicyInfos, searchText]);

	const filterSearch = React.useCallback(
		debounce(val => {
			setData(FuseUtils.filterUsersByString(props.userPolicyInfos, val));
			console.log(val);
		}, 500),
		[props.userPolicyInfos]
	);

	return (
		<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
			<div className="table-search-wrapper">
				<div className="">
					<div className="input-item">
						<Input
							placeholder={t('SEARCH')}
							className="search-input"
							disableUnderline
							fullWidth
							value={searchText}
							inputProps={{
								'aria-label': 'Search'
							}}
							onChange={ev => setSearchText(ev.target.value)}
						/>
					</div>
				</div>
				<div className="total-cnt-info">
					<span>
						{/* {t('TOTAL')} <span className="count">{0}</span>
						{t('CNT')} */}
					</span>
				</div>
				<div className="search-form-wrapper">
					<div className="">
						<Tooltip title={t('REFRESH')} placement="bottom">
							<IconButton key="refresh" aria-label="refresh" color="secondary" onClick={() => props.fnDoSearch()}>
								<CustomIcon className="small" type="refresh" fill="#333d4b" />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</div>
			<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
				<Table stickyHeader className="" aria-labelledby="tableTitle">
					<TableHead>
						<TableRow className={classes.tr}>
							<TableCell className="left" align="left" padding="default" sortDirection>
								<TableSortLabel
									active
									direction={order.direction}
									onClick={e => {
										let direction = 'desc';
										if (order.direction === 'desc') {
											direction = 'asc';
										}

										setOrder({
											direction,
											id: 'id'
										});
									}}
								>
									{t('USER')}
								</TableSortLabel>
							</TableCell>
							<TableCell className="" align="center" padding="default" sortDirection={false}>
								{t('COLLECT_FULL_SCAN_STATUS')}
							</TableCell>
							<TableCell className="" align="center" padding="default" sortDirection={false}>
								{t('COLLECT_FULL_SCAN_RESTART')}
							</TableCell>
							<TableCell className="" align="center" padding="default" sortDirection={false}>
								{t('COLLECT_FULL_SCAN_PRESCANNED_FOLDERS')}
							</TableCell>
							<TableCell className="" align="center" padding="default" sortDirection={false}>
								{t('COLLECT_FULL_SCAN_PENDING_FOLDERS')}
							</TableCell>
							<TableCell className="" align="center" padding="default" sortDirection={false}>
								{t('COLLECT_FULL_SCAN_PENDING_FILES')}
							</TableCell>
							<TableCell className="datetime" align="center" padding="default" sortDirection={false}>
								{t('COLLECT_FULL_SCAN_REQUEST_AT')}
							</TableCell>
							<TableCell className="datetime" align="center" padding="default" sortDirection={false}>
								{t('COLLECT_FULL_PRESCAN_FINISHED_AT')}
							</TableCell>
							<TableCell className="datetime" align="center" padding="default" sortDirection={false}>
								{t('COLLECT_FULL_SCAN_FINISHED_AT')}
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{_.orderBy(
							data,
							[
								d => {
									console.log(d);
									switch (order.id) {
										case 'id': {
											return d.name;
										}
										default: {
											return d[order.id];
										}
									}
								}
							],
							[order.direction]
						).map((d, idx) => {
							// let status = t('COLLECT_FULL_SCAN_STATUS_NONE');
							let status = '-';
							let resetButton = <></>;
							if (d.full_scan_status === 1) {
								status = t('COLLECT_FULL_SCAN_STATUS_STARTED'); // 시작
							} else if (d.full_scan_status === 2) {
								status = t('COLLECT_FULL_SCAN_STATUS_PRESCANED'); // 프리스캔 완료(수집중)
							} else if (d.full_scan_status === 3) {
								if (
									d.full_scan_requested_at !== '' &&
									d.full_scan_finished_at !== '' &&
									commonUtils.getDateStringToDate(d.full_scan_requested_at).getTime() >
										commonUtils.getDateStringToDate(d.full_scan_finished_at).getTime()
								) {
									status = t('요청중'); // 요청중
								} else {
									status = t('COLLECT_FULL_SCAN_STATUS_FINISHED'); // 수집완료
									resetButton = (
										<Button
											className="default"
											variant="contained"
											color=""
											onClick={e => {
												props.fnRestartFullScan(d);
											}}
										>
											{t('COLLECT_FULL_SCAN_RESTART')}
										</Button>
									);
								}
							}
							return (
								<TableRow
									className={classes.tr}
									hover
									role="checkbox"
									tabIndex={-1}
									key={d.user_id}
									onClick={event => {}}
								>
									<TableCell className="left" align="left" padding="default">
										{`${d.name}(${d.user_id})`}
									</TableCell>
									<TableCell className="" align="center" padding="default">
										{d.full_scan ? status : '-'}
									</TableCell>
									<TableCell className="" align="center" padding="default">
										{resetButton}
									</TableCell>
									<TableCell className="" align="center" padding="default">
										{d.full_scan_status >= 1 ? `${d.prescanned_folders}` : '-'}
									</TableCell>
									<TableCell className="" align="center" padding="default">
										{d.full_scan_status >= 1 ? `${d.pending_folders}` : '-'}
									</TableCell>
									<TableCell className="" align="center" padding="default">
										{d.full_scan_status >= 1 ? `${d.pending_files}` : '-'}
									</TableCell>
									<TableCell className="" align="center" padding="default">
										{commonUtils.getDateStringToLocalDate(d.full_scan_requested_at)}
									</TableCell>
									<TableCell className="" align="center" padding="default">
										{d.full_scan_status >= 2
											? commonUtils.getDateStringToLocalDate(d.full_prescan_finished_at)
											: '-'}
									</TableCell>
									<TableCell className="" align="center" padding="default">
										{d.full_scan_status >= 2
											? commonUtils.getDateStringToLocalDate(d.full_scan_finished_at)
											: '-'}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</FuseScrollbars>
		</Paper>
	);
}
