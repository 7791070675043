import { makeStyles } from '@material-ui/core/styles';
import {
	Icon,
	TextField,
	Card,
	CardActions,
	CardContent,
	IconButton,
	Tooltip,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import React from 'react';
import FileIcon from 'app/main/file-manager/FileIcon';
import commonUtils from 'app/utils/CommonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as commonTypes from 'app/main/constants/CommonTypes';
import securityUtils from 'app/utils/SecurityUtils';
import { getArchiveCopy } from 'app/store/docbase/wc/fileSlice';
import CustomIcon from 'app/main/icon/CustomIcon';

const useStyles = makeStyles(theme => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	cardInfo: {
		padding: '40px 30px 20px',
		'& .MuiCardActions-root': {
			padding: 0
		},
		'& .MuiCardContent-root': {
			padding: 0
		}
	},
	paperPerm: { marginTop: 30, padding: '20px 30px 0' },
	paperPermContent: { padding: 0, marginTop: 20 },
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 45,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277
	},
	filedInput: { width: '100%', padding: '0 30px', display: 'flex', alignItems: 'center' },
	icon: {
		marginRight: 10
	},
	downloadCell: {
		width: 50
	}
}));

function DetailSidebarContent(props) {
	const classes = useStyles();
	const permList = React.useRef();
	const { t } = useTranslation('docbase');

	const { key } = useSelector(({ docbase }) => docbase.admin.system.takeoutsObj);
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	React.useEffect(() => {
		if (innerHeight >= 754) {
			permList.current.style.height = `${innerHeight - 140}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {}, []);

	let reqType = '';
	switch (props.selectedObj.request_type) {
		case commonTypes.TAKEOUT_REQUEST_TYPE_DOC:
			reqType = t('TAKEOUT_DOC');
			break;
		case commonTypes.TAKEOUT_REQUEST_TYPE_PRINT:
			reqType = t('TAKEOUT_PRINT');
			break;
		case commonTypes.TAKEOUT_REQUEST_TYPE_WEBLINK:
			reqType = t('TAKEOUT_WEBLINK');
			break;
		default:
	}
	let statusTxt = '';
	switch (props.selectedObj.status) {
		case commonTypes.TAKEOUT_REQUEST_STATUS_APPROVED:
			statusTxt = t('TAKEOUT_APPROVED');
			break;
		case commonTypes.TAKEOUT_REQUEST_STATUS_APPROVED_AUTO:
			statusTxt = t('TAKEOUT_APPROVED_AUTO');
			break;
		case commonTypes.TAKEOUT_REQUEST_STATUS_REJECTED:
			statusTxt = t('TAKEOUT_REJECTED');
			break;
		case commonTypes.TAKEOUT_REQUEST_STATUS_WITHDRAWN:
			statusTxt = t('TAKEOUT_WITHDRAWN');
			break;
		default:
	}
	return (
		<div className="" ref={permList}>
			<Card className={classes.cardInfo}>
				<CardActions className={clsx(classes.card_button_group, 'md:')} disableSpacing>
					<IconButton
						className="md:hidden"
						aria-controls="detail-back"
						onClick={e => {
							props.fnToggleRightSidebar();
						}}
					>
						<Icon>arrow_back</Icon>
					</IconButton>
				</CardActions>

				<CardContent className={classes.infoFiled}>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_REQUESTER')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="requester"
								fullWidth
								value={
									props.selectedObj.requester_name !== ''
										? `${props.selectedObj.requester_name}(${props.selectedObj.requester})`
										: ''
								}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_APPROVER')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="approver"
								fullWidth
								value={
									props.selectedObj.approver_name !== ''
										? `${props.selectedObj.approver_name}(${props.selectedObj.approver})`
										: ''
								}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_REQ_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="requested_at"
								fullWidth
								value={commonUtils.getDateStringToLocalDate(props.selectedObj.requested_at)}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_APP_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="approved_at"
								fullWidth
								value={commonUtils.getDateStringToLocalDate(props.selectedObj.approved_at)}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_REQ_TYPE')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="req_type"
								fullWidth
								value={reqType}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_STATUS')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							{/* <TextField
								select
								className=""
								variant="outlined"
								value={props.selectedObj.status}
								name="status"
								fullWidth
								inputProps={{ readOnly: true }}
							>
								<MenuItem value={commonTypes.TAKEOUT_REQUEST_STATUS_APPROVED}>{t('TAKEOUT_APPROVED')}</MenuItem>
								<MenuItem value={commonTypes.TAKEOUT_REQUEST_STATUS_APPROVED_AUTO}>
									{t('TAKEOUT_APPROVED_AUTO')}
								</MenuItem>
								<MenuItem value={commonTypes.TAKEOUT_REQUEST_STATUS_REJECTED}>{t('TAKEOUT_REJECTED')}</MenuItem>
								<MenuItem value={commonTypes.TAKEOUT_REQUEST_STATUS_WITHDRAWN}>{t('TAKEOUT_WITHDRAWN')}</MenuItem>
							</TextField> */}
							<TextField
								className=""
								variant="outlined"
								value={statusTxt}
								name="status"
								fullWidth
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_REASON_REQ')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="reason_req"
								fullWidth
								value={props.selectedObj.reason_requested}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_REASON_REJ')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="reason_rej"
								fullWidth
								value={props.selectedObj.reason_rejected}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
				</CardContent>
			</Card>

			<Card className={classes.paperPerm}>
				<CardContent className={classes.paperPermContent}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								<TableCell padding="none" align="left" className="left title">
									{t('FILE_NAME')}
								</TableCell>
								<TableCell padding="none" align="left" className={classes.downloadCell} />
							</TableRow>
						</TableHead>
						<TableBody>
							{props.selectedObj.requested_files && props.selectedObj.requested_files.length > 0 ? (
								props.selectedObj.requested_files.map((file, idx) => {
									const fileName = securityUtils
										.decryptAES(key, file.path, 'hex', 'utf8')
										.replace('[_ORG_]', '');
									return (
										<TableRow className="cursor-pointer" hover tabIndex={-1}>
											<TableCell className="left" align="left" component="td" scope="row">
												<div className="flex">
													<span className={classes.icon}>
														<FileIcon targetName={fileName} secureDocInfo={false} />
													</span>
													<span>{fileName}</span>
												</div>
											</TableCell>
											<TableCell className="" align="center" component="td" scope="row">
												<Tooltip arrow title="클릭시 다운로드">
													<IconButton
														onClick={() => {
															getArchiveCopy(props.selectedObj.toid, file.seq, file.archive_id);
														}}
													>
														<CustomIcon className="small" type="download" fill="#333d4b" />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									);
								})
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</div>
	);
}

export default DetailSidebarContent;
