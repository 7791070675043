import { makeStyles } from '@material-ui/core/styles';
import {
	Icon,
	TextField,
	Select,
	Card,
	CardActions,
	CardContent,
	Typography,
	IconButton,
	Button,
	Tooltip,
	MenuItem,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

import commonUtils from 'app/utils/CommonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { updSecureDoc, searchSecureDocs } from 'app/store/docbase/admin/settingSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import PermIcon from '../icon/PermIcon';

const useStyles = makeStyles(theme => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	cardInfo: {
		padding: '40px 30px 20px',
		'& .MuiCardActions-root': {
			padding: 0
		},
		'& .MuiCardContent-root': {
			padding: 0
		}
	},
	card_userid: {
		'margin-left': '10px',
		height: '48px',
		'line-height': '48px'
	},
	paperPerm: { marginTop: 30, padding: '20px 30px 0' },
	paperPermHeader: {
		padding: 0,
		display: 'flex',
		width: '100%',
		'&>div:first-child': {
			width: '100%',
			maxWidth: 534,
			marginRight: 10
		},
		'&>div:first-child .MuiAutocomplete-inputRoot': {
			height: 40
		},
		'&>div:last-child>button': {
			width: 140
		}
	},
	paperPermContent: { padding: 0, marginTop: 20, '& .delete': { width: 100 } },
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 45,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277
	},
	filedInput: { width: '100%', padding: '0 30px', display: 'flex', alignItems: 'center' },
	customInput: {
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 4,
		'&>input': {
			padding: '10.5px 14px'
		},
		'&.Mui-focused': {
			border: '2px solid rgba(0, 0, 0, 1)'
		},
		'&:hover': {
			border: '1px solid rgba(0, 0, 0, 1)'
		}
	}
}));

function DetailSidebarContent(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const permList = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const [permAutoInputVal, setPermAutoInputVal] = React.useState('');

	const { selectedDocObj, currentPage } = useSelector(({ docbase }) => docbase.admin.setting.secureDocObj);
	const { users, usersObj } = useSelector(({ docbase }) => docbase.admin.account);

	const [selectedAutocomplet, setSelectedAutocomplte] = React.useState(null);
	const [autoOptions, setAutoOptions] = React.useState(users);
	const [docLevel, setDocLevel] = React.useState(selectedDocObj.level);

	React.useEffect(() => {
		if (innerHeight >= 754) {
			permList.current.style.height = `${innerHeight - 304}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {
		if (selectedDocObj.doc_id > 0) {
			const secureDocPerm = {};
			selectedDocObj.users.forEach(docUser => {
				secureDocPerm[docUser.user_id] = true;
			});
			setAutoOptions(users.filter(u => !secureDocPerm[u.user_id]));
			setDocLevel(selectedDocObj.level);
		}
	}, [selectedDocObj]);

	function changeSecureDoc(name, value) {
		updSecureDoc(selectedDocObj, name, value)
			.then(data => {
				let msg = t('SECUREDOC_MESSAGE_1');
				if (name === 'level') {
					msg = t('SECUREDOC_MESSAGE_2');
				}

				dispatch(showMessage({ message: msg }));
				props.setLoading(true);
				dispatch(searchSecureDocs({ page: currentPage })).then(d => {
					setDocLevel(value);
					props.setLoading(false);
				});
			})
			.catch(err => {
				dispatch(showErrorMessage({ message: t('SECUREDOC_ERROR_MESSAGE_1') }));
			});
	}

	function addSecureDocUser() {
		changeSecureDoc('users', [...selectedDocObj.users, { user_id: selectedAutocomplet.user_id }]);
	}

	function removeSecureDocUser(userID) {
		changeSecureDoc(
			'users',
			selectedDocObj.users.filter(u => u.user_id !== userID)
		);
	}

	return (
		<div className="" ref={permList}>
			<Card className={classes.cardInfo}>
				<CardActions className={clsx(classes.card_button_group, 'md:')} disableSpacing>
					<IconButton
						className="md:hidden"
						aria-controls="detail-back"
						onClick={e => {
							props.fnToggleRightSidebar();
						}}
					>
						<Icon>arrow_back</Icon>
					</IconButton>
					<Tooltip title={selectedDocObj.path} arrow>
						<Typography className={classes.card_userid} variant="h6">
							<span>{t('DETAIL_INFO')}</span>
							<span>{`[${commonUtils.pathToName(selectedDocObj.path)}]`}</span>
						</Typography>
					</Tooltip>
				</CardActions>
				<CardContent className={classes.infoFiled}>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('ACCESS_LEVEL')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<Select
								id="access_level"
								variant="outlined"
								className=""
								fullWidth
								value={Number(docLevel)}
								onChange={e => changeSecureDoc('level', e.target.value)}
							>
								<MenuItem value={10000}>-</MenuItem>
								<MenuItem value={1}>1</MenuItem>
								<MenuItem value={2}>2</MenuItem>
								<MenuItem value={3}>3</MenuItem>
								<MenuItem value={4}>4</MenuItem>
								<MenuItem value={5}>5</MenuItem>
							</Select>
						</div>
					</div>
				</CardContent>
			</Card>

			<Card className={classes.paperPerm}>
				<CardContent className={classes.paperPermHeader}>
					<div>
						<Autocomplete
							id="autocomplete-permission-search"
							options={autoOptions}
							getOptionLabel={option => (option ? `${option.name}(${option.user_id})` : '')}
							noOptionsText={t('PERM_NO_USER')}
							autoComplete
							includeInputInList
							value=""
							onChange={(e, newVal) => {
								if (newVal) {
									setSelectedAutocomplte(newVal);
								} else {
									setSelectedAutocomplte(null);
								}
							}}
							inputValue={permAutoInputVal}
							onInputChange={(e, newInputVal) => {
								setPermAutoInputVal(newInputVal);
							}}
							renderInput={params => (
								<TextField
									{...params}
									label={t('PERM_USER_INPUT')}
									variant="outlined"
									onKeyDown={e => {
										if (selectedAutocomplet && selectedAutocomplet !== null && e.keyCode === 13) {
											addSecureDocUser();
										}
									}}
								/>
							)}
							fullWidth
							size="small"
						/>
					</div>
					<div>
						<Button
							variant="contained"
							color="secondary"
							className=""
							type="button"
							fullWidth
							onClick={e => addSecureDocUser()}
						>
							{t('ADD')}
						</Button>
					</div>
				</CardContent>

				<CardContent className={classes.paperPermContent}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								<TableCell padding="none" align="left" className="left title">
									{t('USER')}
								</TableCell>
								<TableCell padding="none" align="center" className="delete">
									{t('PERM_DELETE')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{selectedDocObj.users && selectedDocObj.users.length > 0 ? (
								selectedDocObj.users.map((u, idx) => {
									return (
										<TableRow
											className="cursor-pointer"
											hover
											role="checkbox"
											tabIndex={-1}
											// key={d.user_id}
											// onClick={event => handleCheck(event, d.user_id)}
										>
											<TableCell className="left" align="left" component="td" scope="row">
												<div>
													<PermIcon type="person" className="small" />
													<span className="folder-name">
														{usersObj[u.user_id]
															? `${usersObj[u.user_id].name}(${u.user_id})`
															: `john doe(${u.user_id})`}
													</span>
												</div>
											</TableCell>

											<TableCell className="" component="td" scope="row" align="center">
												<Tooltip title={t('PERM_DELETE')} className="font-size-initial" arrow>
													<IconButton
														aria-label="release_permission"
														onClick={e => removeSecureDocUser(u.user_id)}
													>
														<PermIcon type="delete" className="" />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									);
								})
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</div>
	);
}

export default DetailSidebarContent;
