import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ransomHistory } from 'app/store/docbase/admin/settingSlice';
import commonUtils from 'app/utils/CommonUtils';
import ContentHeader from '../component/ContentHeader';
import RansomTable from './RansomTable';

function Ransom() {
	const [loading, setLoading] = React.useState(true);
	const pageLayout = React.useRef(null);
	const dispatch = useDispatch();

	const [searchObj, setSearchObj] = React.useState({
		startDate: commonUtils.getLastWeek(),
		endDate: new Date(),
		status: 0,
		path: '',
		user: '',
		clientAddress: '',
		infectedFilePath: ''
	});

	const { events } = useSelector(({ docbase }) => docbase.admin.setting.ransomObj);
	const [data, setData] = React.useState([]);
	React.useEffect(() => {
		const d = [];
		events.forEach(event => {
			event.infected_files.forEach(file => {
				d.push({ ...event, file_path: file.path, file_status: file.status });
			});
		});

		setData(d);
	}, [events]);

	React.useEffect(() => {
		doSearch();
	}, [searchObj]);

	function doSearch() {
		setLoading(true);

		dispatch(
			ransomHistory({
				page: 1,
				status: searchObj.status,
				day: commonUtils.getDateDifference(searchObj.startDate, searchObj.endDate),
				user: searchObj.user,
				path: searchObj.path,
				clientAddress: searchObj.clientAddress,
				infectedFilePath: searchObj.infectedFilePath
			})
		).then(() => {
			setLoading(false);
		});
	}

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<RansomTable
					data={data}
					loading={loading}
					searchObj={searchObj}
					setLoading={setLoading}
					fnSetSearchObj={(name, value) => setSearchObj({ ...searchObj, [name]: value })}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default Ransom;
