import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import ContentHeader from '../component/ContentHeader';
import PrivateContent from './PrivateContent';

function PrivateSetting() {
	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<PrivateContent />}
		/>
	);
}

export default PrivateSetting;
