import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import WhiteProcessesTable from './WhiteProcessesTable';
import ContentHeader from '../component/ContentHeader';

function WhiteProcesses() {
	const [loading, setLoading] = React.useState(true);
	const pageLayout = React.useRef(null);

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<WhiteProcessesTable
					loading={loading}
					setLoading={setLoading}
					// fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			// rightSidebarContent={
			// 	<DetailSidebarContent
			// 		loading={loading}
			// 		setLoading={setLoading}
			// 		fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
			// 	/>
			// }
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default WhiteProcesses;
