import FuseScrollbars from '@fuse/core/FuseScrollbars';
import {
	Paper,
	Button,
	TextField,
	MenuItem,
	InputAdornment,
	Input,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
	Select,
	IconButton,
	Icon
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import * as commonTypes from 'app/main/constants/CommonTypes';
import commonUtils from 'app/utils/CommonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSysConfigure, setSysConfigure } from 'app/store/docbase/admin/settingSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import SwitchNum01 from 'app/main/component/SwitchNum01';
import macIcon from './icon/mac.png';
import winIcon from './icon/win.png';

const useStyles = makeStyles(theme => ({
	root: {},
	mainPaper: {
		borderRadius: 6,
		padding: 40,
		'&>.flex': {
			flexWrap: 'wrap',
			justifyContent: 'center'
		}
	},
	wrapperList: {
		color: '#353b48',
		marginRight: 30,
		'&>.row': {
			display: 'flex',
			width: 690,
			justifyContent: 'center',
			borderTop: '1px solid #e3e3e3',
			'&:last-child': {
				borderBottom: '1px solid #e3e3e3'
			},
			'&>div': {
				display: 'flex',
				alignItems: 'center',
				paddingLeft: 30
			}
		},
		'&>.row>.title': {
			width: 277,
			height: 45,
			background: '#f5f7fa',
			'& span': { cursor: 'default', display: 'block' }
		},
		'&>.row>.content': {
			width: 413
		},
		'&>.row.tall>div': {
			height: 'auto'
		},
		'&>.row.tall>.content': { display: 'block', padding: '5px 30px' }
	},
	hintSpan: {
		display: 'block',
		marginTop: 10,
		fontSize: 12,
		color: '#9e9e9e'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	inputForm: { width: '100%', maxWidth: 265 },
	select: {
		width: '100%'
	},
	customInput: {
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 4,
		'&>input': {
			padding: '10.5px 14px'
		},
		'&.Mui-focused': {
			border: '2px solid rgba(0, 0, 0, 1)'
		},
		'&:hover': {
			border: '1px solid rgba(0, 0, 0, 1)'
		}
	},
	customIconBtn: {
		color: '#000000',
		backgroundColor: '#eceff4',
		border: '1px solid #d9dee5',
		'&.left': {
			borderRadius: '6px 0 0 6px'
		},

		'&.right': {
			borderRadius: '0 6px 6px 0'
		},
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)'
		},
		'& .MuiIcon-root': { width: 14, height: 14, fontSize: 14 }
	},
	customInputInterval: {
		width: 65,
		'&>input': {
			paddingLeft: 10
		},
		'& .MuiTypography-root': {
			fontSize: 12
		}
	},
	targetWrapperList: {
		color: '#353b48',
		width: '100%',
		maxWidth: '33.33%',
		borderRight: '1px solid #e0e0e0',
		borderTop: '1px solid #e0e0e0',
		'&:last-child': {
			borderRight: 0
		},
		'&.tta_support': {
			maxWidth: 690,
			marginRight: 30
		}
	},
	targetTitle: {
		display: 'flex',
		alignItems: 'center',
		height: 45,
		paddingLeft: 30,
		background: '#f5f7fa'
	},
	targetInputWrapper: {
		display: 'flex',
		alignItems: 'center',
		height: 45,
		paddingLeft: 20,
		'&>div': {
			alignItems: 'center'
		},
		'&>.MuiTextField-root': {
			width: 171,
			marginRight: 10
		}
	},
	targetList: {
		height: '100%',
		maxHeight: 350,
		overflow: 'auto',
		'&>ul': {
			padding: 0
		},
		'&>ul>li': {
			height: 44,
			display: 'flex',
			alignItems: 'center'
		},
		'&>ul>li:nth-of-type(odd)': {
			background: '#f5f7fa'
		},
		'&>ul>li>div': {
			padding: 0
		},
		'&>ul>li>div.ext': {
			paddingLeft: 25
		},
		'&>ul>li>.MuiListItemSecondaryAction-root': {
			borderLeft: '1px solid #e0e0e0'
		},
		'& .MuiTypography-body1': { fontSize: 13, cursor: 'default' }
	},
	chip: {
		margin: theme.spacing(0.5)
	},
	productImageUpload: {
		transitionProperty: 'box-shadow',
		transitionDuration: theme.transitions.duration.short,
		transitionTimingFunction: theme.transitions.easing.easeInOut,
		background: theme.palette.secondary.main
	},
	contentWrapper: {
		height: `${window.innerHeight - 140}px`
	},

	settingTitleInput: {
		display: 'inline-block',
		cursor: 'default',
		'vertical-align': 'middle',
		width: 'calc(100% - 155px)'
	},
	formControl: {
		margin: theme.spacing(1),
		'margin-top': 0,
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	fileIcon: {
		width: '24px',
		height: '24px',
		margin: '0 auto'
	},
	pathWrapper: {
		marginTop: 10
	}
}));

let setTimer;
let changeConf = {};
function GlobalContent() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);

	const [sysConf, setSysConf] = React.useState(sysConfigure);

	const [inclusivePaths, setInclusivePaths] = React.useState([]);
	const [ignorePaths, setIgnorePaths] = React.useState([]);
	const [exts, setExts] = React.useState([]);
	const [exclusiveExt, setExclusiveExt] = React.useState([]);
	const [transferSize, setTransferSize] = React.useState(0);
	const [transferSizeUnit, setTransferSizeUnit] = React.useState(true);
	const [transferInterval, setTransferInterval] = React.useState(sysConfigure.collect_interval_transfer / 1000);

	const [collectInclusive, setCollectInclusive] = React.useState('');
	const [collectInclusiveName, setCollectInclusiveName] = React.useState('');
	const [collectInclusiveMac, setCollectInclusiveMac] = React.useState('');
	const [collectInclusiveNameMac, setCollectInclusiveNameMac] = React.useState('');

	const [collectIgnore, setCollectIgnore] = React.useState('');
	const [collectIgnoreMac, setCollectIgnoreMac] = React.useState('');
	const [collectExts, setCollectExts] = React.useState('');
	const [collectExclusiveExts, setCollectExclusiveExts] = React.useState('');

	React.useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 140}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {
		if (Number(sysConfigure.collect_transfer_size) >= 1024 * 1024 * 1024) {
			setTransferSizeUnit(false);
			setTransferSize(Math.floor(Number(sysConfigure.collect_transfer_size) / 1024 / 1024 / 1024));
		} else {
			setTransferSizeUnit(true);
			setTransferSize(Math.floor(Number(sysConfigure.collect_transfer_size) / 1024 / 1024));
		}

		const ignores = [];
		const extensions = [];
		const inclusives = [];
		const exclusiveExts = [];
		sysConfigure.collect_config.forEach(item => {
			if (item.target_type === commonTypes.COLLECT_TARGET_EXCLUSIVE_PATTERN) {
				ignores.push(item);
			} else if (item.target_type === commonTypes.COLLECT_TARGET_INCLUDE_EXTENSION) {
				extensions.push(item);
			} else if (item.target_type === commonTypes.COLLECT_TARGET_INCLUSIVE_PATTERN) {
				inclusives.push(item);
			} else if (item.target_type === commonTypes.COLLECT_TARGET_EXCLUSIVE_EXTENSION) {
				exclusiveExts.push(item);
			}
		});
		setInclusivePaths(inclusives);
		setIgnorePaths(ignores);
		setExts(extensions);
		setExclusiveExt(exclusiveExts);
		setSysConf(sysConfigure);
		setTransferInterval(sysConfigure.collect_interval_transfer / 1000);
	}, [sysConfigure]);

	React.useEffect(() => {
		dispatch(getSysConfigure());
	}, [dispatch]);

	//  select 변경시
	function changeSettings(e) {
		if (e.target.value !== String(sysConf[e.target.name])) {
			setSysConf({ ...sysConf, [e.target.name]: e.target.value === 'true' });
			requestHandler(e.target.name, e.target.value);
		}
	}

	function handleDeleteCollectTarget(targetObj) {
		const collectConfig = [];
		sysConfigure.collect_config.forEach(target => {
			if (targetObj.target_type !== target.target_type || targetObj.value !== target.value) {
				collectConfig.push(target);
			}
		});

		dispatch(setSysConfigure({ setConf: { collect_config: collectConfig } })).then(() => {
			dispatch(getSysConfigure());
		});
	}

	function setCollectConfig(targetType, value, name, targetClient) {
		if (value.trim().length > 0) {
			if (
				targetType === commonTypes.COLLECT_TARGET_INCLUSIVE_PATTERN &&
				sysConfigure.collect_inclusive_pattern_support &&
				name.trim().length === 0
			) {
				dispatch(showMessage({ message: t('FOLDER_ERROR_MESSAGE_1') }));
				return false;
			}

			dispatch(
				setSysConfigure({
					setConf: {
						collect_config: [
							...sysConfigure.collect_config,
							{
								target_type: targetType,
								value,
								name,
								target_client:
									targetType === commonTypes.COLLECT_TARGET_EXCLUSIVE_EXTENSION ||
									targetType === commonTypes.COLLECT_TARGET_INCLUDE_EXTENSION
										? commonTypes.COLLECT_TARGET_CLIENT_ALL
										: targetClient
							}
						]
					}
				})
			).then(() => {
				setCollectInclusive('');
				setCollectInclusiveName('');
				setCollectInclusiveMac('');
				setCollectInclusiveNameMac('');
				setCollectIgnore('');
				setCollectIgnoreMac('');
				setCollectExts('');
				setCollectExclusiveExts('');
				dispatch(getSysConfigure());
			});
		}
		return true;
	}

	function changeSwitch(e) {
		setSysConf({ ...sysConf, [e.target.name]: !sysConf[e.target.name] });
		requestHandler(e.target.name, !sysConf[e.target.name]);
	}

	function requestHandler(name, value) {
		changeConf[name] = value;
		clearTimeout(setTimer);
		setTimer = setTimeout(() => {
			dispatch(setSysConfigure({ setConf: changeConf })).then(() => {
				changeConf = {};
				dispatch(getSysConfigure());
			});
		}, 500);
	}

	const drawMacCollector = () => {
		if (sysConf.menu_support_type === 2) {
			return <></>;
		}

		return sysConf.collect_inclusive_pattern_support ? (
			<div className={clsx(classes.targetWrapperList, sysConf.menu_support_type === 2 ? 'tta_support' : '')}>
				<div className={classes.targetTitle}>{`${t('COLLECT_INCLUSIVE')} (Mac)`}</div>
				<div className={classes.targetInputWrapper}>
					{/* <FormControl className={classes.formControl}>
                                                        <InputLabel id="collect_client_label">수집 클라이언트</InputLabel>
                                                        <Select
                                                            labelId="collect_client_label"
                                                            id="collect_client"
                                                            className={clsx(classes.w_140, 'inline-block align-middle pr-0')}
                                                            name="collect_exclusive_extension_support"
                                                            value={collectClientType}
                                                            onChange={e => {
                                                                setCollectClientType(e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value={commonTypes.COLLECT_TARGET_CLIENT_WINDOWS}>
                                                                Windows
                                                            </MenuItem>
                                                            <MenuItem value={commonTypes.COLLECT_TARGET_CLIENT_MAC}>Mac</MenuItem>
                                                        </Select>
                                                    </FormControl> */}
					<TextField
						className=""
						type="text"
						name="collect_inclusive_value"
						value={collectInclusiveMac}
						FormHelperTextProps={t('COLLECT_INCLUSIVE')}
						variant="outlined"
						disableUnderline
						onChange={e => setCollectInclusiveMac(e.target.value)}
						onKeyDown={e => {
							if (e.keyCode === 13) {
								if (collectInclusiveMac.length > 0 && collectInclusiveNameMac.length > 0) {
									setCollectConfig(
										commonTypes.COLLECT_TARGET_INCLUSIVE_PATTERN,
										e.target.value,
										collectInclusiveNameMac,
										commonTypes.COLLECT_TARGET_CLIENT_MAC
									);
								}
							}
						}}
					/>
					<TextField
						className=""
						type="text"
						name="collect_inclusive_name"
						value={collectInclusiveNameMac}
						FormHelperTextProps={t('COLLECT_INCLUSIVE_NAME')}
						variant="outlined"
						disableUnderline
						onChange={e => setCollectInclusiveNameMac(e.target.value)}
						onKeyDown={e => {
							if (e.keyCode === 13) {
								if (collectInclusiveMac.length > 0 && collectInclusiveNameMac.length > 0) {
									setCollectConfig(
										commonTypes.COLLECT_TARGET_INCLUSIVE_PATTERN,
										collectInclusiveMac,
										e.target.value,
										commonTypes.COLLECT_TARGET_CLIENT_MAC
									);
								}
							}
						}}
					/>
					<Button
						variant="contained"
						color="primary"
						className=""
						aria-label="ignore-add"
						size="small"
						type="button"
						onClick={e =>
							setCollectConfig(
								commonTypes.COLLECT_TARGET_INCLUSIVE_PATTERN,
								collectInclusiveMac,
								collectInclusiveNameMac,
								commonTypes.COLLECT_TARGET_CLIENT_MAC
							)
						}
					>
						{t('ADD')}
					</Button>
				</div>
				<div className={classes.targetList}>
					<List>
						{inclusivePaths.map((data, idx) => {
							if (data.target_client === commonTypes.COLLECT_TARGET_CLIENT_MAC) {
								return (
									<ListItem key={idx} disablePadding>
										<ListItemIcon>
											<img className={classes.fileIcon} src={macIcon} alt="file-icon" />
										</ListItemIcon>
										<ListItemText
											id={`target_inclusive_path_mac_${idx}`}
											primary={`${data.value} ⟹ ${data.name}`}
										/>
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="remove"
												color="red"
												onClick={e => handleDeleteCollectTarget(data)}
											>
												<Icon>remove_circle</Icon>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								);
							}
							return <></>;
						})}
					</List>
				</div>
			</div>
		) : (
			<div className={clsx(classes.targetWrapperList, sysConf.menu_support_type === 2 ? 'tta_support' : '')}>
				<div className={classes.targetTitle}>{t('COLLECT_IGNORE')}</div>
				<div className={classes.targetInputWrapper}>
					{/* <FormControl className={classes.formControl}>
                                                        <InputLabel id="collect_client_label">수집 클라이언트</InputLabel>
                                                        <Select
                                                            labelId="collect_client_label"
                                                            id="collect_client"
                                                            className={clsx(classes.w_140, 'inline-block align-middle pr-0')}
                                                            name="collect_exclusive_extension_support"
                                                            value={collectClientType}
                                                            onChange={e => {
                                                                setCollectClientType(e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value={commonTypes.COLLECT_TARGET_CLIENT_WINDOWS}>
                                                                Windows
                                                            </MenuItem>
                                                            <MenuItem value={commonTypes.COLLECT_TARGET_CLIENT_MAC}>Mac</MenuItem>
                                                        </Select>
                                                    </FormControl> */}
					<TextField
						className=""
						type="text"
						name="collect_ignore_value"
						value={collectIgnoreMac}
						FormHelperTextProps={t('COLLECT_IGNORE')}
						variant="outlined"
						disableUnderline
						onChange={e => setCollectIgnoreMac(e.target.value)}
						onKeyDown={e => {
							if (e.keyCode === 13)
								setCollectConfig(
									commonTypes.COLLECT_TARGET_EXCLUSIVE_PATTERN,
									e.target.value,
									'',
									commonTypes.COLLECT_TARGET_CLIENT_MAC
								);
						}}
					/>
					<Button
						variant="contained"
						color="primary"
						className=""
						aria-label="ignore-add"
						size="small"
						type="button"
						onClick={e =>
							setCollectConfig(
								commonTypes.COLLECT_TARGET_EXCLUSIVE_PATTERN,
								collectIgnoreMac,
								'',
								commonTypes.COLLECT_TARGET_CLIENT_MAC
							)
						}
					>
						{t('ADD')}
					</Button>
				</div>
				<div className={classes.targetList}>
					<List>
						{ignorePaths.map((data, idx) => {
							if (data.target_client === commonTypes.COLLECT_TARGET_CLIENT_MAC) {
								return (
									<ListItem
										key={idx}
										secondaryAction={
											<IconButton
												className={classes.fileIcon}
												onClick={e => handleDeleteCollectTarget(data)}
											>
												<img className={classes.fileIcon} src={macIcon} alt="file-icon" />
											</IconButton>
										}
										disablePadding
									>
										<ListItemIcon>
											<img className={classes.fileIcon} src={macIcon} alt="file-icon" />
										</ListItemIcon>
										<ListItemText id={`target_ignore_path_mac_${idx}`} primary={`${data.value}`} />
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="remove"
												color="red"
												onClick={e => handleDeleteCollectTarget(data)}
											>
												<Icon>remove_circle</Icon>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								);
							}
							return <></>;
						})}
					</List>
				</div>
			</div>
		);
	};

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<FuseScrollbars className={clsx(classes.contentWrapper, 'overflow-x-auto')} ref={contentTable}>
				<Paper className={classes.mainPaper}>
					<div className="flex">
						<div className={classes.wrapperList}>
							<div className="row">
								<div className="title">
									<span>{t('COLLECT_USE')}</span>
								</div>
								<div className="content">
									<SwitchNum01
										name="collector_support"
										checked={sysConf.collector_support}
										onChange={e => changeSwitch(e)}
									/>
								</div>
							</div>
							<div className="row">
								<div className="title">
									<span>{t('COLLECT_SYNC_REMOVE')}</span>
								</div>
								<div className="content">
									<SwitchNum01
										name="collect_sync_remove_support"
										checked={sysConf.collect_sync_remove_support}
										onChange={e => changeSwitch(e)}
									/>
								</div>
							</div>
							<div className="row">
								<div className="title">
									<span>{t('COLLECTOR_TRANSFER_SIZE')}</span>
								</div>
								<div className="content">
									<div className={classes.inputForm}>
										<Input
											type="number"
											name="collect_transfer_size"
											disableUnderline
											className={classes.customInput}
											value={transferSize}
											onChange={e => setTransferSize(Number(e.target.value))}
											onBlur={e => {
												e.preventDefault();
												const size = transferSizeUnit ? transferSize : transferSize * 1024;
												if (size < 0) {
													dispatch(showErrorMessage({ message: t('NUMBER_ERROR_MESSAGE') }));
													// eslint-disable-next-line no-unused-expressions
													if (Number(sysConfigure.collect_transfer_size) >= 1024 * 1024 * 1024) {
														setTransferSizeUnit(false);
														setTransferSize(
															Math.floor(
																Number(sysConfigure.collect_transfer_size) / 1024 / 1024 / 1024
															)
														);
													} else {
														setTransferSizeUnit(true);
														setTransferSize(
															Math.floor(Number(sysConfigure.collect_transfer_size) / 1024 / 1024)
														);
													}

													return false;
												}

												if (sysConfigure.collect_transfer_size !== String(size * 1024 * 1024)) {
													requestHandler('collect_transfer_size', size * 1024 * 1024);
												}
												return true;
											}}
											endAdornment={
												<InputAdornment position="end" className="">
													<Select
														id="folder_capacity"
														disableUnderline
														variant="standard"
														className={clsx(classes.selectTextAlignRight, '')}
														value={transferSizeUnit}
														onChange={e => {
															if (transferSizeUnit) {
																setTransferSize(
																	transferSize < 1024 ? 0 : Math.floor(transferSize / 1024)
																);
															} else {
																setTransferSize(transferSize * 1024);
															}
															setTransferSizeUnit(!transferSizeUnit);
														}}
													>
														<MenuItem value="false">Gbyte</MenuItem>
														<MenuItem value="true">Mbyte</MenuItem>
													</Select>
												</InputAdornment>
											}
										/>
									</div>
								</div>
							</div>
							<div className="row tall">
								<div className="title">
									<div>
										<span>{t('COLLECTOR_INTERVAL_TRANSFER')}</span>
									</div>
								</div>
								<div className="content">
									<div className="flex">
										<IconButton
											className={clsx(classes.customIconBtn, 'left')}
											onClick={e => {
												const interval = Number(transferInterval);
												if (interval > 0) {
													setTransferInterval(interval - 1);
													setSysConf({
														...sysConf,
														collect_interval_transfer: (interval - 1) * 1000
													});
													requestHandler('collect_interval_transfer', (interval - 1) * 1000);
												}
											}}
										>
											<Icon>remove</Icon>
										</IconButton>

										<Input
											type="text"
											name="collect_interval_transfer"
											className={classes.customInputInterval}
											disableUnderline
											value={transferInterval}
											onChange={e => {
												if (commonUtils.isNumber(e.target.value)) {
													setTransferInterval(Number(e.target.value));
													setSysConf({
														...sysConf,
														[e.target.name]: Number(e.target.value) * 1000
													});
												}
											}}
											onBlur={e => {
												if (commonUtils.isNumber(sysConf.collect_interval_transfer)) {
													requestHandler(e.target.name, sysConf.collect_interval_transfer);
												}
											}}
											endAdornment={<InputAdornment position="end">{t('SEC')}</InputAdornment>}
										/>
										<IconButton
											className={clsx(classes.customIconBtn, 'right')}
											onClick={e => {
												const interval = Number(transferInterval);
												setTransferInterval(interval + 1);
												setSysConf({
													...sysConf,
													collect_interval_transfer: (interval + 1) * 1000
												});
												requestHandler('collect_interval_transfer', (interval + 1) * 1000);
											}}
										>
											<Icon>add</Icon>
										</IconButton>
									</div>
									<span className={clsx(classes.hintSpan)}>{t('COLLECTOR_INTERVAL_TRANSFER_HELP_TEXT')}</span>
								</div>
							</div>
							<div className="row">
								<div className="title">
									<span>{t('COLLECT_DRIVE_FIXED')}</span>
								</div>
								<div className="content">
									<SwitchNum01
										name="collect_drive_fixed"
										checked={sysConf.collect_drive_fixed}
										onChange={e => changeSwitch(e)}
									/>
								</div>
							</div>
							<div className="row">
								<div className="title">
									<span>{t('COLLECT_DRIVE_REMOVABLE')}</span>
								</div>
								<div className="content">
									<SwitchNum01
										name="collect_drive_removable"
										checked={sysConf.collect_drive_removable}
										onChange={e => changeSwitch(e)}
									/>
								</div>
							</div>
							<div className="row">
								<div className="title">
									<span>{t('COLLECT_DRIVE_CDROM')}</span>
								</div>
								<div className="content">
									<SwitchNum01
										name="collect_drive_cdrom"
										checked={sysConf.collect_drive_cdrom}
										onChange={e => changeSwitch(e)}
									/>
								</div>
							</div>
							<div className="row">
								<div className="title">
									<span>{t('COLLECT_DRIVE_RAMDISK')}</span>
								</div>
								<div className="content">
									<SwitchNum01
										name="collect_drive_ramdisk"
										checked={sysConf.collect_drive_ramdisk}
										onChange={e => changeSwitch(e)}
									/>
								</div>
							</div>
						</div>
						<div className={classes.wrapperList}>
							<div className="row">
								<div className="title">
									<span>{t('COLLECT_PATH_SETTING')}</span>
								</div>
								<div className="content">
									<div className={classes.inputForm}>
										<Select
											variant="outlined"
											className={clsx(classes.select, '')}
											name="collect_inclusive_pattern_support"
											value={String(sysConf.collect_inclusive_pattern_support)}
											onChange={e => {
												changeSettings(e);
											}}
										>
											<MenuItem value="true">{t('INCLUSIVE')}</MenuItem>
											<MenuItem value="false">{t('EXCLUSIVE')}</MenuItem>
										</Select>
									</div>
								</div>
							</div>
							<div className="row tall">
								<div className="title">
									<div>
										<span>{t('COLLECT_EXCLUSIVE_EXT_SETTING')}</span>
									</div>
								</div>
								<div className="content">
									<div className={classes.inputForm}>
										<Select
											variant="outlined"
											className={clsx(classes.select, '')}
											name="collect_exclusive_extension_support"
											value={String(sysConf.collect_exclusive_extension_support)}
											onChange={e => {
												changeSettings(e);
											}}
										>
											<MenuItem value="true">{t('EXCLUSIVE_EXT')}</MenuItem>
											<MenuItem value="false">{t('INCLUSIVE_EXT')}</MenuItem>
										</Select>
									</div>
									<span className={clsx(classes.hintSpan)}>{t('COLLECT_EXCLUSIVE_EXT_SETTING_HELP_TEXT')}</span>
								</div>
							</div>
							<div className="row">
								<div className="title">
									<span>{t('COLLECT_DRIVE_REMOTE')}</span>
								</div>
								<div className="content">
									<SwitchNum01
										name="collect_drive_remote"
										checked={sysConf.collect_drive_remote}
										onChange={e => changeSwitch(e)}
									/>
								</div>
							</div>
							<div className="row">
								<div className="title">
									<span>{t('COLLECT_DRIVE_FIXED_HOTPLUG')}</span>
								</div>
								<div className="content">
									<SwitchNum01
										name="collect_drive_fixed_hotplug"
										checked={sysConf.collect_drive_fixed_hotplug}
										onChange={e => changeSwitch(e)}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={clsx(classes.pathWrapper, 'flex')}>
						{/* windows 경로 설정 화면 */}
						{sysConf.collect_inclusive_pattern_support ? (
							<div
								className={clsx(classes.targetWrapperList, sysConf.menu_support_type === 2 ? 'tta_support' : '')}
							>
								<div className={classes.targetTitle}>{`${t('COLLECT_INCLUSIVE')} (windows)`}</div>
								<div className={classes.targetInputWrapper}>
									{/* <FormControl className={classes.formControl}>
                                                        <InputLabel id="collect_client_label">수집 클라이언트</InputLabel>
                                                        <Select
                                                            labelId="collect_client_label"
                                                            id="collect_client"
                                                            className={clsx(classes.w_140, 'inline-block align-middle pr-0')}
                                                            name="collect_exclusive_extension_support"
                                                            value={collectClientType}
                                                            onChange={e => {
                                                                setCollectClientType(e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value={commonTypes.COLLECT_TARGET_CLIENT_WINDOWS}>
                                                                Windows
                                                            </MenuItem>
                                                            <MenuItem value={commonTypes.COLLECT_TARGET_CLIENT_MAC}>Mac</MenuItem>
                                                        </Select>
                                                    </FormControl> */}
									<TextField
										className=""
										type="text"
										name="collect_inclusive_value"
										value={collectInclusive}
										FormHelperTextProps={t('COLLECT_INCLUSIVE')}
										variant="outlined"
										disableUnderline
										onChange={e => setCollectInclusive(e.target.value)}
										onKeyDown={e => {
											if (e.keyCode === 13) {
												if (collectInclusive.length > 0 && collectInclusiveName.length > 0) {
													setCollectConfig(
														commonTypes.COLLECT_TARGET_INCLUSIVE_PATTERN,
														e.target.value,
														collectInclusiveName,
														commonTypes.COLLECT_TARGET_CLIENT_WINDOWS
													);
												}
											}
										}}
									/>

									<TextField
										className=""
										type="text"
										name="collect_inclusive_name"
										value={collectInclusiveName}
										FormHelperTextProps={t('COLLECT_INCLUSIVE_NAME')}
										variant="outlined"
										disableUnderline
										onChange={e => setCollectInclusiveName(e.target.value)}
										onKeyDown={e => {
											if (e.keyCode === 13) {
												if (collectInclusive.length > 0 && collectInclusiveName.length > 0) {
													setCollectConfig(
														commonTypes.COLLECT_TARGET_INCLUSIVE_PATTERN,
														collectInclusive,
														e.target.value,
														commonTypes.COLLECT_TARGET_CLIENT_WINDOWS
													);
												}
											}
										}}
									/>
									<Button
										variant="contained"
										color="primary"
										className=""
										aria-label="ignore-add"
										size="small"
										type="button"
										onClick={e =>
											setCollectConfig(
												commonTypes.COLLECT_TARGET_INCLUSIVE_PATTERN,
												collectInclusive,
												collectInclusiveName,
												commonTypes.COLLECT_TARGET_CLIENT_WINDOWS
											)
										}
									>
										{t('ADD')}
									</Button>
								</div>
								<div className={classes.targetList}>
									<List>
										{inclusivePaths.map((data, idx) => {
											if (data.target_client === commonTypes.COLLECT_TARGET_CLIENT_WINDOWS) {
												return (
													<ListItem key={idx} disablePadding>
														<ListItemIcon>
															<img className={classes.fileIcon} src={winIcon} alt="file-icon" />
														</ListItemIcon>
														<ListItemText
															id={`target_inclusive_path_win_${idx}`}
															primary={`${data.value} ⟹ ${data.name}`}
														/>
														<ListItemSecondaryAction>
															<IconButton
																edge="end"
																aria-label="remove"
																color="red"
																onClick={e => handleDeleteCollectTarget(data)}
															>
																<Icon>remove_circle</Icon>
															</IconButton>
														</ListItemSecondaryAction>
													</ListItem>
												);
											}
											return <></>;
										})}
									</List>
								</div>
							</div>
						) : (
							<div
								className={clsx(classes.targetWrapperList, sysConf.menu_support_type === 2 ? 'tta_support' : '')}
							>
								<div className={classes.targetTitle}>{t('COLLECT_IGNORE')}</div>
								<div className={classes.targetInputWrapper}>
									{/* <FormControl className={classes.formControl}>
                                                        <InputLabel id="collect_client_label">수집 클라이언트</InputLabel>
                                                        <Select
                                                            labelId="collect_client_label"
                                                            id="collect_client"
                                                            className={clsx(classes.w_140, 'inline-block align-middle pr-0')}
                                                            name="collect_exclusive_extension_support"
                                                            value={collectClientType}
                                                            onChange={e => {
                                                                setCollectClientType(e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value={commonTypes.COLLECT_TARGET_CLIENT_WINDOWS}>
                                                                Windows
                                                            </MenuItem>
                                                            <MenuItem value={commonTypes.COLLECT_TARGET_CLIENT_MAC}>Mac</MenuItem>
                                                        </Select>
                                                    </FormControl> */}
									<TextField
										className=""
										type="text"
										name="collect_ignore_value"
										value={collectIgnore}
										FormHelperTextProps={t('COLLECT_IGNORE')}
										variant="outlined"
										disableUnderline
										onChange={e => setCollectIgnore(e.target.value)}
										onKeyDown={e => {
											if (e.keyCode === 13)
												setCollectConfig(
													commonTypes.COLLECT_TARGET_EXCLUSIVE_PATTERN,
													e.target.value,
													'',
													commonTypes.COLLECT_TARGET_CLIENT_WINDOWS
												);
										}}
									/>
									<Button
										variant="contained"
										color="primary"
										className=""
										aria-label="ignore-add"
										size="small"
										type="button"
										onClick={e =>
											setCollectConfig(
												commonTypes.COLLECT_TARGET_EXCLUSIVE_PATTERN,
												collectIgnore,
												'',
												commonTypes.COLLECT_TARGET_CLIENT_WINDOWS
											)
										}
									>
										{t('ADD')}
									</Button>
								</div>
								<div className={classes.targetList}>
									<List>
										{ignorePaths.map((data, idx) => {
											if (data.target_client === commonTypes.COLLECT_TARGET_CLIENT_WINDOWS) {
												return (
													<ListItem key={idx} disablePadding>
														<ListItemIcon>
															<img className={classes.fileIcon} src={winIcon} alt="file-icon" />
														</ListItemIcon>
														<ListItemText id={`target_ignore_win_${idx}`} primary={`${data.value}`} />
														<ListItemSecondaryAction>
															<IconButton
																edge="end"
																aria-label="remove"
																color="red"
																onClick={e => handleDeleteCollectTarget(data)}
															>
																<Icon>remove_circle</Icon>
															</IconButton>
														</ListItemSecondaryAction>
													</ListItem>
												);
											}
											return <></>;
										})}
									</List>
								</div>
							</div>
						)}
						{/* Mac 경로 설정 화면 */}
						{drawMacCollector()}

						{/* 확장자 설정 */}
						{sysConf.collect_exclusive_extension_support ? (
							<div
								className={clsx(classes.targetWrapperList, sysConf.menu_support_type === 2 ? 'tta_support' : '')}
							>
								<div className={classes.targetTitle}>{t('COLLECT_EXCLUSIVE_EXT')}</div>
								<div className={classes.targetInputWrapper}>
									<TextField
										className=""
										type="text"
										name="collect_exts_value"
										value={collectExclusiveExts}
										FormHelperTextProps={t('COLLECT_EXCLUSIVE_EXT')}
										variant="outlined"
										disableUnderline
										onChange={e => setCollectExclusiveExts(e.target.value)}
										onKeyDown={e => {
											if (e.keyCode === 13) {
												setCollectConfig(
													commonTypes.COLLECT_TARGET_EXCLUSIVE_EXTENSION,
													e.target.value,
													'',
													commonTypes.COLLECT_TARGET_CLIENT_ALL
												);
											}
										}}
									/>
									<Button
										variant="contained"
										color="primary"
										className=""
										aria-label="ignore-add"
										size="small"
										type="button"
										onClick={e =>
											setCollectConfig(
												commonTypes.COLLECT_TARGET_EXCLUSIVE_EXTENSION,
												collectExclusiveExts,
												'',
												commonTypes.COLLECT_TARGET_CLIENT_ALL
											)
										}
									>
										{t('ADD')}
									</Button>
								</div>
								<div className={classes.targetList}>
									<List>
										{exclusiveExt.map((data, idx) => (
											<ListItem key={idx} disablePadding>
												{/* <ListItemIcon>
                                                    <img className={classes.fileIcon} src={macIcon} alt="file-icon" />
                                                </ListItemIcon> */}
												<ListItemText id={`target_excluext_${idx}`} primary={`${data.value}`} />
												<ListItemSecondaryAction>
													<IconButton
														edge="end"
														aria-label="remove"
														onClick={e => handleDeleteCollectTarget(data)}
													>
														<Icon>remove_circle</Icon>
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										))}
									</List>
								</div>
							</div>
						) : (
							<div
								className={clsx(classes.targetWrapperList, sysConf.menu_support_type === 2 ? 'tta_support' : '')}
							>
								<div className={classes.targetTitle}>
									{sysConf.collect_exclusive_extension_support ? t('RANSOMWARE_EXT') : t('COLLECT_EXT')}
								</div>
								<div className={classes.targetInputWrapper}>
									<TextField
										className=""
										type="text"
										name="collect_exts_value"
										value={collectExts}
										FormHelperTextProps={t(
											sysConf.collect_exclusive_extension_support ? 'RANSOMWARE_EXT' : 'COLLECT_EXT'
										)}
										variant="outlined"
										disableUnderline
										onChange={e => setCollectExts(e.target.value)}
										onKeyDown={e => {
											if (e.keyCode === 13) {
												setCollectConfig(
													commonTypes.COLLECT_TARGET_INCLUDE_EXTENSION,
													e.target.value,
													'',
													commonTypes.COLLECT_TARGET_CLIENT_ALL
												);
											}
										}}
									/>
									<Button
										variant="contained"
										color="primary"
										className=""
										aria-label="ignore-add"
										size="small"
										type="button"
										style={{ 'margin-left': '10px' }}
										onClick={e =>
											setCollectConfig(
												commonTypes.COLLECT_TARGET_INCLUDE_EXTENSION,
												collectExts,
												'',
												commonTypes.COLLECT_TARGET_CLIENT_ALL
											)
										}
									>
										{t('ADD')}
									</Button>
								</div>
								<div className={classes.targetList}>
									<List>
										{exts.map((data, idx) => (
											<ListItem
												key={idx}
												className="ext"
												secondaryAction={
													<IconButton
														className={classes.fileIcon}
														onClick={e => handleDeleteCollectTarget(data)}
													>
														<img className={classes.fileIcon} src={macIcon} alt="file-icon" />
													</IconButton>
												}
												disablePadding
											>
												{/* <ListItemIcon>
                                                    <img className={classes.fileIcon} src={macIcon} alt="file-icon" />
                                                </ListItemIcon> */}
												<ListItemText id={`target_ignore_path_mac_${idx}`} primary={`${data.value}`} />
												<ListItemSecondaryAction>
													<IconButton
														edge="end"
														aria-label="remove"
														onClick={e => handleDeleteCollectTarget(data)}
													>
														<Icon>remove_circle</Icon>
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										))}
									</List>
								</div>
							</div>
						)}
					</div>
				</Paper>
			</FuseScrollbars>
		</div>
	);
}

export default GlobalContent;
