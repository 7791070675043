import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { IconButton, Dialog, DialogContent, Typography, Button, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import commonUtils from 'app/utils/CommonUtils';
import { stopReleaseBlocking, approveReleaseBlocking } from 'app/store/docbase/admin/dlpSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import OpenDialogForm from '../../component/OpenDialogForm';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});
const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 1208
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 60,
			paddingRight: 30,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' },
		'&>div.tall': {
			height: 120
		}
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277,
		minWidth: 277
	},
	filedInput: {
		width: '100%',
		padding: '0 0 0 15px',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 12,
		marginBottom: 10,
		'& .MuiButton-root': {
			width: 172,
			height: 54,
			marginRight: 16
		},
		'& .MuiButton-root:last-child': {
			marginRight: 0
		}
	}
}));

export default function ViewDialog(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');
	const [reasonRejected, setReasonRejected] = React.useState('');

	function drawInfo() {
		if (props.requestObj.rid) {
			let div = <></>;
			switch (props.requestObj.release_type) {
				case 1: {
					let program = <></>;
					let storage = '전체';
					if (props.requestObj.storage === 2) {
						storage = 'USB';
					} else if (props.requestObj.storage === 3) {
						storage = '네트워크 저장소';
					}
					if (props.requestObj.program !== 0) {
						program = (
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('프로그램')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{t('전체 프로그램')}</div>
							</div>
						);
					} else {
						program = (
							<>
								<div>
									<div className={classes.filedTitle}>
										<p className={clsx('text-left')}>{t('프로그램')}</p>
									</div>
									<div className={clsx(classes.filedInput, 'folder-name')}>
										{`${props.requestObj.program_signing_id}경로${props.requestObj.program_ref_path}`}
									</div>
								</div>
								<div>
									<div className={classes.filedTitle}>
										<p className={clsx('text-left')}>{t('프로그램 해시')}</p>
									</div>
									<div className={clsx(classes.filedInput, 'folder-name')}>{props.requestObj.program_hash}</div>
								</div>
							</>
						);
					}
					div = (
						<>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('대상 매체')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{storage}</div>
							</div>
							{program}
						</>
					);
					break;
				}
				case 2: {
					let program = <></>;
					let inbound = '전체';
					let outbound = '전체';
					if (props.requestObj.inbound_ports !== '*') {
						inbound = props.requestObj.inbound_ports;
					}
					if (props.requestObj.outbound_destinations !== '*:*') {
						outbound = props.requestObj.outbound_destinations;
					}
					if (props.requestObj.program !== 0) {
						program = (
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('프로그램')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{t('전체 프로그램')}</div>
							</div>
						);
					} else {
						program = (
							<>
								<div>
									<div className={classes.filedTitle}>
										<p className={clsx('text-left')}>{t('프로그램')}</p>
									</div>
									<div className={clsx(classes.filedInput, 'folder-name')}>
										{`${props.requestObj.program_signing_id}경로${props.requestObj.program_ref_path}`}
									</div>
								</div>
								<div>
									<div className={classes.filedTitle}>
										<p className={clsx('text-left')}>{t('프로그램 해시')}</p>
									</div>
									<div className={clsx(classes.filedInput, 'folder-name')}>{props.requestObj.program_hash}</div>
								</div>
							</>
						);
					}
					div = (
						<>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('들어오는 포트')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{inbound}</div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('원격지')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{outbound}</div>
							</div>
							{program}
						</>
					);
					break;
				}
				case 3: {
					let program = <></>;
					if (props.requestObj.program !== 0) {
						program = (
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('프로그램')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{t('전체 프로그램')}</div>
							</div>
						);
					} else {
						program = (
							<>
								<div>
									<div className={classes.filedTitle}>
										<p className={clsx('text-left')}>{t('프로그램')}</p>
									</div>
									<div className={clsx(classes.filedInput, 'folder-name')}>
										{`${props.requestObj.program_signing_id}경로${props.requestObj.program_ref_path}`}
									</div>
								</div>
								<div>
									<div className={classes.filedTitle}>
										<p className={clsx('text-left')}>{t('프로그램 해시')}</p>
									</div>
									<div className={clsx(classes.filedInput, 'folder-name')}>{props.requestObj.program_hash}</div>
								</div>
							</>
						);
					}
					div = program;
					break;
				}
				case 4: {
					let url = '전체';
					if (props.requestObj.urls !== '*') {
						url = props.requestObj.urls;
					}

					div = (
						<>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('URL')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{url}</div>
							</div>
						</>
					);
					break;
				}
				default:
			}
			return div;
		}
		return <></>;
	}

	let releaseType = '';
	switch (props.requestObj.release_type || '') {
		case 0:
			releaseType = t('전체');
			break;
		case 1:
			releaseType = t('매체 접근');
			break;
		case 2:
			releaseType = t('네트워크');
			break;
		case 3:
			releaseType = t('실행');
			break;
		case 4:
			releaseType = t('브라우저 업로드');
			break;
		case 5:
			releaseType = t('블루투스 파일전송');
			break;
		case 6:
			releaseType = t('블루투스 장치 페어링');
			break;
		case 7:
			releaseType = t('메신저 파일전송');
			break;
		case 100:
			releaseType = t('설치모드');
			break;
		default:
	}

	let status = '';
	switch (props.requestObj.status) {
		case 1:
			status = t('요청');
			break;
		case 100:
			status = t('승인');
			break;
		case 110:
			status = t('자동 승인');
			break;
		case 200:
			status = t('거절');
			break;
		case 300:
			status = t('요청 철회');
			break;
		default:
	}
	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('DLP_CLIENT_LOG_VIEW_TITLE')}
				</DialogTitle>
				<DialogContent className={classes.infoFiled}>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('요청일시')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.requestObj.requested_at
								? commonUtils.getDateStringToLocalDate(props.requestObj.requested_at)
								: ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('요청자')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.requestObj.requester_name
								? `${props.requestObj.requester_name}(${props.requestObj.requester})`
								: ''}
						</div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('승인자')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.requestObj.approver_name
								? `${props.requestObj.approver_name}(${props.requestObj.approver}})`
								: ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('해제 종류')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{releaseType}</div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('상태')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{status}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('해제 시간')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.requestObj.duration ? `${props.requestObj.duration}분` : ''}
						</div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('해제 종료 시간')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.requestObj.expire_at ? commonUtils.getDateStringToLocalDate(props.requestObj.expire_at) : ''}
						</div>
					</div>
					{drawInfo()}

					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('요청사유')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.requestObj.reason_request || ''}</div>
					</div>

					<div className="tall">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('거절 사유')}</p>
						</div>
						<div className={clsx(classes.filedInput)}>
							<TextField
								id="reason_rejected"
								type="text"
								value={reasonRejected}
								onChange={e => setReasonRejected(e.target.value)}
								variant="outlined"
								size="small"
								multiline
								minRows={4}
								fullWidth
							/>
						</div>
					</div>
				</DialogContent>
				<div className={classes.buttonWrapper}>
					{props.requestObj.status === 1 ? (
						<>
							<Button
								className="default"
								variant="contained"
								type="button"
								onClick={e => {
									if (reasonRejected === '') {
										dispatch(
											showErrorMessage({
												message: t('거절 사유를 입해해주세요.')
											})
										);
										return false;
									}

									dispatch(
										openDialog({
											children: (
												<OpenDialogForm
													title={t('임시해제 제어')}
													content={t('임시해제를 거절 하시겠습니까?')}
													fnHandleOK={() => {
														approveReleaseBlocking(props.requestObj.rid, false, reasonRejected)
															.then(result => {
																if (result) {
																	dispatch(
																		showMessage({
																			message: t('해제 요청을 거절하였습니다.')
																		})
																	);
																	props.fnReset();
																}
															})
															.catch(err => {
																dispatch(
																	showErrorMessage({
																		message: t('해제 요청 거절에 실패하였습니다.')
																	})
																);
															});
													}}
												/>
											)
										})
									);

									return true;
								}}
							>
								{t('TAKEOUT_REJECTED')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className=""
								aria-label="Register"
								type="button"
								onClick={e => {
									dispatch(
										openDialog({
											children: (
												<OpenDialogForm
													title={t('임시해제 제어')}
													content={t('임시해제를 수락 하시겠습니까?')}
													fnHandleOK={() => {
														approveReleaseBlocking(props.requestObj.rid, true, '')
															.then(result => {
																if (result) {
																	dispatch(
																		showMessage({
																			message: t('해제 요청을 수락하였습니다.')
																		})
																	);
																	props.fnReset();
																}
															})
															.catch(err => {
																dispatch(
																	showErrorMessage({
																		message: t('해제 요청 수락에 실패하였습니다.')
																	})
																);
															});
													}}
												/>
											)
										})
									);
								}}
							>
								{t('TAKEOUT_APPROVED')}
							</Button>
						</>
					) : (
						<></>
					)}
					{props.requestObj.expire_at ? (
						commonUtils.getDateDifferenceByNow(props.requestObj.expire_at) > 0 ? (
							<Button
								color="primary"
								variant="contained"
								className=""
								aria-label="Register"
								type="button"
								onClick={e => {
									dispatch(
										openDialog({
											children: (
												<OpenDialogForm
													title={t('임시해제 제어')}
													content={t('임시해제를 중지 하시겠습니까?')}
													fnHandleOK={() => {
														stopReleaseBlocking(props.requestObj.rid)
															.then(result => {
																if (result) {
																	dispatch(
																		showMessage({
																			message: t('해제를 중지하였습니다.')
																		})
																	);
																	props.fnReset();
																}
															})
															.catch(err => {
																dispatch(
																	showErrorMessage({
																		message: t('중지에 실패하였습니다.')
																	})
																);
															});
													}}
												/>
											)
										})
									);
								}}
							>
								{t('중지')}
							</Button>
						) : (
							<></>
						)
					) : (
						<></>
					)}
				</div>
			</Dialog>
		</div>
	);
}
