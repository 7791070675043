import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Button,
	TextField,
	Backdrop,
	CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	titleWrapper: {
		'padding-bottom': '0'
	},
	actionsWrapper: {
		padding: '16px 24px 24px'
	},
	backdrop: {
		zIndex: 100,
		color: '#fff'
	}
}));

export default function DeleteUserModal(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();

	const [options, setOptions] = React.useState([]);

	React.useEffect(() => {
		setOptions([{ user_id: '-', label: t('DELETE') }, ...props.users.filter(user => user.user_id !== props.deleteID)]);
	}, [props.users, props.deleteID]);
	return (
		<div>
			<Dialog open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.titleWrapper} id="alert-dialog-slide-title">{`${props.userID}(${props.name})${t(
					'DELETE_CONTENT'
				)}`}</DialogTitle>
				<DialogContent className="overflow-y-hidden">
					<DialogContentText id="alert-dialog-slide-description">
						{t('USER_DELETE_CONTENT_1')}
						<br />
						{t('USER_DELETE_CONTENT_2')}
					</DialogContentText>
					<Autocomplete
						id="handover-auto"
						options={options}
						getOptionLabel={option => {
							if (option === '') return '';

							return option.user_id === '-' ? t('DELETE') : `${option.name}(${option.user_id})`;
						}}
						autoComplete
						includeInputInList
						value=""
						onChange={(e, newVal) => {
							if (newVal) {
								props.selectedHandoverUserID(newVal);
							}
						}}
						renderInput={params => <TextField {...params} label={t('USER_SELECT')} variant="outlined" />}
						fullWidth
						size="small"
					/>
				</DialogContent>
				<DialogActions className={classes.actionsWrapper}>
					<Button
						onClick={props.fnHandleClose}
						color="primary"
						variant="contained"
						className="w-full mx-auto"
						aria-label="cancel"
						type="button"
					>
						{t('CANCEL')}
					</Button>
					<Button
						variant="contained"
						color="primary"
						className="w-full mx-auto"
						type="button"
						onClick={props.fnHandleOK}
					>
						{t('DELETE')}
					</Button>
				</DialogActions>

				<Backdrop className={classes.backdrop} open={props.openBackdrop}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Dialog>
		</div>
	);
}
