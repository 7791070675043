import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React from 'react';

function UsersTableHead(props) {
	const { t } = useTranslation('docbase');
	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);

	const createSortHandler = property => event => {
		props.onRequestSort(event, property);
	};

	const headerCols = [
		{
			id: 'user_id',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('ID'),
			sort: true
		},
		{
			id: 'name',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('NAME'),
			sort: true
		},
		{
			id: 'local_account',
			align: 'center',
			className: '',
			disablePadding: false,
			label: t('LOCAL_ACCOUNT'),
			sort: true
		},
		{
			id: 'no_sync_osr',
			align: 'center',
			className: '',
			disablePadding: false,
			label: t('NO_SYNC_OSR'),
			sort: true
		},
		{
			id: 'allow_multiple_logins',
			align: 'center',
			className: '',
			disablePadding: false,
			label: t('MULTIPLE_LOGINS'),
			sort: true
		},
		{
			id: 'access_level',
			align: 'center',
			className: '',
			disablePadding: false,
			label: t('ACCESS_LEVEL'),
			sort: true
		},
		{
			id: 'user_delete',
			align: 'center',
			className: '',
			disablePadding: false,
			label: t('DELETE'),
			sort: false
		}
	];

	return (
		<TableHead>
			<TableRow>
				{/* <TableCell padding="none" className="w-40 md:w-64 text-center z-99">
					<Checkbox
						color="secondary"
						size="small"
						indeterminate={numSelected > 0 && numSelected < props.rowCount}
						checked={props.rowCount !== 0 && numSelected === props.rowCount}
						onChange={props.onSelectAllClick}
					/>
				</TableCell> */}

				{headerCols.map(row => {
					if (!sysConfigure.OSR_support && (row.id === 'no_sync_osr' || row.id === 'local_account')) {
						return '';
					}

					if (sysConfigure.menu_support_type === 2 && row.id === 'allow_multiple_logins') {
						return '';
					}

					return (
						<TableCell
							className={row.className}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={props.order.id === row.id ? props.order.direction : false}
						>
							{row.sort ? (
								<TableSortLabel
									active={props.order.id === row.id}
									direction={props.order.direction}
									onClick={createSortHandler(row.id)}
								>
									{row.label}
								</TableSortLabel>
							) : (
								row.label
							)}
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default UsersTableHead;
