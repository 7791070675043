import OfflineDrivePage from './OfflineDrivePage';

const OfflineDriveConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/offlineDrive',
			component: OfflineDrivePage
		}
	]
};

export default OfflineDriveConfig;
