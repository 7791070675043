import GlobalSetting from './GlobalSetting';
import PrivateSetting from './PrivateSetting';

const CollectorConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{ path: '/g_collector', component: GlobalSetting },
		{ path: '/p_collector', component: PrivateSetting }
	]
};

export default CollectorConfig;
