import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import ExportApprovalTable from './ExportApprovalTable';
import DetailSidebarContent from './DetailSidebarContent';

import ContentHeader from '../component/ContentHeader';

function ExportApprovalPage() {
	const pageLayout = React.useRef(null);
	const [selectedFid, setSelectedFid] = React.useState(-1);
	const [path, setPath] = React.useState('');

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<ExportApprovalTable
					selected={selectedFid}
					fnSetSelectedFid={fid => setSelectedFid(fid)}
					fnSetPath={p => setPath(p)}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			rightSidebarContent={
				<DetailSidebarContent
					selected={selectedFid}
					path={path}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default ExportApprovalPage;
