// import FuseSearch from '@fuse/core/FuseSearch';
// import FuseShortcuts from '@fuse/core/FuseShortcuts';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
// import QuickPanelToggleButton from 'app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton';
import { List, ListItem, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import commonUtils from 'app/utils/CommonUtils';
// import FullScreenToggle from '../../shared-components/FullScreenToggle';
// import LanguageSwitcher from '../../shared-components/LanguageSwitcher';

const useStyles = makeStyles(theme => ({
	root: {},
	productInfoLi: {
		'padding-top': 0,
		'padding-bottom': 0
	},
	productInfoTextDiv: {
		'margin-top': '0',
		'margin-bottom': '0',
		'&>span': {
			'font-size': '11px',
			cursor: 'default'
		}
	}
}));

function ToolbarLayout1(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const toolbarTheme = useSelector(selectToolbarTheme);
	const productInfo = useSelector(({ auth }) => auth.initial.productInfo);
	const classes = useStyles(props);
	const { t } = useTranslation('docbase');

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className={clsx(classes.root, 'flex relative z-10 shadow-md')}
				color="default"
				style={{ backgroundColor: toolbarTheme.palette.background.paper }}
			>
				<Toolbar className="p-0 min-h-48 md:min-h-64">
					{config.navbar.display && config.navbar.position === 'left' && (
						<Hidden lgUp>
							<NavbarMobileToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
						</Hidden>
					)}
					<div className="flex flex-1">
						{/* 사용안하는 영역 주석 youngmon 2021-01-26
						<Hidden mdDown>
							<FuseShortcuts className="px-16" />
						</Hidden>
						*/}
					</div>

					<div className="flex items-center px-16">
						{/* 사용안하는 영역 주석 youngmon 2021-01-26
						<LanguageSwitcher />

						<FuseSearch />
						<FullScreenToggle />

						<QuickPanelToggleButton />
						 */}

						<List component="nav" aria-label="secondary mailbox folders">
							<ListItem className={classes.productInfoLi}>
								<ListItemText className={classes.productInfoTextDiv} primary={`${t('LICENSES_TITLE')}`} />
							</ListItem>
							<ListItem className={classes.productInfoLi}>
								<ListItemText
									className={classes.productInfoTextDiv}
									primary={`${t('PRODUCTKEY_STATE')}: ${
										productInfo.validProductKey ? t('VALID') : t('INVALID')
									}`}
								/>
							</ListItem>
							<ListItem className={classes.productInfoLi}>
								<ListItemText
									className={classes.productInfoTextDiv}
									primary={`${t('USER_LICENSES')}: ${productInfo.userLicenses}`}
								/>
							</ListItem>
							<ListItem className={classes.productInfoLi}>
								<ListItemText
									className={classes.productInfoTextDiv}
									primary={`${t('EXPIRE_AT')}: ${commonUtils.getDateStringToLocalDate(productInfo.expireAt)}`}
								/>
							</ListItem>
							<ListItem className={classes.productInfoLi}>
								<ListItemText
									className={classes.productInfoTextDiv}
									primary={`${t('MAINTENANCE_AT')}: ${commonUtils.getDateStringToLocalDate(
										productInfo.settledExpireMaintenance
											? productInfo.expireMaintenanceAt
											: productInfo.expireAt
									)}`}
								/>
							</ListItem>
						</List>
					</div>

					{config.navbar.display && config.navbar.position === 'right' && (
						<Hidden lgUp>
							<NavbarMobileToggleButton />
						</Hidden>
					)}
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default React.memo(ToolbarLayout1);
