import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	IconButton,
	Icon,
	Tooltip,
	Paper,
	Input,
	Button,
	TablePagination
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { inquiryPrintLogs, inquiryPrintLogsPage } from 'app/store/docbase/admin/dlpSlice';
import CustomIcon from 'app/main/icon/CustomIcon';
import excelUtils from 'app/utils/ExcelUtils';
import commonUtils from 'app/utils/CommonUtils';
import PrintLogTableHead from './PrintLogTableHead';
import ViewDialog from './ViewDialog';

const useStyles = makeStyles(theme => ({
	root: {
		marginRight: 30,
		borderRadius: 6,
		'& .search-form-wrapper': {
			display: 'block!important',
			flex: '1 1 auto',
			'& .content': { display: 'flex', marginBottom: 10 },
			'& .input-item': { width: 180 },
			'& .date-picker-wrapper': { marginLeft: '10px!important', marginRight: '0!important' }
		},
		'& .button-group': {
			display: 'flex',
			'& button:first-child': {
				marginRight: 5,
				width: 80
			}
		}
	},
	datePicker: {
		width: ' 190px!important'
	},
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 328}px`,
		padding: '0 20px'
	},
	icon: {
		width: 20,
		height: 20
	},
	btn01: {
		marginRight: 10
	}
}));

function PrintLogTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const [loading, setLoading] = useState(true);
	const [dialogObj, setDialogObj] = React.useState({ open: false, logObj: {} });

	const [searchObj, setSearchObj] = React.useState({
		userID: '',
		clientID: '',
		userName: '',
		startDate: commonUtils.getLastWeek(),
		endDate: new Date()
	});
	const [pageInfo, setPageInfo] = React.useState({ total_rows: 0, total_pages: 0, current_page: 0, rows_per_page: 100 });
	const [data, setData] = useState([]);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 328}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		setLoading(true);
		doSearch(pageInfo.rows_per_page);
		return () => {
			setSearchObj({ userID: '', clientID: '', startDate: new Date(), endDate: new Date() });
		};
	}, [dispatch]);

	if (loading) {
		return <FuseLoading />;
	}
	async function doSearch(rowsPerPage) {
		setLoading(true);
		const respData = await inquiryPrintLogs(
			searchObj.userID,
			searchObj.clientID,
			searchObj.userName,
			commonUtils.getDateString(searchObj.startDate),
			commonUtils.getDateString(searchObj.endDate),
			rowsPerPage
		);

		if (respData.rows > 0) {
			await getPage(1);
		} else {
			setData([]);
		}
		setPageInfo({
			...pageInfo,
			total_rows: respData.rows,
			total_pages: respData.pages,
			rows_per_page: rowsPerPage,
			current_page: 1
		});
		setLoading(false);
	}

	async function getPage(page) {
		const logs = await inquiryPrintLogsPage(page);
		setData(logs);
		setPageInfo({ ...pageInfo, current_page: page });
	}

	async function downloadExcel() {
		// props.fnDoSearch();
		const titles = [
			t('DLP_CLIENT_LOG_LOGGED_AT'),
			t('USER'),
			t('DLP_CLIENT_LOG_PRINT_DOC_FORMAT'),
			t('DLP_CLIENT_LOG_PRINT_JOB_NAME'),
			t('DLP_CLIENT_LOG_PRINT_COPIES'),
			t('DLP_CLIENT_LOG_PRINT_JOB_CREATED_AT'),
			t('DLP_CLIENT_LOG_PRINT_JOB_PROCESSING_AT'),
			t('DLP_CLIENT_LOG_PRINT_JOB_COMPLETED_AT')
		];
		const contents = [];
		const colSizes = [
			{ wpx: 80 },
			{ wpx: 80 },
			{ wpx: 80 },
			{ wpx: 150 },
			{ wpx: 80 },
			{ wpx: 80 },
			{ wpx: 80 },
			{ wpx: 80 }
		];

		for (let i = 1; i <= pageInfo.total_pages; i++) {
			// eslint-disable-next-line no-await-in-loop
			const l = await inquiryPrintLogsPage(i);
			l.forEach(obj => {
				contents.push({
					registered_at: commonUtils.getDateStringToLocalDate(obj.registered_at),
					name: `${obj.user_name}(${obj.user_id}-${obj.client_id})`,
					doc_format: obj.doc_format,
					job_name: obj.job_name,
					copies: obj.copies,
					job_created_at: commonUtils.getDateStringToLocalDate(obj.job_created_at),
					job_processing_at: commonUtils.getDateStringToLocalDate(obj.job_processing_at),
					job_completed_at: commonUtils.getDateStringToLocalDate(obj.job_completed_at)
				});
			});
		}

		excelUtils.excelDownload(
			`${t('프린트로그')}${commonUtils.getDateString(searchObj.startDate)}-${commonUtils.getDateString(searchObj.endDate)}`,
			titles,
			contents,
			colSizes
		);
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<ViewDialog
				open={dialogObj.open}
				logObj={dialogObj.logObj}
				fnHandleClose={() => setDialogObj({ open: false, logObj: {} })}
			/>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="total-cnt-info">
						<span>
							{t('TOTAL')} <span className="count">{pageInfo.total_rows}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div className="content">
							<div className="input-item">
								<span className="title">{t('DELETE_USER_ID')}</span>
								<Input
									className="search-input"
									disableUnderline
									fullWidth
									value={searchObj.userID}
									inputProps={{
										'aria-label': 'Search'
									}}
									onChange={ev => setSearchObj({ ...searchObj, userID: ev.target.value })}
								/>
							</div>
							<div className="input-item">
								<span className="title">{t('DELETE_USER_NAME')}</span>
								<Input
									className="search-input"
									disableUnderline
									fullWidth
									value={searchObj.userName}
									inputProps={{
										'aria-label': 'Search'
									}}
									size="small"
									onChange={ev => setSearchObj({ ...searchObj, userName: ev.target.value })}
								/>
							</div>

							<div className="input-item">
								<span className="title">{t('RESTORE_DAY')}</span>
								<div className="date-picker-wrapper">
									<div className="">
										<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
											<KeyboardDatePicker
												className={classes.datePicker}
												fullWidth
												disableToolbar
												invalidDateMessage=""
												autoOk
												size="small"
												variant="inline"
												inputVariant="outlined"
												format="yyyy/MM/dd"
												id="start_date"
												maxDate={new Date('2999-12-31')}
												value={searchObj.startDate}
												onChange={d => {
													// eslint-disable-next-line no-restricted-globals
													if (!isNaN(d.getTime())) {
														if (searchObj.startDate.getTime() !== d.getTime())
															setSearchObj({ ...searchObj, startDate: d });
													}
												}}
												// onBlur={e => {
												// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
												// 	if (!isNaN(val.getTime()) && props.searchObj.startDate.getTime() !== val.getTime())
												// 		props.fnSetSearchObj('startDate', val);
												// }}
												KeyboardButtonProps={{
													'aria-label': 'change date start'
												}}
											/>
										</MuiPickersUtilsProvider>
									</div>
									<div className="tilde">~</div>
									<div className="">
										<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
											<KeyboardDatePicker
												className={classes.datePicker}
												fullWidth
												disableToolbar
												invalidDateMessage=""
												autoOk
												size="small"
												variant="inline"
												inputVariant="outlined"
												format="yyyy/MM/dd"
												id="end_date"
												maxDate={new Date('2999-12-31')}
												value={searchObj.endDate}
												onChange={d => {
													// eslint-disable-next-line no-restricted-globals
													if (!isNaN(d.getTime())) {
														if (searchObj.endDate.getTime() !== d.getTime())
															setSearchObj({ ...searchObj, endDate: d });
													}
												}}
												// onBlur={e => {
												// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
												// 	if (!isNaN(val.getTime()) && props.searchObj.endDate.getTime() !== val.getTime())
												// 		props.fnSetSearchObj('endDate', val);
												// }}
												KeyboardButtonProps={{
													'aria-label': 'change date end'
												}}
											/>
										</MuiPickersUtilsProvider>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="button-group">
						<Button
							variant="contained"
							className="default"
							type="button"
							onClick={() => {
								doSearch(pageInfo.rows_per_page);
							}}
						>
							{t('SEARCH')}
						</Button>

						<Tooltip title={t('EXCEL_DOWNLOAD')} placement="bottom">
							<IconButton
								key="download"
								aria-label="download"
								color="secondary"
								onClick={() => {
									downloadExcel();
								}}
							>
								<CustomIcon className="small" type="download" fill="#333d4b" />
							</IconButton>
						</Tooltip>
					</div>
				</div>

				{/* <FuseAnimate animation="transition.expandIn" delay={800}>
					<Fab
						color="default"
						aria-label="edit"
						className={clsx(
							'absolute right-60 mx-16 -mb-28 z-10 top-10 md:top-13 md:hidden w-45 h-45 md:w-56 md:h-56'
						)}
						onClick={e => props.fnToggleRightSidebar()}
					>
						<Icon>edit_icon</Icon>
					</Fab>
				</FuseAnimate> */}
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<PrintLogTableHead />

						<TableBody>
							{data.map(d => {
								return (
									<TableRow
										className={clsx(classes.tr, 'course-pointer')}
										hover
										role="checkbox"
										tabIndex={-1}
										key={d.fid}
										selected={d.fid === props.selected}
										onClick={event => {}}
									>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{commonUtils.getDateStringToLocalDate(d.registered_at)}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{`${d.user_name}(${d.user_id}-${d.client_id})`}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{d.doc_format}
										</TableCell>
										<TableCell className="cursor-pointer" component="td" scope="row" align="center">
											{d.job_name}
										</TableCell>
										<TableCell className="cursor-pointer" component="td" scope="row" align="center">
											{d.copies}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{commonUtils.getDateStringToLocalDate(d.job_created_at)}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{commonUtils.getDateStringToLocalDate(d.job_processing_at)}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{commonUtils.getDateStringToLocalDate(d.job_completed_at)}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											<div>
												<Tooltip title={t('DLP_CLIENT_LOG_VIER')} arrow>
													<IconButton
														onClick={e => {
															setDialogObj({ open: true, logObj: d });
														}}
													>
														<Icon style={{ color: '#575757' }}>pageview</Icon>
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>
			</Paper>
			<TablePagination
				className="flex-shrink-0"
				component="div"
				count={pageInfo.total_rows}
				rowsPerPage={pageInfo.rows_per_page}
				page={pageInfo.current_page}
				labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
				labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
				backIconButtonProps={{
					'aria-label': t('PAGE_PREV_BTN_LABEL')
				}}
				nextIconButtonProps={{
					'aria-label': t('PAGE_NEXT_BTN_LABEL')
				}}
				onChangePage={(event, value) => {
					getPage(value);
				}}
				onChangeRowsPerPage={event => {
					setPageInfo({ ...pageInfo, rows_per_page: Number(event.target.value), current_page: 0 });
				}}
			/>
		</div>
	);
}

export default PrintLogTable;
