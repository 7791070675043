import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TablePagination,
	Tooltip,
	IconButton,
	Icon,
	Paper,
	CircularProgress
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { releaseUserHoldFiles } from 'app/store/docbase/admin/systemSlice';
import clsx from 'clsx';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import commonUtils from 'app/utils/CommonUtils';
import CustomIcon from 'app/main/icon/CustomIcon';
import HoldFilesTableHead from './HoldFilesTableHead';
import OpenDialogForm from '../component/OpenDialogForm';

const useStyles = makeStyles(theme => ({
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 296}px`,
		padding: '0 20px'
	}
}));

let timerHoldFiles;
function HoldFilesTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const { t } = useTranslation('docbase');

	const { holdFiles } = useSelector(({ docbase }) => docbase.admin.system);

	const [order, setOrder] = React.useState({
		id: 'document_path',
		direction: 'asc'
	});

	const [rowsPerPage, setRowsPerPage] = React.useState(100);
	const [currentPage, setCurrentPage] = React.useState(1);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 296}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {}, [dispatch]);

	useEffect(() => {
		let isRelease = false;
		holdFiles.forEach(holdFile => {
			if (holdFile.scheduled_release) {
				isRelease = true;
			}
		});
		if (isRelease) {
			if (!timerHoldFiles) {
				timerHoldFiles = setInterval(() => {
					props.fnDoSearch(false);
				}, 3000);
			}
		} else {
			clearInterval(timerHoldFiles);
			timerHoldFiles = undefined;
		}
	}, [holdFiles]);

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	if (props.loading) {
		return <FuseLoading />;
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="total-cnt-info">
						<span>
							{t('TOTAL')} <span className="count">{0}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<IconButton
							onClick={e => {
								props.fnDoSearch(false);
							}}
						>
							<CustomIcon className="small" type="refresh" fill="#333d4b" />
						</IconButton>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<HoldFilesTableHead
							order={order}
							onRequestSort={(e, property) => handleRequestSort(e, property)}
							searchObj={props.searchObj}
							rowCount={holdFiles.length}
							fnSearch={userID => {
								if (props.searchObj.userID !== userID) {
									props.fnSetSearchObj(userID);
								}
							}}
						/>
						<TableBody>
							{_.orderBy(
								holdFiles,
								[
									d => {
										switch (order.id) {
											case 'document_path': {
												return d.path;
											}
											case 'user': {
												return d.user_name;
											}
											case 'start_time': {
												return `${d.year}-${d.month}-${d.day} ${d.hour}:${d.minute}:${d.second}`;
											}
											default: {
												return d[order.id];
											}
										}
									}
								],
								[order.direction]
							).map((file, idx) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={idx}>
										<TableCell className="left" align="left" component="td" scope="row">
											<div>
												<p className="log-name">{commonUtils.splitLastDelimiterString(file.path)}</p>
												<p className="log-path">{commonUtils.splitPreDelimiter(file.path)}</p>
											</div>
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{file.scheduled_release ? (
												<div style={{ padding: '12px' }}>
													<CircularProgress
														className="align-middle ml-5"
														style={{ width: '24px', height: '24px' }}
														color="secondary"
													/>
												</div>
											) : (
												<Tooltip title={t('RELEASE')} arrow>
													<IconButton
														onClick={e => {
															dispatch(
																openDialog({
																	children: (
																		<OpenDialogForm
																			title={t(
																				`[${file.user_name}(${file.user_id})] ${t(
																					'HOLD_FILES_CONF_CONTENT'
																				)}`
																			)}
																			fnHandleOK={() => {
																				releaseUserHoldFiles(file.user_id)
																					.then(() => {
																						dispatch(
																							showMessage({
																								message: t('HOLD_FILES_MESSAGE_1')
																							})
																						);
																						props.fnDoSearch(false);
																					})
																					.catch(err => {});
																			}}
																		/>
																	)
																})
															);
														}}
													>
														<Icon className={classes.deleteIcon}>no_encryption</Icon>
													</IconButton>
												</Tooltip>
											)}
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											{`${file.user_name}(${file.user_id})`}
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											{`${commonUtils.getSecToTime(file.since_secs)}`}
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											{commonUtils.getDateStringToLocalDate(
												`${file.year}-${file.month}-${file.day} ${file.hour}:${file.minute}:${file.second}`
											)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>

				<TablePagination
					className="flex-shrink-0"
					component="div"
					count={holdFiles.length}
					rowsPerPage={rowsPerPage}
					page={currentPage - 1}
					labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
					labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
					backIconButtonProps={{
						'aria-label': t('PAGE_PREV_BTN_LABEL')
					}}
					nextIconButtonProps={{
						'aria-label': t('PAGE_NEXT_BTN_LABEL')
					}}
					onChangePage={(e, value) => {
						setCurrentPage(value);
					}}
					onChangeRowsPerPage={e => {
						setRowsPerPage(Number(e.target.value));
					}}
				/>
			</Paper>
		</div>
	);
}

export default HoldFilesTable;
