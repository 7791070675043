import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .seq': {
			width: 100
		},
		'& .level': {
			width: 100
		},
		'& .delete': {
			width: 80
		}
	}
}));

function SecureDocsTableHead(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();

	const headerCols = [
		// {
		// 	id: 'doc_id',
		// 	align: 'left',
		// 	className: 'left seq',
		// 	disablePadding: false,
		// 	label: t('SECUREDOC_DOCID'),
		// 	sort: false
		// },
		{
			id: 'level',
			align: 'left',
			className: 'left level',
			disablePadding: false,
			label: t('ACCESS_LEVEL'),
			sort: false
		},
		{
			id: 'path',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('SECUREDOC_PATH'),
			sort: false
		}
	];

	return (
		<TableHead>
			<TableRow className={classes.tr}>
				{headerCols.map(row => {
					return (
						<TableCell
							className={row.className}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={false}
						>
							{row.label}
						</TableCell>
					);
				}, this)}
				<TableCell className="delete" padding="none" sortDirection={false} />
			</TableRow>
		</TableHead>
	);
}

export default SecureDocsTableHead;
