import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle, Button, Slide, TextField } from '@material-ui/core';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { useDispatch } from 'react-redux';
import securityUtils from 'app/utils/SecurityUtils';
import { showErrorMessage } from 'app/store/fuse/messageSlice';
import { changePassword } from 'app/store/docbase/admin/accountSlice';
import clsx from 'clsx';

const isMobile = sessionStorage.getItem('device') === 'mobile';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		'& .MuiPaper-root': {
			padding: 0,
			width: '100%',
			maxWidth: 673,
			background: '#f2f3f5',
			'&>div': { padding: 30 }
		}
	},
	contentWrapper: {
		height: 250
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		padding: 0,
		height: 60,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		'& .MuiTypography-root': {
			fontSize: 16,
			fontWeight: 'normal'
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 40,
		'& .MuiButton-root': {
			width: 172,
			height: 54
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

export default function ChangeDialog(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation('docbase');
	const inputName = React.useRef(null);

	const [curPassword, setCurPassword] = React.useState('');
	const [newPassword, setNewPassword] = React.useState('');
	const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('');

	React.useEffect(() => {
		setNewPassword('');
		setNewPasswordConfirm('');
	}, [props.open]);

	React.useEffect(() => {
		if (props.curPassword && props.curPassword !== '') {
			setCurPassword(props.curPassword);
			if (props.open) {
				inputName.current.focus();
			}
		}
	}, [props.curPassword]);
	return (
		<div>
			<Dialog
				className={clsx(classes.root, isMobile ? 'mobile' : '')}
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				maxWidth="xs"
				onClose={props.fnHandleClose}
			>
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					<span className="align-middle">{t('CHANGE_PASSWORD')}</span>
				</DialogTitle>
				<DialogContent className="modal-body">
					<div className="content">
						<div className="row">
							<div className="title">
								<span>{t('CURRENT_PASSWORD')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="password"
									name="cur-password"
									value={curPassword}
									onChange={e => setCurPassword(e.target.value)}
									variant="outlined"
									size="small"
									required
									fullWidth
									inputProps={{ autocapitalize: 'off' }}
								/>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('NEW_PASSWORD')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="password"
									name="new-password"
									value={newPassword}
									onChange={e => setNewPassword(e.target.value)}
									variant="outlined"
									size="small"
									required
									fullWidth
									inputProps={{ autocapitalize: 'off' }}
									inputRef={inputName}
								/>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('NEW_PASSWORD')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="password"
									name="conf-password"
									value={newPasswordConfirm}
									onChange={e => setNewPasswordConfirm(e.target.value)}
									variant="outlined"
									size="small"
									required
									fullWidth
									inputProps={{ autocapitalize: 'off' }}
									inputRef={inputName}
								/>
							</div>
						</div>
						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.fnHandleClose}
								variant="contained"
								className="default"
								aria-label="cancel"
								type="button"
							>
								{t('CANCEL')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className=""
								disabled={
									(!newPassword && newPassword.length <= 0) ||
									(!newPasswordConfirm && newPasswordConfirm.length <= 0)
								}
								type="button"
								onClick={e => {
									if (newPassword !== newPasswordConfirm) {
										dispatch(showErrorMessage({ message: t('CHANGE_PASSWORD_ERROR_MESSAGE_1') }));
									} else {
										changePassword(curPassword, newPassword)
											.then(data => {
												localStorage.setItem('auth', securityUtils.encryptAESCTR(newPassword));
												props.fnHandleComplete();
											})
											.catch(err => {
												let msg = '';
												if (err.error === commonTypes.SERVER_ERROR_PASSWORD_SAME) {
													msg = t('CHANGE_PASSWORD_ERROR_MESSAGE_2');
												} else if (err.error === commonTypes.SERVER_ERROR_WRONG_ID_PASSWORD) {
													msg = t('CHANGE_PASSWORD_ERROR_MESSAGE_3');
												} else if (err.error === commonTypes.SERVER_ERROR_PASSWORD_NOT_STRONG_ENOUGH) {
													msg = t('CHANGE_PASSWORD_ERROR_MESSAGE_4');
												}
												dispatch(showErrorMessage({ message: msg }));
											});
									}
								}}
							>
								{t('CHANGE')}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
