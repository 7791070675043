import axios from 'axios';
import streamSaver from 'streamsaver';
import securityUtils from './SecurityUtils';

const url = window.location.origin + (window.location.protocol === 'https:' && window.location.port === '' ? ':443' : '');

class HttpClient {
	/**
	 * json object의 {key, value}를 string형태의 파라미터로 변경해 준다.
	 * json array의 값도 string 형태로 변경해 준다.
	 *
	 * @param {object} parameterObj
	 */
	jsonToParameter(parameterObj) {
		let returnParm = '';
		let cnt = 0;
		Object.keys(parameterObj).forEach(key => {
			let value = '';
			if (typeof parameterObj[key] === 'string') value = parameterObj[key];
			else value = JSON.stringify(parameterObj[key]);

			if (cnt === 0) returnParm = `${returnParm + encodeURI(key)}=${encodeURIComponent(value)}`;
			else returnParm = `${returnParm}&${encodeURI(key)}=${encodeURIComponent(value)}`;

			cnt++;
		});
		return returnParm;
	}

	/**
	 * 파라미터와 파일을 서버에 전송 할 수 있는 포맷으로 변경해준다.
	 * 전송할 files.length 와 fileParameterNames의 길이가 같아야한다.
	 *
	 * @param {object} parameterObj
	 * @param {object[]} files 전송 할 파일들
	 * @param {string[]} fileParameterNames 전송 할 파일이름들
	 */
	jsonToFormData(parameterObj, files, fileParameterNames) {
		const frm = new FormData();
		if (files.length !== fileParameterNames.length) {
			console.log('The lengths of the files and fileParameterNames do not match.');
			return frm;
		}

		Object.keys(parameterObj).forEach(key => {
			let val = '';
			if (typeof parameterObj[key] === 'string') {
				val = parameterObj[key];
			} else {
				val = JSON.stringify(parameterObj[key]);
			}
			frm.append(key, val);
		});

		for (let i = 0; i < files.length; i++) {
			frm.append(fileParameterNames[i], files[i]);
		}

		return frm;
	}

	/**
	 * api로 요청을 보낸 후 결과 전달.
	 *
	 * @param {string} api
	 * @param {object} parameter
	 */
	sendPost(api, parameter) {
		return new Promise((resolve, reject) => {
			const params = this.jsonToParameter(parameter);
			// let time = new Date().getTime();
			// console.log(url+api)
			axios
				.post(url + api, params, {
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					withCredentials: true
				})
				.then(resp => {
					resolve(resp.data);
				})
				.catch(e => {
					console.error(e);
					reject(e);
				})
				.finally(() => {
					// console.log(api + " || time: " + (new Date().getTime() - time));
				});
		});
	}

	/**
	 * api로 요청을 보낸 후 결과 전달.
	 *
	 * @param {string} api
	 * @param {object} parameter
	 */
	sendGet(api, parameter) {
		return new Promise((resolve, reject) => {
			const params = this.jsonToParameter(parameter);
			// let time = new Date().getTime();
			// console.log(url+api)
			axios
				.get(`${url + api}?${params}`, {
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					withCredentials: true
				})
				.then(resp => {
					resolve(resp.data);
				})
				.catch(e => {
					console.error(e);
				})
				.finally(() => {
					// console.log(api + " || time: " + (new Date().getTime() - time));
				});
		});
	}

	/**
	 * response의 data에 binary값이 넘어오는 요청을 받는 함수.
	 * data로 넘어온 값을 blob로 받아서 대응하는 URL을 돌려준다.
	 *
	 * 요청에 대한 응답의 data가 이미지로 오는 경우... <img />의 src에 사용하면 됨.
	 * @param {string} api
	 * @param {object} parameter
	 */
	sendPostRespFileURL(api, parameter) {
		return new Promise((resolve, reject) => {
			const params = this.jsonToParameter(parameter);
			axios
				.post(api, params, {
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					responseType: 'arraybuffer'
				})
				.then(resp => {
					const blob = new Blob([resp.data], { type: resp.headers['content-type'] });
					const fileURL = URL.createObjectURL(blob);
					resolve(fileURL);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	/**
	 * Content-Type: multipart/form-data로 전송시 사용.
	 * 전달할 파일과 파일의 파라미터명을을 배열로 같이 입력해야하며. 둘의 길이가 다를 경우 파일을 dataForm에 추가하지 않는다.
	 * 기타 파라미터는 params에 JSON형식으로 넘겨준다.
	 *
	 * @param {string} api
	 * @param {object} params
	 * @param {object[]} files
	 * @param {string[]} fileParameterNames
	 * @param {Function} fnCallback
	 */
	sendPostMultipartForm(api, params, files, fileParameterNames, fnCallback) {
		const frm = this.jsonToFormData(params, files, fileParameterNames);
		axios
			.post(api, frm, { headers: { 'Content-Type': 'multipart/form-data' } })
			.then(resp => fnCallback(resp))
			.catch(e => {
				console.error(e);
			});
	}

	/**
	 * response의 data에 binary값이 넘어오는 요청을 받아서 다운로드가능하게 만든다.
	 *
	 * @param {string} api
	 * @param {object} parameter
	 */
	downloadFile(api, parameter) {
		return new Promise((resolve, reject) => {
			const params = this.jsonToParameter(parameter);

			fetch(`${api}?${params}`).then(res => {
				let fileName = 'undefined';
				if (res.headers.get('content-disposition')) {
					const splitVal = res.headers.get('content-disposition').split(';');
					const contentDisposition = {};
					splitVal.forEach((value, key) => {
						const tmp = value.trim().split('=');
						contentDisposition[tmp[0]] = tmp[1] ? securityUtils.decodeUTF8(tmp[1].replaceAll('"', '')) : '';
					});
					fileName = contentDisposition.filename;
				}
				const fileStream = streamSaver.createWriteStream(fileName);
				// if (window.WritableStream && res.body.pipeTo) {
				//     return res.body.pipeTo(fileStream).then(() => console.log('done writing'))
				// }

				// window.writer = fileStream.getWriter();
				// const reader = res.body.getReader();
				// const pump = () =>
				// 	reader.read().then(r => {
				// 		console.log(r.value);

				// 		r.done ? window.writer.close() : window.writer.write(r.value).then(pump);
				// 	});

				// pump();
			});
		});
	}

	/**
	 * 통신 후 result: false시 에러 정보를 출력해준다.
	 * @param {objrc} data
	 */
	printError(data) {
		console.error(`error code: ${data.error}`);
		console.error(`error msg : ${data.message}`);
	}

	secureDocDownlaod = () => {};
}

/**
 * AJAX 요청 후 받은 응답을 처리하는 곳.
 * API에 따른 응답처리 이외의 응답 코드나 전체적으로 응답을 받아서 처리 할 경우 사용한다.
 */
axios.interceptors.response.use(
	resp => {
		if (resp.status === 200) {
			if (!resp.data.result) {
				if (resp.data.error === 14 || resp.data.error === 15) {
					localStorage.setItem('doc_user', '');
					localStorage.setItem('two_auth_flag', 0);
					// window.alert('세션 종료');
					window.location.href = window.location.pathname.indexOf('/wc') === 0 ? '/login' : '/admin';
				}
			}
		}
		return resp;
	},
	error => {
		if (error.response === undefined) {
			console.error(error.message);
		}
		return Promise.reject(error);
	}
);
export default new HttpClient();
