import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import ContentHeader from '../component/ContentHeader';
import SettingsContent from './SettingsContent';

function SettingsPage() {
	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<SettingsContent />}
		/>
	);
}

export default SettingsPage;
