import React, { useCallback } from 'react';
import _ from '@lodash';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	TextField,
	Checkbox,
	Typography,
	IconButton,
	Tabs,
	Tab,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { debounce } from 'lodash';
import * as commonTypes from 'app/main/constants/CommonTypes';
import {
	setFolderPermission,
	setReadableFolderPermissions,
	getDirectOrgList,
	readableFolderPermissionObjects,
	getAllOrgFolderList
} from 'app/store/docbase/admin/folderSlice';
import { setShowBackdrop, getAllFoldersPermissions } from 'app/store/docbase/admin/settingSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { updateUser, getUserList } from 'app/store/docbase/admin/accountSlice';
import PermIcon from '../icon/PermIcon';
import OrgTreeView from './OrgTreeView';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		'& .MuiPaper-root': {
			padding: 0,
			width: '100%',
			maxWidth: 673,
			background: '#f2f3f5',
			'&>div': { padding: 30 }
		}
	},
	contentWrapper: {
		height: 400,
		background: '#fff'
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		padding: 0,
		height: 60,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		'& .MuiTypography-root': {
			fontSize: 16,
			fontWeight: 'normal'
		}
	},
	head: {
		paddingBottom: '0px !important',
		'& .search-wrapper': {
			background: '#ffffff',
			borderRadius: 6,
			padding: '15px 30px',
			display: 'flex',
			'& .input-item': { width: 273 },
			'&>button': { marginLeft: 10 }
		}
	},
	body: {
		paddingTop: '10px !important',
		'& .type-tabs': {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			'& .active_bar': {
				background: '#fff'
			},
			'& .list-tab': {
				borderRadius: '6px 6px 0 0',
				minWidth: 118,
				height: 50,
				padding: 0,
				color: '#192141'
			},
			'& .list-tab.Mui-selected': {
				background: '#ffffff',
				color: '#00cfe2'
			}
		},
		'& .result-list': {
			fontSzie: 14,
			boxShadow: 'none',
			marginTop: '-3px',
			'& .cell-check': {
				width: 60
			},
			'& .MuiTableBody-root': { background: '#ffffff' },
			'& .cell-name': {
				'&>div': {
					display: 'flex',
					alignItems: 'center'
				},
				'&>.etc': { position: 'absolute', right: 0, top: 2 }
			},
			'& .MuiIconButton-label': {
				width: 10,
				height: 10
			},
			'& .obj-name>span': {
				marginLeft: 12
			}
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 40,
		'& .MuiButton-root': {
			width: 172,
			height: 54
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

let isClicked = false;

export default function PermDialog(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');

	const { users, usersObj } = useSelector(({ docbase }) => docbase.admin.account);
	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);
	const { readablePermObjs, sharedPerms, orgsChildren, orgFolders } = useSelector(({ docbase }) => docbase.admin.folder);
	const [searchVal, setSearchVal] = React.useState('');
	const [dataList, setDataList] = React.useState(users);
	const [orgsData, setOrgsData] = React.useState({});
	const [orgsExpanded, setOrgsExpanded] = React.useState([]);

	const [checkedList, setCheckedList] = React.useState({});
	const [allCheck, setAllCheck] = React.useState(false);
	const [tabValue, setTabValue] = React.useState(0); //	0: 조직, 1: 사용자
	const [readablePermsData, setReadablePermData] = React.useState({});
	const [sortObj, setSortObj] = React.useState({
		direction: 'asc'
	});

	React.useEffect(() => {
		if (props.open) {
			if (props.type === '0') {
				dispatch(getAllOrgFolderList());
			} else {
				dispatch(readableFolderPermissionObjects());
			}

			filterDataList('');
		} else {
			setDataList([]);
			setSearchVal('');
			setCheckedList({});
			setOrgTree();
		}
	}, [props.open]);

	React.useEffect(() => {
		setSearchVal('');
		filterDataList('');
		setAllCheck(false);
	}, [users, tabValue]);

	React.useEffect(() => {
		setOrgTree();
	}, [orgsChildren]);

	React.useEffect(() => {
		filterDataList(searchVal);
		return () => {
			filterDataList.cancel();
		};
	}, [searchVal]);

	function setOrgTree() {
		const expanded = [];
		Object.keys(orgsChildren).forEach(key => {
			expanded.push(Number(key));
		});
		setOrgsData(orgsChildren);
		setOrgsExpanded(expanded);
	}

	const filterDataList = useCallback(
		debounce(val => {
			const data = [];
			const checks = {};
			if (tabValue === 0) {
				if (val.length > 0) {
					orgFolders.forEach(org => {
						if (org.name.toLowerCase().includes(val.toLowerCase())) {
							data.push(org);
							checks[org.fid] = false;
						}
					});

					setOrgsData({ 0: { children: data } });
					setOrgsExpanded([]);
				} else {
					setOrgTree();
				}
			} else if (props.type === '0') {
				//	조직폴더 사용자
				users.forEach(u => {
					//	이미 권한이 있는 사용자 제거
					if (!props.permsObj[u.user_id]) {
						if (val === '' || u.name.indexOf(val) >= 0 || u.user_id.indexOf(val) >= 0) {
							data.push({ id: u.user_id, name: `${u.name}(${u.user_id})`, path: '' });
							checks[u.user_id] = false;
						}
					}
				});
			} else {
				//	공유폴더 사용자
				const tempReadablePerms = {};
				readablePermObjs.forEach(obj => {
					if (!props.permsObj[obj.obj_id] && obj.obj_type === 1) {
						checks[obj.obj_id] = false;
						if (val === '') {
							data.push({ id: obj.obj_id, name: obj.name, path: '' });
							tempReadablePerms[obj.obj_id] = obj;
						} else if (obj.name.indexOf(val) >= 0) {
							data.push({ id: obj.obj_id, name: obj.name, path: '' });
							tempReadablePerms[obj.obj_id] = obj;
						}
					}
					setReadablePermData(tempReadablePerms);
				});
			}
			setCheckedList(checks);
			setDataList(data);
		}, 100)
	);

	function handleCheckBox(id) {
		setAllCheck(false);
		setCheckedList({
			...checkedList,
			[id]: !checkedList[id]
		});
	}

	function handleCheckAllBox() {
		const checkedObj = {};
		dataList.forEach(item => {
			checkedObj[item.id] = !allCheck;
		});
		setAllCheck(!allCheck);
		setCheckedList(checkedObj);

		if (tabValue === 0) {
			const tempObj = { ...orgsChildren };
			const expanded = [];
			Object.keys(orgsChildren).forEach(key => {
				if (orgsChildren[key]) {
					const array = orgsChildren[key].children.map(child => {
						const obj = { ...child };
						obj.checked = !allCheck;
						return obj;
					});
					tempObj[key] = { children: array };
				}
				expanded.push(Number(key));
			});
			setOrgsData(tempObj);
			setOrgsExpanded(expanded);
		}
	}

	async function setPerm() {
		if (!isClicked) {
			isClicked = true;
			const selects = Object.keys(checkedList);
			if (selects.length > 0) {
				let isSucc = true;
				try {
					dispatch(setShowBackdrop({ show: true }));
					if (props.type === '0') {
						//	조직폴더 권한 추가
						if (tabValue === 0) {
							const permObjs = await getAllFoldersPermissions();
							for (let i = 0; i < selects.length; i++) {
								//	선택한 조직이 자기자신이 아닌경우
								if (checkedList[selects[i]] && props.fid !== selects[i]) {
									for (let j = 0; j < permObjs[selects[i]].length; j++) {
										/* eslint-disable no-await-in-loop */
										await setFolderPermission(
											props.fid,
											permObjs[selects[i]][j].user_id,
											commonTypes.FILE_PERM_READ_WRITE,
											commonTypes.TRASH_PERM_NONE,
											commonTypes.MANAGE_TYPE_NONE,
											0
										);
									}
								}
							}
						} else {
							for (let i = 0; i < selects.length; i++) {
								if (checkedList[selects[i]]) {
									/* eslint-disable no-await-in-loop */
									await setFolderPermission(
										props.fid,
										selects[i],
										commonTypes.FILE_PERM_READ_WRITE,
										commonTypes.TRASH_PERM_NONE,
										commonTypes.MANAGE_TYPE_NONE,
										0
									);
								}
							}
						}
					} else {
						//	공유폴더 권한 추가ㄹ
						const perms = [...sharedPerms];
						selects.forEach(id => {
							if (checkedList[id]) {
								perms.push({
									...readablePermsData[id],
									file_permission: commonTypes.FILE_PERM_READ_WRITE,
									trash_permission: commonTypes.TRASH_PERM_NONE,
									manage_type: commonTypes.MANAGE_TYPE_NONE,
									manage_priority: 100
								});
							}
						});
						await setReadableFolderPermissions(props.fid, perms);
					}
				} catch (err) {
					isSucc = false;
				}

				if (isSucc) {
					props.fnSetPermComplete();
				} else {
					dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_1') }));
				}
				dispatch(setShowBackdrop({ show: false }));
			}
			isClicked = false;
		}
	}

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog">
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('PERM_FOLDER_TITLE')}
				</DialogTitle>

				<DialogActions className={classes.head}>
					<div className="search-wrapper">
						<div className="input-item">
							<TextField
								className=""
								type="text"
								placeholder={t('SEARCH_KEYWORD')}
								name="search_val"
								fullWidth
								value={searchVal}
								variant="outlined"
								size="small"
								onChange={e => setSearchVal(e.target.value)}
							/>
						</div>
						<Button
							variant="contained"
							className="default"
							type="button"
							fullWidth
							onClick={() => handleCheckAllBox()}
						>
							{t('CHECK_ALL')}
						</Button>
						<Button
							variant="contained"
							color="secondary"
							className=""
							type="button"
							fullWidth
							onClick={() => setPerm()}
						>
							{t('ADD')}
						</Button>
					</div>
				</DialogActions>

				<DialogContent className={classes.body}>
					<div>
						<div className="type-tabs">
							<Tabs
								value={tabValue}
								onChange={(ev, val) => {
									setTabValue(val);
								}}
								indicatorColor="secondary"
								textColor="inherit"
								variant="scrollable"
								scrollButtons="off"
								classes={{ indicator: 'active_bar' }}
							>
								<Tab className="list-tab" disableRipple label={t('ORG_FOLDER')} />
								<Tab className="list-tab" disableRipple label={t('USER')} />
							</Tabs>
						</div>
						<div className="result-list">
							<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')}>
								{tabValue === 0 ? (
									<div>
										<OrgTreeView
											className="full"
											childrenObj={orgsData}
											expandedItems={orgsExpanded}
											checkbox
											fnOnChangeChecked={(fid, pid, checked) => {
												const temp = { ...orgsData };
												const targetArrays = orgsData[pid].children.map(child => {
													const obj = { ...child };
													if (obj.fid === fid) {
														obj.checked = checked;
													}
													return obj;
												});
												setOrgsData({ ...orgsData, [pid]: { children: targetArrays } });
												handleCheckBox(fid);
											}}
											fnOnClickHandler={(e, node) => {}}
										/>
									</div>
								) : (
									<DataTable
										data={dataList}
										allCheck={allCheck}
										checkedList={checkedList}
										sysConfigure={sysConfigure}
										sortObj={sortObj}
										setSortObj={obj => setSortObj(obj)}
										handleCheckAllBox={() => handleCheckAllBox()}
										handleCheckBox={userID => handleCheckBox(userID)}
										handleUserSyncOsr={(e, userID) => {
											e.stopPropagation();
											updateUser(usersObj[userID], 'no_sync_osr', true).then(resp => {
												dispatch(
													showMessage({
														message: t('USER_MESSAGE_10')
													})
												);
												dispatch(getUserList());
											});
										}}
										t={t}
									/>
								)}
							</FuseScrollbars>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
const DataTable = React.memo(
	({
		data,
		allCheck,
		checkedList,
		sortObj,
		sysConfigure,
		handleCheckAllBox,
		setSortObj,
		handleCheckBox,
		handleUserSyncOsr,
		t
	}) => {
		return (
			<Table stickyHeader className="w-full min-w-full">
				<TableHead>
					<TableRow>
						<TableCell padding="none" className="cell-check" align="center">
							<Checkbox
								color="secondary"
								size="small"
								indeterminate={!allCheck && Object.values(checkedList).filter(obj => obj).length > 0}
								checked={allCheck}
								onChange={e => handleCheckAllBox()}
							/>
						</TableCell>
						<TableCell padding="none" className="left" align="left">
							<TableSortLabel
								active
								direction={sortObj.direction}
								onClick={e => {
									setSortObj({
										direction: sortObj.direction === 'asc' ? 'desc' : 'asc'
									});
								}}
							>
								{t('USER')}
							</TableSortLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{_.orderBy(
						data,
						[
							d => {
								return d.name;
							}
						],
						[sortObj.direction]
					).map((d, idx) => {
						console.log(d);
						return (
							<TableRow
								className="cursor-pointer relative"
								hover
								role="checkbox"
								tabIndex={-1}
								key={d.id}
								onClick={event => handleCheckBox(d.id)}
							>
								<TableCell className="" padding="none" align="center">
									<Checkbox
										color="secondary"
										size="small"
										checked={checkedList[d.id]}
										tabIndex={-1}
										disableRipple
										name="checked"
										onChange={e => {
											handleCheckBox(d.id);
										}}
									/>
								</TableCell>
								<TableCell className="left cell-name" component="td" scope="row">
									<div className="obj-name">
										<PermIcon type="person" className="small" />
										<span>{d.name}</span>
									</div>

									{!sysConfigure.OSR_support ? (
										''
									) : d.local_account || d.no_sync_osr ? (
										<div className="etc">
											<Button color="inherit" size="small" onClick={e => {}} disabled>
												{t('NO_SYNC_TARGET')}
											</Button>
										</div>
									) : (
										<div className="etc">
											<Button
												style={{ color: '#666' }}
												color="inherit"
												size="small"
												onClick={e => handleUserSyncOsr(e, d.id)}
											>
												{t('NO_SYNC_OSR')}
											</Button>
										</div>
									)}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		);
	},
	(prevProps, nextProps) => {
		return (
			_.isEqual(prevProps.data, nextProps.data) &&
			_.isEqual(prevProps.checkedList, nextProps.checkedList) &&
			prevProps.allCheck === nextProps.allCheck &&
			_.isEqual(prevProps.sortObj, nextProps.sortObj)
		);
	}
);
