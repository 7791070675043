import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import httpClient from 'app/utils/HttpClient';
import securityUtils from 'app/utils/SecurityUtils';
import commonUtils from 'app/utils/CommonUtils';
import { secureRequest, secureAsyncRequest } from 'app/store/docbase/commonAction';

export const getAccessLog = createAsyncThunk('setting/getAccessLog', async ({ startID }) => {
	const respData = await httpClient.sendPost('/api/todayFileAccessLogs', { start_id: startID && startID > 0 ? startID : 0 });
	if (!respData.result) {
		return { logs: [], id: 0 };
	}
	if (startID !== 0 && respData.last_id === 0) {
		return { logs: [], id: startID };
	}
	return { logs: respData.logs, id: respData.last_id + 1 };
});

export const getOtherSiteInfo = name => {
	return new Promise((resolve, reject) => {
		secureRequest(
			(aes, tk) => {
				httpClient.sendPost('/api/getContentSiteInfo', { token: tk, name }).then(data => {
					if (data.result) {
						resolve({
							...data,
							id: securityUtils.decryptAES(aes, data.id, 'hex', 'utf8'),
							password: securityUtils.decryptAES(aes, data.password, 'hex', 'utf8')
						});
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
			}
			// resolve({ form_id: 'id', form_password: 'password', id: 'admin', password: '1234', login_url: 'docbase.syslogic.co.kr:10060/dashboard' });
		);
	});
};
const etcSlice = createSlice({
	name: 'etc',
	initialState: {},
	reducers: {},
	extraReducers: {
		// [getAccessLog.fulfilled]: (state, action) => {
		// 	if (action.payload.logs.length > 0) {
		// 		state.todayLogs = action.payload.logs;
		// 	}
		// 	state.todayLogID = action.payload.id;
		// }
	}
});

// export const {} = etcSlice.actions;

export default etcSlice.reducer;
