import { makeStyles } from '@material-ui/core/styles';
import {
	Icon,
	Card,
	CardActions,
	CardContent,
	IconButton,
	Tooltip,
	MenuItem,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Select,
	Typography
} from '@material-ui/core';
import React from 'react';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { showMessage } from 'app/store/fuse/messageSlice';
import commonUtils from 'app/utils/CommonUtils';

import { getAutoApprovalSettingsForFolder, setAutoApprovalSettingsForFolder } from 'app/store/docbase/admin/systemSlice';
import PermIcon from '../icon/PermIcon';

const useStyles = makeStyles(theme => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	cardInfo: {
		padding: '40px 30px 20px',
		'& .MuiCardActions-root': {
			padding: 0
		},
		'& .MuiCardContent-root': {
			padding: 0
		}
	},
	cardDetailInfo: {
		'margin-left': '10px',
		height: '48px',
		'line-height': '48px'
	},
	paperPerm: { marginTop: 30, padding: '20px 30px 0' },
	paperPermHeader: {
		padding: 0,
		display: 'flex',
		width: '100%',
		'&>div:first-child': {
			width: '100%',
			maxWidth: 534,
			marginRight: 10
		},
		'&>div:first-child .MuiAutocomplete-inputRoot': {
			height: 40
		},
		'&>div:last-child>button': {
			width: 140
		}
	},
	paperPermContent: { padding: 0, marginTop: 20, '& w-115': { width: 115 } },
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 45,
			paddingRight: 30,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277,
		minWidth: 277
	},
	filedInput: {
		width: '100%',
		padding: '0 0 0 30px',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	},
	customInput: {
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 4,
		'&>input': {
			padding: '10.5px 14px'
		},
		'&.Mui-focused': {
			border: '2px solid rgba(0, 0, 0, 1)'
		},
		'&:hover': {
			border: '1px solid rgba(0, 0, 0, 1)'
		}
	},

	select: {
		width: '100%'
	},
	inputRight: {
		'& input': {
			'text-align': 'right'
		}
	},
	buttonGroup: {
		marginTop: 30,
		'& button': { marginRight: 10 }
	},
	h_29: { height: '29px' }
}));

function DetailSidebarContent(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const permList = React.useRef();
	const { t } = useTranslation('docbase');
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const [approver, setApprovers] = React.useState([]);

	React.useEffect(() => {
		if (innerHeight >= 754) {
			permList.current.style.height = `${innerHeight - 140}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {
		if (props.selected > 0) {
			getAutoApprovalSettingsForFolder(props.selected).then(users => {
				setApprovers(users);
			});
		}
	}, [props.selected]);

	function doAction(userID, e) {
		const users = approver.map(a => {
			if (a.user_id === userID) {
				return { ...a, [e.target.name]: e.target.value };
			}
			return a;
		});

		setAutoApprovalSettingsForFolder(props.selected, users).then(respResult => {
			if (respResult) {
				dispatch(showMessage({ message: t('TAKEOUT_APPROVE_MESSAGE_1') }));

				getAutoApprovalSettingsForFolder(props.selected).then(u => {
					setApprovers(u);
				});
			}
		});
	}

	return (
		<div className="" ref={permList}>
			<Card className={classes.cardInfo}>
				<CardActions disableSpacing>
					<IconButton
						className="md:hidden"
						aria-controls="detail-back"
						onClick={e => {
							props.fnToggleRightSidebar();
						}}
					>
						<Icon>arrow_back</Icon>
					</IconButton>
					<Typography className={clsx(classes.cardDetailInfo, 'noto-bold')} variant="h6">
						<Tooltip title={props.path} arrow>
							<span>{props.selected <= 0 ? '' : commonUtils.splitLastDelimiterString(props.path)}</span>
						</Tooltip>
					</Typography>
				</CardActions>
			</Card>

			<Card className={classes.paperPerm}>
				{/* <CardContent className={classes.paperPermHeader}>
					<div>
						<Button
							variant="contained"
							color="secondary"
							className=""
							type="button"
							fullWidth
							// onClick={setPerm}
							onClick={() => {
								if (props.selected <= 0) {
									dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_3') }));
								}
							}}
						>
							{t('PERM_FOLDER_TITLE')}
						</Button>
					</div>
				</CardContent> */}

				<CardContent className={classes.paperPermContent}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								<TableCell padding="none" align="left" className="left title">
									{t('USER')}
								</TableCell>
								<TableCell padding="none" align="center" className="w-115">
									{t('TAKEOUT_DOC')}
								</TableCell>
								<TableCell padding="none" align="center" className="w-115">
									{t('TAKEOUT_PRINT')}
								</TableCell>
								<TableCell padding="none" align="center" className="w-115">
									{t('TAKEOUT_WEBLINK')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{approver.map(a => {
								return (
									<TableRow
										className="cursor-pointer"
										hover
										role="checkbox"
										tabIndex={-1}
										// key={d.user_id}
										// onClick={event => handleCheck(event, d.user_id)}
									>
										<TableCell className="left" align="left" component="td" scope="row">
											<div>
												<PermIcon type="person" className="" />
												<span className="folder-name">{`${a.user_id}(${a.user_name})`}</span>
											</div>
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											<Select
												id="document"
												name="document"
												variant="outlined"
												className={clsx(classes.select, '')}
												value={a.document}
												onChange={e => {
													doAction(a.user_id, e);
												}}
											>
												<MenuItem
													className="justify-center"
													value={commonTypes.TAKEOUT_APPROVE_TYPE_MANUAL}
												>
													{t('TAKEOUT_APPROVE_TYPE_MANUAL')}
												</MenuItem>
												<MenuItem
													className="justify-center"
													value={commonTypes.TAKEOUT_APPROVE_TYPE_AUTO}
												>
													{t('TAKEOUT_APPROVE_TYPE_AUTO')}
												</MenuItem>
												<MenuItem
													className="justify-center"
													value={commonTypes.TAKEOUT_APPROVE_TYPE_UNAVAILABLE}
												>
													{t('TAKEOUT_APPROVE_TYPE_UNAVAILABLE')}
												</MenuItem>
											</Select>
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											<Select
												id="printer"
												name="printer"
												variant="outlined"
												className={clsx(classes.select, '')}
												value={a.printer}
												onChange={e => {
													doAction(a.user_id, e);
												}}
											>
												<MenuItem
													className="justify-center"
													value={commonTypes.TAKEOUT_APPROVE_TYPE_AUTO}
												>
													{t('TAKEOUT_APPROVE_TYPE_AUTO')}
												</MenuItem>
												<MenuItem
													className="justify-center"
													value={commonTypes.TAKEOUT_APPROVE_TYPE_UNAVAILABLE}
												>
													{t('TAKEOUT_APPROVE_TYPE_UNAVAILABLE')}
												</MenuItem>
											</Select>
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											<Select
												id="weblink"
												name="weblink"
												variant="outlined"
												className={clsx(classes.select, '')}
												value={a.weblink}
												onChange={e => {
													doAction(a.user_id, e);
												}}
											>
												<MenuItem
													className="justify-center"
													value={commonTypes.TAKEOUT_APPROVE_TYPE_MANUAL}
												>
													{t('TAKEOUT_APPROVE_TYPE_MANUAL')}
												</MenuItem>
												<MenuItem
													className="justify-center"
													value={commonTypes.TAKEOUT_APPROVE_TYPE_AUTO}
												>
													{t('TAKEOUT_APPROVE_TYPE_AUTO')}
												</MenuItem>
												<MenuItem
													className="justify-center"
													value={commonTypes.TAKEOUT_APPROVE_TYPE_UNAVAILABLE}
												>
													{t('TAKEOUT_APPROVE_TYPE_UNAVAILABLE')}
												</MenuItem>
											</Select>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</div>
	);
}

export default DetailSidebarContent;
