import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import {
	Dialog,
	DialogContent,
	Button,
	Slide,
	TextField,
	MenuItem,
	Tooltip,
	IconButton,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	AppBar,
	Tabs,
	Box,
	Tab
} from '@material-ui/core';
import clsx from 'clsx';
import {
	getDefaultUserPolicy,
	setDefaultUserPolicy,
	removeDefaultUserPolicy,
	getPrograms,
	getNetworks,
	getPathStorageAccess,
	getExecuteControls,
	setExecuteControl,
	removeExecuteControl,
	getNetworkControls,
	registerNetworkControl,
	updateNetworkControl,
	removeNetworkControl,
	getGrantAccesses,
	grantAccess,
	updateGrantAccess,
	removeGrantAccess,
	getWhiteURLs,
	registerWhiteURL,
	removeWhiteURL
} from 'app/store/docbase/admin/dlpSlice';
import PropTypes from 'prop-types';

import { Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { openDialog } from 'app/store/fuse/dialogSlice';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import CustomIcon from 'app/main/icon/CustomIcon';
import { showErrorMessage } from 'app/store/fuse/messageSlice';
import OpenDialogForm from '../../component/OpenDialogForm';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	tabPanel: {
		overflow: 'auto',
		'&>.MuiBox-root': { padding: '30px 0 0 0' }
	}
});

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={2} className="h-full" style={{ 'margin-top': '-4px' }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%'
		}
	},
	contentWrapper: {
		height: 623,
		'& .header-title': {
			fontSize: 18,
			height: 40,
			lineHeight: '40px',
			margin: '10px 0',
			'&>span': {
				verticalAlign: 'middle'
			},
			'&>.MuiTextField-root': {
				verticalAlign: 'middle',
				width: 300,
				marginLeft: 20
			}
		},
		'& .content': {
			paddingBottom: 50
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6
		},
		'&>.content>div': {
			width: '100%'
		},
		'& .search-form': {
			padding: 12
		}
	},
	tableOrg: {
		'& .MuiTableRow-root.Mui-selected': {
			borderColor: 'rgba(0, 207, 226, 0.3)'
		},
		'& .button': {
			minWidth: 100
		},
		'& .default-policy': {
			minWidth: 130
		},
		'& .policy-btn-col': {
			width: 130
		},
		'& .MuiButton-containedSizeSmall': {
			width: 80
		},
		'& .td-btn': {
			display: 'flex',
			height: '40px',
			alignItems: 'center',
			justifyContent: 'center',
			'& button': {
				width: '60px',
				minWidth: '60px',
				height: '30px',
				fontSize: '12px'
			},
			'& button:nth-child(2)': {
				marginLeft: '5px'
			}
		}
	},
	hint_span: {
		'padding-left': '10px',
		'padding-right': '20px',
		'font-size': '1rem',
		color: '#9f9f9f'
	},
	max_release_duration: {
		height: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& span': {
			paddingRight: 10
		}
	}
}));

const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const days = [
	0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
];
const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const mins = [
	0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
	34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60
];
export default function DLPPolicyDialog(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation('docbase');

	const [policy, setPolicy] = React.useState({
		isGroup: false,
		execute_control: 1,
		network_control: 1,
		takeout_approving: 1,
		mount: true,
		bluetooth: true,
		bluetooth_pairing: true,
		messenger: true,
		install: true,
		airdrop: true,
		allow_release_storage: true,
		allow_release_network: true,
		allow_release_execute: true,
		allow_release_upload_url: true,
		allow_release_bluetooth: true,
		allow_release_messenger: true,
		allow_release_airdrop: true,
		max_release_duration_minutes: 0,
		max_release_duration_hours: 0,
		max_release_duration_days: 0,
		max_release_duration_months: 0
	});
	const [programsObj, setProgramsObj] = React.useState({ programs: [], object: {} });
	const [networksObj, setNetworksObj] = React.useState({ networks: [], object: {} });
	const [storagesObj, setStoragesObj] = React.useState({ storages: [], object: {} });

	const [executeProgramID, setExecuteProgramID] = React.useState({ id: -1 });
	const [networkPolicyObj, setNetworkPolicyObj] = React.useState({
		program: -1,
		network_name: '',
		inbound_ports: '',
		outbound_destinations: ''
	});
	const [grantPolicyObj, setGrantPolicyObj] = React.useState({
		program: -1,
		storage: -1,
		readable: true,
		writable: true,
		executable: true
	});
	const [whiteURLPolicyObj, setWhiteURLPolicyObj] = React.useState({ name: '', url: '', urlRegex: '' });
	const [controlObj, setControlObj] = React.useState({ execute: [], network: [] });
	const [grants, setGrants] = React.useState([]);
	const [whiteURLs, setWhiteURLs] = React.useState([]);
	const [displayURLs, setDisplayURLs] = React.useState([]);

	const [searchURL, setSearchURL] = React.useState('');

	const [tabType, setTabType] = React.useState(0);

	React.useEffect(() => {
		if (props.open) {
			init();
		}
	}, [props.open]);

	React.useEffect(() => {
		filterWhiteURL(whiteURLs, '');
		setSearchURL('');
	}, [whiteURLs]);

	const filterWhiteURL = useCallback(
		debounce(async (urls, searchVal) => {
			try {
				if (searchVal.length > 0) {
					const filterList = [];
					urls.forEach(urlObj => {
						if (
							urlObj.name.indexOf(searchVal) >= 0 ||
							urlObj.url.indexOf(searchVal) >= 0 ||
							urlObj.url_regex.indexOf(searchVal) >= 0
						) {
							filterList.push(urlObj);
						}
					});
					setDisplayURLs(filterList);
				} else {
					setDisplayURLs(urls);
				}
			} catch (e) {
				console.error(e);
			}
		}, 500),
		[]
	);

	function initDefaultPolicy() {
		getDefaultUserPolicy(props.groupID)
			.then(policyInfo => {
				setPolicy({ ...policyInfo, isGroup: true });
			})
			.catch(err => {
				console.log(err);
				if (err.error === 6) {
					getDefaultUserPolicy(0).then(policyInfo => {
						setPolicy({ ...policyInfo, isGroup: false });
					});
				}
				setPolicy({
					isGroup: false,
					execute_control: 1,
					network_control: 1,
					takeout_approving: 1,
					mount: true,
					bluetooth: true,
					bluetooth_pairing: true,
					messenger: true,
					install: true,
					airdrop: true,
					allow_release_storage: true,
					allow_release_network: true,
					allow_release_execute: true,
					allow_release_upload_url: true,
					allow_release_bluetooth: true,
					allow_release_messenger: true,
					allow_release_airdrop: true,
					max_release_duration_minutes: 0,
					max_release_duration_hours: 0,
					max_release_duration_days: 0,
					max_release_duration_months: 0
				});
			});
	}

	function filterControls(controls) {
		const list = [];
		controls.forEach(c => {
			if (c.group_id === props.groupID) {
				if (c.inbound_ports && c.inbound_ports !== '') {
					c.inboundPortsEx = c.inbound_ports;
				}
				if (c.outbound_destinations && c.outbound_destinations !== '') {
					c.outboundDestinationsEx = c.outbound_destinations;
				}
				list.push(c);
			}
		});
		return list;
	}

	async function init() {
		const respPrograms = await getPrograms();
		const respNetworks = await getNetworks();
		const respStorage = await getPathStorageAccess();
		const respExecuteControl = await getExecuteControls();
		const respNetworkControl = await getNetworkControls();
		setControlObj({ execute: filterControls(respExecuteControl), network: filterControls(respNetworkControl) });
		const respGrantAccess = await getGrantAccesses();
		setGrants(filterControls(respGrantAccess));
		const respWhiteURLs = await getWhiteURLs(props.groupID);
		setWhiteURLs(respWhiteURLs);

		initDefaultPolicy();

		const pObj = {};
		respPrograms.forEach(p => {
			pObj[p.id] = p;
		});
		const nObj = {};
		respNetworks.forEach(n => {
			nObj[n.id] = n;
		});
		const sObj = {};
		respStorage.forEach(s => {
			sObj[s.id] = s;
		});
		setProgramsObj({ programs: respPrograms, object: pObj });
		setNetworksObj({ networks: respNetworks, object: nObj });
		setStoragesObj({ storages: respStorage, object: sObj });
	}

	React.useEffect(() => {}, []);

	function updNetworkControl(controlID, groupID, program, networkName, inboundPorts, outboundDestinations) {
		dispatch(
			openDialog({
				children: (
					<OpenDialogForm
						title={t('DLP_POLICY_GROUP_NETWORK_TITLE')}
						content={t('DLP_POLICY_GROUP_NETWORK_CONTENT_1')}
						fnHandleOK={() => {
							updateNetworkControl(
								controlID,
								groupID,
								program,
								networkName,
								inboundPorts,
								outboundDestinations
							).then(() => {
								getNetworkControls().then(controls => {
									setControlObj({
										...controlObj,
										network: filterControls(controls)
									});
								});
							});
						}}
					/>
				)
			})
		);
	}
	function updGrantAccess(grantID, groupID, program, storage, readable, writable, executable) {
		dispatch(
			openDialog({
				children: (
					<OpenDialogForm
						title={t('DLP_POLICY_GROUP_GRANT_TITLE')}
						content={t('DLP_POLICY_GROUP_GRANT_CONTENT_1')}
						fnHandleOK={() => {
							updateGrantAccess(grantID, groupID, program, storage, readable, writable, executable).then(() => {
								getGrantAccesses().then(data => {
									setGrants(filterControls(data));
								});
							});
						}}
					/>
				)
			})
		);
	}

	return (
		<div>
			<Dialog
				className={classes.root}
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				maxWidth="lg"
				onClose={props.fnHandleClose}
			>
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('DLP_POLICY_GROUP_SETTING')}
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div className={clsx(classes.contentWrapper, 'flex-grow')}>
						<AppBar position="static" className={classes.tabsBar}>
							<Tabs
								value={tabType}
								indicatorColor=""
								onChange={(e, newValue) => {
									if (tabType !== newValue) {
										setTabType(newValue);
									}
								}}
							>
								<Tab label={t('DEFAULT_POLICY')} {...a11yProps(0)} />
								<Tab label={t('DLP_POLICY_GROUP_RELEASE')} {...a11yProps(1)} />
								<Tab label={t('DLP_EXECUTE_PROGRAM')} {...a11yProps(2)} />
								<Tab label={t('DLP_ALLOW_RELEASE_NETWORK')} {...a11yProps(3)} />
								<Tab label={t('DLP_POLICY_GROUP_GRANT')} {...a11yProps(4)} />
								<Tab label={t('DLP_POLICY_WHITE_URL_MANAGEMENT')} {...a11yProps(5)} />
							</Tabs>
						</AppBar>
						<TabPanel className={classes.tabPanel} value={tabType} index={0}>
							<div className="header-title">{t('DEFAULT_POLICY')}</div>
							<Table stickyHeader className={classes.tableOrg} aria-labelledby="tableTitle">
								<TableHead>
									<TableRow>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_EXECUTE_PROGRAM')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_NETWORK')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_BLUETOOTH_PAIRING')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_BLUETOOTH_FILE')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_MESSENGER')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_AIRDROP')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_PROGRAM_INSTALL')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_MOUNT')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_TAKEOUT_APPROVING')}
										</TableCell>
										<TableCell className="policy-btn-col" align="center" padding="default" />
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow className="cursor-pointer" hover role="checkbox" tabIndex={-1} onClick={e => {}}>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.execute_control}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, execute_control: e.target.value });
												}}
											>
												<MenuItem value="1">{t('WHITE_LIST')}</MenuItem>
												<MenuItem value="2">{t('BLACK_LIST')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.network_control}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, network_control: e.target.value });
												}}
											>
												<MenuItem value="1">{t('WHITE_LIST')}</MenuItem>
												<MenuItem value="2">{t('BLACK_LIST')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.bluetooth_pairing}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, bluetooth_pairing: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('BLOCK')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.bluetooth}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, bluetooth: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('BLOCK')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.messenger}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, messenger: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('BLOCK')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.airdrop}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, airdrop: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('BLOCK')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.install}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, install: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('BLOCK')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.mount}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, mount: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('BLOCK')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.takeout_approving}
												fullWidth
												onChange={e => {
													setPolicy({
														...policy,
														takeout_approving: e.target.value
													});
												}}
											>
												<MenuItem value={1}>{t('승인 필요')}</MenuItem>
												<MenuItem value={2}>{t('자동 승인')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="td-btn" align="center" component="td" scope="row">
											<Button
												variant="contained"
												color="secondary"
												aria-label="Register"
												type="button"
												size="small"
												onClick={e => {
													dispatch(
														openDialog({
															children: (
																<OpenDialogForm
																	title={t('DLP_POLICY_GROUP_DEFAULT_TITLE')}
																	content={t('DLP_POLICY_GROUP_DEFAULT_CONTENT_1')}
																	fnHandleOK={() => {
																		setDefaultUserPolicy(
																			props.groupID,
																			policy.execute_control,
																			policy.network_control,
																			policy.takeout_approving,
																			policy.mount,
																			policy.bluetooth,
																			policy.bluetooth_pairing,
																			policy.messenger,
																			policy.install,
																			policy.airdrop,
																			policy.allow_release_storage,
																			policy.allow_release_network,
																			policy.allow_release_execute,
																			policy.allow_release_upload_url,
																			policy.allow_release_bluetooth,
																			policy.allow_release_messenger,
																			policy.allow_release_airdrop,
																			policy.max_release_duration_months,
																			policy.max_release_duration_days,
																			policy.max_release_duration_hours,
																			policy.max_release_duration_minutes
																		).then(() => {
																			initDefaultPolicy();
																		});
																	}}
																/>
															)
														})
													);
												}}
											>
												{t('MODIFY')}
											</Button>
											{policy.isGroup ? (
												<Button
													variant="contained"
													color="secondary"
													aria-label="Register"
													type="button"
													size="small"
													onClick={e => {
														dispatch(
															openDialog({
																children: (
																	<OpenDialogForm
																		title={t('DLP_POLICY_GROUP_DEFAULT_TITLE')}
																		content={t('DLP_POLICY_GROUP_DEFAULT_CONTENT_2')}
																		fnHandleOK={() => {
																			removeDefaultUserPolicy(props.groupID).then(() => {
																				initDefaultPolicy();
																			});
																		}}
																	/>
																)
															})
														);
													}}
												>
													{t('RESET')}
												</Button>
											) : (
												<></>
											)}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TabPanel>

						<TabPanel className={classes.tabPanel} value={tabType} index={1}>
							<div className="header-title">{t('DLP_POLICY_GROUP_RELEASE_TITLE')}</div>
							<Table stickyHeader className={classes.tableOrg} aria-labelledby="tableTitle">
								<TableHead>
									<TableRow>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_ALLOW_RELEASE_STORAGE')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_ALLOW_RELEASE_NETWORK')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_ALLOW_RELEASE_EXECUTE')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_ALLOW_RELEASE_UPLOAD_URL')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_ALLOW_RELEASE_BLUETOOTH')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_ALLOW_RELEASE_MESSENGER')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_ALLOW_RELEASE_AIRDROP')}
										</TableCell>
										<TableCell className="policy-btn-col" align="center" padding="default" />
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow className="cursor-pointer" hover role="checkbox" tabIndex={-1} onClick={e => {}}>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.allow_release_storage}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, allow_release_storage: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.allow_release_network}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, allow_release_network: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.allow_release_execute}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, allow_release_execute: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.allow_release_upload_url}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, allow_release_upload_url: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.allow_release_bluetooth}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, allow_release_bluetooth: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.allow_release_messenger}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, allow_release_messenger: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={policy.allow_release_airdrop}
												fullWidth
												onChange={e => {
													setPolicy({ ...policy, allow_release_airdrop: e.target.value });
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="td-btn" align="center" component="td" scope="row">
											<Button
												variant="contained"
												color="secondary"
												aria-label="Register"
												type="button"
												size="small"
												onClick={e => {
													dispatch(
														openDialog({
															children: (
																<OpenDialogForm
																	title={t('DLP_POLICY_GROUP_RELEASE_TITLE')}
																	content={t('DLP_POLICY_GROUP_RELEASE_CONTENT_1')}
																	fnHandleOK={() => {
																		setDefaultUserPolicy(
																			props.groupID,
																			policy.execute_control,
																			policy.network_control,
																			policy.takeout_approving,
																			policy.mount,
																			policy.bluetooth,
																			policy.bluetooth_pairing,
																			policy.messenger,
																			policy.install,
																			policy.airdrop,
																			policy.allow_release_storage,
																			policy.allow_release_network,
																			policy.allow_release_execute,
																			policy.allow_release_upload_url,
																			policy.allow_release_bluetooth,
																			policy.allow_release_messenger,
																			policy.allow_release_airdrop,
																			policy.max_release_duration_months,
																			policy.max_release_duration_days,
																			policy.max_release_duration_hours,
																			policy.max_release_duration_minutes
																		).then(() => {
																			initDefaultPolicy();
																		});
																	}}
																/>
															)
														})
													);
												}}
											>
												{t('MODIFY')}
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>

							<div className="header-title">{t('DLP_POLICY_RELEASE_DURATION')}</div>
							<Table stickyHeader className={classes.tableOrg} aria-labelledby="tableTitle">
								<TableBody>
									<TableRow className="cursor-pointer" hover role="checkbox" tabIndex={-1} onClick={e => {}}>
										<TableCell
											className={classes.max_release_duration}
											align="center"
											component="td"
											scope="row"
										>
											<TextField
												select
												className=""
												label=""
												variant="outlined"
												value={policy.max_release_duration_months}
												onChange={e => {
													setPolicy({
														...policy,
														max_release_duration_months: e.target.value
													});
												}}
											>
												{months.map(m => (
													<MenuItem value={m}>{m}</MenuItem>
												))}
											</TextField>
											<span>{t('MONTH')}</span>
											<TextField
												select
												className=""
												label=""
												variant="outlined"
												value={policy.max_release_duration_days}
												onChange={e => {
													setPolicy({ ...policy, max_release_duration_days: e.target.value });
												}}
											>
												{days.map(d => (
													<MenuItem value={d}>{d}</MenuItem>
												))}
											</TextField>

											<span>{t('DAY')}</span>
											<TextField
												select
												className=""
												label=""
												variant="outlined"
												value={policy.max_release_duration_hours}
												onChange={e => {
													setPolicy({ ...policy, max_release_duration_hours: e.target.value });
												}}
											>
												{hours.map(h => (
													<MenuItem value={h}>{h}</MenuItem>
												))}
											</TextField>

											<span>{t('HOUR')}</span>
											<TextField
												select
												className=""
												label=""
												variant="outlined"
												value={policy.max_release_duration_minutes}
												onChange={e => {
													setPolicy({
														...policy,
														max_release_duration_minutes: e.target.value
													});
												}}
											>
												{mins.map(m => (
													<MenuItem value={m}>{m}</MenuItem>
												))}
											</TextField>
											<span>{t('MINUTE')}</span>
											<span className={classes.hint_span}>합이 '0'일 경우 한제한</span>

											<Button
												variant="contained"
												color="secondary"
												aria-label="Register"
												type="button"
												size="small"
												onClick={e => {
													dispatch(
														openDialog({
															children: (
																<OpenDialogForm
																	title={t('DLP_POLICY_RELEASE_DURATION_TITLE')}
																	content={t('DLP_POLICY_RELEASE_DURATION_CONTENT_1')}
																	fnHandleOK={() => {
																		setDefaultUserPolicy(
																			props.groupID,
																			policy.execute_control,
																			policy.network_control,
																			policy.takeout_approving,
																			policy.mount,
																			policy.bluetooth,
																			policy.bluetooth_pairing,
																			policy.messenger,
																			policy.install,
																			policy.airdrop,
																			policy.allow_release_storage,
																			policy.allow_release_network,
																			policy.allow_release_execute,
																			policy.allow_release_upload_url,
																			policy.allow_release_bluetooth,
																			policy.allow_release_messenger,
																			policy.allow_release_airdrop,
																			policy.max_release_duration_months,
																			policy.max_release_duration_days,
																			policy.max_release_duration_hours,
																			policy.max_release_duration_minutes
																		).then(() => {
																			initDefaultPolicy();
																		});
																	}}
																/>
															)
														})
													);
												}}
											>
												{t('수정')}
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TabPanel>

						<TabPanel className={classes.tabPanel} value={tabType} index={2}>
							<div className="header-title">{t('DLP_EXECUTE_PROGRAM')}</div>
							<Table stickyHeader className={classes.tableOrg} aria-labelledby="tableTitle">
								<TableHead>
									<TableRow>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_PROGRAM_MANAGE_NAME')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_PROGRAM_SIGNING_ID')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_PROGRAM_HASH')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_PROGRAM_NAME')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_PROCESS_TYPE')}
										</TableCell>
										<TableCell className="policy-btn-col" align="center" padding="default" />
									</TableRow>
								</TableHead>
								<TableBody>
									{controlObj.execute.map(execute => {
										const exec = programsObj.object[execute.program];
										if (!exec) {
											return <></>;
										}
										return (
											<TableRow
												className="cursor-pointer"
												hover
												role="checkbox"
												tabIndex={-1}
												onClick={e => {}}
											>
												<TableCell className="" align="center" component="td" scope="row">
													{exec.name}
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													{exec.signing_id}
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													{exec.hash}
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													{exec.program_name}
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													{exec.process_type}
												</TableCell>
												<TableCell className="td-btn" align="center" component="td" scope="row">
													<div>
														<Tooltip title={t('DELETE')} arrow>
															<IconButton
																onClick={e => {
																	dispatch(
																		openDialog({
																			children: (
																				<OpenDialogForm
																					title={t('DLP_EXECUTE_CONTROL_TITLE')}
																					content={t(
																						'DLP_EXECUTE_CONTROL_DELETE_CONTENT'
																					)}
																					fnHandleOK={() => {
																						removeExecuteControl(execute.id).then(
																							() => {
																								getExecuteControls().then(
																									controls => {
																										setControlObj({
																											...controlObj,
																											execute:
																												filterControls(
																													controls
																												)
																										});
																									}
																								);
																							}
																						);
																					}}
																				/>
																			)
																		})
																	);
																}}
															>
																<CustomIcon type="delete" className="" fill="#575757" />
															</IconButton>
														</Tooltip>
													</div>
												</TableCell>
											</TableRow>
										);
									})}

									<TableRow className="cursor-pointer" hover role="checkbox" tabIndex={-1} onClick={e => {}}>
										<TableCell className="" align="center" component="td" scope="row" colSpan={5}>
											<TextField
												select
												className=""
												label=""
												value={executeProgramID}
												fullWidth
												onChange={e => {
													setExecuteProgramID(e.target.value);
												}}
											>
												{programsObj.programs.map(p => {
													return <MenuItem value={p.id}>{p.name}</MenuItem>;
												})}
											</TextField>
										</TableCell>
										<TableCell className="td-btn" align="center" component="td" scope="row">
											<Button
												variant="contained"
												color="secondary"
												aria-label="Register"
												type="button"
												size="small"
												onClick={e => {
													dispatch(
														openDialog({
															children: (
																<OpenDialogForm
																	title={t('DLP_EXECUTE_CONTROL_TITLE')}
																	content={t('DLP_EXECUTE_CONTROL_CONTENT')}
																	fnHandleOK={() => {
																		setExecuteControl(props.groupID, executeProgramID).then(
																			() => {
																				getExecuteControls().then(controls => {
																					setControlObj({
																						...controlObj,
																						execute: filterControls(controls)
																					});
																				});
																			}
																		);
																	}}
																/>
															)
														})
													);
												}}
											>
												{t('APPLY')}
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TabPanel>

						<TabPanel className={classes.tabPanel} value={tabType} index={3}>
							<div className="header-title">{t('DLP_ALLOW_RELEASE_NETWORK')}</div>
							<Table stickyHeader className={classes.tableOrg} aria-labelledby="tableTitle">
								<TableHead>
									<TableRow>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_PROGRAM_MANAGE_NAME')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_NETWORK_MANAGE_NAME')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_NETWORK_INBOUND_PORT')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_NETWORK_INBOUND_PORT')}
										</TableCell>
										<TableCell className="policy-btn-col" align="center" padding="default" />
									</TableRow>
								</TableHead>
								<TableBody>
									{controlObj.network.map(network => {
										return (
											<TableRow
												className="cursor-pointer"
												hover
												role="checkbox"
												tabIndex={-1}
												onClick={e => {}}
											>
												<TableCell className="" align="center" component="td" scope="row">
													<TextField
														select
														className=""
														label=""
														value={network.program}
														fullWidth
														onChange={e => {
															updNetworkControl(
																network.id,
																props.groupID,
																e.target.value,
																network.network_name,
																network.inbound_ports,
																network.outbound_destinations
															);
														}}
													>
														<MenuItem value={0}>{t('ALL_PROGRAM')}</MenuItem>;
														{programsObj.programs.map(p => {
															return <MenuItem value={p.id}>{p.name}</MenuItem>;
														})}
													</TextField>
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													<TextField
														select
														className=""
														label=""
														value={network.network_name}
														fullWidth
														onChange={e => {
															updNetworkControl(
																network.id,
																props.groupID,
																network.program,
																e.target.value,
																network.inbound_ports,
																network.outbound_destinations
															);
														}}
													>
														<MenuItem value="">{t('NOT_SELECTED')}</MenuItem>;
														{networksObj.networks.map(p => {
															return <MenuItem value={p.name}>{p.name}</MenuItem>;
														})}
													</TextField>
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													<TextField
														className=""
														label=""
														value={network.inboundPortsEx}
														fullWidth
														onChange={e => {
															setControlObj({
																...controlObj,
																network: controlObj.network.map(n => {
																	if (n.id === network.id) {
																		n.inboundPortsEx = e.target.value;
																	}
																	return n;
																})
															});
														}}
														onBlur={() => {
															updNetworkControl(
																network.id,
																props.groupID,
																network.program,
																network.network_name,
																network.inboundPortsEx,
																network.outbound_destinations
															);
														}}
													/>
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													<TextField
														className=""
														label=""
														value={network.outboundDestinationsEx}
														fullWidth
														onChange={e => {
															setControlObj({
																...controlObj,
																network: controlObj.network.map(n => {
																	if (n.id === network.id) {
																		n.outboundDestinationsEx = e.target.value;
																	}
																	return n;
																})
															});
														}}
														onBlur={() => {
															updNetworkControl(
																network.id,
																props.groupID,
																network.program,
																network.network_name,
																network.inbound_ports,
																network.outboundDestinationsEx
															);
														}}
													/>
												</TableCell>
												<TableCell className="td-btn" align="center" component="td" scope="row">
													<div>
														<Tooltip title={t('DELETE')} arrow>
															<IconButton
																onClick={e => {
																	dispatch(
																		openDialog({
																			children: (
																				<OpenDialogForm
																					title={t('DLP_POLICY_GROUP_NETWORK_TITLE')}
																					content={t(
																						'DLP_POLICY_GROUP_NETWORK_CONTENT_2'
																					)}
																					fnHandleOK={() => {
																						removeNetworkControl(network.id).then(
																							() => {
																								getNetworkControls().then(
																									controls => {
																										setControlObj({
																											...controlObj,
																											network:
																												filterControls(
																													controls
																												)
																										});
																									}
																								);
																							}
																						);
																					}}
																				/>
																			)
																		})
																	);
																}}
															>
																<CustomIcon type="delete" className="" fill="#575757" />
															</IconButton>
														</Tooltip>
													</div>
												</TableCell>
											</TableRow>
										);
									})}

									<TableRow className="cursor-pointer" hover role="checkbox" tabIndex={-1} onClick={e => {}}>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={networkPolicyObj.program}
												fullWidth
												onChange={e => {
													setNetworkPolicyObj({ ...networkPolicyObj, program: e.target.value });
												}}
											>
												<MenuItem value={0}>{t('ALL_PROGRAM')}</MenuItem>;
												{programsObj.programs.map(p => {
													return <MenuItem value={p.id}>{p.name}</MenuItem>;
												})}
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={networkPolicyObj.network_name}
												fullWidth
												onChange={e => {
													setNetworkPolicyObj({
														...networkPolicyObj,
														network_name: e.target.value
													});
												}}
											>
												<MenuItem value="">{t('NOT_SELECTED')}</MenuItem>;
												{networksObj.networks.map(p => {
													return <MenuItem value={p.name}>{p.name}</MenuItem>;
												})}
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												className=""
												label=""
												value={networkPolicyObj.inbound_ports}
												fullWidth
												onChange={e => {
													setNetworkPolicyObj({
														...networkPolicyObj,
														inbound_ports: e.target.value
													});
												}}
											/>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												className=""
												label=""
												value={networkPolicyObj.outbound_destinations}
												fullWidth
												onChange={e => {
													setNetworkPolicyObj({
														...networkPolicyObj,
														outbound_destinations: e.target.value
													});
												}}
											/>
										</TableCell>
										<TableCell className="td-btn" align="center" component="td" scope="row">
											<Button
												variant="contained"
												color="secondary"
												aria-label="Register"
												type="button"
												size="small"
												onClick={e => {
													dispatch(
														openDialog({
															children: (
																<OpenDialogForm
																	title={t('DLP_POLICY_GROUP_NETWORK_TITLE')}
																	content={t('DLP_POLICY_GROUP_NETWORK_CONTENT_3')}
																	fnHandleOK={() => {
																		registerNetworkControl(
																			props.groupID,
																			networkPolicyObj.program,
																			networkPolicyObj.network_name,
																			networkPolicyObj.inbound_ports,
																			networkPolicyObj.outbound_destinations
																		).then(() => {
																			getNetworkControls().then(controls => {
																				setControlObj({
																					...controlObj,
																					network: filterControls(controls)
																				});
																			});
																		});
																	}}
																/>
															)
														})
													);
												}}
											>
												{t('APPLY')}
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TabPanel>

						<TabPanel className={classes.tabPanel} value={tabType} index={4}>
							<div className="header-title">{t('DLP_POLICY_GROUP_GRANT')}</div>
							<Table stickyHeader className={classes.tableOrg} aria-labelledby="tableTitle">
								<TableHead>
									<TableRow>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_PROGRAM_MANAGE_NAME')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('STORAGE_NAME')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('ALLOW_READ')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('ALLOW_WRITE')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('ALLOW_EXECUTE')}
										</TableCell>
										<TableCell className="policy-btn-col" align="center" padding="default" />
									</TableRow>
								</TableHead>
								<TableBody>
									{grants.map(grant => {
										return (
											<TableRow
												className="cursor-pointer"
												hover
												role="checkbox"
												tabIndex={-1}
												onClick={e => {}}
											>
												<TableCell className="" align="center" component="td" scope="row">
													<TextField
														select
														className=""
														label=""
														value={grant.program}
														fullWidth
														onChange={e => {
															updGrantAccess(
																grant.id,
																props.groupID,
																e.target.value,
																grant.storage,
																grant.readable,
																grant.writable,
																grant.executable
															);
														}}
													>
														<MenuItem value={0}>{t('ALL_PROGRAM')}</MenuItem>;
														{programsObj.programs.map(p => {
															return <MenuItem value={p.id}>{p.name}</MenuItem>;
														})}
													</TextField>
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													<TextField
														select
														className=""
														label=""
														value={grant.storage}
														fullWidth
														onChange={e => {
															updGrantAccess(
																grant.id,
																props.groupID,
																grant.program,
																e.target.value,
																grant.readable,
																grant.writable,
																grant.executable
															);
														}}
													>
														<MenuItem value={2}>{t('USB')}</MenuItem>
														<MenuItem value={1}>{t('COODOC Drive')}</MenuItem>
														<MenuItem value={3}>{t('Network Drive')}</MenuItem>
														{storagesObj.storages.map(p => {
															return <MenuItem value={p.storage_id}>{p.path}</MenuItem>;
														})}
													</TextField>
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													<TextField
														select
														className=""
														label=""
														value={grant.readable}
														fullWidth
														onChange={e => {
															updGrantAccess(
																grant.id,
																props.groupID,
																grant.program,
																grant.storage,
																e.target.value,
																grant.writable,
																grant.executable
															);
														}}
													>
														<MenuItem value>{t('PERMIT')}</MenuItem>
														<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
													</TextField>
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													<TextField
														select
														className=""
														label=""
														value={grant.writable}
														fullWidth
														onChange={e => {
															updGrantAccess(
																grant.id,
																props.groupID,
																grant.program,
																grant.storage,
																grant.readable,
																e.target.value,
																grant.executable
															);
														}}
													>
														<MenuItem value>{t('PERMIT')}</MenuItem>
														<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
													</TextField>
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													<TextField
														select
														className=""
														label=""
														value={grant.executable}
														fullWidth
														onChange={e => {
															updGrantAccess(
																grant.id,
																props.groupID,
																grant.program,
																grant.storage,
																grant.readable,
																grant.writable,
																e.target.value
															);
														}}
													>
														<MenuItem value>{t('PERMIT')}</MenuItem>
														<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
													</TextField>
												</TableCell>
												<TableCell className="td-btn" align="center" component="td" scope="row">
													<div>
														<Tooltip title={t('DELETE')} arrow>
															<IconButton
																onClick={e => {
																	dispatch(
																		openDialog({
																			children: (
																				<OpenDialogForm
																					title={t('DLP_POLICY_GROUP_GRANT_TITLE')}
																					content={t(
																						'DLP_POLICY_GROUP_GRANT_CONTENT_2'
																					)}
																					fnHandleOK={() => {
																						removeGrantAccess(grant.id).then(() => {
																							getGrantAccesses().then(data => {
																								setGrants(filterControls(data));
																							});
																						});
																					}}
																				/>
																			)
																		})
																	);
																}}
															>
																<CustomIcon type="delete" className="" fill="#575757" />
															</IconButton>
														</Tooltip>
													</div>
												</TableCell>
											</TableRow>
										);
									})}

									<TableRow className="cursor-pointer" hover role="checkbox" tabIndex={-1} onClick={e => {}}>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={grantPolicyObj.program}
												fullWidth
												onChange={e => {
													setGrantPolicyObj({ ...grantPolicyObj, program: e.target.value });
												}}
											>
												<MenuItem value={0}>{t('ALL_PROGRAM')}</MenuItem>;
												{programsObj.programs.map(p => {
													return <MenuItem value={p.id}>{p.name}</MenuItem>;
												})}
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={grantPolicyObj.storage}
												fullWidth
												onChange={e => {
													setGrantPolicyObj({
														...grantPolicyObj,
														storage: e.target.value
													});
												}}
											>
												<MenuItem value={2}>{t('USB')}</MenuItem>
												<MenuItem value={1}>{t('COODOC Drive')}</MenuItem>
												<MenuItem value={3}>{t('Network Drive')}</MenuItem>

												{storagesObj.storages.map(p => {
													return <MenuItem value={p.storage_id}>{p.path}</MenuItem>;
												})}
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={grantPolicyObj.readable}
												fullWidth
												onChange={e => {
													setGrantPolicyObj({
														...grantPolicyObj,
														readable: e.target.value
													});
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={grantPolicyObj.writable}
												fullWidth
												onChange={e => {
													setGrantPolicyObj({
														...grantPolicyObj,
														writable: e.target.value
													});
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												select
												className=""
												label=""
												value={grantPolicyObj.executable}
												fullWidth
												onChange={e => {
													setGrantPolicyObj({
														...grantPolicyObj,
														executable: e.target.value
													});
												}}
											>
												<MenuItem value>{t('PERMIT')}</MenuItem>
												<MenuItem value={false}>{t('UNAVAILABLE')}</MenuItem>
											</TextField>
										</TableCell>
										<TableCell className="td-btn" align="center" component="td" scope="row">
											<Button
												variant="contained"
												color="secondary"
												aria-label="Register"
												type="button"
												size="small"
												onClick={e => {
													dispatch(
														openDialog({
															children: (
																<OpenDialogForm
																	title={t('DLP_POLICY_GROUP_GRANT_TITLE')}
																	content={t('DLP_POLICY_GROUP_GRANT_CONTENT_3')}
																	fnHandleOK={() => {
																		grantAccess(
																			props.groupID,
																			grantPolicyObj.program,
																			grantPolicyObj.storage,
																			grantPolicyObj.readable,
																			grantPolicyObj.writable,
																			grantPolicyObj.executable
																		).then(() => {
																			getGrantAccesses().then(data => {
																				setGrants(filterControls(data));
																			});
																		});
																	}}
																/>
															)
														})
													);
												}}
											>
												{t('APPLY')}
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TabPanel>
						<TabPanel className={classes.tabPanel} value={tabType} index={5}>
							<div className="header-title">
								<span> {t('DLP_POLICY_WHITE_URL_MANAGEMENT')}</span>

								<TextField
									className=""
									label=""
									variant="outlined"
									placeholder={t('SEARCH')}
									value={searchURL}
									onChange={ev => {
										setSearchURL(ev.target.value);
										filterWhiteURL(whiteURLs, ev.target.value);
									}}
								/>
							</div>
							<Table stickyHeader className={classes.tableOrg} aria-labelledby="tableTitle">
								<TableHead>
									<TableRow>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_POLICY_WHITE_URL_NAME')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('URL')}
										</TableCell>
										<TableCell className="default-policy" align="center" padding="default">
											{t('DLP_POLICY_WHITE_URL_REGEX')}
										</TableCell>
										<TableCell className="policy-btn-col" align="center" padding="default" />
									</TableRow>
								</TableHead>
								<TableBody>
									{displayURLs.map(u => {
										return (
											<TableRow
												className="cursor-pointer"
												hover
												role="checkbox"
												tabIndex={-1}
												onClick={e => {}}
											>
												<TableCell className="" align="center" component="td" scope="row">
													{u.name}
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													{u.url}
												</TableCell>
												<TableCell className="" align="center" component="td" scope="row">
													{u.url_regex.replaceAll('(.+)?', '*')}
												</TableCell>
												<TableCell className="td-btn" align="center" component="td" scope="row">
													<div>
														<Tooltip title={t('DELETE')} arrow>
															<IconButton
																onClick={e => {
																	dispatch(
																		openDialog({
																			children: (
																				<OpenDialogForm
																					title={t('DLP_POLICY_WHITE_URL_TITLE')}
																					content={t(
																						'DLP_POLICY_WHITE_URL_DELETE_CONTENT'
																					)}
																					fnHandleOK={() => {
																						removeWhiteURL(
																							u.name_ex,
																							props.groupID
																						).then(() => {
																							getWhiteURLs(props.groupID).then(
																								urls => {
																									setWhiteURLs(urls);
																								}
																							);
																						});
																					}}
																				/>
																			)
																		})
																	);
																}}
															>
																<CustomIcon type="delete" className="" fill="#575757" />
															</IconButton>
														</Tooltip>
													</div>
												</TableCell>
											</TableRow>
										);
									})}
									<TableRow className="cursor-pointer" hover role="checkbox" tabIndex={-1} onClick={e => {}}>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												className=""
												label=""
												value={whiteURLPolicyObj.name}
												fullWidth
												onChange={e => {
													setWhiteURLPolicyObj({
														...whiteURLPolicyObj,
														name: e.target.value
													});
												}}
											/>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												className=""
												label=""
												value={whiteURLPolicyObj.url}
												fullWidth
												onChange={e => {
													setWhiteURLPolicyObj({
														...whiteURLPolicyObj,
														url: e.target.value
													});
												}}
											/>
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											<TextField
												className=""
												label=""
												value={whiteURLPolicyObj.urlRegex}
												placeholder={t('DLP_POLICY_WHITE_URL_REGEX_PLACEHOLDER')}
												fullWidth
												onChange={e => {
													setWhiteURLPolicyObj({
														...whiteURLPolicyObj,
														urlRegex: e.target.value
													});
												}}
											/>
										</TableCell>
										<TableCell className="td-btn" align="center" component="td" scope="row">
											<Button
												variant="contained"
												color="secondary"
												aria-label="Register"
												type="button"
												size="small"
												onClick={e => {
													dispatch(
														openDialog({
															children: (
																<OpenDialogForm
																	title={t('DLP_POLICY_WHITE_URL_TITLE')}
																	content={t('DLP_POLICY_WHITE_URL_CONTENT')}
																	fnHandleOK={() => {
																		if (whiteURLPolicyObj.name === '') {
																			dispatch(
																				showErrorMessage(
																					t('DLP_POLICY_WHITE_URL_MESSAGE_1')
																				)
																			);
																			return false;
																		}
																		registerWhiteURL(
																			whiteURLPolicyObj.name,
																			props.groupID,
																			whiteURLPolicyObj.url,
																			whiteURLPolicyObj.urlRegex.replaceAll('*', '(.+)?')
																		).then(() => {
																			getWhiteURLs(props.groupID).then(urls => {
																				setWhiteURLs(urls);
																			});
																		});
																		return true;
																	}}
																/>
															)
														})
													);
												}}
											>
												{t('APPLY')}
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TabPanel>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
