import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .log_types': {
			width: 120
		},
		'& .ip_addr': {
			width: 162
		},
		'& .date_time': {
			width: 195
		}
	}
}));

function LogsTableHead(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();

	const headerCols = [
		{
			id: 'logged_at',
			align: 'center',
			className: 'date_time',
			disablePadding: false,
			label: t('DATE'),
			isSearch: false,
			sort: false
		},
		{
			id: 'log_type',
			align: 'center',
			className: 'log_types',
			disablePadding: false,
			label: t('INSTANT_LOGS_LOG_TYPES'),
			isSearch: false,
			sort: false
		},
		{
			id: 'ref',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('NOTE'),
			isSearch: false,
			sort: false
		},
		{
			id: 'ip_address',
			align: 'left',
			className: 'left ip_addr',
			disablePadding: false,
			label: t('IP_ADDRESS'),
			isSearch: false,
			sort: false
		}
	];

	return (
		<TableHead>
			<TableRow className={classes.tr}>
				{headerCols.map(row => {
					return (
						<TableCell
							className={clsx(row.className)}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={false}
						>
							<span className="align-middle">{row.label}</span>
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default LogsTableHead;
