import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import commonUtils from 'app/utils/CommonUtils';
import { collectLogs, collectLogsStatus, getCollectedLogsPage, getCollectedLogs } from 'app/store/docbase/admin/systemSlice';

import ContentHeader from '../component/ContentHeader';
import LogsTable from './LogsTable';

function Logs() {
	const [loading, setLoading] = React.useState(false);
	const pageLayout = React.useRef(null);

	const today = new Date();
	const [searchObj, setSearchObj] = React.useState({
		startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
		endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
		userID: '',
		omitServer: true // true: 미수집, false: 수집
	});

	const [totalInfo, setTotalInfo] = React.useState({ total_rows: 0, total_pages: 0, isSearch: false });
	const [data, setData] = React.useState([]);

	let timer = 0;
	function getUserLogs() {
		setLoading(true);
		(async () => {
			const callResp = await collectLogs(
				searchObj.userID,
				commonUtils.getDateString(searchObj.startDate),
				commonUtils.getDateString(searchObj.endDate),
				searchObj.omitServer
			);

			if (callResp.result) {
				timer = setInterval(() => {
					collectLogsStatus().then(resp => {
						console.log(resp);
						if (resp.client_status >= 2 && resp.server_status >= 2) {
							clearInterval(timer);
							doSearch({
								file_types: ['engine', 'collector', 'agent', 'server'],
								log_types: ['trace', 'info', 'warn', 'err', 'critical'],
								includes: [],
								excludes: [],
								rows_per_page: 100
							});
						}
					});
				}, 1000);
			}
		})();
	}

	function doSearch(conditions) {
		//	시간도 첨에 설정한 값 그대로 용용
		getCollectedLogs({
			...conditions,
			start_time: commonUtils.getDateTimeString(searchObj.startDate),
			end_time: commonUtils.getDateTimeString(searchObj.endDate)
		})
			.then(info => {
				if (info.result && info.total_rows > 0) {
					setTotalInfo({ total_rows: info.total_rows, total_pages: info.total_pages, isSearch: true });
					getLogsPage(1);
				} else {
					setData([]);
					setLoading(false);
				}
			})
			.catch(err => {
				setLoading(false);
			});
	}

	function getLogsPage(page) {
		getCollectedLogsPage(page)
			.then(logs => {
				setData(logs);
				setLoading(false);
			})
			.catch(err => {
				setLoading(false);
			});
	}

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<LogsTable
					loading={loading}
					searchObj={searchObj}
					totalInfo={totalInfo}
					data={data}
					setLoading={setLoading}
					fnGetLogsPage={page => getLogsPage(page)}
					fnSetSearchObj={(name, value) => setSearchObj({ ...searchObj, [name]: value })}
					fnGetUserLogs={() => {
						getUserLogs();
					}}
					fnDoSearch={conditionsObj => {
						setLoading(true);
						doSearch(conditionsObj);
					}}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default Logs;
