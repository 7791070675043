import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import ContentHeader from '../component/ContentHeader';
import RestoreContent from './RestoreContent';

function UsersPage() {
	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<RestoreContent />}
			innerScroll
			sidebarInner
		/>
	);
}

export default UsersPage;
