import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
	root: {
		width: 55,
		height: 31,
		padding: 0
	},
	switchBase: {
		padding: 4,
		color: '#22b9db'
	},
	thumb: {
		width: 23,
		height: 23,
		backgroundColor: '#fff'
	},
	track: {
		background: '#eceff4',
		opacity: '1 !important',
		border: '1px solid #d9dee5',
		borderRadius: 20,
		position: 'relative',
		'&:before, &:after': {
			display: 'inline-block',
			position: 'absolute',
			top: '50%',
			width: '50%',
			transform: 'translateY(-50%)',
			color: '#fff',
			textAlign: 'center',
			fontSize: 10
		},
		'&:before': {
			content: '"on"',
			left: 2,
			opacity: 0
		},
		'&:after': {
			content: '"off"',
			right: 0,
			color: '#8b95a1'
		}
	},
	checked: {
		'&$switchBase': {
			color: '#185a9d',
			transform: 'translateX(24px)',
			'&:hover': {
				backgroundColor: 'rgba(24,90,257,0.08)'
			}
		},
		'& $thumb': {
			backgroundColor: '#fff'
		},
		'& + $track': {
			background: '#00cfe2',
			border: '1px solid #e1e5eb',
			'&:before': {
				opacity: 1
			},
			'&:after': {
				opacity: 0
			}
		}
	}
}));

const SwitchNum01 = props => {
	const classes = useStyles();

	return (
		<Switch
			classes={classes}
			name={props.name}
			checked={props.checked}
			disabled={props.disabled}
			onChange={e => props.onChange(e)}
		/>
	);
};

export default SwitchNum01;
