import { TableCell, TableHead, TableRow, Icon, Paper, Input, InputAdornment, IconButton } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

function DupDocsTableHead(props) {
	const { t } = useTranslation('docbase');

	const headerCols = [
		{
			id: 'path',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('DOCUMENT'),
			isSearch: false,
			sort: false
		},
		{
			id: 'file_size',
			align: 'center',
			className: 'center file-size',
			disablePadding: false,
			label: t('FILE_SIZE'),
			isSearch: false,
			sort: false
		},
		{
			id: 'dup_path',
			align: 'left',
			className: 'search-cell',
			disablePadding: false,
			label: t('DUP_FILE_PATH'),
			isSearch: true,
			sort: false
		}
	];

	const [searchObj, setSearchObj] = React.useState({
		dup_path: { show: props.searchObj.path !== '', value: props.searchObj.path }
	});

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	return (
		<TableHead>
			<TableRow>
				{headerCols.map(row => {
					return (
						<TableCell
							className={row.className}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={false}
						>
							{searchObj[row.id] && searchObj[row.id].show ? (
								<Paper className="flex items-center px-8 py-4 rounded-8 shadow mx-5">
									<Icon color="action">search</Icon>

									<Input
										placeholder={row.label}
										className="flex flex-1 mx-8 w-0"
										disableUnderline
										value={searchObj[row.id].value}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													className="p-5"
													aria-label="toggle password visibility"
													onClick={e =>
														setSearchObj({ ...searchObj, [row.id]: { show: false, value: '' } })
													}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													<Icon color="action">close</Icon>
												</IconButton>
											</InputAdornment>
										}
										inputProps={{
											'aria-label': 'Search'
										}}
										onChange={e => {
											setSearchObj({ ...searchObj, [row.id]: { show: true, value: e.target.value } });
										}}
										onKeyPress={e => {
											if (e.charCode === 13) {
												e.preventDefault();
												props.fnSearch(searchObj.dup_path.value);
											}
										}}
									/>
								</Paper>
							) : (
								<>
									<span className="align-middle">{row.label}</span>
									{row.isSearch ? (
										<Icon
											className="align-middle ml-3 cursor-pointer"
											onClick={e => {
												setSearchObj({ ...searchObj, [row.id]: { show: true, value: '' } });
											}}
										>
											search
										</Icon>
									) : (
										<></>
									)}
								</>
							)}
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default DupDocsTableHead;
