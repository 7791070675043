import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	Slide,
	TextField,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
	IconButton
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { getOSROrgs, setOSROrgs } from 'app/store/docbase/admin/systemSlice';

import CustomIcon from 'app/main/icon/CustomIcon';
import OpenDialogForm from '../component/OpenDialogForm';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 604
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6,
			padding: 40
		},
		'&>.content>.row:first-child': {
			marginTop: 0
		},
		'&>.content>.MuiTypography-root': { color: '#404040' }
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 40,
		'& .MuiButton-root': {
			width: 172
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	},
	insertForm: {
		display: 'flex',
		'&>div': { display: 'flex', alignItems: 'center' },
		'&>div:first-child': { width: '100%' },
		'&>div:first-child input.MuiOutlinedInput-input': { padding: '5.7px 10px' },
		'&>div.button-group': {
			paddingRight: 20
		},
		'&>div.button-group>button': {
			width: 106,
			height: 28,
			marginLeft: 10
		}
	}
});

export default function OsrOrgDialog(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation('docbase');

	const [ids, setIds] = React.useState([]);
	const [orgCode, setOrgCode] = React.useState('');
	React.useEffect(() => {
		if (props.open) getOrgs();
	}, [props.open]);

	const getOrgs = async () => {
		const data = await getOSROrgs();
		console.log(data);

		setIds(data.split(','));
	};

	const addCode = code => {
		if (ids.find(id => code === id)) {
			dispatch(showErrorMessage({ message: t('OSR_ERROR_MESSAGE_6') }));
		} else {
			setIds([...ids, code]);
			setOrgCode('');
		}
	};

	const setOrgs = async () => {
		let codes = '';
		ids.forEach((id, idx) => {
			if (idx > 0) {
				codes += `,${id}`;
			} else {
				codes += id;
			}
		});
		const resp = await setOSROrgs(codes);
		if (resp.result) {
			dispatch(
				showMessage({
					message: t('COLLECT_MESSAGE_1')
				})
			).then(() => {
				getOrgs();
			});
		}
	};
	return (
		<div>
			<Dialog
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				maxWidth="sm"
				onClose={props.fnHandleClose}
			>
				<DialogTitle className={classes.title} onClose={props.fnHandleClose}>
					<span className="align-middle">{t('OSR_ORG_TITLE')}</span>
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div className="content">
						<Table stickyHeader className="" aria-labelledby="tableTitle">
							<TableHead>
								<TableRow>
									<TableCell className="left" align="left" padding="default" sortDirection={false}>
										{t('OSR_ORG_CODE')}
									</TableCell>
									<TableCell className="delete-cell" align="center" padding="default" sortDirection={false}>
										{t('REMOVE')}
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{ids.map((d, idx) => {
									return (
										<TableRow className="cursor-pointer" hover role="checkbox" tabIndex={-1} key={idx}>
											<TableCell className="cursor-pointer left" component="td" scope="row">
												{d}
											</TableCell>
											<TableCell className="" component="td" scope="row" align="center">
												<div className="">
													<Tooltip title={t('DELETE')} arrow>
														<IconButton
															onClick={e => {
																e.stopPropagation();
																setIds(ids.filter(id => id !== d));
															}}
														>
															<CustomIcon type="delete" className="" fill="#575757" />
														</IconButton>
													</Tooltip>
												</div>
											</TableCell>
										</TableRow>
									);
								})}
								<TableRow className="cursor-pointer">
									<TableCell className="left" component="td" scope="row">
										<div className={classes.insertForm}>
											<div>
												<TextField
													className=""
													placeholder={t('OSR_ORG_CODE')}
													type="text"
													name="osr_org_code"
													variant="outlined"
													size="small"
													autoFocus
													fullWidth
													value={orgCode}
													onChange={e => setOrgCode(e.target.value)}
												/>
											</div>
											<div className="button-group">
												<Button
													variant="contained"
													color="secondary"
													aria-label="Register"
													type="button"
													onClick={e => {
														addCode(orgCode);
													}}
												>
													{t('ADD')}
												</Button>
											</div>
										</div>
									</TableCell>
									<TableCell className="" component="td" scope="row" align="right" />
								</TableRow>
							</TableBody>
						</Table>
						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.fnHandleClose}
								variant="contained"
								className="default"
								aria-label="btn-cancel"
								type="button"
							>
								{t('CANCEL')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className=""
								aria-label="btn-send"
								type="button"
								onClick={e => {
									dispatch(
										openDialog({
											children: (
												<OpenDialogForm
													title={t('OSR_ORG_TITLE')}
													content={t('OSR_CONFIRM_MESSAGE_3')}
													fnHandleOK={() => setOrgs()}
												/>
											)
										})
									);
								}}
							>
								{t('APPLY')}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
