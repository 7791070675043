import streamSaver from 'streamsaver';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from 'app/utils/HttpClient';
import securityUtils from 'app/utils/SecurityUtils';
import commonUtils from 'app/utils/CommonUtils';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { secureRequest, secureAsyncRequest } from 'app/store/docbase/commonAction';

export const checkStructFolders = createAsyncThunk('wc/file/checkStructFolder', async (_, { dispatch, getState }) => {
	const folders = [];
	for (let i = 0; i < initFolders.length; i++) {
		/* eslint-disable no-await-in-loop */
		const respData = await httpClient.sendPost('/wcapi/fileList', { path: initFolders[i].name });
		if (respData.result) {
			folders.push(initFolders[i]);
		}
	}
	return folders;
});

/**
 * 파일목록
 */
export const fileList = createAsyncThunk('wc/file/fileList', async ({ targetFile, isDirect }, { dispatch, getState }) => {
	if (!targetFile.name) {
		dispatch(initFileList());
		return false;
	}
	const curPath = isDirect
		? `/${targetFile.name}`
		: `${getState().docbase.wc.file.curPath === '/' ? '' : getState().docbase.wc.file.curPath}/${targetFile.name}`;
	const respData = await httpClient.sendPost('/wcapi/fileList', { path: curPath });

	if (!respData.result) {
		if (curPath !== '공유' && curPath !== 'Shared') {
			dispatch(initFileList());
		} else {
			dispatch(setCurPathInfo({ fileInfo: targetFile, isFirst: isDirect }));
			dispatch(setFileList({ files: [], curPath }));
		}
		return false;
	}

	dispatch(setCurPathInfo({ fileInfo: targetFile, isFirst: isDirect }));
	dispatch(setFileList({ files: respData.files, curPath }));
	return true;
});
/**
 * 뒤로가기
 */
export const backSpace = createAsyncThunk('wc/file/fileList', async (_, { dispatch, getState }) => {
	const path = commonUtils.splitPreDelimiter(getState().docbase.wc.file.curPath);
	if (path === '') {
		dispatch(initFileList());
		dispatch(setBackSpace({ isInitial: true }));
		return false;
	}
	const respData = await httpClient.sendPost('/wcapi/fileList', { path });
	if (!respData.result) {
		dispatch(initFileList());
		dispatch(setBackSpace({ isInitial: true }));
		return false;
	}

	dispatch(setFileList({ files: respData.files, curPath: path }));
	dispatch(setBackSpace({ isInitial: false }));
	return true;
});
/**
 * 특정경로로 목록가져오기
 */
export const fileListByPath = createAsyncThunk('wc/file/fileList', async ({ targetPath, isRefresh }, { dispatch, getState }) => {
	if (targetPath === '' || targetPath === '/') {
		dispatch(initFileList());
		return false;
	}
	const respData = await httpClient.sendPost('/wcapi/fileList', { path: targetPath });
	if (!respData.result) {
		dispatch(initFileList());
		return false;
	}
	dispatch(setFileList({ files: respData.files, curPath: targetPath }));
	if (!isRefresh) {
		dispatch(setCurPathInfoByPath({ reqPath: targetPath }));
	}
	return true;
});
/**
 * 특정경로로 목록가져오기
 */
export const refreshFileList = createAsyncThunk('wc/file/refreshFileList', async (_, { dispatch, getState }) => {
	const respData = await httpClient.sendPost('/wcapi/fileList', {
		path: getState().docbase.wc.file.curPath
	});
	if (!respData.result) {
		dispatch(initFileList());
		return false;
	}
	dispatch(setFileList({ files: respData.files, curPath: getState().docbase.wc.file.curPath }));
	return true;
});
/**
 * 선택한 파일의 리비전 파일 목록 조회
 */
export const getRevisionFiles = createAsyncThunk('wc/file/revisionFiles', async ({ targetPath }) => {
	const respData = await httpClient.sendPost('/wcapi/revisionFiles', { path: targetPath });

	if (!respData.result) {
		return [];
	}
	return respData.revisions;
});
/**
 * 보안문서 정보 조회
 */
export const secureDocumentInfo = createAsyncThunk('wc/file/secureDocumentInfo', async ({ targetPath }) => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/wcapi/secureDocumentInfo', { path: targetPath, token: secureObj.token });
	if (!respData.result) {
		return {};
	}
	return respData;
});
/**
 * 파일 검색
 */
export const searchFile = createAsyncThunk(
	'wc/file/searchFile',
	async ({ targetPath, searchVal, excludeVal, findOption, exts }) => {
		const respData = await httpClient.sendPost('/wcapi/searchFile', {
			base_path: targetPath,
			search_string: searchVal,
			exclude_string: excludeVal,
			find_option: findOption,
			exts
		});
		if (!respData.result) {
			return [];
		}
		return respData.files;
	}
);
/**
 * 삭제된 파일 검색
 */
export const findDeleteFiles = createAsyncThunk(
	'wc/file/findDeleteFiles',
	async ({ targetPath, searchVal, userName, userID, startDate, endDate }) => {
		const respData = await httpClient.sendPost('/wcapi/searchTrashFile', {
			path: targetPath,
			text: searchVal,
			user_name: userName,
			user_id: userID,
			start_date: startDate,
			end_date: endDate
		});
		if (!respData.result) {
			return [];
		}
		return respData.files;
	}
);
/**
 * 새폴더 만들기
 *
 * @param {string} 새폴더 명
 */
export const createNewFolder = (curPath, name) => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/wcapi/createFolder', { path: `${curPath}/${name}` }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};
/**
 * 파일업로드
 */
// const BUFFER_SIZE = 1024 * 64; //	64kb 문서상 64가 최대 단위...
const BUFFER_SIZE = 1024 * 1024; //	1Mb

export const uploadFile = async (targetPath, file, curPath, dispatch) => {
	const respCreate = await httpClient.sendPost('/wcapi/createFile', {
		path: targetPath,
		size: file.size
	});

	if (respCreate.result) {
		dispatch(startUpload());

		const reader = new FileReader();
		let arrays;
		if (file.size < BUFFER_SIZE) {
			arrays = await commonUtils.loadFileArray(reader, file, 0, file.size);

			const secureObj = await secureAsyncRequest();
			const respWrite = await httpClient.sendPost('/wcapi/writeFile', {
				path: targetPath,
				offset: 0,
				size: file.size,
				token: secureObj.token,
				data: securityUtils.encryptAES(secureObj.aesKey, arrays, 'arrayBuffer', 'hex')
			});
			if (respWrite.result) {
				dispatch(fileListByPath({ targetPath: curPath, isRefresh: true }));
			} else {
				console.error('Failed to write to server');
			}
		} else {
			console.log(file.size);
			let isFailed = false;
			for (let cnt = 0; cnt < file.size / BUFFER_SIZE; cnt++) {
				/* eslint-disable no-await-in-loop */
				arrays = await commonUtils.loadFileArray(
					reader,
					file,
					cnt * BUFFER_SIZE,
					cnt * BUFFER_SIZE < file.size ? cnt * BUFFER_SIZE + BUFFER_SIZE : file.size
				);

				const secureObj = await secureAsyncRequest();
				const respWrite = await httpClient.sendPost('/wcapi/writeFile', {
					path: targetPath,
					offset: cnt * BUFFER_SIZE,
					size: (cnt + 1) * BUFFER_SIZE < file.size ? BUFFER_SIZE : file.size - BUFFER_SIZE * cnt,
					token: secureObj.token,
					data: securityUtils.encryptAES(secureObj.aesKey, arrays, 'arrayBuffer', 'hex')
				});

				if (!respWrite.result) {
					isFailed = true;
				}

				const percent = ((cnt * BUFFER_SIZE) / file.size) * 100;
				console.log(`send percent: ${percent}%, count: ${cnt}`);
				dispatch(uploadingFile({ percent }));
				await commonUtils.sleep(10);
			}
			if (!isFailed) {
				dispatch(fileListByPath({ targetPath: curPath, isRefresh: true }));
			}
		}

		dispatch(finishUpload());
	}
};
/**
 * store에 있는 현재 경로에 파일을 업로드 한다.
 * 300Mb 이상은 대용량 처리로 이동
 *
 */
export const upFile = createAsyncThunk('wc/file/upFile', async ({ targetPath, file }, { dispatch, getState }) => {
	if (file.size / (1024 * 1024) > 300) {
		await uploadFile(targetPath, file, getState().docbase.wc.file.curPath, dispatch);
	} else {
		await updateMultipartForm(targetPath, file, getState().docbase.wc.file.curPath, dispatch);
	}
});

/**
 * 용량이 작은 파일은 멀티파트로 한번에 전송한다.
 *
 * @param {*} targetPath
 * @param {*} file
 * @param {*} curPath
 * @param {*} dispatch
 */
export const updateMultipartForm = async (targetPath, file, curPath, dispatch) => {
	dispatch(startUpload());
	httpClient.sendPostMultipartForm('/wcapi/uploadFile', { path: targetPath }, [file], ['file'], resp => {
		if (resp.data.result) {
			dispatch(fileListByPath({ targetPath: curPath, isRefresh: true }));
		}
		dispatch(finishUpload());
	});
};

/**
 * 파일 다운로드
 * @param {*} curPath
 * @param {*} fileName
 * @param {*} size
 * @returns
 */
export const fileDownload = async (curPath, files, legacyObj) => {
	if (legacyObj && legacyObj.enable) {
		for (let i = 0; i < files.length; i++) {
			if (legacyObj.isInstantDownload) {
				createInstantDownloadURL(`${curPath}/${files[i].name}`)
					.then(url => {
						const href = legacyObj.prefix
							.replace('##FILE_NAME##', encodeURIComponent(commonUtils.splitLastDelimiterString(files[i].name)))
							.replace('##FILE_URL##', url);

						const link = document.createElement('a');
						link.target = '_blank';
						link.href = href;
						link.click();
					})
					.catch(err => {
						console.error(err);
					});
			}
			await commonUtils.sleep(100);
		}
	} else if (window.isSecureContext) {
		// fileDownloadEx(curPath, files);
		for (let i = 0; i < files.length; i++) {
			download(`${curPath}/${files[i].name}`);
			await commonUtils.sleep(100);
		}
	} else {
		for (let i = 0; i < files.length; i++) {
			download(`${curPath}/${files[i].name}`);
			// downloadEx(`${curPath}/${files[i].name}`);
			await commonUtils.sleep(100);
		}
	}
	return true;
};

const download = path => {
	secureRequest((aes, tk) => {
		const link = document.createElement('a');
		link.href = `/wcapi/downloadFile?token=${tk}&path=${encodeURIComponent(path)}`;
		link.download = commonUtils.pathToName(path);
		link.click();
	});
};

const downloadEx = path => {
	secureRequest((aes, tk) => {
		fetch(`/wcapi/downloadFile?token=${tk}&path=${encodeURIComponent(path)}`, { method: 'GET' })
			.then(res => {
				return res.blob();
			})
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = '파일명.xlsx';
				document.body.appendChild(a);
				a.click();
				setTimeout(_ => {
					window.URL.revokeObjectURL(url);
				}, 60000);
				a.remove();
			})
			.catch(err => {
				console.error('err: ', err);
			});
	});
};

const fileDownloadEx = async (curPath, files) => {
	for (let i = 0; i < files.length; i++) {
		await readFile(curPath, files[i].name, files[i].size);
	}
};

/**
 * 파일정보를 읽어오는  함수.
 * 다운로드 로직에 async 함수 사용하기 위하여 함수를 뺀다.
 * @param {string} curPath
 * @param {string} fileName
 * @param {number} size
 */
const readFile = async (curPath, fileName, size) => {
	streamSaver.mitm = '/mitm.html?version=2.0.0';
	const fileStream = streamSaver.createWriteStream(fileName, { size });
	if (size < BUFFER_SIZE) {
		const secureObj = await secureAsyncRequest();
		const readResp = await httpClient.sendPost('/wcapi/readFile', {
			path: `${curPath}/${fileName}`,
			offset: 0,
			size,
			token: secureObj.token
		});
		if (readResp.result) {
			window.writer = fileStream.getWriter();
			window.writer.write(
				new Uint8Array(securityUtils.decryptAES(secureObj.aesKey, readResp.data, 'hex', 'arrayBuffer', size))
			);
			window.writer.close();
		}
	} else {
		window.writer = fileStream.getWriter();
		let readSize = 0;
		let secureObj;
		let readResp;
		do {
			secureObj = await secureAsyncRequest();
			readResp = await httpClient.sendPost('/wcapi/readFile', {
				path: `${curPath}/${fileName}`,
				offset: readSize,
				size: BUFFER_SIZE + readSize > size ? size - readSize : BUFFER_SIZE,
				token: secureObj.token
			});

			window.writer.write(
				new Uint8Array(
					securityUtils.decryptAES(secureObj.aesKey, readResp.data, 'hex', 'arrayBuffer', readResp.data_size)
				)
			);
			readSize += readResp.data_size;
		} while (readSize < size);
		window.writer.close();
	}
	return true;
};

export const createInstantDownloadURL = path => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/wcapi/createInstantDownloadURL', { path, token: tk }).then(data => {
				if (data.result) {
					resolve(data.url);
				} else {
					httpClient.printError(data);
					reject();
				}
			});
		});
	});
};

export const removeFile = (targetPath, targetType) => {
	return new Promise((resolve, reject) => {
		let api = '/wcapi/removeFile';
		if (targetType === 1) {
			api = '/wcapi/removeFolder';
		}
		httpClient.sendPost(api, { path: targetPath }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};
/**
 * 파일 이동 / 복사 붙여넣기.
 *
 * @param {string} pastePath
 * @param {string} targetPath
 * @param {type} pasteType 0-none, 1-copy, 2-cut
 * @returns
 */
export const pasteFile = (pastePath, targetPath, pasteType) => {
	return new Promise((resolve, reject) => {
		if (pasteType === 1) {
			httpClient
				.sendPost('/wcapi/copyFile', {
					source_path: targetPath,
					dest_path: `${pastePath}/${commonUtils.pathToName(targetPath)}`
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		} else if (pasteType === 2) {
			httpClient
				.sendPost('/wcapi/moveFile', {
					source_path: targetPath,
					dest_path: `${pastePath}/${commonUtils.pathToName(targetPath)}`
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		} else {
			reject();
		}
	});
};
/**
 * 이름 변경
 *
 * @param {string} targetPath
 * @param {string} name
 * @returns
 */
export const renameFile = (targetPath, name) => {
	return new Promise((resolve, reject) => {
		httpClient
			.sendPost('/wcapi/moveFile', {
				source_path: targetPath,
				dest_path: `${commonUtils.splitPreDelimiter(targetPath)}/${name}`
			})
			.then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
	});
};
/**
 * 휴지통 파일 복원
 *
 * @param {string} targetPath
 * @returns
 */
export const recoveryTrashFile = targetPath => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/wcapi/recoveryTrashFile', { path: targetPath }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};
/**
 * 휴지통 파일 완전 삭제
 *
 * @param {string} targetPath
 * @returns
 */
export const purgeTrashFile = targetPath => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/wcapi/purgeTrashFile', { path: targetPath }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};

/**
 * 보안문서 수정
 * @param {string} targetPath
 * @param {number} level
 * @param {JsonArray} users
 * @returns
 */
export const updSecureDoc = (targetPath, level, users) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			let accessUsers = '';
			users.forEach((user, idx) => {
				accessUsers += idx === 0 ? user.user_id : `,${user.user_id}`;
			});
			httpClient
				.sendPost('/wcapi/updateSecureDocument', {
					path: targetPath,
					access_level: level,
					access_users: accessUsers,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};
/**
 * 보안문서 해제
 * @param {string} targetPath
 * @returns
 */
export const releaseSecureDoc = targetPath => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/wcapi/releaseSecureDocument', { path: targetPath, token: tk }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
/**
 * 보안문서 등록
 * @param {string} targetPath
 * @param {number} level
 * @param {jsonArray} users
 * @returns
 */
export const setSecureDoc = (targetPath, level, users) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			let accessUsers = '';
			users.forEach((user, idx) => {
				accessUsers += idx === 0 ? user.user_id : `,${user.user_id}`;
			});

			httpClient
				.sendPost('/wcapi/setSecureDocument', {
					path: targetPath,
					access_level: level,
					access_users: accessUsers,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};
/**
 * 리비전 파일 다운로드
 * @param {*} targetPath
 * @param {*} tag
 * @param {*} size
 * @returns
 */
export const readRevision = (targetPath, tag, size) => {
	return new Promise((resolve, reject) => {
		if (revisionDownload(targetPath, tag, size)) {
			resolve({ result: true });
		} else {
			reject();
		}
	});
};
/**
 * 다운로드 로직에 async 함수 사용하기 위하여 함수를 뺀다.
 * @param {string} curPath
 * @param {string} fileName
 * @param {number} size
 */
const revisionDownload = async (targetPath, tag, size) => {
	const fileStream = streamSaver.createWriteStream(commonUtils.splitLastDelimiterString(targetPath), { size });
	if (size < BUFFER_SIZE) {
		const secureObj = await secureAsyncRequest();
		const readResp = await httpClient.sendPost('/wcapi/readRevision', {
			path: `${targetPath}`,
			tag,
			offset: 0,
			size,
			token: secureObj.token
		});
		if (readResp.result) {
			window.writer = fileStream.getWriter();
			window.writer.write(
				new Uint8Array(securityUtils.decryptAES(secureObj.aesKey, readResp.data, 'hex', 'arrayBuffer', size))
			);
			window.writer.close();
		}
	} else {
		window.writer = fileStream.getWriter();
		let readSize = 0;
		let secureObj;
		let readResp;
		do {
			secureObj = await secureAsyncRequest();
			readResp = await httpClient.sendPost('/wcapi/readRevision', {
				path: `${targetPath}`,
				tag,
				offset: readSize,
				size: BUFFER_SIZE + readSize > size ? size - readSize : BUFFER_SIZE,
				token: secureObj.token
			});
			window.writer.write(
				new Uint8Array(
					securityUtils.decryptAES(secureObj.aesKey, readResp.data, 'hex', 'arrayBuffer', readResp.data_size)
				)
			);
			readSize += readResp.data_size;
			await commonUtils.sleep(50);
		} while (readSize < size);
		window.writer.close();
	}
	return true;
};
export const recoveryByRevision = (targetPath, tag) => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/wcapi/recoveryByRevision', { path: targetPath, tag }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};
export const removeRevision = (targetPath, tag) => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/wcapi/removeRevision', { path: targetPath, tag }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};
export const prepareCreateWeblink = files => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/wcapi/prepareCreateWeblink', { files: { files }, token: tk }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
export const createWeblink = (webID, password, limits, expireDate, reason) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			const splitDate = expireDate.split('-');
			httpClient
				.sendPost('/wcapi/createWeblink', {
					req: {
						id: webID,
						confirm: true,
						password: securityUtils.encryptAES(aes, password, 'utf8', 'hex'),
						download_limits: limits === '' ? 0 : Number(limits),
						expire_year: Number(splitDate[0]),
						expire_month: Number(splitDate[1]),
						expire_day: Number(splitDate[2]),
						auto_update: false,
						reason: reason || ''
					},
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};
export const cancelWeblink = webID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/wcapi/createWeblink', {
					req: {
						id: webID,
						confirm: false,
						password: ''
					},
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

export const getArchiveCopy = (toid, fileSeq, archiveID) => {
	return new Promise((resolve, reject) => {
		secureRequest((sea, tk) => {
			httpClient.sendPost('/api/getArchiveCopy', { toid, seq: fileSeq, archive_id: archiveID, token: tk }).then(data => {
				if (data.result) {
					// resolve(data.id, data.target_path);
					download(data.target_path);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 * 파일목록
 */
export const fileListEx = targetPath => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/wcapi/fileList', { path: targetPath }).then(data => {
			if (data.result) {
				resolve(data.files);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};
export const initFolders = [
	{
		accessible: false,
		name: '조직',
		purge_trash_file: false,
		secure_document: false,
		size: 82,
		trash_file: false,
		type: 1,
		folder_type: commonTypes.FOLDER_TYPE_ORGANIZE
	},
	{
		accessible: false,
		name: '공유',
		purge_trash_file: false,
		secure_document: false,
		size: 82,
		trash_file: false,
		type: 1,
		folder_type: commonTypes.FOLDER_TYPE_SHARED
	},
	{
		accessible: false,
		name: '개인',
		purge_trash_file: false,
		secure_document: false,
		size: 82,
		trash_file: false,
		type: 1,
		folder_type: commonTypes.FOLDER_TYPE_PRIVATE
	},
	{
		accessible: false,
		name: '수집',
		purge_trash_file: false,
		secure_document: false,
		size: 82,
		trash_file: false,
		type: 1,
		folder_type: commonTypes.FOLDER_TYPE_COLLECT
	}
];

/**
 * pasteTargetType: 0-none, 1-copy, 2-cut
 */
const foldersSlice = createSlice({
	name: 'wc/file',
	initialState: {
		structFolders: [],
		isSettingPage: false,
		curPath: '/',
		curPathInfo: {},
		navigator: [],
		curFileList: initFolders,
		pasteTargets: [],
		pasteTargetPath: '',
		pasteTargetType: 0,
		searchFileList: [],
		revisionFileList: [],
		secureDocInfo: {},
		uploadInfo: { uploading: false, percent: 0 }
	},
	reducers: {
		initFileList: (state, action) => {
			state.curFileList = initFolders;
			state.curPath = '/';
			state.navigator = [];
			state.isSettingPage = false;
		},
		setFileList: (state, action) => {
			state.curFileList = action.payload.files;
			state.curPath = action.payload.curPath;
		},
		setCurPathInfo: (state, action) => {
			state.curPathInfo = action.payload.fileInfo;
			if (action.payload.isFirst) {
				state.navigator = [action.payload.fileInfo];
			} else {
				state.navigator = [...state.navigator, action.payload.fileInfo];
			}
		},
		setCurPathInfoByPath: (state, action) => {
			let path = '';
			const navis = [];
			state.navigator.forEach(naviObj => {
				path += `/${naviObj.name}`;
				if (action.payload.reqPath.indexOf(path) === 0) {
					state.curPathInfo = naviObj;
					navis.push(naviObj);
				}
			});
			state.navigator = navis;
		},
		setBackSpace: (state, action) => {
			const navis = [];
			if (!action.payload.isInitial) {
				state.navigator.forEach((naviObj, idx) => {
					if (idx < state.navigator.length - 1) {
						state.curPathInfo = naviObj;
						navis.push(naviObj);
					}
				});
			}
			state.navigator = navis;
		},
		startUpload: (state, action) => {
			state.uploadInfo = { uploading: true, percent: 0 };
		},
		uploadingFile: (state, action) => {
			state.uploadInfo.percent = action.payload.percent;
		},
		finishUpload: (state, action) => {
			state.uploadInfo = { uploading: false, percent: 0 };
		},
		setCopyPath: (state, action) => {
			state.pasteTargets = action.payload.targets;
		},
		setCutPath: (state, action) => {
			state.pasteTargets = action.payload.targets;
		},
		resetSearchList: (state, action) => {
			state.searchFileList = [];
		},
		setSettingPage: (state, action) => {
			state.isSettingPage = action.payload.isSettingPage;
		}
	},
	extraReducers: {
		[searchFile.fulfilled]: (state, action) => {
			state.searchFileList = action.payload;
		},
		[findDeleteFiles.fulfilled]: (state, action) => {
			state.searchFileList = action.payload;
		},
		[getRevisionFiles.fulfilled]: (state, action) => {
			state.revisionFileList = action.payload;
		},
		[secureDocumentInfo.fulfilled]: (state, action) => {
			state.secureDocInfo = action.payload;
		},
		[checkStructFolders.fulfilled]: (state, action) => {
			state.structFolders = action.payload;
		}
	}
});

export const {
	initFileList,
	setFileList,
	setCurPathInfo,
	setCurPathInfoByPath,
	resetSearchList,
	setBackSpace,
	startUpload,
	uploadingFile,
	finishUpload,
	setCopyPath,
	setCutPath,
	setSettingPage
} = foldersSlice.actions;

export default foldersSlice.reducer;
