/* eslint-disable jsx-a11y/click-events-have-key-events */
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button, Paper, Input, Box } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as commonTypes from 'app/main/constants/CommonTypes';
import {
	initOrgPage,
	initOrgSearchText,
	getOrgFolderList,
	createOrgFolder,
	removeOrgFolder,
	selectedOrgFolderID,
	getAllOrgFolderList
} from 'app/store/docbase/admin/folderSlice';
import { setShowBackdrop } from 'app/store/docbase/admin/settingSlice';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks/useTreeViewApiRef';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import CommonUtils from 'app/utils/CommonUtils';
import OpenDialogForm from '../component/OpenDialogForm';
import OrgTreeView from '../component/OrgTreeView';

const useStyles = makeStyles(theme => ({
	root: { marginRight: 30, borderRadius: 6 },
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 228}px`,
		'&>.insert-form': {
			padding: '0 0 10px 20px'
		}
	},
	icon: {
		width: 20,
		height: 20
	}
}));

//	트리 클릭시 input 값이 사라지는것을 방지기 기위한 값
const inputChildren = {};
let isFirst = true;
function OrgsTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const formRef = React.useRef(null);
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const { orgFolders, selectedOrgID, orgsChildren, allOrgs } = useSelector(({ docbase }) => docbase.admin.folder);
	const [searchText, setSearchText] = useState('');

	const [loading, setLoading] = useState(true);
	const [children, setChildren] = useState({});
	const [expandedItems, setExpandedItems] = useState([]);

	//	조직트리 조작
	const apiRef = useTreeViewApiRef();

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 228}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		CommonUtils.infoLog('orgTable selectedID end');
	}, [selectedOrgID]);

	useEffect(() => {
		if (orgFolders.length > 0) {
			filterSearch(searchText);
		}
		return () => {
			filterSearch.cancel();
		};
	}, [searchText]);

	const filterSearch = useCallback(
		debounce(val => {
			try {
				if (val.length > 0) {
					setChildren({
						0: { children: orgFolders.filter(folder => folder.name.toLowerCase().includes(val.toLowerCase())) }
					});
					setExpandedItems([0]);
				} else {
					setOrgTree();
				}
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		}, 500),
		[orgFolders]
	);

	useEffect(() => {
		setOrgTree();
	}, [orgsChildren]);

	function setOrgTree() {
		const tempObj = { ...orgsChildren };
		const expanded = [];
		Object.keys(orgsChildren).forEach(key => {
			if (inputChildren[key] && orgsChildren[key]) {
				const array = [...orgsChildren[key].children];
				array.push(inputChildren[key].children[0]);
				tempObj[key] = { children: array };
			}
			expanded.push(Number(key));
		});
		setChildren(tempObj);
		setExpandedItems(expanded);
		setLoading(false);
	}

	useEffect(() => {
		isFirst = false;
		setLoading(true);
		dispatch(initOrgPage());

		return () => {
			isFirst = true;
			dispatch(selectedOrgFolderID({ fid: -1 })).then(() => {
				dispatch(initOrgSearchText());
			});
		};
	}, [dispatch]);

	function handleExpandClick() {
		if (expandedItems.length === 0) {
			const tempObj = { ...orgsChildren };
			const expanded = [];
			Object.keys(orgsChildren).forEach(key => {
				if (inputChildren[key] && orgsChildren[key]) {
					const array = [...orgsChildren[key].children];
					array.push(inputChildren[key].children[0]);
					tempObj[key] = { children: array };
				}
				expanded.push(Number(key));
			});
			setExpandedItems(expanded);
		} else {
			setExpandedItems([]);
		}
	}

	function handleRowClick(fid) {
		if (selectedOrgID !== fid) {
			dispatch(selectedOrgFolderID({ fid }));
			props.fnToggleRightSidebar();
		}
	}

	function doRemoveOrg(deleteFid) {
		dispatch(setShowBackdrop({ show: true }));
		removeOrgFolder(deleteFid, dispatch)
			.then(respData => {
				dispatch(showMessage({ message: t('ORG_MESSAGE_1') }));
				// dispatch(getOrgFolderList({ parentFid: selectedOrgID })).then(() => setLoading(false));

				delete inputChildren[selectedOrgID];
				dispatch(getAllOrgFolderList()).then(() => setLoading(false));
			})
			.catch(err => {
				dispatch(showErrorMessage({ message: t('ORG_ERROR_MESSAGE_1') }));
			});
	}

	function onClickAdd(name) {
		if (!name || name.trim().length <= 0) {
			dispatch(showErrorMessage({ message: t('FOLDER_ERROR_MESSAGE_1') }));
			return false;
		}
		if (name.trim().length > 30) {
			dispatch(showErrorMessage({ message: t('FOLDER_ERROR_MESSAGE_3') }));
			return false;
		}
		if (/[\\/:*?\\"<>|]/.test(name.trim())) {
			dispatch(showErrorMessage({ message: t('PATTERN_ERROR_MESSAGE') }));
			return false;
		}

		dispatch(setShowBackdrop({ show: true }));
		const pid = selectedOrgID > -1 ? selectedOrgID : 0;
		createOrgFolder(name, pid, dispatch)
			.then(respData => {
				dispatch(showMessage({ message: t('ORG_ADD') }));
				// dispatch(getOrgFolderList({ parentFid: selectedOrgID })).then(() => setLoading(false));
				// formRef.current.reset();
				//	입력 창 등록시 같이 등록하여 값이 이미 존대.

				delete inputChildren[pid];
				dispatch(getAllOrgFolderList()).then(() => setLoading(false));
			})
			.catch(err => {
				if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
					dispatch(showErrorMessage({ message: t('FOLDER_ERROR_MESSAGE_2') }));
				}
			});
		return true;
	}

	const onClickDelete = (e, folderObj) => {
		e.stopPropagation();
		dispatch(
			openDialog({
				children: (
					<OpenDialogForm
						title={t('DELETE_FOLDER_TITLE')}
						content={`'${folderObj.name}'${t('DELETE_CONTENT')}`}
						fnHandleOK={() => {
							doRemoveOrg(folderObj.fid);
						}}
					/>
				)
			})
		);
	};

	if (loading) {
		return <FuseLoading />;
	}

	// if (data.length === 0) {
	// 	return (
	// 		<FuseAnimate delay={100}>
	// 			<div className="flex flex-1 items-center justify-center h-full">
	// 				<Typography color="textSecondary" variant="h5">
	// 					There are no users!
	// 				</Typography>
	// 			</div>
	// 		</FuseAnimate>
	// 	);
	// }

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className={clsx('items-center w-full flex table-search-wrapper')}>
					<div className="">
						<div className="input-item">
							<Input
								placeholder={t('SEARCH')}
								className="search-input"
								disableUnderline
								fullWidth
								value={searchText}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={e => {
									setSearchText(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="total-cnt-info">
						{/* <span className="pipe">|</span>
						<span>
							{t('TOTAL')} <span className="count">{data.length}</span>
							{t('CNT')}
						</span> */}
					</div>
					<div className="btn-group">
						<div className="">
							<Button onClick={e => handleExpandClick()}>
								{expandedItems.length === 0 ? t('EXPAND_ALL') : t('COLLAPSE_ALL')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className=""
								type="button"
								onClick={e => {
									const targetID = selectedOrgID === -1 ? 0 : selectedOrgID;
									if (inputChildren[targetID]) {
										dispatch(showErrorMessage({ message: t('FOLDER_ERROR_MESSAGE_6') }));
										return false;
									}

									//	자식 배열에 신규 등록 하는 입력창그려주는 정보 추가.
									const newChildren = children[targetID] ? [...children[targetID].children] : [];
									const obj = { fid: Date.now() * -1, name: '' };
									newChildren.unshift(obj);
									setChildren({ ...children, [targetID]: { children: newChildren } });
									inputChildren[targetID] = { children: [obj] };
									apiRef.current.setItemExpansion(e, targetID, true);
									return true;
								}}
							>
								{t('NEW_REGISTRATION')}
							</Button>
						</div>
					</div>
				</div>
				{/* <FuseAnimate animation="transition.expandIn" delay={800}>
					<Fab
						color="default"
						aria-label="edit"
						className={clsx(
							'absolute right-60 mx-16 -mb-28 z-10 top-10 md:top-13 md:hidden w-45 h-45 md:w-56 md:h-56'
						)}
						onClick={e => props.fnToggleRightSidebar()}
					>
						<Icon>edit_icon</Icon>
					</Fab>
				</FuseAnimate> */}
				<FuseScrollbars
					className={clsx(classes.contentWrapper, 'flex-grow overflow-x-hidden flex flex-col')}
					ref={contentTable}
				>
					<div>
						<OrgTreeView
							className=""
							childrenObj={children}
							apiRef={apiRef}
							expandedItems={expandedItems}
							fnOnChangeInput={(e, itemId, parentId) => {
								//	입력 창 등록시 같이 등록하여 값이 이미 존대. 최상위는 parentID가 0으로 온다.
								const pid = parentId || 0;
								const newChildren = [...inputChildren[pid].children];

								newChildren.forEach(c => {
									if (c.fid === itemId) {
										c.name = e.target.value;
									}
								});
								inputChildren[selectedOrgID] = { children: newChildren };
							}}
							fnOnClickAdd={(e, name) => {
								onClickAdd(name);
							}}
							fnOnClickDelete={(e, folderObj) => onClickDelete(e, folderObj)}
							fnOnClickHandler={(e, node) => handleRowClick(node.fid)}
						/>
					</div>
					<Box
						style={{ height: 'inherit' }}
						onClick={e => {
							dispatch(selectedOrgFolderID({ fid: -1 }));
						}}
					/>
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

export default OrgsTable;
