import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .col-name': {
			minWidth: 210
		},
		'& .col-desc': {
			minWidth: 350
		}
	}
}));

function GroupsTableHead(props) {
	const classes = useStyles(props);
	const { t } = useTranslation('docbase');

	return (
		<TableHead>
			<TableRow className={classes.tr}>
				<TableCell className="left col-name">{t('그릅명')}</TableCell>
				<TableCell className="left col-desc">{t('설명')}</TableCell>
				<TableCell className="delete-cell" align="center" padding="default" sortDirection={false}>
					{t('DELETE')}
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

export default GroupsTableHead;
