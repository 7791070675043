import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import {
	Button,
	TextField,
	MenuItem,
	List,
	ListItem,
	ListItemText,
	Tabs,
	Tab,
	Box,
	RadioGroup,
	Radio,
	FormControlLabel,
	Paper
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { getUserList } from 'app/store/docbase/admin/accountSlice';
import { getAllOrgFolderList } from 'app/store/docbase/admin/folderSlice';
import { getOsrInfo, procOsr } from 'app/store/docbase/admin/systemSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import commonUtils from 'app/utils/CommonUtils';

import OsrOrgDialog from './OsrOrgDialog';
import OpenDialogForm from '../component/OpenDialogForm';

const useStyles = makeStyles(theme => ({
	list: {
		padding: 0,
		'& .MuiListItem-root': {
			cursor: 'default',
			borderBottom: '1px dotted rgb(139 149 161 / 24%)'
		},
		'& .MuiListItemText-root': {
			cursor: 'default'
		}
	},
	rowTitle: {
		height: 50,
		background: '#353b48',
		borderBottom: '1px solid #8b95a1',
		color: '#ffffff',
		paddingLeft: 25,
		'& .MuiCheckbox-root': {
			color: '#ffffff'
		}
	},
	row: {
		height: 44,
		color: '#000000',
		paddingLeft: 25,
		'& .MuiTextField-root': {
			background: '#ffffff'
		},
		'& .MuiTypography-body2': {
			fontSize: 13,
			fontFamily: 'S-CoreDream-Regu'
		}
	},
	addIcon: {
		top: '25px'
	},
	deleteIcon: {
		color: '#ff6565'
	},
	col_max_w_100: {
		'max-width': '100px'
	},
	contentWrapper: {
		height: `${window.innerHeight - 296}px`
	},
	tr: {
		height: '53px'
	},
	actionsWrapper: {
		padding: '16px 24px 24px'
	},
	btnGroup: { display: 'flex', width: '300px' },
	tabHeader: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		'& .active_bar': {
			background: '#fff'
		},
		'& .list-tab': {
			borderRadius: '6px 6px 0 0',
			minWidth: 118,
			height: 50,
			padding: 0,
			color: '#192141'
		},
		'& .list-tab.Mui-selected': {
			background: '#ffffff',
			color: '#00cfe2'
		}
	},
	tabContent: {
		boxShadow: 'none',
		marginTop: '-3px'
	},
	tabPanel: {
		width: '100%',
		'& .MuiPaper-root': {
			boxShadow: 'none'
		}
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box className="">{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function OsrProcessesContent(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const { osrObj } = useSelector(({ docbase }) => docbase.admin.system);
	const { users } = useSelector(({ docbase }) => docbase.admin.account);
	const { orgFolders } = useSelector(({ docbase }) => docbase.admin.folder);

	const [loading, setLoading] = React.useState(true);
	const [loadingMsg, setLoadingMsg] = React.useState('');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const [processingObj, setProcessingObj] = React.useState({
		newObj: {},
		moveObj: {},
		removeObj: {},
		removeUserObj: {}
	});
	const [existsUsers, setExistsUsers] = React.useState([]);
	const [existsOrgs, setExistsOrgs] = React.useState([]);
	const [removeOrgs, setRemoveOrgs] = React.useState([]);

	const [removeOrgsOne, setRemoveOrgsOne] = React.useState(false);

	const [removeUsersOne, setRemoveUsersOne] = React.useState(false);
	const [tabType, setTabType] = React.useState(0);
	const [orgOpen, setOrgOpen] = React.useState(false);

	React.useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 296}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);
	let timer = 0;
	useEffect(() => {
		initObj();
		if (osrObj.new_organizations.length > 0) {
			setTabType(0);
		} else if (osrObj.remove_organizations.length === 0 && osrObj.remove_users.length > 0) {
			setTabType(2);
		} else {
			setTabType(1);
		}

		clearTimeout(timer);
		if (osrObj.error) {
			setLoading(true);
			if (osrObj.exists) {
				setLoadingMsg(t('OSR_ERROR_MESSAGE_4'));
				timer = setTimeout(() => {
					dispatch(getOsrInfo()).then(() => {
						setLoading(false);
					});
				}, 1500);
			} else {
				setLoadingMsg(t('OSR_ERROR_MESSAGE_5'));
			}
		} else {
			setLoading(false);
			setLoadingMsg('');
		}
	}, [osrObj]);

	const initObj = () => {
		const newObj = {};
		const moveObj = {};
		const removeObj = {};
		const removeUserObj = {};

		osrObj.new_organizations.forEach(o => {
			newObj[o.id] = { path: o.path };
		});
		osrObj.remove_organizations.forEach(o => {
			removeObj[o.path] = { id: 0, path: '' };
		});
		osrObj.remove_users.forEach(o => {
			if (o.supervisor_id) {
				removeUserObj[o.user_id] = { handover_user_id: o.supervisor_id, handover_user_name: o.supervisor_name };
			} else {
				removeUserObj[o.user_id] = {};
			}
		});

		// path가 없으면 selected에서 Exception 발생
		setProcessingObj({ newObj, moveObj, removeObj, removeUserObj });
		setRemoveOrgs(osrObj.remove_organizations);
	};

	useEffect(() => {}, [processingObj]);

	useEffect(() => {
		setExistsUsers(
			[...users].sort((a, b) => {
				if (a.name > b.name) {
					//	b가 a보다 앞으로 오도록 설정
					return 1;
				}
				if (a.name === b.name) {
					//	변경 없음
					return 0;
				}
				//	a 가 b보다 앞으로 오도록 설정
				return -1;
			})
		);
	}, [users]);
	useEffect(() => {
		setExistsOrgs(
			[...orgFolders].sort((a, b) => {
				if (a.path > b.path) {
					//	b가 a보다 앞으로 오도록 설정
					return 1;
				}
				if (a.path === b.path) {
					//	변경 없음
					return 0;
				}
				//	a 가 b보다 앞으로 오도록 설정
				return -1;
			})
		);
	}, [orgFolders]);

	useEffect(() => {
		initPage('');
		return () => {
			clearTimeout(timer);
		};
	}, [dispatch]);

	function initPage(msg) {
		setLoading(true);
		setLoadingMsg(msg);
		dispatch(getUserList()).then(() => {
			dispatch(getAllOrgFolderList()).then(() => {
				dispatch(getOsrInfo()).then(() => {
					setLoading(false);
				});
			});
		});
	}

	if (loading) {
		return <FuseLoading msg={loadingMsg} />;
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<OsrOrgDialog open={orgOpen} fnHandleClose={() => setOrgOpen(false)} />
			<div className={classes.tabHeader}>
				<Tabs
					value={tabType}
					onChange={(e, newValue) => {
						if (newValue !== 0 && osrObj.new_organizations.length > 0) {
							dispatch(showErrorMessage({ message: t('OSR_ERROR_MESSAGE_1') }));
							return false;
						}

						if (tabType !== newValue) {
							initObj();
							setTabType(newValue);
						}
						return true;
					}}
					indicatorColor="secondary"
					textColor="inherit"
					variant="scrollable"
					scrollButtons="off"
					classes={{ indicator: 'active_bar' }}
				>
					<Tab
						className="list-tab"
						label={`${t('OSR_ADD_ORG')}(${osrObj.new_organizations.length})`}
						{...a11yProps(0)}
					/>
					<Tab
						className="list-tab"
						label={`${t('OSR_REMOVE_ORG')}(${osrObj.remove_organizations.length})`}
						{...a11yProps(1)}
					/>
					<Tab
						className="list-tab"
						label={`${t('OSR_REMOVE_USER')}(${osrObj.remove_users.length})`}
						{...a11yProps(2)}
					/>
				</Tabs>

				<Button
					onClick={e => {
						setOrgOpen(true);
					}}
					className="default"
					style={{
						position: 'absolute',
						right: 0,
						width: 'fit-content',
						height: '35px',
						marginBottom: '5px',
						fontSize: '12px'
					}}
				>
					{t('OSR_ORG_TITLE')}
				</Button>
			</div>
			<div className={classes.tabContent}>
				<TabPanel className={classes.tabPanel} value={tabType} index={0}>
					<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
						<div className="osr-info-wrapper">
							<div className="title">{t('OSR_ADD_ORG')}</div>
							<div className="hint-span">{t('OSR_HINT_MESSAGE_1')}</div>
							<div className="btn-group">
								<Button
									onClick={e => {
										initObj();
									}}
									className="default"
								>
									{t('CANCEL')}
								</Button>

								<Button
									variant="contained"
									color="secondary"
									aria-label="proc-confirm"
									type="button"
									onClick={e => {
										dispatch(
											openDialog({
												children: (
													<OpenDialogForm
														title={t('OSR')}
														content={t('OSR_CONFIRM_MESSAGE_1')}
														fnHandleOK={() => {
															procOsr(
																processingObj.newObj,
																processingObj.moveObj,
																processingObj.removeObj,
																processingObj.removeUserObj
															)
																.then(data => {
																	dispatch(
																		showMessage({
																			message: t('OSR_ERROR_MESSAGE_4')
																		})
																	);
																	initPage(t('OSR_ERROR_MESSAGE_4'));
																})
																.catch(err => {
																	dispatch(
																		showErrorMessage({
																			message: t('OSR_ERROR_MESSAGE_5')
																		})
																	);
																});
														}}
													/>
												)
											})
										);
									}}
								>
									{t('APPLY')}
								</Button>
							</div>
						</div>
						<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
							<List className={clsx(classes.list)} dense component="div" role="list">
								<ListItem className={classes.rowTitle}>{t('ORG_NAME')}</ListItem>
								{osrObj.new_organizations.map((obj, idx) => {
									const labelId = `transfer-list-all-item-${idx}-label`;

									//	하위조직 이동가능하게 풀어둔다.
									let isDisabled = false;
									const k = Object.keys(processingObj.moveObj);

									for (let i = 0; i < k.length; i++) {
										if (obj.id === Number(k[i])) {
											// eslint-disable-next-line no-continue
											continue;
										}

										if (obj.path.indexOf(processingObj.moveObj[k[i]].newPath) === 0) {
											isDisabled = true;
											break;
										}
									}

									return (
										<ListItem className={classes.row} hover role="checkbox" tabIndex={-1} key={idx}>
											<ListItemText className="w-1/2" id={labelId} primary={`[${obj.id}] ${obj.path}`} />
											<TextField
												select
												className="w-1/2"
												name="osr_add_folder"
												// disabled={isDisabled}
												variant="outlined"
												value={processingObj.moveObj[obj.id] ? processingObj.moveObj[obj.id].path : '-'}
												fullWidth
												onChange={e => {
													if (e.target.value === '-') {
														const mo = processingObj.moveObj;
														delete mo[obj.id];

														setProcessingObj({
															...processingObj,
															newObj: {
																...processingObj.newObj,
																[obj.id]: { path: obj.path }
															},
															moveObj: mo
														});
													} else {
														const removeObj = { ...processingObj.removeObj };
														const moveObj = {
															[obj.id]: { path: e.target.value, newPath: obj.path }
														};
														//	지금 등록하는 것보다 하위부서는 삭제해준다.
														//	상위부서 다음 위위부서를 선택할수는 없다. 화면단에서 제어됨
														Object.keys(processingObj.moveObj).forEach(key => {
															if (processingObj.moveObj[key].newPath.indexOf(obj.path) < 0) {
																moveObj[key] = {
																	path: processingObj.moveObj[key].path,
																	newPath: processingObj.moveObj[key].newPath
																};
															}
														});

														//	이관대상에 대한 삭제 정보 초기화. 이관 폴더를 지정햇을 우선수위를 가지게 한다.
														Object.keys(removeObj).forEach(key => {
															//	하위부서에대한것도 다 초기화
															if (key.indexOf(e.target.value) === 0) {
																removeObj[key] = { path: '', id: 0 };
															}
														});

														const newObj = {};
														const mKeys = Object.keys(moveObj);
														osrObj.new_organizations.forEach(o => {
															let isMove = false;
															for (let i = 0; i < mKeys.length; i++) {
																if (o.path.indexOf(moveObj[mKeys[i]].newPath) === 0) {
																	isMove = true;
																	break;
																}
															}
															if (!isMove) {
																newObj[o.id] = { path: o.path };
															}
														});
														//	이관하는 조직의  하위폴더는 추가에서 제거
														setProcessingObj({
															...processingObj,
															newObj,
															moveObj,
															removeObj
														});
													}
												}}
											>
												<MenuItem value="-">{isDisabled ? '상위조직과 같이 이동' : t('ADD')}</MenuItem>
												{removeOrgs.map(o => {
													const moveKeys = Object.keys(processingObj.moveObj);
													let isMove = false;
													for (let i = 0; i < moveKeys.length; i++) {
														if (moveKeys[i] === String(obj.id)) {
															// eslint-disable-next-line no-continue
															continue;
														}

														if (processingObj.moveObj[moveKeys[i]].path === o.path) {
															isMove = true;
															break;
														}
													}
													if (!isMove && processingObj.removeObj[o.path].path === '') {
														return <MenuItem value={o.path}>{o.path}</MenuItem>;
													}
													return '';
												})}
											</TextField>
										</ListItem>
									);
								})}
							</List>
						</FuseScrollbars>
					</Paper>
				</TabPanel>

				<TabPanel className={classes.tabPanel} value={tabType} index={1}>
					<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
						<div className="osr-info-wrapper">
							<div className="title">{t('OSR_REMOVE_ORG')}</div>
							<div className="hint-span">{t('OSR_HINT_MESSAGE_2')}</div>
							<div className="radio-group">
								<span className="inline-block">
									<RadioGroup
										row
										aria-labelledby="demo-form-control-label-placement"
										name="osr_removeorg"
										defaultValue="osr_removeorg_0"
										onChange={(e, value) => {
											if (value === 'osr_removeorg_0') {
												initObj();
												setRemoveOrgsOne(false);
											}
											if (value === 'osr_removeorg_1') {
												setRemoveOrgsOne(true);
												initObj();
											}
											if (value === 'osr_removeorg_2') {
												const removeObj = {};
												osrObj.remove_organizations.forEach(ro => {
													removeObj[ro.path] = { path: '#DELETE#', id: 1 };
												});
												setRemoveOrgsOne(false);
												setProcessingObj({
													...processingObj,
													removeObj
												});
											}
										}}
									>
										<FormControlLabel
											value="osr_removeorg_0"
											control={<Radio size="small" color="primary" />}
											label={t('OSR_BTN_1')}
										/>
										<FormControlLabel
											value="osr_removeorg_1"
											control={<Radio size="small" color="primary" />}
											label={t('OSR_BTN_2')}
										/>
										<FormControlLabel
											value="osr_removeorg_2"
											control={<Radio size="small" color="primary" />}
											label={t('OSR_BTN_3')}
										/>
									</RadioGroup>
								</span>
							</div>
							<div className="btn-group">
								<Button
									onClick={e => {
										initObj();
									}}
									className="default"
								>
									{t('CANCEL')}
								</Button>

								<Button
									variant="contained"
									color="secondary"
									className=""
									aria-label="proc-confirm"
									type="button"
									onClick={e => {
										dispatch(
											openDialog({
												children: (
													<OpenDialogForm
														title={t('OSR')}
														content={t('OSR_CONFIRM_MESSAGE_1')}
														fnHandleOK={() => {
															procOsr(
																processingObj.newObj,
																processingObj.moveObj,
																processingObj.removeObj,
																processingObj.removeUserObj
															)
																.then(data => {
																	dispatch(
																		showMessage({
																			message: t('OSR_ERROR_MESSAGE_4')
																		})
																	);
																	initPage(t('OSR_ERROR_MESSAGE_4'));
																})
																.catch(err => {
																	console.log(err);
																	dispatch(
																		showErrorMessage({
																			message: t('OSR_ERROR_MESSAGE_5')
																		})
																	);
																});
														}}
													/>
												)
											})
										);
									}}
								>
									{t('APPLY')}
								</Button>
							</div>
						</div>

						<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
							<List className={clsx(classes.list)} dense component="div" role="list">
								<ListItem className={classes.rowTitle}>조직명</ListItem>
								{osrObj.remove_organizations.map((obj, idx) => {
									const labelId = `transfer-list-all-item-${idx}-label`;

									let isDisabled = false;
									// 추가 될 조직에서 선택한 삭제한 부서는 선택 안되게한다.
									const k = Object.keys(processingObj.moveObj);
									for (let i = 0; i < k.length; i++) {
										if (obj.path.indexOf(processingObj.moveObj[k[i]].path) === 0) {
											isDisabled = true;
											break;
										}
									}

									return (
										<ListItem
											key={idx}
											className={classes.row}
											role="listitem"
											disabled={isDisabled}
											selected={isDisabled}
										>
											<ListItemText id={labelId} primary={`${obj.path}`} />
											<TextField
												select
												className="w-1/2"
												name="osr_remove_folder"
												variant="outlined"
												disabled={isDisabled}
												value={
													processingObj.removeObj[obj.path] &&
													processingObj.removeObj[obj.path].path !== ''
														? processingObj.removeObj[obj.path].path
														: '-'
												}
												fullWidth
												onChange={e => {
													if (e.target.value === '-') {
														//	상위 직직이 삭제 또는 이관이 있는 경는는 유지 할 수 없다.
														if (
															processingObj.removeObj[commonUtils.splitPreDelimiter(obj.path)] &&
															processingObj.removeObj[commonUtils.splitPreDelimiter(obj.path)]
																.path !== ''
														) {
															dispatch(
																showErrorMessage({
																	message: t('OSR_ERROR_MESSAGE_2')
																})
															);
															return false;
														}
														setProcessingObj({
															...processingObj,
															removeObj: {
																...processingObj.removeObj,
																[obj.path]: { path: '', id: 0 }
															}
														});
													} else {
														const { removeObj, moveObj } = processingObj;
														const mKeys = Object.keys(moveObj);
														//	하위부서들도 같은곳으로 이동하게 변경
														Object.keys(removeObj).forEach(key => {
															// 특정조직으로 이동 선택시 든든 조직 변경
															if (key.indexOf(obj.path) === 0 || removeOrgsOne) {
																removeObj[key] = { path: e.target.value, id: 1 };
															}
														});

														//	이미 이관대상일 경우에는 대상에서 제외한다.
														for (let i = 0; i < mKeys.length; i++) {
															if (moveObj[mKeys[i]].path.indexOf(obj.path) === 0) {
																delete moveObj[mKeys[i]];
															}
														}

														const mKeysBefore = Object.keys(moveObj);
														const newObj = {};
														osrObj.new_organizations.forEach(o => {
															let isMove = false;
															for (let i = 0; i < mKeysBefore.length; i++) {
																if (o.path.indexOf(moveObj[mKeysBefore[i]].newPath) === 0) {
																	isMove = true;
																	break;
																}
															}
															if (!isMove) {
																newObj[o.id] = { path: o.path };
															}
														});
														setProcessingObj({
															...processingObj,
															newObj,
															moveObj,
															removeObj
														});
													}
													return true;
												}}
											>
												<MenuItem value="-">{t('OSR_NO_CHANGE')}</MenuItem>
												<MenuItem value="#DELETE#">{t('CLEARANCE')}</MenuItem>
												{existsOrgs.map(org => {
													if (processingObj.removeObj[org.disp_path]) {
														return '';
													}
													return <MenuItem value={`${org.disp_path}`}>{org.disp_path}</MenuItem>;
												})}
											</TextField>
										</ListItem>
									);
								})}
								<ListItem />
							</List>
						</FuseScrollbars>
					</Paper>
				</TabPanel>

				<TabPanel className={classes.tabPanel} value={tabType} index={2}>
					<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
						<div className="osr-info-wrapper">
							<div className="title">{t('OSR_REMOVE_USER')}</div>
							<div className="hint-span">{t('OSR_HINT_MESSAGE_3')}</div>
							<div className="radio-group">
								<span className="inline-block">
									<RadioGroup
										row
										aria-labelledby="demo-form-control"
										name="osr_removeuser"
										defaultValue="osr_removeuser_0"
										onChange={(e, value) => {
											if (value === 'osr_removeuser_0') {
												initObj();
												setRemoveUsersOne(false);
											}
											if (value === 'osr_removeuser_1') {
												setRemoveUsersOne(true);
												initObj();
											}
											if (value === 'osr_removeuser_2') {
												const removeUserObj = {};
												osrObj.remove_users.forEach(ru => {
													removeUserObj[ru.user_id] = {
														handover_user_id: '#DELETE#',
														handover_user_name: '#DELETE#',
														searchVal: '#DELETE#[#DELETE#]'
													};
												});
												setRemoveUsersOne(false);
												setProcessingObj({
													...processingObj,
													removeUserObj
												});
											}
										}}
									>
										<FormControlLabel
											value="osr_removeuser_0"
											control={<Radio size="small" color="primary" />}
											label={t('OSR_BTN_1')}
										/>
										<FormControlLabel
											value="osr_removeuser_1"
											control={<Radio size="small" color="primary" />}
											label={t('OSR_BTN_4')}
										/>
										<FormControlLabel
											value="osr_removeuser_2"
											control={<Radio size="small" color="primary" />}
											label={t('OSR_BTN_3')}
										/>
									</RadioGroup>
								</span>
							</div>
							<div className="btn-group">
								<Button
									onClick={e => {
										initObj();
									}}
									className="default"
								>
									{t('CANCEL')}
								</Button>

								<Button
									variant="contained"
									color="secondary"
									className=""
									aria-label="proc-confirm"
									type="button"
									onClick={e => {
										dispatch(
											openDialog({
												children: (
													<OpenDialogForm
														title={t('OSR')}
														content={t('OSR_CONFIRM_MESSAGE_1')}
														fnHandleOK={() => {
															procOsr(
																processingObj.newObj,
																processingObj.moveObj,
																processingObj.removeObj,
																processingObj.removeUserObj
															)
																.then(data => {
																	dispatch(
																		showMessage({
																			message: t('OSR_ERROR_MESSAGE_4')
																		})
																	);
																	initPage(t('OSR_ERROR_MESSAGE_4'));
																})
																.catch(err => {
																	console.log(err);
																	dispatch(
																		showErrorMessage({
																			message: t('OSR_ERROR_MESSAGE_5')
																		})
																	);
																});
														}}
													/>
												)
											})
										);
									}}
								>
									{t('APPLY')}
								</Button>
							</div>
						</div>
						<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
							<List className={clsx(classes.list)} dense component="div" role="list">
								<ListItem className={classes.rowTitle}>사용자</ListItem>
								{osrObj.remove_users.map((obj, idx) => {
									const labelId = `transfer-list-all-item-${idx}-label`;
									return (
										<ListItem key={idx} className={classes.row} role="listitem" button onClick={e => {}}>
											<ListItemText
												id={labelId}
												className="w-1/2"
												primary={`${obj.name}[${obj.user_id}]`}
											/>
											<TextField
												select
												className="w-1/2"
												name="osr_remove_user"
												variant="outlined"
												value={
													processingObj.removeUserObj[obj.user_id] &&
													processingObj.removeUserObj[obj.user_id].handover_user_id
														? processingObj.removeUserObj[obj.user_id].handover_user_id
														: '-'
												}
												fullWidth
												onChange={e => {
													if (e.target.value === '-') {
														setProcessingObj({
															...processingObj,
															removeUserObj: {
																...processingObj.removeUserObj,
																[obj.user_id]: { handover_user_id: '' }
															}
														});
													} else if (removeUsersOne) {
														const removeUserObj = {};
														osrObj.remove_users.forEach(ru => {
															removeUserObj[ru.user_id] = {
																handover_user_id: e.target.value,
																handover_user_name: e.target.value,
																searchVal: `${e.target.value}[${e.target.value}]`
															};
														});
														setProcessingObj({
															...processingObj,
															removeUserObj
														});
													} else {
														setProcessingObj({
															...processingObj,
															removeUserObj: {
																...processingObj.removeUserObj,
																[obj.user_id]: { handover_user_id: e.target.value }
															}
														});
													}
												}}
											>
												<MenuItem value="-">{t('OSR_NO_CHANGE')}</MenuItem>
												<MenuItem value="#DELETE#">{t('CLEARANCE')}</MenuItem>
												{existsUsers.map(u => {
													if (u.user_id !== obj.user_id && !processingObj.removeUserObj[u.user_id]) {
														return (
															<MenuItem value={`${u.user_id}`}>
																{u.name}[{u.user_id}]
															</MenuItem>
														);
													}
													return '';
												})}
											</TextField>
											{/* <Autocomplete
														id="autocomplete-permission-search"
														className="w-1/2"
														options={[
															{
																access_level: 1,
																email: '',
																id: '',
																local_account: false,
																name: '',
																privilege: 1,
																user_id: ''
															},
															{
																access_level: 1,
																email: '',
																id: '',
																local_account: false,
																name: '#DELETE#',
																privilege: 1,
																user_id: '#DELETE#'
															},
															...existsUsers.filter(
																u =>
																	u.user_id !== obj.user_id &&
																	!processingObj.removeUserObj[u.user_id]
															)
														]}
														getOptionLabel={option => {
															return option.name === ''
																? t('OSR_NO_CHANGE')
																: option.name === '#DELETE#'
																? t('CLEARANCE')
																: `${option.name}[${option.user_id}]`;
														}}
														noOptionsText={t('PERM_NO_USER')}
														autoComplete
														includeInputInList
														onChange={(e, newVal) => {
															if (newVal === null) {
																setProcessingObj({
																	...processingObj,
																	removeUserObj: {
																		...processingObj.removeUserObj,
																		[obj.user_id]: {
																			handover_user_id: '',
																			handover_user_name: '',
																			searchVal: ''
																		}
																	}
																});
															} else {
																if (removeUsersOne) {
																	const removeUserObj = {};
																	osrObj.remove_users.forEach(ru => {
																		removeUserObj[ru.user_id] = {
																			handover_user_id: newVal.user_id,
																			handover_user_name: newVal.name,
																			searchVal: `${newVal.name}[${newVal.user_id}]`
																		};
																	});
																	setProcessingObj({
																		...processingObj,
																		removeUserObj
																	});
																} else {
																	setProcessingObj({
																		...processingObj,
																		removeUserObj: {
																			...processingObj.removeUserObj,
																			[obj.user_id]: {
																				handover_user_id: newVal.user_id,
																				handover_user_name: newVal.name,
																				searchVal: `${newVal.name}[${newVal.user_id}]`
																			}
																		}
																	});
																}
															}
														}} 
													inputValue={processingObj.removeUserObj[obj.user_id].searchVal}
													onInputChange=
													{(e, newInputVal) => {
														setProcessingObj({
															...processingObj,
															removeUserObj: {
																...processingObj.removeUserObj,
																[obj.user_id]: { searchVal: newInputVal }
															}
														});
													}}
													renderInput=
													{params => (
														<TextField
															{...params}
															label={t('OSR_REMOVE_USER_LABEL')}
															variant="outlined"
															onKeyDown={e => {}}
														/>
													)}
													fullWidth size="small" /> */}
										</ListItem>
									);
								})}
								<ListItem />
							</List>
						</FuseScrollbars>
					</Paper>
				</TabPanel>
			</div>
		</div>
	);
}

export default OsrProcessesContent;
