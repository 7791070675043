import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	Slide,
	TextField,
	Card,
	Divider,
	List,
	ListItem,
	ListItemText,
	Table,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import clsx from 'clsx';
import { getDirectOrgList, transferOrgFolder, updOrgsObj } from 'app/store/docbase/admin/folderSlice';
import { showMessage, showErrorMessage } from 'app/store/fuse/messageSlice';
import { setShowBackdrop } from 'app/store/docbase/admin/settingSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import CommonUtils from 'app/utils/CommonUtils';
import OpenDialogForm from '../component/OpenDialogForm';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%'
		}
	},
	contentWrapper: {
		height: 623
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6
		},
		'&>.content>div': {
			width: '100%'
		},
		'& .search-form': {
			padding: 12
		}
	},
	tableOrg: {
		'& .MuiTableRow-root.Mui-selected': {
			borderColor: 'rgba(0, 207, 226, 0.3)'
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 20,
		paddingBottom: 20,
		'& .MuiButton-root': {
			width: 172,
			height: 54
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

export default function TransferDialog(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation('docbase');

	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);
	const { currentFid } = useSelector(({ docbase }) => docbase.admin.folder);

	const [targetObj, setTargetObj] = React.useState({ fid: -1, path: '' });
	const [orgObj, setOrgObj] = React.useState({ orgs: [], targetOrgs: [] });
	const [targetText, setTargetText] = React.useState('');
	React.useEffect(() => {
		if (props.open) {
			getDirectOrgList().then(data => {
				setOrgObj({ orgs: data.orgs, targetOrgs: data.orgs });
			});
			setTargetText('');
			setTargetObj({ fid: -1, path: '' });
		}
	}, [props.open]);

	React.useEffect(() => {
		setOrgObj({
			...orgObj,
			targetOrgs: targetText.length > 0 ? orgObj.orgs.filter(org => org.name.indexOf(targetText) >= 0) : orgObj.orgs
		});
	}, [targetText]);

	const targetName = CommonUtils.splitLastDelimiterString(props.targetPath);
	const topPath = `${sysConfigure.language === 'korean' ? '/조직' : '/Organization'}`;
	return (
		<div>
			<Dialog
				className={classes.root}
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				maxWidth="sm"
				onClose={props.fnHandleClose}
			>
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('TRANSFER_ORG')}[{targetName}]
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div className="content">
						<div>
							<div className="search-form">
								<TextField
									className=""
									placeholder={t('ORG_NAME')}
									type="text"
									name="org-name-text"
									variant="outlined"
									value={targetText}
									onChange={e => setTargetText(e.target.value)}
									fullWidth
								/>
							</div>
							<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')}>
								<Table stickyHeader className={classes.tableOrg} aria-labelledby="tableTitle">
									<TableBody>
										<TableRow
											className="cursor-pointer"
											hover
											role="checkbox"
											tabIndex={-1}
											onClick={e => {
												setTargetObj({ fid: 0, path: topPath });
											}}
											selected={targetObj.fid === 0}
										>
											<TableCell className="left" align="left" component="td" scope="row">
												{topPath}
											</TableCell>
										</TableRow>
										{orgObj.targetOrgs.map((org, idx) => {
											return (
												<TableRow
													className="cursor-pointer"
													hover
													role="checkbox"
													tabIndex={-1}
													key={idx}
													onClick={e => {
														setTargetObj({ fid: org.fid, path: org.path });
													}}
													selected={targetObj.fid === org.fid}
												>
													<TableCell className="left" align="left" component="td" scope="row">
														{org.path}
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</FuseScrollbars>
						</div>
						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.fnHandleClose}
								variant="contained"
								className="default"
								aria-label="cancel"
								type="button"
							>
								{t('CANCEL')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className=""
								aria-label="action"
								type="button"
								onClick={e => {
									if (targetObj.fid < 0) {
										dispatch(showErrorMessage({ message: t('TRANSFER_ORG_ERROR_MESSAGE_1') }));
										return;
									}
									const sourcePath = `${topPath}${props.targetPath}`;
									const targetPath = `${targetObj.path}/${targetName}`;

									if (targetPath === sourcePath) {
										dispatch(showErrorMessage({ message: t('상위 폴더로 이동할 수 없습니다.') }));
										return;
									}

									if (targetPath.indexOf(sourcePath) === 0) {
										dispatch(showErrorMessage({ message: t('자기 하위 폴더로 이동할 수 없습니다.') }));
										return;
									}

									dispatch(
										openDialog({
											children: (
												<OpenDialogForm
													title={t('TRANSFER_ORG')}
													content={t('TRANSFER_ORG_MESSAGE_1')}
													fnHandleOK={() => {
														dispatch(setShowBackdrop({ show: true }));
														transferOrgFolder(
															`${topPath}${props.targetPath}`,
															`${targetObj.path}/${targetName}`
														)
															.then(data => {
																dispatch(updOrgsObj({ parentFid: currentFid })).then(() => {
																	dispatch(
																		showMessage({ message: t('TRANSFER_ORG_MESSAGE_2') })
																	);
																	props.fnResetList();
																	props.fnHandleClose();
																});
															})
															.catch(err =>
																dispatch(
																	showErrorMessage({
																		message: t('TRANSFER_ORG_ERROR_MESSAGE_2')
																	})
																)
															)
															.finally(() => {
																dispatch(setShowBackdrop({ show: false }));
															});
													}}
												/>
											)
										})
									);
								}}
							>
								{t('MOVE')}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
