import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Tab, Tabs, Button, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { getStorageDashboard } from 'app/store/docbase/admin/systemSlice';
import { getUserList } from 'app/store/docbase/admin/accountSlice';
import { getAllOrgFolderList, getSharedFolderList } from 'app/store/docbase/admin/folderSlice';
import commonUtils from 'app/utils/CommonUtils';
import * as commonTypes from 'app/main/constants/CommonTypes';
import ContentHeader from '../component/ContentHeader';

import TotalSizeWidget from './widgets/TotalSizeWidget';
import UnitPieWidget from './widgets/UnitPieWidget';
import UnitListWidget from './widgets/UnitListWidget';
import FolderDetailWidget from './widgets/FolderDetailWidget';
import FolderSizeWidget from './widgets/FolderSizeWidget';

const useStyles = makeStyles(theme => ({
	selectedProject: {
		background: lighten(theme.palette.primary.dark, 0.1),
		color: theme.palette.primary.contrastText,
		borderRadius: '8px 0 0 0'
	},
	projectMenuButton: {
		background: lighten(theme.palette.primary.dark, 0.1),
		color: theme.palette.primary.contrastText,
		borderRadius: '0 8px 0 0',
		marginLeft: 1
	},
	contentWrapper: {
		'& .total-widget': {
			maxWidth: 600,
			marginRight: 30
		},
		'& .unit-widget': {
			maxWidth: 600
		},
		'& .graph-wrapper': {
			flexWrap: 'wrap',
			marginTop: 20,
			'&>.widget': {
				paddingRight: 10
			},
			'&>.widget:last-child': {
				paddingRight: 0
			},
			'& > .widget:nth-child(4n)': {
				paddingRight: 0
			},
			'& > .widget:not(:nth-child(-n+4))': {
				marginTop: 10
			}
		}
	},
	btnGroup: {
		padding: 4,
		background: '#e8ebf0',
		color: '#333d4b',
		'& .MuiButton-root': {
			width: 57,
			height: 32,
			fontSize: 14,
			border: 'none !important',
			padding: '5px 10px'
		},
		'& .MuiButton-root.active': {
			background: '#00cfe2',
			color: '#ffffff'
		}
	},
	listTabHeader: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		marginTop: 30,
		'& .active_bar': {
			background: '#fff'
		},
		'& .list-tab': {
			borderRadius: '6px 6px 0 0',
			minWidth: 118,
			height: 50,
			padding: 0,
			color: '#192141'
		},
		'& .list-tab.Mui-selected': {
			background: '#ffffff',
			color: '#00cfe2'
		}
	},
	listTabContent: {
		boxShadow: 'none',
		marginTop: '-3px'
	}
}));

let timer;
function Dashboard(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');

	const dashboardObj = useSelector(({ docbase }) => docbase.admin.system.dashboardObj);
	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);
	const { usersObj } = useSelector(({ docbase }) => docbase.admin.account);
	const { orgsObj, sharedsObj } = useSelector(({ docbase }) => docbase.admin.folder);

	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const classes = useStyles(props);
	const [isFirst, setIsFirst] = React.useState(true);
	const [tabValue, setTabValue] = useState(0);

	const [totalDataObj, setTotalDataObj] = useState({
		title: '',
		series: [],
		labels: [t('USAGE'), t('REMAINING_CAPACITY')]
	});
	const [unitDataObj, setUnitDataObj] = useState({
		title: '',
		series: [],
		labels: [t('ORG_FOLDER'), t('SHARED_FOLDER'), t('PRIVATE_FOLDER'), t('COLLECTOR_FOLDER'), t('ETC')]
	});
	const [unitListData, setUnitListData] = useState({ titles: [], rows: [] });

	const [orgFolderObj, setOrgFolderObj] = useState({});
	const [graphPage, setGraphPage] = useState(0);
	const [orgDetailData, setOrgDetailData] = useState({ title: t('ORG_FOLDER'), series: [], labels: [] });
	const [privateDetailData, setPrivateDetailData] = useState({
		title: t('PRIVATE_FOLDER'),
		series: [],
		labels: []
	});
	const [sharedDetailData, setSharedDetailData] = useState({ title: t('SHARED_FOLDER'), series: [], labels: [] });
	const [isLoading, setIsLoading] = useState(false);
	const [selectedSizeInfo, setSelectedSizeInfo] = useState({});

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 141}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		return () => {
			if (timer) {
				clearInterval(timer);
			}
		};
	}, []);

	useEffect(() => {
		if (!isFirst) commonUtils.setLocalStorage('dashboard_selected_object', selectedSizeInfo);
	}, [selectedSizeInfo]);

	useEffect(() => {
		dispatch(getAllOrgFolderList()).then(() => {
			dispatch(getStorageDashboard()).then(() => {
				if (!timer) {
					timer = setInterval(() => {
						dispatch(getStorageDashboard());
					}, 1000 * 60);
				}
				dispatch(getUserList()).then(() => {
					dispatch(getSharedFolderList());
				});
			});
		});

		if (isFirst) {
			const storageObj = commonUtils.getLocalStorage('dashboard_selected_object');
			setSelectedSizeInfo(storageObj);
			setIsFirst(false);
		}
	}, [dispatch]);

	useEffect(() => {
		const firstDepth = [];
		let curOrg = {};
		let curPaths = [];
		const orgs = dashboardObj ? [...dashboardObj.organizes] : [];
		const orgDataObj = {};
		orgs.splice(-1, 1);

		if (Object.keys(orgsObj).length > 0) {
			orgs.forEach((org, idx) => {
				const key = Object.keys(org);
				curOrg = orgsObj[org[key].fid];
				curPaths = curOrg.fid_path.split('/');

				orgDataObj[curOrg.fid] = {
					name: curOrg.name,
					loc: org[key].size,
					capacity: org[key].capacity,
					children: orgDataObj[curOrg.fid] && orgDataObj[curOrg.fid].children ? orgDataObj[curOrg.fid].children : []
				};
				if (curPaths.length <= 2) {
					firstDepth.push(curOrg.fid);
				} else {
					// eslint-disable-next-line no-lonely-if
					if (orgDataObj[curPaths[curPaths.length - 2]]) {
						orgDataObj[curPaths[curPaths.length - 2]] = {
							...orgDataObj[curPaths[curPaths.length - 2]],
							loc: orgDataObj[curPaths[curPaths.length - 2]].loc - org[key].size,
							children: [
								...orgDataObj[curPaths[curPaths.length - 2]].children,
								{ fid: curOrg.fid, name: curOrg.name, loc: org[key].size, children: [] }
							]
						};
					} else {
						orgDataObj[curPaths[curPaths.length - 2]] = {
							name: orgsObj[curPaths[curPaths.length - 2]].name,
							children: [{ fid: curOrg.fid, name: curOrg.name, loc: org[key].size, children: [] }]
						};
					}
				}
			});
		}
		setOrgFolderObj(orgDataObj);
		if (graphPage === 0) {
			setPrivateDetailData({ ...privateDetailData, series: [], labels: [] });
			setOrgDetailData({ ...orgDetailData, series: [], labels: [] });
			setSharedDetailData({ ...sharedDetailData, series: [], labels: [] });
		} else if (graphPage === 1) {
			if (dashboardObj.organizes.length > 1) {
				//	조직폴더 상세그래프
				// firstDepth.forEach(fid => {
				// 	if (orgDataObj[fid].children.length > 0) {
				// 		let sumLoc = 0;
				// 		orgDataObj[fid].children.forEach(chObj => {
				// 			const resultObj = checkChildren(chObj.fid);
				// 			sumLoc += chObj.loc - resultObj.sumLoc;
				// 			chObj.children = resultObj.children;
				// 		});
				// 		orgDataObj[fid].loc -= sumLoc;
				// 	}

				// 	orgData.push(orgDataObj[fid]);
				// });

				// setOrgDetailData(orgData);
				//	사용자 상세그래프 데이터 정리

				let etcOrg = 0;
				const orgName = [];
				const orgData = [];
				if (dashboardObj.organizes.length > 1) {
					dashboardObj.organizes.forEach((ori, idx) => {
						if (dashboardObj.organizes.length - 1 > idx) {
							const key = dashboardObj.organizes[dashboardObj.organizes.length - 1].organize_names[idx];
							const per = (orgDataObj[ori[key].fid].loc / dashboardObj.types.organizes.size) * 100;
							// console.log(dashboardObj.types.organizes.size);
							// console.log(orgDataObj[ori[key].fid].loc);
							// console.log(per);
							if (per <= 5) {
								etcOrg += orgDataObj[ori[key].fid].loc;
							} else {
								orgName.push(orgsObj[ori[key].fid].disp_path);
								orgData.push(orgDataObj[ori[key].fid].loc);
							}
						}
					});

					orgName.push(t('ETC'));
					orgData.push(etcOrg);
				}
				setOrgDetailData({ ...orgDetailData, series: orgData, labels: orgName });
			}

			//	사용자 상세그래프 데이터 정리
			let etcPrivate = 0;
			const privateName = [];
			const privateData = [];
			if (dashboardObj.privates.length > 1) {
				dashboardObj.privates.forEach((pri, idx) => {
					if (dashboardObj.privates.length - 1 > idx) {
						const key = dashboardObj.privates[dashboardObj.privates.length - 1].private_names[idx];
						if (pri[key].percent <= 5) {
							etcPrivate += pri[key].size;
						} else {
							privateName.push(usersObj[key] ? `${usersObj[key].name}(${key})` : `-(${key})`);
							privateData.push(pri[key].size);
						}
					}
				});
				privateName.push(t('ETC'));
				privateData.push(etcPrivate);
			}
			setPrivateDetailData({
				...privateDetailData,
				series: privateData,
				labels: privateName
			});

			//	공유폴더 상세그래프 데이터
			let etcShared = 0;
			const sharedName = [];
			const sharedData = [];
			if (dashboardObj.shareds.length > 1) {
				dashboardObj.shareds.forEach((shared, idx) => {
					if (dashboardObj.shareds.length - 1 > idx) {
						const key = dashboardObj.shareds[dashboardObj.shareds.length - 1].shared_names[idx];
						if (shared[key].percent <= 10) {
							etcShared += shared[key].size;
						} else {
							sharedName.push(key);
							sharedData.push(shared[key].size);
						}
					}
				});
				sharedName.push(t('ETC'));
				sharedData.push(etcShared);
			}
			setSharedDetailData({ ...sharedDetailData, series: sharedData, labels: sharedName });
		}

		if (dashboardObj && Object.keys(dashboardObj.total).length > 0) {
			setTotalDataObj({
				title: `${t('TOTAL_CAPACITY')} [${commonUtils.changeByteToString(dashboardObj.total.storage)}]`,
				series: [dashboardObj.total.used.size, dashboardObj.total.free.size],
				labels: [t('USAGE'), t('REMAINING_CAPACITY')]
			});

			setUnitDataObj({
				title: t('GROUP_USAGE'),
				series: [
					dashboardObj.types.organizes.size,
					dashboardObj.types.shareds.size,
					dashboardObj.types.privates.size,
					dashboardObj.types.collects.size,
					dashboardObj.types.others.size
				],
				labels: [t('ORG_FOLDER'), t('SHARED_FOLDER'), t('PRIVATE_FOLDER'), t('COLLECTOR_FOLDER'), t('ETC')]
			});
			// makeUnitList(tabValue);
		}
	}, [dashboardObj, graphPage]);

	useEffect(() => {
		makeUnitList(tabValue);
	}, [orgFolderObj]);

	function handleChangeTab(event, value) {
		setTabValue(value);
		makeUnitList(value);
	}

	async function makeUnitList(value) {
		let titles = [];
		const rows = [];
		setIsLoading(true);
		const changeSizeObj = commonUtils.getLocalStorage('dashboardObj');
		const currentTime = new Date();
		const currentTimestamp = currentTime.getTime();

		const tempSelectedInfo = { ...selectedSizeInfo };
		switch (value) {
			case 0: {
				// console.log(orgFolderObj);
				titles = [t('ORG_NAME'), t('USAGE'), t('ACCESS_TYPE_REMOVE_TRASH'), t('CHANGE')];
				const orgs = [...dashboardObj.organizes];
				orgs.splice(-1, 1);
				for (let i = 0; i < orgs.length; i++) {
					const key = Object.keys(orgs[i])[0];
					// console.log(orgFolderObj[orgs[i][key].fid]);
					const obj = {
						changed: false,
						folderType: commonTypes.FOLDER_TYPE_ORGANIZE,
						name: orgsObj && orgsObj[orgs[i][key].fid] ? orgsObj[orgs[i][key].fid].path : key,
						used: orgFolderObj[orgs[i][key].fid]
							? {
									fid: orgs[i][key].fid,
									capacity: orgFolderObj[orgs[i][key].fid].capacity,
									size: orgFolderObj[orgs[i][key].fid].loc,
									usage_percent:
										orgFolderObj[orgs[i][key].fid].capacity === 0
											? 0
											: (orgFolderObj[orgs[i][key].fid].loc / orgFolderObj[orgs[i][key].fid].capacity) *
											  100,
									path: orgsObj[orgs[i][key].fid].disp_path,
									trash_size: orgs[i][key].trash_size
							  }
							: {
									fid: 0,
									capacity: 0,
									size: 0,
									usage_percent: 0,
									trash_size: 0,
									path: orgsObj[orgs[i][key].fid].disp_path
							  }
					};
					const changeObj = changeSizeObj[orgsObj[orgs[i][key].fid].disp_path];
					// console.log(currentTimestamp);
					if (changeObj) {
						// console.log(changeObj);
						if (currentTimestamp - changeSizeObj[orgsObj[orgs[i][key].fid].disp_path].timestamp > 300000) {
							delete changeSizeObj[orgsObj[orgs[i][key].fid].disp_path];
						} else {
							obj.changed = true;
						}
					}
					rows.push(obj);

					if (tempSelectedInfo[orgs[i][key].fid]) {
						tempSelectedInfo[orgs[i][key].fid] = obj;
					}
				}
				break;
			}
			case 1: {
				titles = [t('FOLDER_NAME'), t('USAGE'), t('ACCESS_TYPE_REMOVE_TRASH'), t('CHANGE')];
				//	맨 마지막에는 공유폴더 정보가 들어있다.
				for (let i = 0; i < dashboardObj.shareds.length - 1; i++) {
					const name = dashboardObj.shareds[dashboardObj.shareds.length - 1].shared_names[i];
					const obj = {
						changed: false,
						folderType: commonTypes.FOLDER_TYPE_SHARED,
						name,
						used: {
							...dashboardObj.shareds[i][name],
							path: sharedsObj[dashboardObj.shareds[i][name].fid].real_path
						}
					};

					if (changeSizeObj[sharedsObj[dashboardObj.shareds[i][name].fid].real_path]) {
						if (
							currentTimestamp - changeSizeObj[sharedsObj[dashboardObj.shareds[i][name].fid].real_path].timestamp >
							300000
						) {
							delete changeSizeObj[changeSizeObj[sharedsObj[dashboardObj.shareds[i][name].fid].real_path]];
						} else {
							obj.changed = true;
						}
					}

					rows.push(obj);
					if (tempSelectedInfo[dashboardObj.shareds[i][name].fid]) {
						tempSelectedInfo[dashboardObj.shareds[i][name].fid] = obj;
					}
				}
				break;
			}
			case 2: {
				titles = [t('USER'), t('USAGE'), t('ACCESS_TYPE_REMOVE_TRASH'), t('CHANGE')];

				for (let i = 0; i < dashboardObj.privates.length - 1; i++) {
					const name = dashboardObj.privates[dashboardObj.privates.length - 1].private_names[i];
					const path = `${sysConfigure.language === 'korean' ? '/개인' : '/Private'}/${name}`;
					const obj = {
						changed: false,
						folderType: commonTypes.FOLDER_TYPE_PRIVATE,
						name: `${usersObj[name] ? usersObj[name].name : '-'}(${name})`,
						used: {
							...dashboardObj.privates[i][name],
							path
						}
					};

					if (changeSizeObj[path]) {
						if (currentTimestamp - changeSizeObj[path].timestamp > 300000) {
							delete changeSizeObj[path];
						} else {
							obj.changed = false;
						}
					}
					rows.push(obj);
					if (tempSelectedInfo[dashboardObj.privates[i][name].fid]) {
						tempSelectedInfo[dashboardObj.privates[i][name].fid] = obj;
					}
				}

				break;
			}
			case 3: {
				titles = [t('USER'), t('USAGE'), t('ACCESS_TYPE_REMOVE_TRASH'), t('CHANGE')];

				for (let i = 0; i < dashboardObj.collects.length - 1; i++) {
					const key = dashboardObj.collects[dashboardObj.collects.length - 1].collect_names[i];
					const path = `${sysConfigure.language === 'korean' ? '/수집' : '/Collect'}/${key}`;
					let changed = false;
					if (changeSizeObj[path]) {
						if (currentTimestamp - changeSizeObj[path].timestamp > 300000) {
							delete changeSizeObj[path];
						} else {
							changed = true;
						}
					}
					const obj = {
						changed,
						folderType: commonTypes.FOLDER_TYPE_COLLECT,
						name: `${usersObj[key] ? usersObj[key].name : '-'}(${key})`,
						used: {
							...dashboardObj.collects[i][key],
							path
						}
					};

					rows.push(obj);
					if (tempSelectedInfo[dashboardObj.collects[i][key].fid]) {
						tempSelectedInfo[dashboardObj.collects[i][key].fid] = obj;
					}
				}
				break;
			}
			default:
		}

		setIsLoading(false);

		commonUtils.setLocalStorage('dashboardObj', changeSizeObj);
		setUnitListData({ titles, rows });

		if (!isFirst) {
			const tsi = getNewSizeInfo(value, tempSelectedInfo);
			setSelectedSizeInfo(tsi);
		}
	}

	function getNewSizeInfo(value, tempInfo) {
		let existsOrg = false;
		let existsShared = false;
		let existsPrivate = false;
		let existsCollect = false;
		const keys = Object.keys(tempInfo);
		keys.forEach(key => {
			if (tempInfo[key].folderType === commonTypes.FOLDER_TYPE_ORGANIZE) {
				existsOrg = true;
			} else if (tempInfo[key].folderType === commonTypes.FOLDER_TYPE_SHARED) {
				existsShared = true;
			} else if (tempInfo[key].folderType === commonTypes.FOLDER_TYPE_PRIVATE) {
				existsPrivate = true;
			} else if (tempInfo[key].folderType === commonTypes.FOLDER_TYPE_COLLECT) {
				existsCollect = true;
			}
		});

		if (value !== 0 && existsOrg) {
			for (let i = 0; i < dashboardObj.organizes.length - 1; i++) {
				const key = dashboardObj.organizes[dashboardObj.organizes.length - 1].organize_names[i];
				const obj = {
					changed: false,
					folderType: commonTypes.FOLDER_TYPE_ORGANIZE,
					name: key,
					used: {
						...dashboardObj.organizes[i][key],
						path: orgsObj[dashboardObj.organizes[i][key].fid].disp_path
					}
				};

				if (tempInfo[dashboardObj.organizes[i][key].fid]) {
					tempInfo[dashboardObj.organizes[i][key].fid] = obj;
				}
			}
		}
		if (value !== 1 && existsShared) {
			for (let i = 0; i < dashboardObj.shareds.length - 1; i++) {
				const key = dashboardObj.shareds[dashboardObj.shareds.length - 1].shared_names[i];
				const obj = {
					changed: false,
					folderType: commonTypes.FOLDER_TYPE_SHARED,
					name: key,
					used: {
						...dashboardObj.shareds[i][key],
						path: sharedsObj[dashboardObj.shareds[i][key].fid]
							? sharedsObj[dashboardObj.shareds[i][key].fid].real_path
							: ''
					}
				};

				if (tempInfo[dashboardObj.shareds[i][key].fid]) {
					tempInfo[dashboardObj.shareds[i][key].fid] = obj;
				}
			}
		}
		if (value !== 2 && existsPrivate) {
			for (let i = 0; i < dashboardObj.privates.length - 1; i++) {
				const name = dashboardObj.privates[dashboardObj.privates.length - 1].private_names[i];
				const path = `${sysConfigure.language === 'korean' ? '/개인' : '/Private'}/${name}`;
				const obj = {
					changed: false,
					folderType: commonTypes.FOLDER_TYPE_PRIVATE,
					name: `${usersObj[name] ? usersObj[name].name : '-'}(${name})`,
					used: {
						...dashboardObj.privates[i][name],
						path
					}
				};

				if (tempInfo[dashboardObj.privates[i][name].fid]) {
					tempInfo[dashboardObj.privates[i][name].fid] = obj;
				}
			}
		}
		if (value !== 3 && existsCollect) {
			for (let i = 0; i < dashboardObj.collects.length - 1; i++) {
				const key = dashboardObj.collects[dashboardObj.collects.length - 1].collect_names[i];
				const path = `${sysConfigure.language === 'korean' ? '/수집' : '/Collect'}/${key}`;
				const obj = {
					changed: false,
					folderType: commonTypes.FOLDER_TYPE_COLLECT,
					name: `${usersObj[key] ? usersObj[key].name : '-'}(${key})`,
					used: {
						...dashboardObj.collects[i][key],
						path
					}
				};

				if (tempInfo[dashboardObj.collects[i][key].fid]) {
					tempInfo[dashboardObj.collects[i][key].fid] = obj;
				}
			}
		}

		return tempInfo;
	}

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			innerScroll
			sidebarInner
			header={<ContentHeader />}
			content={
				<FuseScrollbars
					className={clsx(classes.contentWrapper, 'w-full flex-grow overflow-x-auto reboot')}
					ref={contentTable}
				>
					<div className="w-full">
						<div className="">
							<ButtonGroup className={classes.btnGroup} color="primary" aria-label="outlined primary button group">
								<Button
									className={clsx('default', graphPage === 0 ? 'active' : '')}
									onClick={e => setGraphPage(0)}
								>
									{t('ALL')}
								</Button>
								<Button
									className={clsx('default', graphPage === 1 ? 'active' : '')}
									onClick={e => setGraphPage(1)}
								>
									{t('DETAIL')}
								</Button>
								<Button
									className={clsx('default', graphPage === 2 ? 'active' : '')}
									onClick={e => setGraphPage(2)}
								>
									★
								</Button>
							</ButtonGroup>
						</div>
						<DrawDoughnut
							graphPage={graphPage}
							dataObj={{
								totalDataObj,
								unitDataObj,
								privateDetailData,
								orgDetailData,
								sharedDetailData,
								selectedSizeInfo
							}}
							deleteFolderInfo={fid => {
								if (fid !== '') {
									delete selectedSizeInfo[fid.toString()];
									setSelectedSizeInfo({ ...selectedSizeInfo });
								}
							}}
						/>
					</div>

					<div className={classes.listTabHeader}>
						<Tabs
							value={tabValue}
							onChange={(e, v) => handleChangeTab(e, v)}
							indicatorColor="secondary"
							textColor="inherit"
							variant="scrollable"
							scrollButtons="off"
							classes={{ indicator: 'active_bar' }}
						>
							<Tab className="list-tab" disableRipple label={t('ORG_FOLDER')} />
							<Tab className="list-tab" disableRipple label={t('SHARED_FOLDER')} />
							<Tab className="list-tab" disableRipple label={t('USER')} />
							<Tab className="list-tab" disableRipple label={t('COLLECTOR_FOLDER')} />
						</Tabs>
					</div>
					<div className={classes.listTabContent}>
						<UnitListWidget
							tabValue={tabValue}
							data={unitListData}
							isLoading={isLoading}
							selectedInfo={selectedSizeInfo}
							fnMakeUnitList={() => makeUnitList(tabValue)}
							fnSelectedInfo={folderObj => {
								if (Object.keys(selectedSizeInfo).includes(folderObj.used.fid.toString())) {
									delete selectedSizeInfo[folderObj.used.fid];
									setSelectedSizeInfo({ ...selectedSizeInfo });
								} else {
									setSelectedSizeInfo({ ...selectedSizeInfo, [folderObj.used.fid]: folderObj });
								}
							}}
						/>
					</div>
				</FuseScrollbars>
			}
		/>
	);
}

export default Dashboard;

const DrawDoughnut = React.memo(({ graphPage, dataObj, deleteFolderInfo }) => {
	if (graphPage === 0) {
		return (
			<div className="flex graph-wrapper">
				<div className="total-widget flex sm:w-1/3">
					<TotalSizeWidget obj={dataObj.totalDataObj} />
				</div>
				<div className="unit-widget flex sm:w-1/3">
					<UnitPieWidget obj={dataObj.unitDataObj} />
				</div>
			</div>
		);
	}
	if (graphPage === 1) {
		return (
			<div className="sm:flex graph-wrapper">
				<div className="widget flex sm:w-1/3">
					<FolderDetailWidget obj={dataObj.privateDetailData} />
				</div>
				<div className="widget flex sm:w-1/3">
					<FolderDetailWidget obj={dataObj.orgDetailData} />
				</div>
				<div className="widget flex sm:w-1/3">
					<FolderDetailWidget obj={dataObj.sharedDetailData} />
				</div>
			</div>
		);
	}
	return (
		<div className="sm:flex graph-wrapper">
			{Object.keys(dataObj.selectedSizeInfo).map(key => {
				return (
					<div className="widget flex w-1/4">
						<FolderSizeWidget
							fnDeleteFolderInfo={fid => deleteFolderInfo(fid)}
							obj={{
								folderType: dataObj.selectedSizeInfo[key].folderType,
								title: dataObj.selectedSizeInfo[key].name,
								fid: dataObj.selectedSizeInfo[key].used.fid,
								series: [
									dataObj.selectedSizeInfo[key].used.capacity !== 0
										? dataObj.selectedSizeInfo[key].used.capacity - dataObj.selectedSizeInfo[key].used.size
										: 0,
									dataObj.selectedSizeInfo[key].used.size - dataObj.selectedSizeInfo[key].used.trash_size,
									dataObj.selectedSizeInfo[key].used.trash_size
								]
							}}
						/>
					</div>
				);
			})}
		</div>
	);
});
