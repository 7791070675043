import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
	IconButton,
	Dialog,
	DialogContent,
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import commonUtils from 'app/utils/CommonUtils';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});
const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 1508
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	table: {
		'& .path': {
			minWidth: '600px',
			width: '600px',
			maxWidth: '600px'
		},
		'& .mid': {
			minWidth: '250px',
			width: '250px',
			maxWidth: '250px',
			whiteSpace: 'break-spaces',
			wordBreak: 'break-all',
			fontSize: 12
		},
		'& .type': {
			minWidth: '70px',
			width: '70px',
			maxWidth: '70px'
		},
		'& .text': {
			whiteSpace: 'break-spaces'
		},
		'& .MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root': {
			fontSize: 12
		}
	}
}));

export default function ViewInstalledDialog(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('클라이언트 설치 목록')}
				</DialogTitle>
				<DialogContent>
					<Table stickyHeader className={classes.table} aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								<TableCell className="default-policy table_col_date" align="center" padding="default">
									{t('DLP_CLIENT_LOG_LOGGED_AT')}
								</TableCell>
								<TableCell className="default-policy" align="center" padding="default">
									{t('TYPE')}
								</TableCell>
								<TableCell className="default-policy" align="center" padding="default">
									{t('DLP_CLIENT_LOG_PATH')}
								</TableCell>
								<TableCell className="default-policy" align="center" padding="default">
									{t('DLP_CLIENT_LOG_SIGNING_ID')}
								</TableCell>
								<TableCell className="default-policy" align="center" padding="default">
									{t('DLP_CLIENT_LOG_HASH')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.list.map(d => {
								const path = (
									<div>
										<p className="log-name">{commonUtils.splitLastDelimiterString(d.path)}</p>
										<p className="log-path">{commonUtils.splitPreDelimiter(d.path)}</p>
									</div>
								);

								return (
									<TableRow className="cursor-pointer" hover role="checkbox" tabIndex={-1} onClick={e => {}}>
										<TableCell className="left" align="left" component="td" scope="row">
											{commonUtils.getDateStringToLocalDate(d.installed_at)}
										</TableCell>
										<TableCell className="type" align="center" component="td" scope="row">
											{d.install ? t('INSTALL') : t('DELETE')}
										</TableCell>
										<TableCell className="path left" align="left" component="td" scope="row">
											{path}
										</TableCell>
										<TableCell className="mid left" align="left" component="td" scope="row">
											<p className="text">{d.signing_id}</p>
										</TableCell>
										<TableCell className="mid left" align="left" component="td" scope="row">
											<p className="text">{d.hash}</p>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</DialogContent>
			</Dialog>
		</div>
	);
}
