import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogContent, Typography, Button, Slide, TextField, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from 'react-redux';
import { openDialog } from 'app/store/fuse/dialogSlice';
import clsx from 'clsx';
import OpenDialogForm from '../../component/OpenDialogForm';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 604
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6,
			padding: 40
		},
		'&>.content>.row': {
			display: 'flex',
			alignItems: 'center',
			margin: '15px 0'
		},
		'&>.content>.row:first-child': {
			marginTop: 0
		},
		'&>.content>.row:last-child': {
			marginBottom: 0
		},
		'&>.content>.row>.title': {
			width: 160,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.title>span:before': {
			color: '#00cfe2',
			content: '"*"'
		},
		'&>.content>.row>.input-area': {
			width: '100%',
			maxWidth: 382,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.input-area>.hint': {
			color: '#9f9f9f',
			fontSize: '1.2rem'
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 40,
		'& .MuiButton-root': {
			width: 172,
			height: 54
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

export default function NetworkDialog(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation('docbase');
	const inputName = React.useRef(null);

	const [name, setName] = React.useState('');
	const [inboundPorts, setInboundPorts] = React.useState('');
	const [outboundDestinations, setOutboundDestinations] = React.useState('');

	React.useEffect(() => {
		if (props.open) {
			inputName.current.focus();
			if (props.mode === 1) {
				setName(props.selectedObj.name);
				setInboundPorts(props.selectedObj.inbound_ports);
				setOutboundDestinations(props.selectedObj.outbound_destinations);
			}
		}
		return () => {
			setName('');
			setInboundPorts('');
			setOutboundDestinations('');
		};
	}, [props.open]);
	return (
		<div>
			<Dialog
				className={clsx(classes.root)}
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={props.fnHandleClose}
				aria-labelledby="form-dialog"
			>
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					<span className="align-middle">{props.mode === 0 ? t('네트워크 추가') : t('네트워크 수정')} </span>
				</DialogTitle>

				<DialogContent className={clsx(classes.body, 'modal-body')}>
					<div className="content">
						<div className="row">
							<div className="title">
								<span>{t('NAME')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="text"
									name="name"
									value={name}
									onChange={e => setName(e.target.value)}
									variant="outlined"
									size="small"
									required
									fullWidth
									inputProps={{ autocapitalize: 'off' }}
									inputRef={inputName}
								/>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('인바운드 포트')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="text"
									name="inbound_ports"
									value={inboundPorts}
									onChange={e => setInboundPorts(e.target.value)}
									variant="outlined"
									size="small"
									required
									fullWidth
									inputProps={{ autocapitalize: 'off' }}
									inputRef={inputName}
								/>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('아웃바운드 주소')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="text"
									name="outbound_destinations"
									value={outboundDestinations}
									onChange={e => setOutboundDestinations(e.target.value)}
									variant="outlined"
									size="small"
									required
									fullWidth
									inputProps={{ autocapitalize: 'off' }}
								/>
							</div>
						</div>
						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.fnHandleClose}
								variant="contained"
								className="default"
								aria-label="cancel"
								type="button"
							>
								{t('CANCEL')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className=""
								type="button"
								onClick={e => {
									if (props.mode === 0) {
										props.fnHandleOk(name, inboundPorts, outboundDestinations);
									} else {
										dispatch(
											openDialog({
												children: (
													<OpenDialogForm
														title={t('네트워크 수정')}
														content={t('네트워크를 수정 하시겠습니까?')}
														fnHandleOK={() => {
															props.fnHandleUpdate(
																props.selectedObj.id,
																name,
																inboundPorts,
																outboundDestinations
															);
														}}
													/>
												)
											})
										);
									}
								}}
							>
								{props.mode === 0 ? t('ADD') : t('수정')}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
