import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import UsersTable from './UsersTable';
import DetailSidebarContent from './DetailSidebarContent';
import ContentHeader from '../component/ContentHeader';

function UsersPage() {
	const pageLayout = React.useRef(null);
	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<UsersTable fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()} />}
			rightSidebarContent={<DetailSidebarContent fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()} />}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default UsersPage;
