import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseUtils from '@fuse/utils';
import _, { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, IconButton, Tooltip, Paper, Input, Button } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import FuseLoading from '@fuse/core/FuseLoading';

import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { getUserList, selectedUserID, removeUser } from 'app/store/docbase/admin/accountSlice';
import { getAllFolderList } from 'app/store/docbase/admin/folderSlice';
import * as commonTypes from 'app/main/constants/CommonTypes';
import CustomIcon from 'app/main/icon/CustomIcon';
import UsersTableHead from './UsersTableHead';
import DeleteUserModal from './DeleteUserModal';
import CreateModal from './CreateModal';

const useStyles = makeStyles(theme => ({
	root: { marginRight: 30, borderRadius: 6 },
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 228}px`,
		padding: '0 20px'
	},
	icon: {
		width: 20,
		height: 20
	}
}));

let dCnt = 0;
function UsersTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const { users, usersObj, selectedID } = useSelector(({ docbase }) => docbase.admin.account);
	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);

	const [openDelete, setOpenDelete] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [deleteID, setDeleteID] = useState('');
	const [handoverUserID, setHandoverUserID] = useState('-');
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(users);
	const [order, setOrder] = useState({
		id: 'user_id',
		direction: 'asc'
	});
	const [openBackdrop, setOpenBackdrop] = useState(false);
	const [searchText, setSearchText] = React.useState('');

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 228}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		setLoading(true);
		dispatch(getUserList()).then(() => {
			setLoading(false);
			dispatch(getAllFolderList());
		});
		return () => {
			dispatch(selectedUserID({ userID: '' }));
		};
	}, [dispatch]);

	useEffect(() => {
		// setData(FuseUtils.filterUsersByString(users, searchText));
		filterSearch(searchText);
	}, [users, searchText]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	function handleCheck(event, id) {
		if (selectedID !== id) {
			props.fnToggleRightSidebar();
			dispatch(selectedUserID({ userID: id }));
		}
	}

	function deleteConfirm(e, did) {
		e.stopPropagation();
		if (usersObj[did].privilege !== 1) {
			setDeleteID(did);
			setOpenDelete(true);
		} else {
			dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_1') }));
		}
	}

	function doRemoveUser() {
		setOpenBackdrop(true);
		removeUser(deleteID, handoverUserID === '-' ? '' : handoverUserID)
			.then(respData => {
				dispatch(showMessage({ message: t('USER_MESSAGE_9') }));
				dispatch(selectedUserID({ userID: '' }));
				dispatch(getUserList());
				setOpenBackdrop(false);
				setOpenDelete(false);
			})
			.catch(err => {
				if (err.error === commonTypes.SERVER_ERROR_SYSTEM) {
					if (err.message === "can't remove the user in the samba") {
						if (dCnt < 3) {
							dCnt++;
							setTimeout(() => {
								doRemoveUser();
							}, 1000);
						} else {
							dCnt = 0;
							dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_19') }));
							setOpenBackdrop(false);
						}
					}
				} else {
					dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_2') }));
					setOpenBackdrop(false);
				}
			});
	}

	//	debounce로 처리 버튼이 다시 필요 할 경우. 사ㅇ.
	// function doSearch() {
	// 	setData(FuseUtils.filterUsersByString(users, searchText));
	// }

	const filterSearch = useCallback(
		debounce(val => {
			setData(FuseUtils.filterUsersByString(users, val));
		}, 500),
		[users]
	);

	if (loading) {
		return <FuseLoading />;
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="">
						<div className="input-item" style={{ display: 'flex' }}>
							<Input
								placeholder={t('SEARCH')}
								className="search-input"
								disableUnderline
								fullWidth
								value={searchText}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => setSearchText(ev.target.value)}
							/>
							{/* <Button
								size="small"
								variant="contained"
								className="default"
								type="button"
								onClick={() => {
									doSearch();
								}}
								style={{ width: 80, marginLeft: 5 }}
							>
								{t('SEARCH')}
							</Button> */}
						</div>
					</div>
					<div className="total-cnt-info">
						<span className="pipe">|</span>
						<span>
							{t('TOTAL')} <span className="count">{data.length}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="btn-group">
						<div>
							<Button variant="contained" color="secondary" className="" type="button" onClick={handleClickOpen}>
								{t('NEW_REGISTRATION')}
							</Button>
						</div>
					</div>
				</div>

				<CreateModal open={open} fnHandleClose={handleClose} />
				<DeleteUserModal
					userID={usersObj[deleteID] ? usersObj[deleteID].user_id : ''}
					name={usersObj[deleteID] ? usersObj[deleteID].name : ''}
					users={users}
					deleteID={deleteID}
					open={openDelete}
					openBackdrop={openBackdrop}
					fnHandleClose={() => setOpenDelete(false)}
					selectedHandoverUserID={selectedHandOverUserObj => setHandoverUserID(selectedHandOverUserObj.user_id)}
					fnHandleOK={() => doRemoveUser()}
				/>

				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<DataTable
						data={data}
						selectedID={selectedID}
						order={order}
						sysConfigure={sysConfigure}
						handleRequestSort={(e, property) => handleRequestSort(e, property)}
						handleCheck={(e, userID) => handleCheck(e, userID)}
						deleteConfirm={(e, userID) => deleteConfirm(e, userID)}
						t={t}
					/>
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

const DataTable = React.memo(
	({ data, selectedID, order, handleRequestSort, handleCheck, sysConfigure, deleteConfirm, t }) => {
		return (
			<Table stickyHeader className="" aria-labelledby="tableTitle">
				<UsersTableHead
					selectedOrderIds={selectedID}
					order={order}
					onRequestSort={(event, property) => handleRequestSort(event, property)}
					rowCount={data.length}
				/>

				<TableBody>
					{_.orderBy(
						data,
						[
							d => {
								switch (order.id) {
									case 'user_id': {
										return d.user_id;
									}
									case 'name': {
										return d.name;
									}
									case 'access_level': {
										return d.access_level;
									}
									case 'local_account': {
										return d.local_account;
									}
									case 'no_sync_osr': {
										return d.no_sync_osr;
									}
									case 'allow_multiple_logins': {
										return d.allow_multiple_logins;
									}
									default: {
										return d[order.id];
									}
								}
							}
						],
						[order.direction]
					).map(d => {
						const isSelected = selectedID === d.user_id;
						return (
							<TableRow
								className="cursor-pointer"
								hover
								role="checkbox"
								aria-checked={isSelected}
								tabIndex={-1}
								key={d.user_id}
								selected={isSelected}
								onClick={event => handleCheck(event, d.user_id)}
							>
								<TableCell className="left" component="td" scope="row">
									{d.user_id}
								</TableCell>

								<TableCell className="left" component="td" scope="row">
									{d.name}
								</TableCell>

								{sysConfigure.OSR_support ? (
									<>
										<TableCell className="" align="center" padding="default" sortDirection={false}>
											{d.local_account ? t('MANUAL') : t('AUTO')}
										</TableCell>
										<TableCell className="" align="center" padding="default" sortDirection={false}>
											{d.no_sync_osr ? t('EXCLUSION') : ''}
										</TableCell>
									</>
								) : (
									<></>
								)}
								{sysConfigure.menu_support_type === 2 ? (
									<></>
								) : (
									<>
										<TableCell className=" " component="td" scope="row" align="center">
											{d.allow_multiple_logins === 0 ? t('UNUSED') : d.allow_multiple_logins}
										</TableCell>
									</>
								)}

								<TableCell className=" " component="td" scope="row" align="center">
									{d.access_level < 10000 ? d.access_level : '-'}
								</TableCell>
								<TableCell className=" " component="td" scope="row" align="center">
									<div>
										<Tooltip title={t('DELETE')} arrow>
											<IconButton onClick={e => deleteConfirm(e, d.user_id)}>
												<CustomIcon type="delete" className="" fill="#575757" />
											</IconButton>
										</Tooltip>
									</div>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		);
	},
	(prevProps, nextProps) => {
		return (
			_.isEqual(prevProps.data, nextProps.data) &&
			_.isEqual(prevProps.selected, nextProps.selected) &&
			prevProps.selectedID === nextProps.selectedID &&
			_.isEqual(prevProps.order, nextProps.order)
		);
	}
);

export default React.memo(UsersTable);
