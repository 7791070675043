import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import OrgsTable from './OrgsTable';
import DetailSidebarContent from './DetailSidebarContent';

import ContentHeader from '../component/ContentHeader';

function OrgsPage() {
	const pageLayout = React.useRef(null);
	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<OrgsTable fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()} />}
			rightSidebarContent={<DetailSidebarContent fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()} />}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default OrgsPage;
