import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import ContentHeader from '../component/ContentHeader';
import OsrProcessesContent from './OsrProcessesContent';

function WhiteProcesses() {
	const pageLayout = React.useRef(null);

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<OsrProcessesContent />}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default WhiteProcesses;
