/* eslint-disable no-restricted-globals */
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TablePagination, Paper, Tooltip, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { onClickDoc, searchPiiDocs, getPiiDocs, changePiiRowsPerPage } from 'app/store/docbase/admin/settingSlice';
import CustomIcon from 'app/main/icon/CustomIcon';
import commonUtils from 'app/utils/CommonUtils';
import PiiDocsTableHead from './PiiDocsTableHead';

const useStyles = makeStyles(theme => ({
	datePicker: {
		'& .MuiOutlinedInput-notchedOutline': { display: 'none' },
		'& input': {
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 6,
			width: 130,
			textAlign: 'center',
			padding: '9.5px 0'
		},
		'& .MuiOutlinedInput-root.Mui-focused input': {
			boxShadow: '0 0 0 2px #000 inset'
		},
		'& .MuiButtonBase-root': {
			background: '#eceff4',
			borderRadius: 6,
			border: '1px solid #d9dee5',
			padding: 7
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	},
	contentWrapper: {
		height: `${window.innerHeight - 296}px`,
		padding: '0 20px'
	}
}));

function PiiDocsTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const { t } = useTranslation('docbase');

	const { docs, rowsPerPage, currentPage, totalRows } = useSelector(({ docbase }) => docbase.admin.setting.piiDocObj);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 296}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {}, [dispatch]);

	function handleRowClick(docObj) {
		dispatch(onClickDoc({ docObj }));
		// props.fnToggleRightSidebar();
	}

	if (props.loading) {
		return <FuseLoading />;
	}

	function handleChangePage(event, value) {
		props.setLoading(true);
		dispatch(getPiiDocs({ page: value + 1 })).then(() => props.setLoading(false));
	}
	function handleChangeRowsPerPage(event) {
		props.setLoading(true);
		dispatch(changePiiRowsPerPage({ value: event.target.value }));
		dispatch(
			searchPiiDocs({
				page: 1,
				startDate: commonUtils.getDateString(props.searchObj.startDate),
				endDate: commonUtils.getDateString(props.searchObj.endDate),
				searchName: props.searchObj.searchName
			})
		).then(() => props.setLoading(false));
	}
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="total-cnt-info">
						<span>
							{t('TOTAL')} <span className="count">{totalRows}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div className="date-picker-wrapper">
							<span className="title">{t('RESTORE_DAY')}</span>
							<div className="">
								<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
									<KeyboardDatePicker
										className={classes.datePicker}
										fullWidth
										disableToolbar
										invalidDateMessage=""
										autoOk
										size="small"
										variant="inline"
										inputVariant="outlined"
										format="yyyy/MM/dd"
										id="start_date"
										maxDate={new Date('2999-12-31')}
										value={props.searchObj.startDate}
										onChange={d => {
											if (!isNaN(d.getTime())) {
												if (props.searchObj.startDate.getTime() !== d.getTime())
													props.fnSetSearchObj('startDate', d);
											}
										}}
										// onBlur={e => {
										// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
										// 	if (!isNaN(val.getTime()) && props.searchObj.startDate.getTime() !== val.getTime())
										// 		props.fnSetSearchObj('startDate', val);
										// }}
										KeyboardButtonProps={{
											'aria-label': 'change date start'
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
							<div className="tilde">~</div>
							<div className="">
								<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
									<KeyboardDatePicker
										className={classes.datePicker}
										fullWidth
										disableToolbar
										invalidDateMessage=""
										autoOk
										size="small"
										variant="inline"
										inputVariant="outlined"
										format="yyyy/MM/dd"
										id="end_date"
										maxDate={new Date('2999-12-31')}
										value={props.searchObj.endDate}
										onChange={d => {
											if (!isNaN(d.getTime())) {
												if (props.searchObj.endDate.getTime() !== d.getTime())
													props.fnSetSearchObj('endDate', d);
											}
										}}
										// onBlur={e => {
										// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
										// 	if (!isNaN(val.getTime()) && props.searchObj.endDate.getTime() !== val.getTime())
										// 		props.fnSetSearchObj('endDate', val);
										// }}
										KeyboardButtonProps={{
											'aria-label': 'change date end'
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
						</div>
						<div className="">
							<Tooltip title={t('REFRESH')} placement="bottom">
								<IconButton
									key="refresh"
									aria-label="refresh"
									color="secondary"
									onClick={() => props.fnDoSearch()}
								>
									<CustomIcon className="small" type="refresh" fill="#333d4b" />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<PiiDocsTableHead
							searchObj={props.searchObj}
							fnSearch={searchName => {
								if (props.searchObj.searchName !== searchName) {
									props.fnSetSearchObj('searchName', searchName);
								}
							}}
						/>

						<TableBody>
							{docs.map(d => {
								return (
									<TableRow
										hover
										className="cursor-default"
										role="checkbox"
										tabIndex={-1}
										key={d.fid}
										onClick={event => handleRowClick(d)}
									>
										<TableCell className="left">
											<div>
												<p className="log-name">{commonUtils.splitLastDelimiterString(d.disp_path)}</p>
												<p className="log-path">{commonUtils.splitPreDelimiter(d.disp_path)}</p>
											</div>
										</TableCell>
										<TableCell className="left" align="left">
											{d.pii && d.pii.map((p, idx) => (idx > 0 ? ` | ${p.name}` : p.name))}
										</TableCell>
										<TableCell className="left" align="left">
											{d.pii_elements.map(el => (
												<p>{`${el.name}: ${el.hits}`}</p>
											))}
										</TableCell>
										<TableCell className="left" align="left">
											{d.registered_at}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>

				<TablePagination
					className="flex-shrink-0 border-t-1 mx-20"
					component="div"
					count={totalRows}
					rowsPerPage={rowsPerPage}
					page={currentPage - 1}
					labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
					labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
					backIconButtonProps={{
						'aria-label': t('PAGE_PREV_BTN_LABEL')
					}}
					nextIconButtonProps={{
						'aria-label': t('PAGE_NEXT_BTN_LABEL')
					}}
					onChangePage={(e, value) => handleChangePage(e, value)}
					onChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
				/>
			</Paper>
		</div>
	);
}

export default PiiDocsTable;
