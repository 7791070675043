import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const excelFileExtension = '.xlsx';
class ExcelUtils {
	/**
	 *
	 * @param {*} tableTitles	['타이틀1', '타이틀2']
	 * @param {*} excelData 	[{title: '타이틀1', body: '내용1'}, {title: '타이틀2', body: '내용2'}]
	 * @param {*} colSizes  	[{ wpx: 200 }, { wpx: 200 }]
	 */
	excelDownload = (fileName, tableTitles, excelData, colSizes) => {
		const ws = XLSX.utils.aoa_to_sheet([tableTitles]);

		excelData.map(data => {
			const tableContents = [];
			Object.keys(data).forEach(key => {
				tableContents.push(data[key]);
			});

			XLSX.utils.sheet_add_aoa(ws, [tableContents], { origin: -1 });
			if (colSizes && colSizes.length > 0) ws['!cols'] = colSizes;
			return false;
		});
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const excelFile = new Blob([excelButter], { type: excelFileType });
		FileSaver.saveAs(excelFile, `${fileName}${excelFileExtension}`);
	};
}
export default new ExcelUtils();
