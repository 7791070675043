import ClientMonitoringLogPage from './ClientMonitoringLogPage';

const ClientMonitoringLogConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/clientStorageLog',
			component: ClientMonitoringLogPage
		},
		{
			path: '/dlpMonitoringLogs',
			component: ClientMonitoringLogPage
		}
	]
};

export default ClientMonitoringLogConfig;
