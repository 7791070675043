import { TableCell, TableHead, TableRow, Icon, Paper, Input, InputAdornment, IconButton } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .col-type': {
			minWidth: 130
		},
		'& .col-user': {
			minWidth: 200
		},
		'& .col-new-obj': {
			minWidth: 320
		},
		'& .col-old-obj': {
			minWidth: 320
		},
		'& .col-extra': {
			minWidth: 105
		}
	}
}));

function LogsTableHead(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');

	const headerCols = [
		{
			id: 'type',
			align: 'left',
			className: 'left col-type',
			disablePadding: false,
			label: t('DIVISION'),
			isSearch: false,
			sort: false
		},
		{
			id: 'user',
			align: 'left',
			className: 'search-cell col-user',
			disablePadding: false,
			label: t('USER'),
			isSearch: true,
			sort: false
		},
		{
			id: 'object',
			align: 'left',
			className: 'search-cell col-new-obj',
			disablePadding: false,
			label: t('CHANGED_INFO'),
			isSearch: true,
			sort: false
		},
		{
			id: 'extra',
			align: 'left',
			className: 'left col-extra',
			disablePadding: false,
			label: t('MORE_INFO'),
			isSearch: false,
			sort: false
		},

		{
			id: 'date',
			align: 'center',
			className: 'table_col_date',
			disablePadding: false,
			label: t('DATE'),
			isSearch: false,
			sort: false
		}
	];

	const [searchObj, setSearchObj] = React.useState({
		object: { show: props.searchObj.object !== '', value: props.searchObj.object },
		user: { show: props.searchObj.user !== '', value: props.searchObj.user }
	});

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	return (
		<TableHead>
			<TableRow className={classes.tr}>
				{headerCols.map(row => {
					return (
						<TableCell
							className={row.className}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={false}
						>
							{searchObj[row.id] && searchObj[row.id].show ? (
								<Paper className="flex items-center px-8 py-4 rounded-8 shadow mx-5">
									<Icon color="action">search</Icon>

									<Input
										placeholder={row.label}
										className="flex flex-1 mx-8 w-0"
										disableUnderline
										value={searchObj[row.id].value}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													className="p-5"
													aria-label="toggle password visibility"
													onClick={e =>
														setSearchObj({ ...searchObj, [row.id]: { show: false, value: '' } })
													}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													<Icon color="action">close</Icon>
												</IconButton>
											</InputAdornment>
										}
										inputProps={{
											'aria-label': 'Search'
										}}
										onChange={e => {
											setSearchObj({ ...searchObj, [row.id]: { show: true, value: e.target.value } });
										}}
										onKeyPress={e => {
											if (e.charCode === 13) {
												e.preventDefault();
												props.fnSearch(searchObj.object.value, searchObj.user.value);
											}
										}}
									/>
								</Paper>
							) : (
								<>
									<span className="align-middle">{row.label}</span>
									{row.isSearch ? (
										<Icon
											className="align-middle ml-3 cursor-pointer"
											onClick={e => {
												setSearchObj({ ...searchObj, [row.id]: { show: true, value: '' } });
											}}
										>
											search
										</Icon>
									) : (
										<></>
									)}
								</>
							)}
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default LogsTableHead;
