import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import DLPClientTable from './DLPClientTable';
import ContentHeader from '../../component/ContentHeader';

function DLPClientPage() {
	const pageLayout = React.useRef(null);
	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<DLPClientTable fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()} />}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default DLPClientPage;
