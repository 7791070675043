import { makeStyles } from '@material-ui/core/styles';
import {
	TextField,
	MenuItem,
	Card,
	CardActions,
	CardContent,
	Typography,
	IconButton,
	Button,
	Tooltip,
	InputAdornment,
	Input,
	Select,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import * as commonTypes from 'app/main/constants/CommonTypes';
import commonUtils from 'app/utils/CommonUtils';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	setFolderPermission,
	releaseFolderPermission,
	setFolderMaxSize,
	getReadableFolderPermissions,
	setReadableFolderPermissions
} from 'app/store/docbase/admin/folderSlice';
import {
	selectedUserID,
	updateUser,
	getUserList,
	resetPassword,
	createPaperPassword,
	resetTOTP
} from 'app/store/docbase/admin/accountSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { setShowBackdrop } from 'app/store/docbase/admin/settingSlice';
import { naturalizeFiles } from 'app/store/docbase/admin/systemSlice';
import ResetPasswordModal from './ResetPasswordModal';
import OpenDialogForm from '../component/OpenDialogForm';
import PermIcon from '../icon/PermIcon';

const useStyles = makeStyles(theme => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	cardInfo: {
		padding: '40px 30px 20px',
		'& .MuiCardActions-root': {
			padding: 0
		},
		'& .MuiCardContent-root': {
			padding: 0
		}
	},
	cardDetailInfo: {
		'margin-left': '10px',
		height: '48px',
		'line-height': '48px'
	},
	paperPerm: { marginTop: 30, padding: '20px 30px 0' },
	paperPermHeader: {
		padding: 0,
		display: 'flex',
		width: '100%',
		'&>div:first-child': {
			width: '100%',
			maxWidth: 534,
			marginRight: 10
		},
		'&>div:first-child .MuiAutocomplete-inputRoot': {
			height: 40
		},
		'&>div:last-child>button': {
			width: 140
		}
	},
	paperPermContent: { padding: 0, marginTop: 20 },
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 45,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277
	},
	filedInput: { width: '100%', padding: '0 30px', display: 'flex', alignItems: 'center' },
	customInput: {
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 4,
		'&>input': {
			padding: '10.5px 14px'
		},
		'&.Mui-focused': {
			border: '2px solid rgba(0, 0, 0, 1)'
		},
		'&:hover': {
			border: '1px solid rgba(0, 0, 0, 1)'
		}
	},
	select: {
		width: '100%'
	},
	hint_span: {
		'padding-left': '10px',
		'font-size': '1rem',
		color: '#9f9f9f'
	},
	buttonGroup: {
		marginTop: 30,
		'&>div>button': { marginRight: 10 }
	},
	datePicker: {
		'& .MuiOutlinedInput-notchedOutline': { display: 'none' },
		'& input': {
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 6,
			width: 185,
			textAlign: 'center',
			padding: '9.5px 0'
		},
		'& .MuiOutlinedInput-root.Mui-focused input': {
			boxShadow: '0 0 0 2px #000 inset'
		},
		'& .MuiButtonBase-root': {
			background: '#eceff4',
			borderRadius: 6,
			border: '1px solid #d9dee5',
			padding: 7
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	}
}));

function DetailSidebarContent(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const permList = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const { t } = useTranslation('docbase');

	const [permAutoInputVal, setPermAutoInputVal] = React.useState('');
	const { userPerms, selectedID, usersObj, collectorSize, collectorPath, privateSize, privatePath } = useSelector(
		({ docbase }) => docbase.admin.account
	);

	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);
	const { folders } = useSelector(({ docbase }) => docbase.admin.folder);
	const [selectedUser, setSelectedUser] = React.useState(
		selectedID && selectedID !== ''
			? usersObj[selectedID]
			: {
					user_id: '',
					name: '',
					access_level: 3,
					local_account: false,
					no_sync_osr: false,
					allow_multiple_logins: 0,
					locked: false,
					allow_takeout: 0,
					allow_takeout_private_folder: true
			  }
	);
	const [selectedAutocomplete, setSelectedAutocomplete] = React.useState(null);
	const [autoOptions, setAutoOptions] = React.useState(folders);
	const [modUserName, setModUserName] = React.useState(selectedUser.name);
	const [modEmail, setModEmail] = React.useState(selectedUser.email);
	const [openResetPasswd, setOpenResetPasswd] = React.useState(false);
	const [privateMaxSize, setPrivateMaxSize] = React.useState(privateSize);
	const [collectorMaxSize, setCollectorMaxSize] = React.useState(collectorSize);

	const [privateCapacityEx, setPrivateCapacityEx] = React.useState(privateSize);
	const [collectorCapacityEx, setCollectorCapacityEx] = React.useState(collectorSize);
	const [privateSizeUnit, setPrivateSizeUnit] = React.useState(true);
	const [collectorSizeUnit, setCollectSizeUnit] = React.useState(true);

	const [expireAt, setExpireAt] = React.useState(null);

	// const [folderID, setFolderID] = React.useState(0);

	React.useEffect(() => {
		if (innerHeight >= 754) {
			if (permList.current) {
				permList.current.style.height = `${innerHeight - 140}px`;
			}
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {
		let obj = {
			user_id: '',
			name: '  ',
			access_level: 10000,
			email: '  ',
			privateSize: 0,
			collectorSize: 0,
			local_account: false,
			no_sync_osr: false,
			locked: false,
			allow_takeout: 0,
			allow_takeout_private_folder: true,
			expire_at: ''
		};
		if (selectedID && selectedID !== '') {
			obj = usersObj[selectedID];
		}

		permList.current.style.height = `${innerHeight - 140}px`;

		if (privateSize && privateSize >= 1024) {
			setPrivateMaxSize(Math.floor(privateSize / 1024));
			setPrivateSizeUnit(false);
		} else {
			setPrivateSizeUnit(true);
			setPrivateMaxSize(privateSize || 0);
		}
		if (collectorSize && collectorSize >= 1024) {
			setCollectorMaxSize(Math.floor(collectorSize / 1024));
			setCollectSizeUnit(false);
		} else {
			setCollectSizeUnit(true);
			setCollectorMaxSize(collectorSize || 0);
		}

		setPrivateCapacityEx(privateSize);
		setCollectorCapacityEx(collectorSize);

		if (obj.expire_at && obj.expire_at !== '') {
			setExpireAt(commonUtils.getDateStringToDate(obj.expire_at));
		} else {
			setExpireAt(null);
		}

		setSelectedUser(obj);
		setModUserName(obj.name);
		setModEmail(obj.email);
	}, [selectedID, usersObj]);

	React.useEffect(() => {
		const userPermsObj = {};
		userPerms.forEach(perm => {
			userPermsObj[perm.fid] = true;
		});
		setAutoOptions(folders.filter(f => !userPermsObj[f.fid]));
	}, [userPerms]);

	// function setPermEx() {
	// 	if (selectedID === '') {
	// 		dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_12') }));
	// 	} else {
	// 		dispatch(setShowBackdrop({ show: true }));
	// 		setFolderPermission(
	// 			folderID,
	// 			selectedID,
	// 			commonTypes.FILE_PERM_READ_WRITE,
	// 			commonTypes.TRASH_PERM_NONE,
	// 			commonTypes.MANAGE_TYPE_NONE,
	// 			0
	// 		)
	// 			.then(data => {
	// 				dispatch(showMessage({ message: t('PERM_MESSAGE_1') }));
	// 				dispatch(selectedUserID({ userID: selectedID }));
	// 			})
	// 			.catch(err => {
	// 				if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
	// 					dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_1') }));
	// 				}
	// 			})
	// 			.finally(() => {
	// 				dispatch(setShowBackdrop({ show: false }));
	// 			});
	// 	}
	// }

	function setPerm() {
		if (selectedID === '') {
			dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_12') }));
		} else if (selectedAutocomplete) {
			dispatch(setShowBackdrop({ show: true }));
			if (selectedAutocomplete.folder_type === commonTypes.FOLDER_TYPE_ORGANIZE) {
				setFolderPermission(
					selectedAutocomplete.fid,
					selectedID,
					commonTypes.FILE_PERM_READ_WRITE,
					commonTypes.TRASH_PERM_NONE,
					commonTypes.MANAGE_TYPE_NONE,
					0
				)
					.then(data => {
						dispatch(showMessage({ message: t('PERM_MESSAGE_1') }));
						setPermAutoInputVal('');
						setSelectedAutocomplete(null);
						dispatch(selectedUserID({ userID: selectedID }));
					})
					.catch(err => {
						if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
							dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_1') }));
						}
					})
					.finally(() => {
						dispatch(setShowBackdrop({ show: false }));
					});
			} else {
				getReadableFolderPermissions(selectedAutocomplete.fid)
					.then(permissions => {
						const perms = [
							...permissions,
							{
								name: `${usersObj[selectedID].name}(${selectedID})`,
								obj_id: selectedID,
								obj_type: commonTypes.READABLE_OBJ_TYPE_USER,
								file_permission: commonTypes.FILE_PERM_READ_WRITE,
								trash_permission: commonTypes.TRASH_PERM_NONE,
								manage_type: commonTypes.MANAGE_TYPE_NONE,
								manage_priority: 0
							}
						];
						setReadableFolderPermissions(selectedAutocomplete.fid, perms)
							.then(data => {
								dispatch(showMessage({ message: t('PERM_MESSAGE_1') }));
								setPermAutoInputVal('');
								setSelectedAutocomplete(null);
								dispatch(selectedUserID({ userID: selectedID }));
							})
							.catch(err => {
								if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
									dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_1') }));
								}
							})
							.finally(() => {
								dispatch(setShowBackdrop({ show: false }));
							});
					})
					.catch(err => {
						console.log(err);
						dispatch(setShowBackdrop({ show: false }));
					});
			}
		} else {
			dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_14') }));
		}
	}

	function changePerm(fid, userID, filePerm, trashPerm, manageType, managePriority, folderType) {
		dispatch(setShowBackdrop({ show: true }));
		if (
			folderType === commonTypes.FOLDER_TYPE_ORGANIZE ||
			folderType === commonTypes.FOLDER_TYPE_SHARED ||
			folderType === commonTypes.FOLDER_TYPE_ORGSHARED
		) {
			setFolderPermission(fid, userID, filePerm, trashPerm, manageType, managePriority)
				.then(data => {
					dispatch(showMessage({ message: t('PERM_MESSAGE_1') }));
					dispatch(selectedUserID({ userID: selectedID }));
				})
				.catch(err => {
					if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
						dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_1') }));
					}
				})
				.finally(() => {
					dispatch(setShowBackdrop({ show: false }));
				});
		} else {
			dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_1') }));
		}
	}

	function releasePerm(fid, userID, folderType) {
		dispatch(setShowBackdrop({ show: true }));
		if (folderType !== commonTypes.FOLDER_TYPE_ORGSHARED) {
			releaseFolderPermission(fid, userID)
				.then(data => {
					dispatch(showMessage({ message: t('PERM_MESSAGE_2') }));
					dispatch(selectedUserID({ userID: selectedID }));
				})
				.catch(err => {
					if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
						dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_2') }));
					}
				})
				.finally(() => {
					dispatch(setShowBackdrop({ show: false }));
				});
		} else {
			getReadableFolderPermissions(fid)
				.then(permissions => {
					const perms = [];
					let isExist = false;
					permissions.forEach(sp => {
						if (sp.obj_type === commonTypes.READABLE_OBJ_TYPE_USER && sp.obj_id === userID) {
							isExist = true;
						} else {
							perms.push(sp);
						}
					});
					if (isExist) {
						setReadableFolderPermissions(fid, perms)
							.then(data => {
								dispatch(showMessage({ message: t('PERM_MESSAGE_2') }));
								dispatch(selectedUserID({ userID: selectedID }));
							})
							.catch(err => {
								if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
									dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_2') }));
								}
							})
							.finally(() => {
								dispatch(setShowBackdrop({ show: false }));
							});
					} else {
						dispatch(
							showErrorMessage({ message: t('조직으로 추가된 권한입다다. "공유"에서 조직을 제거해 주세요.') })
						);
						dispatch(setShowBackdrop({ show: false }));
					}
				})
				.catch(err => {
					dispatch(setShowBackdrop({ show: false }));
				});
		}
	}

	function updUser(name, value) {
		if (selectedID === '') {
			dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_12') }));
			return false;
		}
		if (selectedUser[name] !== value) {
			updateUser(selectedUser, name, value).then(resp => {
				let msg = '';
				switch (name) {
					case 'name':
						msg = t('USER_MESSAGE_4');
						break;
					case 'email':
						msg = t('USER_MESSAGE_5');
						break;
					case 'no_sync_osr':
						if (value) {
							msg = t('USER_MESSAGE_10');
						} else {
							msg = t('USER_MESSAGE_11');
						}
						break;
					case 'local_account':
						msg = t('USER_MESSAGE_12');
						break;
					case 'allow_multiple_logins':
						msg = t('USER_MESSAGE_13');
						break;
					case 'allow_takeout_private_folder':
						msg = t('USER_MESSAGE_14');
						break;
					case 'allow_takeout':
						msg = t('USER_MESSAGE_15');
						break;
					case 'locked':
						msg = t('USER_MESSAGE_16');
						break;
					case 'expire_at':
						if (value === '') {
							msg = t('EXPIRE_AT_RELEASE_MESSAGE_2');
						} else {
							msg = t('EXPIRE_AT_CHANGE_MESSAGE_2');
						}
						break;
					default:
						msg = t('USER_MESSAGE_6');
				}
				dispatch(showMessage({ message: msg }));
				dispatch(getUserList());
			});
		}
		return true;
	}

	function printPassword(data) {
		const passwdObj = JSON.parse(data.passwords);
		const passwdKey = Object.keys(passwdObj);
		const table = document.createElement('table');
		const firstRow = document.createElement('tr');
		table.style.border = '1px solid #000000';
		table.style.width = '100%';
		table.style.borderCollapse = 'collapse';
		table.style.textAlign = 'center';
		table.style.webkitPrintColorAdjust = 'exact';
		firstRow.style.border = '1px solid #000000';
		table.appendChild(firstRow);

		for (let i = 0; i < 11; i++) {
			const td = document.createElement('td');

			if (i > 0) {
				td.innerText = `${i - 1}`;
			}

			td.style.backgroundColor = '#333333';
			td.style.color = '#ffffff';
			td.style.border = '1px solid #000000';
			firstRow.appendChild(td);
		}

		passwdKey.forEach((key, idx) => {
			const tr = document.createElement('tr');
			const col = document.createElement('td');
			col.innerText = key;
			col.style.border = '1px solid #000000';
			col.style.backgroundColor = '#333333';
			col.style.color = '#ffffff';
			tr.appendChild(col);

			passwdObj[key].forEach(code => {
				const td = document.createElement('td');
				if (code < 10) {
					td.innerText = `0${code}`;
				} else {
					td.innerText = code;
				}
				td.style.border = '1px solid #000000';
				tr.appendChild(td);
			});
			table.appendChild(tr);
		});

		const windowObject = window.open(
			'',
			'print_window',
			'width=1090, height=550, top=200, left=200, toolbars=no, scrollbars=no, status=no, resizable= no'
		);
		windowObject.addEventListener('afterprint', event => {
			windowObject.close();
		});

		windowObject.document.body.appendChild(table);
		windowObject.document.close();
		windowObject.focus();
		windowObject.print();
	}

	function makeTooltip(folderType) {
		switch (folderType) {
			case commonTypes.FOLDER_TYPE_ORGANIZE:
				return (
					<Tooltip title={t('ORG_FOLDER')} arrow>
						<PermIcon type="org" className="small" />
					</Tooltip>
				);
			case commonTypes.FOLDER_TYPE_SHARED:
				return (
					<Tooltip title={t('SHARED_FOLDER')} arrow>
						<PermIcon type="shared" className="small" />
					</Tooltip>
				);
			case commonTypes.FOLDER_TYPE_ORGSHARED:
				return (
					<Tooltip title={t('ORGSHARED_FOLDER')} arrow>
						<PermIcon type="org-shared" className="small" />
					</Tooltip>
				);
			default:
				return '';
		}
	}

	return (
		<div className="" ref={permList}>
			<ResetPasswordModal
				userID={selectedID}
				name={selectedUser.name}
				open={openResetPasswd}
				fnHandleClose={() => setOpenResetPasswd(false)}
				fnHandleOK={password => {
					resetPassword(selectedID, password)
						.then(data => {
							dispatch(showMessage({ message: t('USER_MESSAGE_7') }));
							setOpenResetPasswd(false);
						})
						.catch(err => {
							dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_16') }));
						});
				}}
			/>

			<Card className={classes.cardInfo}>
				<CardActions className="" disableSpacing>
					<IconButton className="md:hidden" aria-controls="detail-back" onClick={e => props.fnToggleRightSidebar()}>
						<ArrowBack />
					</IconButton>
					<Typography className={clsx(classes.cardDetailInfo, 'noto-bold')} variant="h6">
						<span>{t('DETAIL_INFO')}</span>
						<span>{selectedUser.user_id === '' ? '' : `[${selectedUser.user_id}]`}</span>
					</Typography>
				</CardActions>
				<CardContent className={classes.infoFiled}>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('NAME')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="name"
								fullWidth
								value={modUserName}
								onChange={e => setModUserName(e.target.value)}
								onBlur={e => {
									e.preventDefault();
									if (e.target.value === '') {
										dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_4') }));
									} else {
										updUser('name', e.target.value);
									}
								}}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('EMAIL')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="email"
								fullWidth
								value={modEmail}
								onChange={e => setModEmail(e.target.value)}
								onBlur={e => {
									e.preventDefault();
									updUser('email', e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('ACCESS_LEVEL')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<Select
								id="access_level"
								variant="outlined"
								className={clsx(classes.select, '')}
								value={selectedUser.access_level}
								onChange={e => updUser('access_level', e.target.value)}
							>
								<MenuItem value={10000}>-</MenuItem>
								<MenuItem value={1}>1</MenuItem>
								<MenuItem value={2}>2</MenuItem>
								<MenuItem value={3}>3</MenuItem>
								<MenuItem value={4}>4</MenuItem>
								<MenuItem value={5}>5</MenuItem>
							</Select>
						</div>
					</div>
					{sysConfigure.OSR_support ? (
						<>
							<div className="">
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('NO_SYNC_OSR')}</p>
								</div>
								<div className={clsx(classes.filedInput, '')}>
									<Select
										id="no_sync_osr"
										variant="outlined"
										className={clsx(classes.select, '')}
										value={selectedUser.no_sync_osr.toString()}
										onChange={e => updUser('no_sync_osr', e.target.value)}
									>
										<MenuItem value="true">{t('NO_SYNC_OSR')}</MenuItem>
										<MenuItem value="false">{t('SYNC_OSR')}</MenuItem>
									</Select>
								</div>
							</div>
							<div className="">
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('LOCAL_ACCOUNT')}</p>
								</div>
								<div className={clsx(classes.filedInput, '')}>
									<Select
										id="local_account"
										variant="outlined"
										className={clsx(classes.select, '')}
										value={selectedUser.local_account.toString()}
										onChange={e => updUser('local_account', e.target.value)}
									>
										<MenuItem value="true">{t('MANUAL')}</MenuItem>
										<MenuItem value="false">{t('AUTO')}</MenuItem>
									</Select>
								</div>
							</div>
						</>
					) : (
						<></>
					)}
					{sysConfigure.menu_support_type === 2 ? (
						<></>
					) : (
						<>
							<div className="">
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('MULTIPLE_LOGINS')}</p>
								</div>
								<div className={clsx(classes.filedInput, '')}>
									<Select
										id="allow_multiple_logins"
										variant="outlined"
										className={clsx(classes.select, '')}
										value={selectedUser.allow_multiple_logins}
										onChange={e => updUser('allow_multiple_logins', e.target.value)}
									>
										<MenuItem value={0}>{t('UNUSED')}</MenuItem>
										<MenuItem value={2}>2</MenuItem>
										<MenuItem value={3}>3</MenuItem>
										<MenuItem value={4}>4</MenuItem>
										<MenuItem value={5}>5</MenuItem>
									</Select>
								</div>
							</div>
							<div className="">
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('USER_LOCK')}</p>
								</div>
								<div className={clsx(classes.filedInput, '')}>
									<Select
										id="locked"
										variant="outlined"
										className={clsx(classes.select, '')}
										value={selectedUser.locked.toString()}
										onChange={e => updUser('locked', e.target.value)}
									>
										<MenuItem value="true">{t('LOCK')}</MenuItem>
										<MenuItem value="false">{t('UNLOCK')}</MenuItem>
									</Select>
								</div>
							</div>
							<div className="">
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('EXPIRE_AT')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>
									<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
										<KeyboardDatePicker
											className={classes.datePicker}
											fullWidth
											disableToolbar
											invalidDateMessage=""
											autoOk
											size="small"
											variant="inline"
											inputVariant="outlined"
											format="yyyy/MM/dd"
											id="start_date"
											maxDate={new Date('2999-12-31')}
											value={expireAt}
											onChange={d => {
												// eslint-disable-next-line no-restricted-globals
												if (!isNaN(d.getTime())) {
													if (expireAt === null || expireAt.getTime() !== d.getTime()) setExpireAt(d);
												}
											}}
											KeyboardButtonProps={{
												'aria-label': 'change date start'
											}}
										/>
									</MuiPickersUtilsProvider>
									<div className={clsx('align-middle items-center flex')}>
										<Button
											variant="contained"
											color="secondary"
											className=""
											type="button"
											fullWidth
											style={{ width: 60, minWidth: 60, marginRight: 10 }}
											onClick={() => {
												if (selectedID <= 0) {
													dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_12') }));
												} else {
													dispatch(
														openDialog({
															children: (
																<OpenDialogForm
																	title={t('EXPIRE_AT_CHANGE')}
																	content={t('EXPIRE_AT_CHANGE_MESSAGE_1')}
																	fnHandleOK={() => {
																		updUser('expire_at', commonUtils.getDateString(expireAt));
																	}}
																/>
															)
														})
													);
												}
											}}
										>
											{t('CHANGE')}
										</Button>
										<Button
											variant="contained"
											color="secondary"
											className=""
											type="button"
											fullWidth
											style={{ width: 60, minWidth: 60 }}
											onClick={() => {
												if (selectedID <= 0) {
													dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_12') }));
												} else {
													dispatch(
														openDialog({
															children: (
																<OpenDialogForm
																	title={t('EXPIRE_AT_RELEASE')}
																	content={t('EXPIRE_AT_RELEASE_MESSAGE_1')}
																	fnHandleOK={() => {
																		updUser('expire_at', '');
																	}}
																/>
															)
														})
													);
												}
											}}
										>
											{t('RELEASE')}
										</Button>
									</div>
								</div>
							</div>
						</>
					)}

					{sysConfigure.dataleak_control ? (
						<>
							<div className="">
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('ALLOW_TAKEOUT_PRIVATE_FOLDER')}</p>
								</div>
								<div className={clsx(classes.filedInput, '')}>
									<Select
										id="allow_takeout_private_folder"
										variant="outlined"
										className={clsx(classes.select, '')}
										value={selectedUser.allow_takeout_private_folder}
										onChange={e => updUser('allow_takeout_private_folder', e.target.value)}
									>
										<MenuItem value="true">{t('PERMIT')}</MenuItem>
										<MenuItem value="false">{t('UNAVAILABLE')}</MenuItem>
									</Select>
								</div>
							</div>
							<div className="">
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('ALLOW_TAKEOUT_FOLDER')}</p>
								</div>
								<div className={clsx(classes.filedInput, '')}>
									<Select
										id="allow_takeout"
										variant="outlined"
										className={clsx(classes.select, '')}
										value={selectedUser.allow_takeout.toString()}
										onChange={e => updUser('allow_takeout', e.target.value)}
									>
										<MenuItem value={1}>{t('PERMIT')}</MenuItem>
										<MenuItem value={0}>{t('UNAVAILABLE')}</MenuItem>
										<MenuItem value={2}>{t('FOLLOWS_FOLDER_MASTER_POLICIES')}</MenuItem>
									</Select>
								</div>
							</div>
						</>
					) : (
						<></>
					)}

					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('PRIVATE_MAX_CAPACITY')}</p>
							<span className={classes.hint_span}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<Input
								type="number"
								name="private_capacity"
								disableUnderline
								className={classes.customInput}
								endAdornment={
									<InputAdornment position="end" className="">
										<Select
											id="private_capacity"
											disableUnderline
											variant="standard"
											className=""
											value={privateSizeUnit}
											onChange={e => {
												let tmpPrivSize = 0;
												if (!privateSizeUnit) {
													tmpPrivSize =
														privateMaxSize < 1024
															? privateMaxSize
															: Math.floor(privateMaxSize / 1024);
												} else {
													tmpPrivSize = privateMaxSize * 1024;
												}

												setFolderMaxSize(privatePath, tmpPrivSize)
													.then(() => {
														setPrivateSizeUnit(!privateSizeUnit);
														dispatch(showMessage({ message: t('CHANGE_CAPACITY_MESSAGE_1') }));
													})
													.catch(err => {
														dispatch(
															showErrorMessage({
																message: t('CHANGE_CAPACITY_ERROR_MESSAGE_1')
															})
														);
													});
											}}
										>
											<MenuItem value="false">Gbyte</MenuItem>
											<MenuItem value="true">Mbyte</MenuItem>
										</Select>
									</InputAdornment>
								}
								value={privateMaxSize}
								onChange={e => setPrivateMaxSize(e.target.value)}
								onBlur={e => {
									e.preventDefault();
									if (selectedID === '') {
										dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_12') }));
										return false;
									}
									const size = privateSizeUnit ? privateMaxSize : privateMaxSize * 1024;
									if (size < 0) {
										dispatch(showErrorMessage({ message: t('NUMBER_ERROR_MESSAGE') }));
										if (privateCapacityEx && privateCapacityEx >= 1024) {
											setPrivateMaxSize(Math.floor(privateCapacityEx / 1024));
											setPrivateSizeUnit(false);
										} else {
											setPrivateSizeUnit(true);
											setPrivateMaxSize(privateCapacityEx || 0);
										}
										return false;
									}

									if (privateCapacityEx !== size) {
										setFolderMaxSize(privatePath, size)
											.then(() => {
												setPrivateCapacityEx(size);
												dispatch(showMessage({ message: t('CHANGE_CAPACITY_MESSAGE_1') }));
											})
											.catch(err => {
												dispatch(showErrorMessage({ message: t('CHANGE_CAPACITY_ERROR_MESSAGE_1') }));
											});
									}
									return true;
								}}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('COLLECT_MAX_CAPACITY')}</p>
							<span className={classes.hint_span}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<Input
								type="number"
								name="collector_capacity"
								disableUnderline
								className={classes.customInput}
								endAdornment={
									<InputAdornment position="end">
										<Select
											id="collect_capacity"
											disableUnderline
											variant="standard"
											className=""
											value={collectorSizeUnit}
											onChange={e => {
												let tmpCollSize = 0;
												if (!collectorSizeUnit) {
													tmpCollSize =
														collectorMaxSize < 1024
															? collectorMaxSize
															: Math.floor(collectorMaxSize / 1024);
												} else {
													tmpCollSize = collectorMaxSize * 1024;
												}

												setFolderMaxSize(collectorPath, tmpCollSize)
													.then(() => {
														setCollectSizeUnit(!collectorSizeUnit);
														dispatch(showMessage({ message: t('CHANGE_CAPACITY_MESSAGE_2') }));
													})
													.catch(err => {
														dispatch(
															showErrorMessage({
																message: t('CHANGE_CAPACITY_ERROR_MESSAGE_1')
															})
														);
													});
											}}
										>
											<MenuItem value="false">Gbyte</MenuItem>
											<MenuItem value="true">Mbyte</MenuItem>
										</Select>
									</InputAdornment>
								}
								value={collectorMaxSize}
								onChange={e => setCollectorMaxSize(e.target.value)}
								onBlur={e => {
									e.preventDefault();
									if (selectedID === '') {
										dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_12') }));
										return false;
									}
									const size = collectorSizeUnit ? collectorMaxSize : collectorMaxSize * 1024;
									if (size < 0) {
										dispatch(showErrorMessage({ message: t('NUMBER_ERROR_MESSAGE') }));

										if (collectorCapacityEx && collectorCapacityEx >= 1024) {
											setCollectorMaxSize(Math.floor(collectorCapacityEx / 1024));
											setCollectSizeUnit(false);
										} else {
											setCollectSizeUnit(true);
											setCollectorMaxSize(collectorCapacityEx || 0);
										}

										return false;
									}

									if (collectorCapacityEx !== size) {
										setFolderMaxSize(collectorPath, size)
											.then(() => {
												setCollectorCapacityEx(size);
												dispatch(showMessage({ message: t('CHANGE_CAPACITY_MESSAGE_2') }));
											})
											.catch(err => {
												dispatch(showErrorMessage({ message: t('CHANGE_CAPACITY_ERROR_MESSAGE_1') }));
											});
									}
									return true;
								}}
							/>
						</div>
					</div>
				</CardContent>
				<CardActions className={clsx(classes.buttonGroup, 'block')}>
					<div className="w-full flex">
						<Button
							variant="contained"
							className="default"
							type="button"
							fullWidth
							onClick={e => {
								if (selectedID === '') {
									dispatch(showMessage({ message: t('USER_ERROR_MESSAGE_12') }));
								} else {
									setOpenResetPasswd(true);
								}
							}}
							size="small"
						>
							{t('USER_MENU_1')}
						</Button>
						{sysConfigure.menu_support_type === 2 ? (
							<></>
						) : (
							<>
								<Button
									variant="contained"
									className="default"
									type="button"
									fullWidth
									onClick={e => {
										if (selectedID === '') {
											dispatch(showMessage({ message: t('USER_ERROR_MESSAGE_12') }));
										} else {
											createPaperPassword(selectedID)
												.then(data => {
													printPassword(data);
												})
												.catch(err => {
													dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_17') }));
												});
										}
									}}
									size="small"
								>
									{t('USER_MENU_2')}
								</Button>
								<Button
									variant="contained"
									className="default"
									type="button"
									fullWidth
									onClick={e => {
										if (selectedID === '') {
											dispatch(showMessage({ message: t('USER_ERROR_MESSAGE_12') }));
										} else {
											dispatch(
												openDialog({
													children: (
														<OpenDialogForm
															title={t('USER_TOTP_TITLE')}
															content={t('USER_TOTP_CONTENT')}
															fnHandleOK={() => {
																resetTOTP(selectedID)
																	.then(data => {
																		dispatch(
																			showMessage({
																				message: t('USER_MESSAGE_8')
																			})
																		);
																	})
																	.catch(err => {
																		dispatch(
																			showErrorMessage({
																				message: t('USER_ERROR_MESSAGE_18')
																			})
																		);
																	});
															}}
														/>
													)
												})
											);
										}
									}}
									size="small"
								>
									{t('USER_MENU_3')}
								</Button>
							</>
						)}
					</div>

					<div className={clsx(classes.buttonGroup, 'w-full flex')} style={{ display: 'none' }}>
						<Button
							variant="contained"
							className="default"
							type="button"
							fullWidth
							size="small"
							onClick={() => {
								if (selectedID <= 0) {
									dispatch(showErrorMessage({ message: t('ORG_ERROR_MESSAGE_2') }));
								} else {
									naturalizeFiles(
										`${sysConfigure.language === 'korean' ? '/개인' : '/Private'}/${selectedID}`
									).then(() => {
										dispatch(
											showMessage({
												message: t('SEARCH_INDEXING_MESSAGE_1')
											})
										);
									});
								}
							}}
						>
							{t('SEARCH_PRIVATE_INDEXING')}
						</Button>
						<Button
							variant="contained"
							className="default"
							type="button"
							fullWidth
							size="small"
							onClick={() => {
								if (selectedID <= 0) {
									dispatch(showErrorMessage({ message: t('ORG_ERROR_MESSAGE_2') }));
								} else {
									naturalizeFiles(
										`${sysConfigure.language === 'korean' ? '/수집' : '/Collect'}/${selectedID}`
									).then(() => {
										dispatch(
											showMessage({
												message: t('SEARCH_INDEXING_MESSAGE_1')
											})
										);
									});
								}
							}}
						>
							{t('SEARCH_COLLECT_INDEXING')}
						</Button>
					</div>
				</CardActions>
			</Card>

			<Card className={classes.paperPerm}>
				{/* <CardContent className={classes.paperPermHeader}>
					<div>
						<TextField
							className=""
							variant="outlined"
							type="text"
							name="folderID"
							fullWidth
							value={folderID}
							onChange={e => setFolderID(e.target.value)}
						/>
					</div>
					<div>
						<Button
							variant="contained"
							color="secondary"
							className=""
							type="button"
							fullWidth
							onClick={() => setPermEx()}
						>
							{t('ADD')}
						</Button>
					</div>
				</CardContent> */}
				<CardContent className={classes.paperPermHeader}>
					<div>
						<Autocomplete
							id="autocomplete-permission-search"
							options={autoOptions}
							getOptionLabel={option => {
								if (option.path) {
									console.log(option.path.substring(1));

									if (option.folder_type === commonTypes.FOLDER_TYPE_ORGSHARED) {
										return option.path
											.substring(1)
											.replace(
												`${sysConfigure.language === 'korean' ? '조직/' : 'Organization/'}`,
												`[${t('ORGSHARED')}]`
											);
									}
									return option.path.substring(1);
								}
								return option.path;
							}}
							noOptionsText={t('PERM_NO_FOLDER')}
							autoComplete
							includeInputInList
							value=""
							onChange={(e, newVal) => {
								if (newVal) {
									setSelectedAutocomplete(newVal);
								} else {
									setSelectedAutocomplete(null);
								}
							}}
							inputValue={permAutoInputVal}
							onInputChange={(e, newInputVal) => {
								setPermAutoInputVal(newInputVal);
							}}
							renderInput={params => (
								<TextField
									{...params}
									label={t('PERM_FOLDER_INPUT')}
									variant="outlined"
									onKeyDown={e => {
										if (selectedAutocomplete && selectedAutocomplete !== null && e.keyCode === 13) {
											setPerm();
										}
									}}
								/>
							)}
							fullWidth
							size="small"
						/>
					</div>
					<div>
						<Button
							variant="contained"
							color="secondary"
							className=""
							type="button"
							fullWidth
							onClick={() => setPerm()}
						>
							{t('ADD')}
						</Button>
					</div>
				</CardContent>

				<CardContent className={classes.paperPermContent}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								<TableCell padding="none" align="left" className="left title">
									{t('FOLDER_NAME')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-1">
									{t('PERM_READ_WRITE')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-2">
									{t('PERM_TRASH')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-1">
									{t('PERM_DELETE')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{userPerms && userPerms.length > 0 ? (
								userPerms.map((perm, idx) => {
									return perm.folder_type !== commonTypes.FOLDER_TYPE_COLLECT &&
										perm.folder_type !== commonTypes.FOLDER_TYPE_PRIVATE ? (
										<TableRow
											className="cursor-pointer"
											hover
											role="checkbox"
											tabIndex={-1}
											// key={d.user_id}
											// onClick={event => handleCheck(event, d.user_id)}
										>
											<TableCell className="left" align="left" component="td" scope="row">
												<div>
													{makeTooltip(perm.folder_type)}
													<span className="folder-name">
														{perm.folder_type === commonTypes.FOLDER_TYPE_ORGSHARED
															? perm.disp_path.replace(
																	`${
																		sysConfigure.language === 'korean'
																			? '/조직/'
																			: '/Organization/'
																	}`,
																	`[${t('ORGSHARED')}]`
															  )
															: perm.disp_path.substring(1)}
													</span>
												</div>
											</TableCell>

											<TableCell className="" align="center" component="td" scope="row">
												<Tooltip
													className="font-size-initial"
													arrow
													title={
														perm.file_permission === commonTypes.FILE_PERM_READ
															? t('PERM_READ')
															: t('PERM_READ_WRITE')
													}
												>
													<IconButton
														onClick={e =>
															changePerm(
																perm.fid,
																selectedID,
																perm.file_permission === commonTypes.FILE_PERM_READ
																	? commonTypes.FILE_PERM_READ_WRITE
																	: commonTypes.FILE_PERM_READ,
																perm.trash_permission,
																perm.manage_type,
																perm.manage_priority,
																perm.folder_type
															)
														}
													>
														{perm.file_permission === commonTypes.FILE_PERM_READ ? (
															<PermIcon type="readonly" className="" />
														) : (
															<PermIcon type="write" className="" />
														)}
													</IconButton>
												</Tooltip>
											</TableCell>

											<TableCell className="" component="td" scope="row" align="center">
												<Tooltip
													className="font-size-initial"
													arrow
													title={
														perm.trash_permission === commonTypes.TRASH_PERM_NONE
															? t('PERM_TRASH_IMPOSSIBLE')
															: t('PERM_TRASH_POSSIBLE')
													}
												>
													<IconButton
														onClick={e =>
															changePerm(
																perm.fid,
																selectedID,
																perm.file_permission,
																perm.trash_permission === commonTypes.TRASH_PERM_NONE
																	? commonTypes.TRASH_PERM_PURGE
																	: commonTypes.TRASH_PERM_NONE,
																perm.manage_type,
																perm.manage_priority,
																perm.folder_type
															)
														}
													>
														{perm.trash_permission === commonTypes.TRASH_PERM_NONE ? (
															<PermIcon type="trash-delete" className="" />
														) : (
															<PermIcon type="trash" className="" />
														)}
													</IconButton>
												</Tooltip>
											</TableCell>
											<TableCell className="" component="td" scope="row" align="center">
												<Tooltip title={t('PERM_DELETE')} className="font-size-initial" arrow>
													<IconButton
														aria-label="release_permission"
														onClick={e => releasePerm(perm.fid, selectedID, perm.folder_type)}
													>
														<PermIcon type="delete" className="" />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									) : (
										<></>
									);
								})
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</div>
	);
}

export default DetailSidebarContent;
