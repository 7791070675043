import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
	Button,
	Select,
	MenuItem,
	Input
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { setFolderMaxSize } from 'app/store/docbase/admin/folderSlice';
import { useTranslation } from 'react-i18next';

import commonUtils from 'app/utils/CommonUtils';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	titleWrapper: {
		'padding-bottom': '0'
	},
	actions: {
		padding: '0 24px 16px',
		justifyContent: 'center',
		'& button': { width: '100%', minWidth: 130 },
		'& button:first-child': {
			marginRight: 8
		}
	},
	inputRight: {
		'& input': {
			'text-align': 'right'
		}
	},
	hint_span: {
		'padding-left': '10px',
		'font-size': '1rem',
		color: '#9f9f9f'
	},
	filedTitle: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'default'
	},
	filedInput: {
		width: '100%',
		padding: '0',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	},
	customInput: {
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 4,
		'&>input': {
			padding: '10.5px 14px'
		},
		'&.Mui-focused': {
			border: '2px solid rgba(0, 0, 0, 1)'
		},
		'&:hover': {
			border: '1px solid rgba(0, 0, 0, 1)'
		}
	}
}));

export default function CapacityDialog(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();
	const dispatch = useDispatch();

	const [capacityObj, setCapacityObj] = React.useState({ capacity: 0, unit: false });
	React.useEffect(() => {
		let capacity = 0;
		const dashboardObj = commonUtils.getLocalStorage('dashboardObj');
		const currentTime = new Date();
		const currentTimestamp = currentTime.getTime();

		if (dashboardObj[props.path] && dashboardObj[props.path].timestamp - currentTimestamp <= 300000) {
			capacity = dashboardObj[props.path].size;
		} else {
			capacity = props.capacity;
		}

		if (capacity >= 1024) {
			setCapacityObj({ capacity: Math.floor(capacity / 1024), unit: false });
		} else {
			setCapacityObj({ capacity: capacity || 0, unit: true });
		}
	}, [props.open]);

	function validation() {}

	return (
		<div>
			<Dialog open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="alert-dialog-slide-title" className={classes.titleWrapper}>{`${props.title}${t(
					'CHANGE_CAPACYTI_TITLE'
				)}`}</DialogTitle>
				<DialogContent className="overflow-y-hidden">
					<div className="min-w-350">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('MAX_CAPACITY')}</p>
							<span className={classes.hint_span}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<Input
								type="number"
								name="capacity"
								disableUnderline
								className={classes.customInput}
								endAdornment={
									<InputAdornment position="end" className="">
										<Select
											id="folder_capacity"
											disableUnderline
											variant="standard"
											className={clsx(classes.selectTextAlignRight, '')}
											value={capacityObj.unit}
											onChange={e => {
												let size = 0;
												if (!capacityObj.unit) {
													size =
														capacityObj.capacity < 1024
															? capacityObj.capacity
															: Math.floor(capacityObj.capacity / 1024);
												} else {
													size = capacityObj.capacity * 1024;
												}
												setCapacityObj({ capacity: size, unit: !capacityObj.unit });
											}}
										>
											<MenuItem value="false">Gbyte</MenuItem>
											<MenuItem value="true">Mbyte</MenuItem>
										</Select>
									</InputAdornment>
								}
								value={capacityObj.capacity}
								onChange={e => setCapacityObj({ ...capacityObj, capacity: Number(e.target.value) })}
							/>
						</div>
					</div>
				</DialogContent>
				<DialogActions className={classes.actions}>
					<Button
						onClick={props.fnHandleClose}
						variant="contained"
						className="default"
						aria-label="cancel"
						type="button"
					>
						{t('CANCEL')}
					</Button>
					<Button
						onClick={() => {
							const size = capacityObj.unit ? capacityObj.capacity : capacityObj.capacity * 1024;
							if (size < 0) {
								dispatch(showErrorMessage({ message: t('NUMBER_ERROR_MESSAGE') }));

								return false;
							}

							setFolderMaxSize(props.path, size)
								.then(() => {
									dispatch(showMessage({ message: t('CHANGE_CAPACITY_MESSAGE_4') }));
									const dashboardObj = commonUtils.getLocalStorage('dashboardObj');
									const currentTime = new Date();
									const obj = { timestamp: currentTime.getTime(), size };
									dashboardObj[props.path] = obj;
									commonUtils.setLocalStorage('dashboardObj', dashboardObj);
								})
								.catch(err => {
									dispatch(showErrorMessage({ message: t('CHANGE_CAPACITY_ERROR_MESSAGE_1') }));
								})
								.finally(() => {
									props.fnHandleClose();
								});
							return true;
						}}
						className=""
						color="secondary"
						type="button"
						variant="contained"
						aria-label="ok"
					>
						{t('CHANGE')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
