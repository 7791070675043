import FusePageSimple from '@fuse/core/FusePageSimple';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import commonUtils from 'app/utils/CommonUtils';
import { getAccessLog } from 'app/store/docbase/admin/systemSlice';
import * as commonTypes from 'app/main/constants/CommonTypes';

import ContentHeader from '../component/ContentHeader';
import LogsTable from './LogsTable';

function Logs() {
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(true);
	const pageLayout = React.useRef(null);
	const { t } = useTranslation('docbase');

	const today = new Date();
	const [searchObj, setSearchObj] = React.useState({
		startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
		endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
		accessType: 0,
		path: '',
		operator: ''
	});

	useEffect(() => {
		doSearch();
	}, [searchObj]);

	function doSearch() {
		//	마지막날도 검색하기위하여 +1 해준다
		let days = commonUtils.getDateDifference(searchObj.startDate, searchObj.endDate);
		if (days <= 0) {
			days = 0;
		} else {
			days++;
		}
		setLoading(true);
		dispatch(
			getAccessLog({
				startID: 0,
				date: commonUtils.getDateString(searchObj.startDate),
				days,
				path: searchObj.path,
				operator: searchObj.operator,
				accessType: searchObj.accessType === 0 ? '' : searchObj.accessType,
				isToday: false
			})
		).then(() => {
			setLoading(false);
		});
	}

	function checkAccessType(type) {
		switch (type) {
			case commonTypes.ACCESS_TYPE_CREATE:
				return t('ACCESS_TYPE_CREATE');
			case commonTypes.ACCESS_TYPE_READ:
				return t('ACCESS_TYPE_READ');
			case commonTypes.ACCESS_TYPE_MOVE:
				return t('ACCESS_TYPE_MOVE');
			case commonTypes.ACCESS_TYPE_UPDATE:
				return t('ACCESS_TYPE_UPDATE');
			case commonTypes.ACCESS_TYPE_HOLD:
				return t('ACCESS_TYPE_HOLD');
			case commonTypes.ACCESS_TYPE_REMOVE:
				return t('ACCESS_TYPE_REMOVE');
			case commonTypes.ACCESS_TYPE_REMOVE_TRASH:
				return t('ACCESS_TYPE_REMOVE_TRASH');
			case commonTypes.ACCESS_TYPE_RECOVERY_TRASH:
				return t('ACCESS_TYPE_RECOVERY_TRASH');
			case commonTypes.ACCESS_TYPE_PURGE_TRASH:
				return t('ACCESS_TYPE_PURGE_TRASH');
			case commonTypes.ACCESS_TYPE_VIEWING:
				return t('ACCESS_TYPE_VIEWING');
			case commonTypes.ACCESS_TYPE_WEB_CREATE:
				return t('ACCESS_TYPE_WEB_CREATE');
			case commonTypes.ACCESS_TYPE_WEB_DOWNLOAD:
				return t('ACCESS_TYPE_WEB_DOWNLOAD');
			case commonTypes.ACCESS_TYPE_COLLECT_FILE:
				return t('ACCESS_TYPE_COLLECT_FILE');
			default:
				return t('ETC');
		}
	}

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<LogsTable
					loading={loading}
					searchObj={searchObj}
					setLoading={setLoading}
					fnSetSearchObj={(name, value) => setSearchObj({ ...searchObj, [name]: value })}
					fnDoSearch={() => doSearch()}
					fnCheckAccessType={type => checkAccessType(type)}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default Logs;
