import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { statisticValues } from 'app/store/docbase/admin/systemSlice';
import Chart from 'react-apexcharts';
import CommonUtils from 'app/utils/CommonUtils';
import ContentHeader from '../component/ContentHeader';

const useStyles = makeStyles(theme => ({
	graphWrapper: {
		flex: '1 1 auto',
		marginTop: 10,
		'&.mr': {
			marginRight: 10
		},
		'&.short': {
			minWidth: 520
		},
		'&.middle': {
			minWidth: 780
		},
		'&>.content': {
			padding: '15px 15px'
		}
	}
}));

let timerAccessLog;
let timerAverage;
function Logs(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');

	const { averageInfo } = useSelector(({ docbase }) => docbase.admin.system);

	const classes = useStyles(props);

	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const initAvgApicall = {
		series: [{ name: 'web-api-call', data: [] }],
		options: {
			chart: {
				height: 350,
				width: 740,
				type: 'area',
				zoom: {
					enabled: false
				},
				animations: {
					enabled: false
				},
				toolbar: {
					show: true
				}
			},
			dataLabels: {
				enabled: false
			},
			title: {
				text: t('AVERAGE_CALL_API_TITLE'),
				align: 'left',
				fontFamily: 'S-CoreDream-Medi'
			},
			stroke: {
				curve: 'smooth'
			},
			legend: {
				position: 'top',
				horizontalAlign: 'right',
				onItemClick: {
					toggleDataSeries: false
				},
				onItemHover: {
					highlightDataSeries: false
				}
			},
			tooltip: {
				y: {
					formatter(value) {
						return value.toFixed(2);
					}
				}
			},
			yaxis: {
				labels: {
					formatter(val, index) {
						return val.toFixed(2);
					}
				},
				max: m => Math.floor(m + (m / 3 < 1 ? 1 : m / 3))
			},
			xaxis: {
				categories: [
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					''
				]
			}
		}
	};
	const initAvgConnected = {
		series: [{ name: 'connected', data: [] }],
		options: {
			chart: {
				height: 350,
				width: 740,
				type: 'area',
				zoom: {
					enabled: false
				},
				animations: {
					enabled: false
				},
				toolbar: {
					show: true
				}
			},
			dataLabels: {
				enabled: false
			},
			title: {
				text: t('AVERAGE_CONNECTED'),
				align: 'left',
				fontFamily: 'S-CoreDream-Medi'
			},
			stroke: {
				curve: 'smooth'
			},
			legend: {
				position: 'top',
				horizontalAlign: 'right',
				onItemClick: {
					toggleDataSeries: false
				},
				onItemHover: {
					highlightDataSeries: false
				}
			},
			tooltip: {
				y: {
					formatter(value) {
						return value;
					}
				}
			},
			yaxis: {
				labels: {
					formatter(val, index) {
						return val.toFixed(0);
					}
				},
				max: m => Math.floor(m + (m / 3 < 1 ? 1 : m / 3))
			},
			xaxis: {
				categories: [
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					''
				]
			}
		}
	};

	const initAvgIO = {
		series: [
			{ name: 'read', data: [] },
			{ name: 'write', data: [] }
		],
		options: {
			chart: {
				height: 350,
				width: 1480,
				type: 'area',
				zoom: {
					enabled: false
				},
				animations: {
					enabled: false
				},
				toolbar: {
					show: true
				}
			},
			dataLabels: {
				enabled: false
			},
			title: {
				text: t('TODAY_AVERAGE_DATA'),
				align: 'left',
				fontFamily: 'S-CoreDream-Medi'
			},
			stroke: {
				curve: 'smooth'
			},
			legend: {
				position: 'top',
				horizontalAlign: 'right',
				onItemClick: {
					toggleDataSeries: false
				},
				onItemHover: {
					highlightDataSeries: false
				}
			},
			tooltip: {
				y: {
					formatter(value) {
						return CommonUtils.changeByteToString(value);
					}
				}
			},
			yaxis: {
				labels: {
					formatter(val, index) {
						return val.toFixed(0);
					}
				}
			},
			xaxis: {
				categories: [
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					''
				]
			}
		}
	};

	const initAvgFolder = {
		series: [
			{ name: 'create', data: [] },
			{ name: 'remove', data: [] }
		],
		options: {
			chart: {
				height: 350,
				width: 480,
				type: 'area',
				zoom: {
					enabled: false
				},
				animations: {
					enabled: false
				},
				toolbar: {
					show: true
				}
			},
			dataLabels: {
				enabled: false
			},
			title: {
				text: t('AVERAGE_FOLDER_TITLE'),
				align: 'left',
				fontFamily: 'S-CoreDream-Medi'
			},
			stroke: {
				curve: 'smooth'
			},
			legend: {
				position: 'top',
				horizontalAlign: 'right',
				onItemClick: {
					toggleDataSeries: false
				},
				onItemHover: {
					highlightDataSeries: false
				}
			},
			tooltip: {
				y: {
					formatter(value) {
						return value.toFixed(2);
					}
				}
			},
			yaxis: {
				labels: {
					formatter(val, index) {
						return val.toFixed(2);
					}
				},
				max: m => Math.floor(m + (m / 3 < 1 ? 1 : m / 3))
			},
			xaxis: {
				categories: [
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					''
				]
			}
		}
	};

	const initAvgFile1 = {
		series: [
			{ name: 'create', data: [] },
			{ name: 'remove', data: [] }
		],
		options: {
			chart: {
				height: 350,
				width: 480,
				type: 'area',
				zoom: {
					enabled: false
				},
				animations: {
					enabled: false
				},
				toolbar: {
					show: true
				}
			},
			dataLabels: {
				enabled: false
			},
			title: {
				text: t('AVERAGE_FILE_TITLE_1'),
				align: 'left',
				fontFamily: 'S-CoreDream-Medi'
			},
			stroke: {
				curve: 'smooth'
			},
			legend: {
				position: 'top',
				horizontalAlign: 'right',
				onItemClick: {
					toggleDataSeries: false
				},
				onItemHover: {
					highlightDataSeries: false
				}
			},
			tooltip: {
				y: {
					formatter(value) {
						return value.toFixed(2);
					}
				}
			},
			yaxis: {
				labels: {
					formatter(val, index) {
						return val.toFixed(2);
					}
				},
				max: m => Math.floor(m + (m / 3 < 1 ? 1 : m / 3))
			},
			xaxis: {
				categories: [
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					''
				]
			}
		}
	};

	const initAvgFile2 = {
		series: [
			{ name: 'rename', data: [] },
			{ name: 'update', data: [] },
			{ name: 'copy', data: [] }
		],
		options: {
			chart: {
				height: 350,
				width: 480,
				type: 'area',
				zoom: {
					enabled: false
				},
				animations: {
					enabled: false
				},
				toolbar: {
					show: true
				}
			},
			dataLabels: {
				enabled: false
			},
			title: {
				text: t('AVERAGE_FILE_TITLE_2'),
				align: 'left',
				fontFamily: 'S-CoreDream-Medi'
			},
			stroke: {
				curve: 'smooth'
			},
			legend: {
				position: 'top',
				horizontalAlign: 'right',
				onItemClick: {
					toggleDataSeries: false
				},
				onItemHover: {
					highlightDataSeries: false
				}
			},
			tooltip: {
				y: {
					formatter(value) {
						return value.toFixed(2);
					}
				}
			},
			yaxis: {
				labels: {
					formatter(val, index) {
						return val.toFixed(2);
					}
				},
				max: m => Math.floor(m + (m / 3 < 1 ? 1 : m / 3))
			},
			xaxis: {
				categories: [
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					''
				]
			}
		}
	};

	const [averageIOData, setAverageIOData] = React.useState(initAvgIO);
	const [averageConnectedData, setAverageConnectedData] = React.useState(initAvgConnected);
	const [averageApiCall, setAverageApiCall] = React.useState(initAvgApicall);
	const [averageFolder, setAverageFolder] = React.useState(initAvgFolder);
	const [averageFile1, setAverageFile1] = React.useState(initAvgFile1);
	const [averageFile2, setAverageFile2] = React.useState(initAvgFile2);
	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 141}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		dispatch(statisticValues()).then(() => {
			if (!timerAverage) {
				timerAverage = setInterval(() => {
					dispatch(statisticValues());
				}, 3000);
			}
		});

		return () => {
			if (timerAverage) {
				clearInterval(timerAverage);
				timerAverage = undefined;
			}
			if (timerAccessLog) {
				clearInterval(timerAccessLog);
				timerAccessLog = undefined;
			}
		};
	}, [dispatch]);

	useEffect(() => {
		const newReadData = [];
		const newWriteData = [];
		const newApiCall = [];
		const newConnectedData = [];
		const newCreateFolder = [];
		const newRemoveFolder = [];
		const newCreateFile = [];
		const newRemoveFile = [];
		const newRenameFile = [];
		const newUpdateFile = [];
		const newCopyFile = [];

		for (let x = 1; x < 30; x++) {
			newReadData.push(averageIOData.series[0].data[x] ? averageIOData.series[0].data[x] : 0);
			newWriteData.push(averageIOData.series[1].data[x] ? averageIOData.series[1].data[x] : 0);
			newApiCall.push(averageApiCall.series[0].data[x] ? averageApiCall.series[0].data[x] : 0);
			newConnectedData.push(averageConnectedData.series[0].data[x] ? averageConnectedData.series[0].data[x] : 0);
			newCreateFolder.push(averageFolder.series[0].data[x] ? averageFolder.series[0].data[x] : 0);
			newRemoveFolder.push(averageFolder.series[1].data[x] ? averageFolder.series[1].data[x] : 0);
			newCreateFile.push(averageFile1.series[0].data[x] ? averageFile1.series[0].data[x] : 0);
			newRemoveFile.push(averageFile1.series[1].data[x] ? averageFile1.series[1].data[x] : 0);
			newRenameFile.push(averageFile2.series[0].data[x] ? averageFile2.series[0].data[x] : 0);
			newUpdateFile.push(averageFile2.series[1].data[x] ? averageFile2.series[1].data[x] : 0);
			newCopyFile.push(averageFile2.series[2].data[x] ? averageFile2.series[2].data[x] : 0);
		}
		newReadData.push(averageInfo.readBytes);
		newWriteData.push(averageInfo.writeBytes);
		newApiCall.push(averageInfo.average_webapi_calls);
		newConnectedData.push(averageInfo.connected_clients);
		newCreateFolder.push(averageInfo.average_created_directories);
		newRemoveFolder.push(averageInfo.average_removed_directories);
		newCreateFile.push(averageInfo.average_created_files);
		newRemoveFile.push(averageInfo.average_removed_files);
		newRenameFile.push(averageInfo.average_renamed_files);
		newUpdateFile.push(averageInfo.average_updated_files);
		newCopyFile.push(averageInfo.average_copied_files);

		setAverageIOData({
			...initAvgIO,
			series: [
				{ name: t('AVERAGE_READ'), data: newReadData },
				{ name: t('AVERAGE_WRITE'), data: newWriteData }
			]
		});

		setAverageApiCall({
			...initAvgApicall,
			series: [{ name: t('AVERAGE_CALL_API_TITLE'), data: newApiCall }]
		});

		setAverageConnectedData({
			...initAvgConnected,
			series: [{ name: t('AVERAGE_CONNECTED'), data: newConnectedData }]
		});

		setAverageFolder({
			...initAvgFolder,
			series: [
				{ name: t('AVERAGE_FOLDER_CREATE'), data: newCreateFolder },
				{ name: t('AVERAGE_FOLDER_REMOVE'), data: newRemoveFolder }
			]
		});

		setAverageFile1({
			...initAvgFile1,
			series: [
				{ name: t('AVERAGE_FILE_CREATE'), data: newCreateFile },
				{ name: t('AVERAGE_FILE_REMOVE'), data: newRemoveFile }
			]
		});
		setAverageFile2({
			...initAvgFile2,
			series: [
				{ name: t('AVERAGE_FILE_RENAME'), data: newRenameFile },
				{ name: t('AVERAGE_FILE_UPDATE'), data: newUpdateFile },
				{ name: t('AVERAGE_FILE_COPY'), data: newCopyFile }
			]
		});
	}, [averageInfo]);

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			sidebarInner
			header={<ContentHeader />}
			content={
				<FuseScrollbars className={clsx(classes.contentWrapper, 'w-full flex-grow overflow-x-auto')} ref={contentTable}>
					<div className="w-full">
						<Paper className={classes.graphWrapper}>
							<div className="flex w-full content">
								<Chart
									options={averageIOData.options}
									series={averageIOData.series}
									type="area"
									height={350}
									width={1480}
								/>
							</div>
						</Paper>
						<div className="flex flex-wrap">
							<Paper className={clsx(classes.graphWrapper, 'mr', 'short')}>
								<div className="flex w-full content">
									<Chart
										options={averageFolder.options}
										series={averageFolder.series}
										type="area"
										height={350}
										width={460}
									/>
								</div>
							</Paper>
							<Paper className={clsx(classes.graphWrapper, 'mr', 'short')}>
								<div className="flex w-full content">
									<Chart
										options={averageFile1.options}
										series={averageFile1.series}
										type="area"
										height={350}
										width={460}
									/>
								</div>
							</Paper>
							<Paper className={clsx(classes.graphWrapper, 'short')}>
								<div className="flex w-full content">
									<Chart
										options={averageFile2.options}
										series={averageFile2.series}
										type="area"
										height={350}
										width={460}
									/>
								</div>
							</Paper>
						</div>
						<div className="flex flex-wrap">
							<Paper className={clsx(classes.graphWrapper, 'mr', 'middle')}>
								<div className="flex w-full content">
									<Chart
										options={averageConnectedData.options}
										series={averageConnectedData.series}
										type="area"
										height={350}
										width={720}
									/>
								</div>
							</Paper>
							<Paper className={clsx(classes.graphWrapper, 'middle')}>
								<div className="flex w-full content">
									<Chart
										options={averageApiCall.options}
										series={averageApiCall.series}
										type="area"
										height={350}
										width={720}
									/>
								</div>
							</Paper>
						</div>
					</div>
				</FuseScrollbars>
			}
		/>
	);
}

export default Logs;
