import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, MenuItem, DialogContent, DialogTitle, DialogContentText, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { showErrorMessage } from 'app/store/fuse/messageSlice';

const useStyles = makeStyles(theme => ({
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 604
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6,
			padding: 40
		},
		'&>.content>.row': {
			display: 'flex',
			alignItems: 'center',
			margin: '15px 0'
		},
		'&>.content>.row:first-child': {
			marginTop: 0
		},
		'&>.content>.row:last-child': {
			marginBottom: 0
		},
		'&>.content>.row>.title': {
			width: 136,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.title>span:before': {
			color: '#00cfe2',
			content: '"*"'
		},
		'&>.content>.row>.input-area': {
			width: '100%',
			maxWidth: 382,
			cursor: 'default',
			fontSize: 14
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 40,
		'& .MuiButton-root': {
			width: 172,
			height: 54
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

export default function RegisterWhiteURLModal(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();
	const dispatch = useDispatch();

	const [name, setName] = React.useState('');
	const [url, setURL] = React.useState('');
	const [type, setType] = React.useState('regex');

	React.useEffect(() => {
		setName('');
		setURL('');
		setType('regex');
	}, [props.open]);

	function validation() {
		if (name.trim().length === 0) {
			dispatch(showErrorMessage({ message: t('WHITE_PROCESS_ERROR_MESSAGE_3') }));
			return false;
		}
		if (url.trim().length === 0) {
			dispatch(showErrorMessage({ message: t('WHITE_PROCESS_ERROR_MESSAGE_4') }));
			return false;
		}
		props.fnHandleChange(name, type, url);
		return true;
	}

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.title} id="form-dialog-title">
					{t('WHITE_URL_MODAL_1')}
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div className="content">
						<DialogContentText className="text-1" id="alert-dialog-slide-description">
							{t('WHITE_URL_MODAL_2')}
						</DialogContentText>
						<div className="row">
							<div className="title">
								<span>{t('WHITE_URL_NAME')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									placeholder={t('WHITE_URL_NAME_PLACEHOLDER')}
									name="name"
									fullWidth
									value={name}
									variant="outlined"
									onChange={e => setName(e.target.value)}
									size="small"
								/>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('WHITE_URL_SET_TYPE')}</span>
							</div>
							<div className="input-area">
								<TextField
									select
									className=""
									name="type"
									fullWidth
									value={type}
									variant="outlined"
									onChange={e => setType(e.target.value)}
									size="small"
								>
									<MenuItem value="regex">{t('WHITE_URL_REGEX')}</MenuItem>
									<MenuItem value="plain">{t('WHITE_URL_PLAIN')}</MenuItem>
								</TextField>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('WHITE_URL_URL')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									placeholder={t('WHITE_URL_URL_PLACEHOLDER')}
									name="url"
									fullWidth
									value={url}
									variant="outlined"
									onChange={e => setURL(e.target.value)}
									size="small"
								/>
							</div>
						</div>

						<DialogContentText className="text-2" id="alert-dialog-slide-description">
							{t('WHITE_PROCESS_MESSAGE_4')}
						</DialogContentText>

						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.fnHandleClose}
								variant="contained"
								className="default"
								aria-label="cancel"
								type="button"
							>
								{t('CANCEL')}
							</Button>
							<Button variant="contained" color="secondary" className="" type="button" onClick={e => validation()}>
								{t('REGISTER')}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
