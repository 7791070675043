import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { IconButton, Dialog, DialogContent, Typography, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import commonUtils from 'app/utils/CommonUtils';
import { secureRequest } from 'app/store/docbase/commonAction';
import { openDialog } from 'app/store/fuse/dialogSlice';
import OpenDialogForm from '../../component/OpenDialogForm';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});
const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 1208
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 60,
			paddingRight: 30,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277,
		minWidth: 277
	},
	filedInput: {
		width: '100%',
		padding: '0 0 0 30px',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	},
	tall: {
		height: 'inherit!important',
		'& .folder-name': { padding: '20px 20px 20px 30px', overflowWrap: 'anywhere' }
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 12,
		marginBottom: 10,
		'& .MuiButton-root': {
			width: 172,
			height: 54,
			marginRight: 16
		},
		'& .MuiButton-root:last-child': {
			marginRight: 0
		}
	}
}));

export default function ViewDialog(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');
	const dispatch = useDispatch();

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('DLP_CLIENT_LOG_VIEW_TITLE')}
				</DialogTitle>
				<DialogContent className={classes.infoFiled}>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_LOGGED_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.registered_at ? commonUtils.getDateStringToLocalDate(props.logObj.registered_at) : ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_USERNAME')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.user_name
								? `${props.logObj.user_name}(${props.logObj.user_id}-${props.logObj.client_id})`
								: ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PRINT_DOC_FORMAT')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.doc_format || ''}</div>

						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PRINT_COPIES')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.copies || ''}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PRINT_JOB_NAME')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.job_name || ''}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PRINT_JOB_CREATED_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.job_created_at ? commonUtils.getDateStringToLocalDate(props.logObj.job_created_at) : ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PRINT_JOB_PROCESSING_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.job_processing_at
								? commonUtils.getDateStringToLocalDate(props.logObj.job_processing_at)
								: ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PRINT_JOB_COMPLETED_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.job_completed_at
								? commonUtils.getDateStringToLocalDate(props.logObj.job_completed_at)
								: ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PRINT_PRINTER_URL')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.printer_uri || ''}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PRINT_JOB_STATE_MESSAGE')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.job_state_message || ''}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PRINT_JOB_STATE_REASONS')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.job_state_reasons || ''}</div>
					</div>
					<div className={classes.tall}>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_CONTROL_INFO')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.control_info || ''}</div>
					</div>
				</DialogContent>
				<div className={classes.buttonWrapper}>
					<Button
						className="default"
						variant="contained"
						type="button"
						onClick={e => {
							dispatch(
								openDialog({
									children: (
										<OpenDialogForm
											title={t('DLP_CLIENT_LOG_PRINT_FILE_DOWNLOAD_TITLE')}
											content={t('DLP_CLIENT_LOG_PRINT_FILE_DOWNLOAD_CONTENT')}
											fnHandleOK={() => {
												secureRequest((aes, token) => {
													const href = `${window.location.origin}/dlpapi/downloadPrintedFile?print_log_id=${props.logObj.id}&token=${token}`;

													const link = document.createElement('a');
													link.target = '_blank';
													link.href = href;
													link.click();
												});
											}}
										/>
									)
								})
							);

							return true;
						}}
					>
						{t('DOWNLOAD')}
					</Button>
				</div>
			</Dialog>
		</div>
	);
}
