import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from 'app/utils/HttpClient';
import securityUtils from 'app/utils/SecurityUtils';
import { secureRequest, secureAsyncRequest } from 'app/store/docbase/commonAction';

/**
 * 반출목록 페이지 조회
 */
export const getHoldFiles = createAsyncThunk('dlp/getHoldFiles', async ({ page }, { dispatch, getState }) => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/inquiryTakeoutsPage', { page, token: secureObj.token });
	if (respData.result) {
		return { takeouts: respData.takeouts, page, aes: secureObj.aesKey };
	}
	return { takeouts: [], page: 1, aes: '' };
});

export const getClientRunningLogsPage = page => {
	return new Promise((resolve, reject) => {
		httpClient
			.sendPost('/api/clientRunningLogsPage', {
				page
			})
			.then(data => {
				if (data.result) {
					resolve(data.clients);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
	});
};

/**
 * 특정 클라이언트의 등록된 블루투스 장치 리스트를 조회한다.  관리자로 로그인했을 경우만 허용된다.
 *
 * @param {*} clientID
 * @returns
 */
export const clientBluetoothDevices = clientID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/clientBluetoothDevices', {
					client_id: clientID,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(
							data.devices.map(device => {
								device.device_name = securityUtils.decryptAES(aes, device.device_name, 'hex', 'utf8');
								device.device_address = securityUtils.decryptAES(aes, device.device_address, 'hex', 'utf8');
								device.device_type = securityUtils.decryptAES(aes, device.device_type, 'hex', 'utf8');
								return device;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 등록 (설치)된 클라이언트의 정보를 조회한다. 관리자로 로그인했을 경우만 허용된다
 *
 * @param {*} userID
 * @returns
 */
export const clientsInfo = userID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/clientsInfo', {
					user_id: userID && userID !== '' ? securityUtils.encryptAES(aes, userID, 'utf8', 'hex') : '',
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve({ clients: data.clients, aes });
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 DLP Client를 제거한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 * 이미 등록된 DLP Client를 제거한 후 해당 사용자에 대해 신규 클라이언트 등록 (설치)를 허용하면 기존에 설치된 사용자 PC의 DLP를 제거하고 다시 설치할 수 있다.
 *
 * @param {*} userID
 * @param {*} mac
 * @returns
 */
export const removeClient = (userID, mac) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removeClient', {
					user_id: securityUtils.encryptAES(aes, userID, 'utf8', 'hex'),
					mac_address: securityUtils.encryptAES(aes, mac, 'utf8', 'hex'),
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 사용자 혹은 전체 사용자에 대해 신규 클라이언트 등록 (설치)의 허용 여부를 지정한다.
 * 관리자로 로그인했을 경우에만 사용 가능하다.
 * 참고) 신규 사용자 생성 시 기본적으로 신규 클라이언트 등록 (설치)가 허용된다.
 * @param {*} userID
 * @param {*} allow	true로 설정할 경우 해당 사용자 또는 전체 사용자에 대해 신규 클라이언트 설치(등록)를 허용한다
 * @returns
 */
export const allowClientInstall = (userID, allow) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/allowClientInstall', {
					user_id: userID && userID !== '' ? securityUtils.encryptAES(aes, userID, 'utf8', 'hex') : '',
					allow,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 매체별 기본 access 정책을 조회한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @returns
 */
export const getStorageAccess = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getStorageAccess', { token: tk })
				.then(data => {
					if (data.result) {
						resolve(
							data.storages.sort((a, b) => {
								if (a.storage_type > b.storage_type) {
									//	b가 a보다 앞으로 오도록 설정
									return 1;
								}
								if (a.storage_type === b.storage_type) {
									//	변경 없음
									return 0;
								}
								//	a 가 b보다 앞으로 오도록 설정
								return -1;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 매체별 기본 access 정책을 지정한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} storage_type 1 = COODOC Drive, 2 = USB, 3 = Network Drive (COODOC 제외)
 * @param {*} read
 * @param {*} write
 * @param {*} execute
 * @returns
 */
export const setStorageAccess = (storageType, read, write, execute) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/setStorageAccess', { storage_type: storageType, read, write, execute, token: tk })
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * Path로 지정된 가상 매체들의 기본 access 정책을 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @returns
 */
export const getPathStorageAccess = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getPathStorageAccess', { token: tk })
				.then(data => {
					if (data.result) {
						resolve(
							data.storages.map(s => {
								s.path = securityUtils.decryptAES(aes, s.path, 'hex', 'utf8');
								s.path_regex = securityUtils.decryptAES(aes, s.path_regex, 'hex', 'utf8');
								return s;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * Path로 지정된 가상 매체를 추가하고 기본 access 정책을 지정한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @param {*} path enc, 경로. “”일 경우 path_regex를 사용
 * @param {*} path_regex end, RegEx 경로
 * @param {*} read
 * @param {*} write
 * @param {*} execute
 * @returns
 */
export const addPathStorageAccess = (path, pathRegex, read, write, execute) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/addPathStorageAccess', {
					path: path && path !== '' ? securityUtils.encryptAES(aes, path, 'utf8', 'hex') : '',
					path_regex: pathRegex && pathRegex !== '' ? securityUtils.encryptAES(aes, pathRegex, 'utf8', 'hex') : '',
					read,
					write,
					execute,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 *  Path로 지정된 가상 매체를 갱신한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} storage_id Storage ID (100 이상)
 * @param {*} path enc, 경로. “”일 경우 path_regex를 사용
 * @param {*} path_regex end, RegEx 경로
 * @param {*} read
 * @param {*} write
 * @param {*} execute
 * @returns
 */
export const updatePathStorageAccess = (storageID, path, pathRegex, read, write, execute) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/updatePathStorageAccess', {
					storage_id: storageID,
					path: path && path !== '' ? securityUtils.encryptAES(aes, path, 'utf8', 'hex') : '',
					path_regex: pathRegex && pathRegex !== '' ? securityUtils.encryptAES(aes, pathRegex, 'utf8', 'hex') : '',
					read,
					write,
					execute,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * Path로 지정된 가상 매체를 삭제한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} storageID
 * @returns
 */
export const removePathStorageAccess = storageID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removePathStorageAccess', {
					storage_id: storageID,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 프로그램 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @returns
 */
export const getPrograms = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getPrograms', { token: tk })
				.then(data => {
					if (data.result) {
						resolve(data.programs);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 프로그램을 등록한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 * 등록된 프로그램은 개별 또는 전체 사용자에 대한 매체 제어 또는 실행 제어 등에서 사용된다.
 *
 * @param {*} name 			프로그램 명. 관리자 지정. 시스템 전체에서 유일한 명칭이어야 한다
 * @param {*} signingID 	Signing ID. $ codesign -dv –verbose=3 <path> 위 명령으로 추출된 Code-Sign 정보 중 Identifier 정보. e.g. kr.co.whub.COODOC
 * @param {*} hash 			Content hash. $ codesign -dv –verbose=3 <path> 위 명령으로 추출된 Code-Sign 정보 중 CDHash 정보
 * @param {*} programName	프로그램 명. e.g. COODOC
 * @param {*} processType
 * @returns
 */
export const registerProgram = (name, signingID, hash, programName, processType) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/registerProgram', {
					name,
					signing_id: signingID,
					hash,
					program_name: programName,
					process_type: processType,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 프로그램을 변경한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} programID 프로그램 아이디
 * @param {*} name 			프로그램 명. 관리자 지정. 시스템 전체에서 유일한 명칭이어야 한다
 * @param {*} signingID 	Signing ID. $ codesign -dv –verbose=3 <path> 위 명령으로 추출된 Code-Sign 정보 중 Identifier 정보. e.g. kr.co.whub.COODOC
 * @param {*} hash 			Content hash. $ codesign -dv –verbose=3 <path> 위 명령으로 추출된 Code-Sign 정보 중 CDHash 정보
 * @param {*} programName	프로그램 명. e.g. COODOC
 * @param {*} processType
 * @returns
 */
export const updateProgram = (programID, name, signingID, hash, programName, processType) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/updateProgram', {
					program_id: programID,
					name,
					signing_id: signingID,
					hash,
					program_name: programName,
					process_type: processType,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 프로그램을 삭제한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} programID
 * @param {*} forceRemove true로 설정될 경우 해당 프로그램을 가진 execute control, network control, grant access, release blocking 등이 함께 제거된다. false로 지정될 경우 해당 프로그램을 가진 다른 정책이 있을 경우 API 호출이 실패한다
 * @returns
 */
export const removeProgram = (programID, forceRemove) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removeProgram', {
					program_id: programID,
					force_remove: forceRemove,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 업로드 가능한 URL 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @params {*} groupID 0일경우 모두
 * @returns
 */
export const getWhiteURLs = groupID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getWhiteURLs', { group_id: groupID && groupID !== '' ? groupID : 0, token: tk })
				.then(data => {
					if (data.result) {
						resolve(
							data.urls.map(url => {
								url.name_ex = securityUtils.decryptAES(aes, url.name, 'hex', 'utf8'); //	그룹명을 제외하지 않은 실제 이름
								url.name = url.name_ex.replace(`${groupID}_`, '');
								url.url = securityUtils.decryptAES(aes, url.url, 'hex', 'utf8');
								url.url_regex = securityUtils.decryptAES(aes, url.url_regex, 'hex', 'utf8');
								return url;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 업로드 가능한 URL을 등록한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @param {*} name 				네트워크 명. 관리자 지정. 시스템 전체에서 유일한 명칭이어야 한다. 중복을 방지하기위해
 * @param {*} groupID 			적용될 그룹아이디 . ‘0’일 경우 전체 사용자
 * @param {*} url 	URL 또는 앞부분 일부. ‘http://’ 또는 ‘https://’ 를 제외한 url만 기재해야 한다
 * @returns
 */
export const registerWhiteURL = (name, groupID, url, urlRegex) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/registerWhiteURL', {
					name: securityUtils.encryptAES(aes, `${groupID}_${name}`, 'utf8', 'hex'),
					group_id: groupID && groupID !== '' ? groupID : 0,
					url: securityUtils.encryptAES(aes, url, 'utf8', 'hex'),
					url_regex: securityUtils.encryptAES(aes, urlRegex, 'utf8', 'hex'),
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 네트워크를 삭제한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} name 					네트워크 명. 관리자 지정. 시스템 전체에서 유일한 명칭이어야 한다
 * @param {*} groupID 			적용될 그룹 아이디. ‘0’일 경우 전체 사용자
 * @returns
 */
export const removeWhiteURL = (name, groupID) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removeWhiteURL', {
					name: securityUtils.encryptAES(aes, name, 'utf8', 'hex'),
					group_id: groupID && groupID !== '' ? groupID : 0,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};
/**
 * 기 등록된 네트워크 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @returns
 */
export const getNetworks = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getNetworks', { token: tk })
				.then(data => {
					if (data.result) {
						resolve(data.networks);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 네트워크를 등록한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 * 등록된 네트워크는 개별 또는 전체 사용자에 대한 네트워크 제어 및 프로그램 제어 등에 사용될 수 있다.
 * 다만 네트워크를 등록하지 않고도 각각의 제어에 개별적인 네트워크를 그때 그때 지정하여 사용할 수 있다.
 *
 * @param {*} name 					네트워크 명. 관리자 지정. 시스템 전체에서 유일한 명칭이어야 한다
 * @param {*} inboundPorts 			comma(,)로 구분되는 영숫자로 inbound를 허용 또는 차단하는 포트 번호들을 가진다. *으로 지정될 경우 모든 포트에 대해 inbound를 허용 또는 차단한다.
 * 									여기서 inbound라 함은 결국 port listenning을 허용 또는 차단하는지를 의미한다. “-” 를 제공할 경우 inbound_ports는 사용하지 않는다
 * @param {*} outboundDestinations 	comma(,)로 구분되는 <IP-Address>:<Port> 형식의 주소 형식이다. ‘*:*’로 지정할 경우 모든 주소 및 모든 포트에 대해 outbound를 허용 또는 차단한다. E.g. ‘210.1.1.1:1008’
 * @returns
 */
export const registerNetwork = (name, inboundPorts, outboundDestinations) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/registerNetwork', {
					name,
					inbound_ports: inboundPorts,
					outbound_destinations: outboundDestinations,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 네트워크를 변경한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} networkID
 * @param {*} name
 * @param {*} inboundPorts 			comma(,)로 구분되는 영숫자로 inbound를 허용 또는 차단하는 포트 번호들을 가진다. *으로 지정될 경우 모든 포트에 대해 inbound를 허용 또는 차단한다.
 * 									여기서 inbound라 함은 결국 port listenning을 허용 또는 차단하는지를 의미한다. “-” 를 제공할 경우 inbound_ports는 사용하지 않는다
 * @param {*} outboundDestinations 	comma(,)로 구분되는 <IP-Address>:<Port> 형식의 주소 형식이다. ‘*:*’로 지정할 경우 모든 주소 및 모든 포트에 대해 outbound를 허용 또는 차단한다. E.g. ‘210.1.1.1:1008’
 * @returns
 */
export const updateNetwork = (networkID, name, inboundPorts, outboundDestinations) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/updateNetwork', {
					network_id: networkID,
					name,
					inbound_ports: inboundPorts,
					outbound_destinations: outboundDestinations,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 네트워크를 삭제한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} networkID
 * @returns
 */
export const removeNetwork = networkID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removeNetwork', {
					network_id: networkID,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 사용자 정책 그룹별 기본 정책 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @returns
 */
export const getDefaultUsersPolicy = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getDefaultUsersPolicy', {
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.groups);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 사용자의 기본 정책 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @param {*} groupID 사용자 정책 그룸 아이디. ‘0’를 제공할 경우 Global 정책
 * @returns
 */
export const getDefaultUserPolicy = groupID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getDefaultUserPolicy', {
					group_id: groupID,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 사용자의 기본정책을 설정한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @param {*} groupID		사용자 정책 그룹 아이디. ‘0’로 제공될 경우 Global 정책을 설정한다
 * @param {*} executeControl 	실행제어 방식. Control Type 참조. 화이트 리스트 방식인 경우 지정된 프로그램들만 허용되며 그 이외는 차단된다
 * @param {*} networkControl 	네트워크 제어 방식 Control Type 참조. 화이트 리스트 방식인 경우 지정된 네트워크들만 허용되며 그 이외는 차단된다
 * @param {*} takeoutApproving 	반출 요청 처리 방식.  Approval Type 참조
 * @param {*} mount 			마운트 허용 여부
 * @param {*} bluetooth 		블루투스 허용 여부
 * @param {*} bluetoothPairing 	블루투스 페어링 허용 여부
 * @param {*} messenger 		메신저 파일 전송 허용 여부
 * @param {*} install 			프로그램 설치 허용 여부 *
 * @param {boolean} allowReleaseStorage		매체제어 해제 허용 여부
 * @param {boolean} allowReleaseNetwork		네워워크 해제 허용 여부
 * @param {boolean} allowReleaseExecute		실행제어 해제 허용 여부
 * @param {boolean} allowReleaseUploadUrl	브라우저업로드 해제 허용 여부
 * @param {boolean} allowReleaseBluetooth	블루투스 해제 허용 여부
 * @param {boolean} allowReleaseMessenger	메신저 해제 허용 여부
 * @param {int} maxReleaseDurationMonths	최대 허용 시간(월)
 * @param {int} maxReleaseDurationDays		최대 허용 시간(일)
 * @param {int} maxReleaseDurationHours		최대 허용 시간(시)
 * @param {int} maxReleaseDurationMinutes	최대 허용 시간(분)
 * @returns
 */
export const setDefaultUserPolicy = (
	groupID,
	executeControl,
	networkControl,
	takeoutApproving,
	mount,
	bluetooth,
	bluetoothPairing,
	messenger,
	install,
	airdrop,
	allowReleaseStorage,
	allowReleaseNetwork,
	allowReleaseExecute,
	allowReleaseUploadUrl,
	allowReleaseBluetooth,
	allowReleaseMessenger,
	allowReleaseAirdrop,
	maxReleaseDurationMonths,
	maxReleaseDurationDays,
	maxReleaseDurationHours,
	maxReleaseDurationMinutes
) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/setDefaultUserPolicy', {
					group_id: groupID,
					execute_control: executeControl,
					network_control: networkControl,
					takeout_approving: takeoutApproving,
					bluetooth,
					bluetooth_pairing: bluetoothPairing,
					messenger,
					install,
					mount,
					airdrop,
					token: tk,
					allow_release_storage: allowReleaseStorage,
					allow_release_network: allowReleaseNetwork,
					allow_release_execute: allowReleaseExecute,
					allow_release_upload_url: allowReleaseUploadUrl,
					allow_release_bluetooth: allowReleaseBluetooth,
					allow_release_messenger: allowReleaseMessenger,
					allow_release_airdrop: allowReleaseAirdrop,
					max_release_duration_months: maxReleaseDurationMonths,
					max_release_duration_days: maxReleaseDurationDays,
					max_release_duration_hours: maxReleaseDurationHours,
					max_release_duration_minutes: maxReleaseDurationMinutes
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 사용자의 기본정책을 삭제한다. 해당 사용자는 전체 기본 정책을 따른다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} groupID 			사용자 정책 그룹 아이디. ‘0’로 제공될 경우 Global 정책을 설정한다
 * @returns
 */
export const removeDefaultUserPolicy = groupID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removeDefaultUserPolicy', {
					group_id: groupID,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 실행 제어 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 * 사용자별 기본 정책이 White list 일 경우 등록된 프로그램들만 실행이 가능하다.
 *
 * @returns
 */
export const getExecuteControls = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getExecuteControls', {
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.controls);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 실행 제어를 설정한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @param {*} groupID 	사용자 정책 그룹 아이디. ‘0’인 경우 전체 사용자
 * @param {*} program 	프로그램 아이디
 * @returns
 */
export const setExecuteControl = (groupID, program) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/setExecuteControl', {
					group_id: groupID,
					program,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.control_id);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 설정된 실행 제어를 삭제한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} controlID 	실행 제어 아이디
 * @returns
 */
export const removeExecuteControl = controlID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removeExecuteControl', {
					control_id: controlID,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.control_id);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 네트워크 제어 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 * 사용자 기본 정책이 White list 방식일 경우, 등록된 네트워크만 사용 가능하다.
 *
 * @returns
 */
export const getNetworkControls = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getNetworkControls', {
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.controls);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 네트워크 제어를 설정한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * network_name 이 설정된 경우 dlp_network_master에 의해 inbound_ports 및 outbound_destinations가 자동으로 설정된다. 
 * 즉, network_name과 inbound_ports, outbound_destinations가 함께 설정될 수 없다.
 * 
 * @param {*} groupID 	사용자 정책 그룹 아이디. ‘0’인 경우 전체 사용자
 * @param {*} program 				프로그램 아이디
 * @param {*} networkName 			Optional. 네트워크 명. 네트워크 제어 설정 시, 기 등록된 네트워크를 사용했을 경우에만 적용된다. 네트워크를 직접 지정했거나 기존 네트워크를 변경했을 경우는 ‘’
 * @param {*} inboundPorts 			Optional. comma(,)로 구분되는 영숫자로 inbound를 허용 또는 차단하는 포트 번호들을 가진다. *으로 지정될 경우 모든 포트에 대해 inbound를 허용 또는 차단한다.
									여기서 inbound라 함은 결국 port listenning을 허용 또는 차단하는지를 의미한다. 생략할 경우 “*”로 지정된다
 * @param {*} outboundDestinations 	Optional. comma(,)로 구분되는 <IP-Address>:<Port> 형식의 주소 형식이다. ‘*:*’로 지정할 경우 모든 주소 및 모든 포트에 대해 outbound를 허용 또는 차단한다.
									IPv6를 사용할 경우 ‘fe80::8c18:66ff:fe85:62fb.5353’와 같은 형식을 따르도록 한다.
									E.g. ‘210.1.1.1:1008’ 
									생략할 경우 “*:*”로 지정된다
 * @returns
 */
export const registerNetworkControl = (groupID, program, networkName, inboundPorts, outboundDestinations) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/registerNetworkControl', {
					group_id: groupID,
					program,
					network_name: networkName,
					inbound_ports: inboundPorts,
					outbound_destinations: outboundDestinations,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기존 네트워크 제어를 갱신한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @param {*} controlID 			제어 아이디
 * @param {*} groupID 	사용자 정책 그룹 아이디. ‘0’인 경우 전체 사용자
 * @param {*} program 				프로그램 아이디
 * @param {*} networkName 			Optional. 네트워크 명. 네트워크 제어 설정 시, 기 등록된 네트워크를 사용했을 경우에만 적용된다. 네트워크를 직접 지정했거나 기존 네트워크를 변경했을 경우는 ‘’
 * @param {*} inboundPorts 			Optional. comma(,)로 구분되는 영숫자로 inbound를 허용 또는 차단하는 포트 번호들을 가진다. *으로 지정될 경우 모든 포트에 대해 inbound를 허용 또는 차단한다.
									여기서 inbound라 함은 결국 port listenning을 허용 또는 차단하는지를 의미한다. 생략할 경우 “*”로 지정된다
 * @param {*} outboundDestinations 	Optional. comma(,)로 구분되는 <IP-Address>:<Port> 형식의 주소 형식이다. ‘*:*’로 지정할 경우 모든 주소 및 모든 포트에 대해 outbound를 허용 또는 차단한다.
									IPv6를 사용할 경우 ‘fe80::8c18:66ff:fe85:62fb.5353’와 같은 형식을 따르도록 한다.
									E.g. ‘210.1.1.1:1008’ 
									생략할 경우 “*:*”로 지정된다
 * @returns
 */
export const updateNetworkControl = (controlID, groupID, program, networkName, inboundPorts, outboundDestinations) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/updateNetworkControl', {
					control_id: controlID,
					group_id: groupID,
					program,
					network_name: networkName,
					inbound_ports: inboundPorts,
					outbound_destinations: outboundDestinations,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 설정된 네트워크 제어를 삭제한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} networkID
 * @returns
 */
export const removeNetworkControl = controlID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removeNetworkControl', {
					control_id: controlID,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * Access 권한 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 * Access 권한은 사용자 기본 정책 및 실행 제어, 네트워크 제어로 설정된 정책과는 별개로 예외적으로 허용하는 Access 권한을 지정한다.
 *
 * 사용자 / 전체 Access 제어 적용 우선 순위는 다음과 같다.
 * 1. 허가된 제한 해제 요청
 * 2. Access 권한
 * 3. 기본정책 + (실행 제어 + 네트워크 제어)
 *
 * @returns
 */
export const getGrantAccesses = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getGrantAccesses', {
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.grants);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * Access 권한을 등록한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @param {*} groupID 	사용자 정책 그룹 아이디. ‘0’인 경우 전체 사용자
 * @param {*} program 		프로그램 아이디. 0일 경우 전체 프로그램
 * @param {*} storage 		매체 아이디
 * @param {*} readable 		읽기 허용 여부
 * @param {*} writable 		쓰기 허용 여부
 * @param {*} executable 	실행 허용 여부
 * @returns
 */
export const grantAccess = (groupID, program, storage, readable, writable, executable) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/grantAccess', {
					group_id: groupID,
					program,
					storage,
					readable,
					writable,
					executable,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.grant_id);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 Access 권한를 변경한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} grantID 		Grant ID
 * @param {*} groupID 		사용자 정책 그룹 아이디. ‘0’인 경우 전체 사용자
 * @param {*} program 		프로그램 아이디. 0일 경우 전체 프로그램
 * @param {*} storage 		매체 아이디
 * @param {*} readable 		읽기 허용 여부
 * @param {*} writable 		쓰기 허용 여부
 * @param {*} executable 	실행 허용 여부
 * @returns
 */
export const updateGrantAccess = (grantID, groupID, program, storage, readable, writable, executable) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/updateGrantAccess', {
					grant_id: grantID,
					group_id: groupID,
					program,
					storage,
					readable,
					writable,
					executable,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.grant_id);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 Access 권한을 철회한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} grantID 		Grant ID
 * @returns
 */
export const removeGrantAccess = grantID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removeGrantAccess', {
					grant_id: grantID,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.grant_id);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 제한 해제 요청 건에 대한 상태 및 정보를 조회한다. 이 API를 호출한 후 inquiryReleaseBlockingsPage를 호출해야만 실제 자료를 조회할 수 있다
 *
 * @param {*} startDate		요청 일자 기준 조회 시작 일자. YYYY-MM-DD 형식. UTC
 * @param {*} endDate 		요청 일자 기준 조회 종료 일자. YYYY-MM-DD 형식. UTC. 생략 가능
 * @param {*} status		지정할 경우 해당 상태의 자료만 조회된다. 생략하거나 0으로 지정할 경우 모든 상태의 자료가 조회된다
 * @param {*} rowsPerPage
 * @returns
 */
export const inquiryReleaseBlockings = (startDate, endDate, status, rowsPerPage) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/inquiryReleaseBlockings', {
					start_date: startDate,
					end_date: endDate,
					status,
					rows_per_page: rowsPerPage,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve({ rows: data.total_rows, pages: data.total_pages });
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 관리자로 로그인했을 경우에만 호출 가능하다.
 * inquiryReleaseBlockings(Inquiry Release Blocking)를 호출한 이후에만 사용 가능하며
 * inquieryReleaseBlockings를 다시 호출하기 전까지 동일한 결과에 대해 여러 번 호출하여 각각의 페이지를 조회할 수 있다.
 *
 * @param {*} page
 * @returns
 */
export const inquiryReleaseBlockingsPage = page => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/inquiryReleaseBlockingsPage', { page, token: tk })
				.then(data => {
					if (data.result) {
						resolve(
							data.requests.map(req => {
								req.inbound_ports = securityUtils.decryptAES(aes, req.inbound_ports, 'hex', 'utf8');
								req.outbound_destinations = securityUtils.decryptAES(
									aes,
									req.outbound_destinations,
									'hex',
									'utf8'
								);
								req.program_signing_id = securityUtils.decryptAES(aes, req.program_signing_id, 'hex', 'utf8');
								req.program_hash = securityUtils.decryptAES(aes, req.program_hash, 'hex', 'utf8');
								req.program_ref_path = securityUtils.decryptAES(aes, req.program_ref_path, 'hex', 'utf8');
								req.urls = securityUtils.decryptAES(aes, req.urls, 'hex', 'utf8');
								req.requester = securityUtils.decryptAES(aes, req.requester, 'hex', 'utf8');
								req.requester_name = securityUtils.decryptAES(aes, req.requester_name, 'hex', 'utf8');
								req.reason_request = securityUtils.decryptAES(aes, req.reason_request, 'hex', 'utf8');
								req.approver = securityUtils.decryptAES(aes, req.approver, 'hex', 'utf8');
								req.approver_name = securityUtils.decryptAES(aes, req.approver_name, 'hex', 'utf8');
								req.reason_rejected = securityUtils.decryptAES(aes, req.reason_rejected, 'hex', 'utf8');
								return req;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 지정된 제한 해제 요청에 대한 승인 또는 반려 처리한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * @param {*} rid				제한 해제 요청 아이디
 * @param {*} approve			승인 또는 반려
 * @param {*} reasonRejected	반려 사유. approve가 false일 경우에만 해당
 * @returns
 */
export const approveReleaseBlocking = (rid, approve, reasonRejected) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/approveReleaseBlocking', {
					rid,
					approve,
					reason_rejected: reasonRejected ? securityUtils.encryptAES(aes, reasonRejected, 'utf8', 'hex') : '',
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 이미 승인된 제한해제 요청 건을 조기 마감처리한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 * 마감처리된 제한해제 요청 건은 즉시 만료되며 이를 요청한 클라이언트는 약 10초 이내에 한시적 사용이 제한된다.
 *
 * @param {*} rid				제한 해제 요청 아이디
 * @returns
 */
export const stopReleaseBlocking = rid => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/stopReleaseBlocking', {
					rid,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 사용자 및 클라이언트 또는 전체 사용자에 대한 프린트 출력 로그를 조회한다. 이 API를 호출한 후 inquiryPrintLogsPage를 호출해야만 실제 자료를 조회할 수 있다
 *
 *
 * @param {*} userID 		사용자 아이디. "" 일 경우 전체 사용자
 * @param {*} clientID 		클라이트트 아이디. "" 일 경우 전체
 * @param {*} userName 		사용자 이름
 * @param {*} startDate		요청 일자 기준 조회 시작 일자. YYYY-MM-DD 형식. UTC
 * @param {*} endDate 		요청 일자 기준 조회 종료 일자. YYYY-MM-DD 형식. UTC. 생략 가능
 * @param {*} status		지정할 경우 해당 상태의 자료만 조회된다. 생략하거나 0으로 지정할 경우 모든 상태의 자료가 조회된다
 * @param {*} rowsPerPage
 * @returns
 */
export const inquiryPrintLogs = (userID, clientID, userName, startDate, endDate, rowsPerPage) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/inquiryPrintLogs', {
					user_id: userID && userID !== '' ? securityUtils.encryptAES(aes, userID, 'utf8', 'hex') : '',
					user_name: userName && userName !== '' ? securityUtils.encryptAES(aes, userName, 'utf8', 'hex') : '',
					client_id: clientID,
					start_date: startDate,
					end_date: endDate,
					rows_per_page: rowsPerPage,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve({ rows: data.total_rows, pages: data.total_pages });
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * @param {*} page
 * @returns
 */
export const inquiryPrintLogsPage = page => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/inquiryPrintLogsPage', { page, token: tk })
				.then(data => {
					if (data.result) {
						resolve(
							data.logs.map(log => {
								log.user_id = securityUtils.decryptAES(aes, log.user_id, 'hex', 'utf8');
								log.user_name = securityUtils.decryptAES(aes, log.user_name, 'hex', 'utf8');
								log.job_name = securityUtils.decryptAES(aes, log.job_name, 'hex', 'utf8');
								log.printer_uri = securityUtils.decryptAES(aes, log.printer_uri, 'hex', 'utf8');
								log.job_state_message = securityUtils.decryptAES(aes, log.job_state_message, 'hex', 'utf8');
								log.job_state_reasons = securityUtils.decryptAES(aes, log.job_state_reasons, 'hex', 'utf8');
								log.control_info = securityUtils.decryptAES(aes, log.control_info, 'hex', 'utf8');

								return log;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 사용자 및 클라이언트 또는 전체 사용자에 대한 클라이언트 엑세스 로그를 조회한다.
 * 조회 결과에 포함되는 로그들은 ‘제한 해제’ 기간 동안 한시적으로 허용된 객체(대상)들에 대한 엑세스들만 포함된다.
 *
 *
 * @param {*} userID 		사용자 아이디. "" 일 경우 전체 사용자
 * @param {*} clientID 		클라이트트 아이디. "" 일 경우 전체
 * @param {*} userName 		사용자 이름.
 * @param {*} logGroup 		로그 그룹, '' 전체
 * @param {*} startDate		요청 일자 기준 조회 시작 일자. YYYY-MM-DD 형식. UTC
 * @param {*} endDate 		요청 일자 기준 조회 종료 일자. YYYY-MM-DD 형식. UTC. 생략 가능
 * @param {*} status		지정할 경우 해당 상태의 자료만 조회된다. 생략하거나 0으로 지정할 경우 모든 상태의 자료가 조회된다
 * @param {*} orderAscending 날짜 정렬 false: 림차차순 true: 오름차순
 * @param {*} rowsPerPage
 * @returns
 */
export const inquiryClientAccessLogs = (
	userID,
	clientID,
	userName,
	logGroup,
	startDate,
	endDate,
	orderAscending,
	logType,
	rowsPerPage
) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/inquiryClientAccessLogs', {
					user_id: userID && userID !== '' ? securityUtils.encryptAES(aes, userID, 'utf8', 'hex') : '',
					user_name: userName && userName !== '' ? securityUtils.encryptAES(aes, userName, 'utf8', 'hex') : '',
					client_id: clientID,
					log_group: logGroup,
					start_date: startDate,
					end_date: endDate,
					order_ascending: orderAscending,
					rows_per_page: rowsPerPage,
					log_type: logType,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve({ rows: data.total_rows, pages: data.total_pages });
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * @param {*} page
 * @returns
 */
export const inquiryClientAccessLogsPage = page => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/inquiryClientAccessLogsPage', { page, token: tk })
				.then(data => {
					if (data.result) {
						resolve(
							data.logs.map(log => {
								log.user_id = securityUtils.decryptAES(aes, log.user_id, 'hex', 'utf8');
								log.user_name = securityUtils.decryptAES(aes, log.user_name, 'hex', 'utf8');
								log.args = log.args.map(arg => {
									return securityUtils.decryptAES(aes, arg, 'hex', 'utf8');
								});
								log.path = securityUtils.decryptAES(aes, log.path, 'hex', 'utf8');
								log.source_path = securityUtils.decryptAES(aes, log.source_path, 'hex', 'utf8');
								log.dest_path = securityUtils.decryptAES(aes, log.dest_path, 'hex', 'utf8');
								log.signing_id = securityUtils.decryptAES(aes, log.signing_id, 'hex', 'utf8');
								log.hash = securityUtils.decryptAES(aes, log.hash, 'hex', 'utf8');
								log.parent_signing_id = securityUtils.decryptAES(aes, log.parent_signing_id, 'hex', 'utf8');
								log.parent_hash = securityUtils.decryptAES(aes, log.parent_hash, 'hex', 'utf8');
								log.parent_args = log.parent_args.map(arg => {
									return securityUtils.decryptAES(aes, arg, 'hex', 'utf8');
								});
								log.local_endpoint = securityUtils.decryptAES(aes, log.local_endpoint, 'hex', 'utf8');
								log.remote_endpoint = securityUtils.decryptAES(aes, log.remote_endpoint, 'hex', 'utf8');
								log.url = securityUtils.decryptAES(aes, log.url, 'hex', 'utf8');
								log.files = log.files.map(file => {
									return securityUtils.decryptAES(aes, file, 'hex', 'utf8');
								});
								log.log = securityUtils.decryptAES(aes, log.log, 'hex', 'utf8');

								return log;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 사용자 정책 그룹 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @returns
 */
export const getUserGroups = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getUserGroups', { token: tk })
				.then(data => {
					if (data.result) {
						resolve(
							data.groups.map(group => {
								group.name = securityUtils.decryptAES(aes, group.name, 'hex', 'utf8');
								group.desc = securityUtils.decryptAES(aes, group.desc, 'hex', 'utf8');

								return group;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 새로운 사용자 정책 그룹을 등록한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * @returns
 */
export const registerUserGroup = (name, desc) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/registerUserGroup', {
					name: securityUtils.encryptAES(aes, name, 'utf8', 'hex'),
					desc: securityUtils.encryptAES(aes, desc, 'utf8', 'hex'),
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 사용자 정책 그룹을 삭제한다. 관리자로 로그인했을 경우에만 호출 가능하다. 해당 사용자 정책 그룹에 소속된 사용자가 존재할 경우 이 API는 실패한다.
 *
 * @returns
 */
export const removeUserGroup = groupID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removeUserGroup', {
					group_id: groupID,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 사용자 정책 그룹에 속한 사용자 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다
 *
 * @returns
 */
export const getUserGroupMembers = groupID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getUserGroupMembers', {
					group_id: groupID,
					token: tk
				})
				.then(data => {
					if (data.result) {
						const usersObj = {};
						const users = data.users.map(u => {
							usersObj[u.user_id] = true;
							// u.user_id = securityUtils.decryptAES(aes, u.user_id, 'hex', 'utf8');
							// u.user_name = securityUtils.decryptAES(aes, u.user_name, 'hex', 'utf8');
							return u;
						});
						resolve({ users, usersObj });
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 사용자 정책 그룹에서 특정 사용자를 삭제한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 * 특정 사용자는 단 하나의 사용자 정책 그룹에 소속될 수 있으며, 이 API가 수행된 후 해당 사용자는 어떤 사용자 정책 그룹에도 속하지 않은 상태가 된다.
 * 사용자 정책 그룹에 소속되지 않은 사용자들은 Global 정책을 따르게 된다.
 *
 * @returns
 */
export const removeUserGroupMember = (groupID, userID) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/removeUserGroupMember', {
					group_id: groupID,
					user_id: securityUtils.encryptAES(aes, userID, 'utf8', 'hex'),
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.users);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 사용자 정책 그룹에 특정 사용자를 등록한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 * 특정 사용자는 단 하나의 사용자 정책 그룹에만 등록 가능하다. 해당 사용자가 기존에 다른 사용자 정책 그룹에 등록되어 있었다면 이 API로 인해 기존 정책 그룹에서 자동으로 탈퇴하게 된다.
 * 사용자 정책 그룹에 소속되지 않은 사용자들은 Global 정책을 따르게 된다.
 *
 * @returns
 */
export const registerUserGroupMember = (groupID, userID) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/registerUserGroupMember', {
					group_id: groupID,
					user_id: securityUtils.encryptAES(aes, userID, 'utf8', 'hex'),
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 사용자 및 클라이언트 또는 전체 사용자에 대한 클라이언트 Alert(경고) 로그를 조회한다.
 *  관리자로 로그인 했을 경우에만 사용 가능하다.
 *
 * @param {*} userID 		사용자 아이디. "" 일 경우 전체 사용자
 * @param {*} clientID 		클라이트트 아이디. "" 일 경우 전체
 * @param {*} logGroup 		로그 그룹, '' 전체
 * @param {*} startDate		요청 일자 기준 조회 시작 일자. YYYY-MM-DD 형식. UTC
 * @param {*} endDate 		요청 일자 기준 조회 종료 일자. YYYY-MM-DD 형식. UTC. 생략 가능
 * @param {*} status		지정할 경우 해당 상태의 자료만 조회된다. 생략하거나 0으로 지정할 경우 모든 상태의 자료가 조회된다
 * @param {*} rowsPerPage
 * @returns
 */
export const inquiryClientAlertLogs = (userID, clientID, logGroup, startDate, endDate, rowsPerPage) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/inquiryClientAlertLogs', {
					user_id: userID && userID !== '' ? securityUtils.encryptAES(aes, userID, 'utf8', 'hex') : '',
					client_id: clientID,
					log_group: logGroup,
					start_date: startDate,
					end_date: endDate,
					order_ascending: false,
					rows_per_page: rowsPerPage,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve({ rows: data.total_rows, pages: data.total_pages });
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * @param {*} page
 * @returns
 */
export const inquiryClientAlertLogsPage = page => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/inquiryClientAlertLogsPage', { page, token: tk })
				.then(data => {
					if (data.result) {
						resolve(
							data.logs.map(log => {
								log.user_id = securityUtils.decryptAES(aes, log.user_id, 'hex', 'utf8');
								log.user_name = securityUtils.decryptAES(aes, log.user_name, 'hex', 'utf8');
								return log;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 특정 클라이언트의 프로그램 설치 로그를 확인한다. 최신 순으로 정렬되어 조회된다.
 *
 * @param {*} clientID	클라이언트 아이디
 * @returns
 */
export const clientInstallApps = clientID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/clientInstallApps', { client_id: clientID, token: tk })
				.then(data => {
					if (data.result) {
						resolve(
							data.installs.map(install => {
								install.path = securityUtils.decryptAES(aes, install.path, 'hex', 'utf8');
								install.signing_id = securityUtils.decryptAES(aes, install.signing_id, 'hex', 'utf8');
								install.hash = securityUtils.decryptAES(aes, install.hash, 'hex', 'utf8');

								return install;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 비로깅 앱 리스트를 조회한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * 비로깅 앱으로 지정된 앱이 생성하는  클라이언트 로그는 수집되지 않고 버려진다.
 *
 * @returns
 */
export const getNonLoggingApps = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/getNonLoggingApps', { token: tk })
				.then(data => {
					if (data.result) {
						resolve(
							data.signing_ids.map(id => {
								id.signing_id = securityUtils.decryptAES(aes, id.signing_id, 'hex', 'utf8');
								id.desc = securityUtils.decryptAES(aes, id.description, 'hex', 'utf8');
								id.regex = id.regex !== '' ? securityUtils.decryptAES(aes, id.regex, 'hex', 'utf8') : '';
								id.word = id.word !== '' ? securityUtils.decryptAES(aes, id.word, 'hex', 'utf8') : '';
								return id;
							})
						);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 비로깅 앱을 등록한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * 비로깅 앱으로 지정된 앱이 생성하는  클라이언트 로그는 수집되지 않고 버려진다.
 *
 * @returns
 */
export const registerNonLoggingApp = (signingId, desc, word, regex) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/registerNonLoggingApp', {
					signing_id: securityUtils.encryptAES(aes, signingId, 'utf8', 'hex'),
					desc: securityUtils.encryptAES(aes, desc, 'utf8', 'hex'),
					word: word !== '' ? securityUtils.encryptAES(aes, word, 'utf8', 'hex') : '',
					regex: regex !== '' ? securityUtils.encryptAES(aes, regex, 'utf8', 'hex') : '',
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 기 등록된 비로깅 앱을 등록 해제한다. 관리자로 로그인했을 경우에만 호출 가능하다.
 *
 * 비로깅 앱으로 지정된 앱이 생성하는  클라이언트 로그는 수집되지 않고 버려진다.
 *
 * @returns
 */
export const unregisterNonLoggingApp = id => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/dlpapi/unregisterNonLoggingApp', {
					id,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {});
		});
	});
};

/**
 * 신규 DLP 클라이언트를 강제 갱신하도록 한다. 관리자로 로그인했을 경우에만 사용 가능하다.
 *
 * 강제 갱신은 Push를 통해 DLP 클라이언트들에게 강제 갱신을 요청한다.
 * 갱신 요청을 받은 클라이언트들은 서버에 등록된 신규 클라이언트가 자신보다 최신 버전일 경우 즉시 갱신을 시도한다.
 * Push를 수신할 수 없는 클라이언트들은 향후 Push를 수신할 수 있는 환경이 되었을 때 갱신 요청 Push를 수신하게 된다.
 *
 * @returns
 */
export const updateClient = () => {
	return new Promise((resolve, reject) => {
		httpClient
			.sendPost('/dlpapi/updateClient', {})
			.then(data => {
				if (data.result) {
					resolve(data.result);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			})
			.finally(() => {});
	});
};
const dlpSlice = createSlice({
	name: 'dlp',
	initialState: {
		holdFiles: []
	},
	reducers: {
		testReducers: (state, action) => {
			state.auditLogObj.rowsPerPage = action.payload.value;
		}
	},
	extraReducers: {
		[getHoldFiles.fulfilled]: (state, action) => {
			state.holdFiles = action.payload.files;
		}
	}
});

export const { testReducers } = dlpSlice.actions;

export default dlpSlice.reducer;
