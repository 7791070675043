import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import OfflineDriveTable from './OfflineDriveTable';
import DetailSidebarContent from './DetailSidebarContent';
import ContentHeader from '../component/ContentHeader';

function OfflineDrivePage() {
	const [loading, setLoading] = React.useState(true);
	const [selectedObj, setSelectedObj] = React.useState({
		user_id: '',
		userName: '',
		requested_at: '',
		expire_at: '',
		status: 0,
		statusTxt: '',
		reason: '',
		offline_drive_id: 0,
		offline_drive_latter: '',
		offline_map_path: ''
	});
	const pageLayout = React.useRef(null);

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<OfflineDriveTable
					loading={loading}
					setLoading={val => setLoading(val)}
					selectedObj={selectedObj}
					fnSetSelectedObj={obj => setSelectedObj(obj)}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			rightSidebarContent={
				<DetailSidebarContent
					loading={loading}
					setLoading={setLoading}
					selectedObj={selectedObj}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default OfflineDrivePage;
