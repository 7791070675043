const locale = {
	DASHBOARD: '대시보드',
	MANAGEMENT_GROUP: '관리',
	USER_MANAGEMENT: '사용자',
	ORG_MANAGEMENT: '조직',
	SHARED_MANAGEMENT: '공유',
	SECUREDOCS_MANAGEMENT: '보안문서',
	SETTING_GROUP: '설정',
	RESTORE_SETTING: '자료 복원',
	SERVER_SETTING: '기타',
	ACCESSLOG: '실시간 사용 내역',
	GLOBAL_SETTING: '수집기',
	PRIVATE_SETTING: '사용자별 수집',
	PII_MANAGEMENT: '개인정보 검출',
	DUP_MANAGEMENT: '중복 문서',
	WHITE_PROCESS_MANAGEMENT: '보안 프로세스',
	OSR_MANAGEMENT: '인사 연동',
	FILE_ACCESS_LOG: '파일 로그',
	AUDIT_LOG: '감사 로그',
	LOGOUT: '로그아웃',
	RANSOM_MANAGEMENT: '랜섬웨어 탐지',
	TAKEOUT_MANAGEMENT: '반출문서',
	HOLD_FILES: '사용 문서',
	SETUP_INFO: '클라이언트',
	EXPORT_APPROVAL_MANAGEMENT: '반출 승인자 관리',
	INSTANT_LOG: '클라이언트 로그',
	CLIENT_RUNNING_LOG: '클라이언트 실행 로그',
	DLP_CLIENT_INFO: 'DLP 클라이언트 정보',
	DLP_POLICY_SETTING: 'DLP 카테고리 설정',
	RELEASE_BLOCKING: 'DLP 임시 정책 관리',
	PRINT_LOG: 'DLP 프린트 로그',
	CLIENT_ACCESS_LOG: 'DLP 임시해제 로그',
	DLP_GROUP_SETTING: 'DLP 정책 설정',
	CLIENT_MONITORING_LOG: 'DLP 차단/유출 로그',
	CLIENT_ALERT_LOG: 'DLP 경고 로그',
	DLP_POLICY_GROUP: 'DLP 정책 관리',
	DLP_LOG_GROUP: 'DLP 로그',
	CLIENT_STORAGE_LOG: 'DLP 매체 로그',
	OFFLINE_DRIVE_MANAGEMENT: '오프라인드라이브 관리'
};

export default locale;
