import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import { getUserGroups, removeUserGroup } from 'app/store/docbase/admin/dlpSlice';
import GroupsTable from './GroupsTable';
import DetailSidebarContent from './DetailSidebarContent';

import ContentHeader from '../../component/ContentHeader';

function GroupsPage() {
	const pageLayout = React.useRef(null);

	const [selectedID, setSelectedID] = React.useState(0);
	const [groups, setGroups] = React.useState([]);
	const [selectedObj, setSelectedObj] = React.useState({ name: '', desc: '', id: 0 });
	const [DLPPolicy, setDLPPolicy] = React.useState({ open: false, selectedID: 0 });

	React.useEffect(() => {
		if (selectedID === 0) {
			setSelectedObj({ name: '', desc: '', id: 0 });
		} else {
			groups.forEach(g => {
				if (g.id === selectedID) {
					setSelectedObj(g);
				}
			});
		}
		setDLPPolicy({ ...DLPPolicy, selectedID });
	}, [selectedID]);

	const getGroups = () => {
		getUserGroups().then(g => {
			setGroups(g);
		});
	};
	const removeGroups = id => {
		removeUserGroup(id).then(resp => {
			getUserGroups().then(g => {
				setGroups(g);
			});
		});
	};

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<GroupsTable
					selectedID={selectedID}
					groups={groups}
					DLPPolicy={DLPPolicy}
					fnSetSelectedID={id => setSelectedID(id)}
					fnGetGroups={() => getGroups()}
					fnRemoveGroups={id => removeGroups(id)}
					fnOpenDLPPolicy={() => {
						setDLPPolicy({ open: true, selectedID: 0 });
					}}
					fnCloseDLPPolicy={() => {
						setDLPPolicy({ ...DLPPolicy, open: false });
					}}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			rightSidebarContent={
				<DetailSidebarContent
					selectedID={selectedID}
					selectedObj={selectedObj}
					fnGetGroups={() => getGroups()}
					fnOpenDLPPolicy={() => {
						setDLPPolicy({ open: true, selectedID });
					}}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default GroupsPage;
