import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import ClientAlertLogTable from './ClientAlertLogTable';

import ContentHeader from '../../component/ContentHeader';

function ClientAlertLogPage() {
	const pageLayout = React.useRef(null);
	const [selectedFid, setSelectedFid] = React.useState(-1);

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<ClientAlertLogTable selected={selectedFid} fnSetSelectedFid={fid => setSelectedFid(fid)} />}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default ClientAlertLogPage;
