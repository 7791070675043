import { createSlice } from '@reduxjs/toolkit';
import httpClient from '../../utils/HttpClient';
import securityUtils from '../../utils/SecurityUtils';
import { secureRequest } from '../../store/docbase/commonAction';

const initialState = {
	success: false
};

const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		loginSuccess: (state, action) => {
			state.success = true;
		},
		loginError: (state, action) => {
			state.success = false;
			state.error = action.payload;
		},
		test: {
			reducer: (state, action) => {
				state.sample = '어마어마하게';
			},
			prepare: (t, t1, t2) => {
				return { payload: { t1, t2 } };
			}
		}
	},
	extraReducers: {}
});

const actions = {
	doLogin: (userID, passwd, domain) => {
		return new Promise((resolve, reject) => {
			secureRequest((aes, tk) => {
				httpClient
					.sendPost('/api/login', {
						user_id: securityUtils.encryptAES(aes, userID, 'utf8', 'hex'),
						password: securityUtils.encryptAES(aes, passwd, 'utf8', 'hex'),
						client_type: 5,
						pc_id: 'qq',
						version: '2.0.0.0',
						token: tk,
						domain: securityUtils.encryptAES(aes, domain, 'utf8', 'hex') || ''
					})
					.then(data => {
						if (data.result) {
							httpClient.sendPost('/api/loginInfo', {}).then(respInfo => {
								if (respInfo.result) {
									localStorage.setItem('doc_name', respInfo.user_name);
									localStorage.setItem('doc_user', userID);
									localStorage.setItem('login', 'true');
								}
							});
							localStorage.setItem('two_auth_flag', data.two_factor_authentication_flag);
							resolve(data);
						} else {
							httpClient.printError(data);
							reject(data);
						}
					});
			});
		});
	},
	getTOTPSecretKey: userID => {
		return new Promise((resolve, reject) => {
			secureRequest((aes, tk) => {
				httpClient.sendPost('/api/getTOTPSecretKey', { account_name: userID, token: tk }).then(data => {
					if (data.result) {
						data.aes = aes;
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
			});
		});
	},
	registerTOTP: (key, value) => {
		return new Promise((resolve, reject) => {
			secureRequest((aes, tk) => {
				httpClient
					.sendPost('/api/registerTOTP', {
						key: securityUtils.encryptAES(aes, key, 'utf8', 'hex'),
						value: securityUtils.encryptAES(aes, value, 'utf8', 'hex'),
						token: tk
					})
					.then(data => {
						if (data.result) {
							resolve(data);
						} else {
							httpClient.printError(data);
							reject(data);
						}
					});
			});
		});
	},
	directRegisterTOTP: (key, value) => {
		return new Promise((resolve, reject) => {
			secureRequest((aes, tk) => {
				httpClient
					.sendPost('/api/registerTOTP', {
						key: securityUtils.encryptAES(aes, key, 'utf8', 'hex'),
						value: securityUtils.encryptAES(aes, value, 'utf8', 'hex'),
						password: securityUtils.encryptAES(
							aes,
							securityUtils.decryptAESCTR(localStorage.getItem('auth')),
							'utf8',
							'hex'
						),
						token: tk
					})
					.then(data => {
						if (data.result) {
							resolve(data);
						} else {
							httpClient.printError(data);
							reject(data);
						}
					});
			});
		});
	},
	authTOTP: value => {
		return new Promise((resolve, reject) => {
			secureRequest((aes, tk) => [
				httpClient
					.sendPost('/api/totpAuth', { value: securityUtils.encryptAES(aes, value, 'utf8', 'hex'), token: tk })
					.then(data => {
						if (data.result) {
							resolve(data);
						} else {
							httpClient.printError(data);
							reject(data);
						}
					})
			]);
		});
	},
	requestPaperPassword: () => {
		return new Promise((resolve, reject) => {
			secureRequest((aes, tk) => {
				httpClient.sendPost('/wcapi/requestPaperPassword', { token: tk }).then(data => {
					if (data.result) {
						resolve(securityUtils.decryptAES(aes, data.question, 'hex', 'utf8'));
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
			});
		});
	},
	paperPasswordAuth: answer => {
		return new Promise((resolve, reject) => {
			secureRequest((aes, tk) => [
				httpClient
					.sendPost('/wcapi/paperPasswordAuth', {
						answer: securityUtils.encryptAES(aes, answer, 'utf8', 'hex'),
						token: tk
					})
					.then(data => {
						if (data.result) {
							resolve(data);
						} else {
							httpClient.printError(data);
							reject(data);
						}
					})
			]);
		});
	},
	doLogout: () => {
		return new Promise((resolve, reject) => {
			secureRequest((aes, tk) => {
				httpClient.sendPost('/api/logoff', {}).then(data => {
					if (data.result) {
						localStorage.setItem('doc_user', '');
						localStorage.setItem('two_auth_flag', 0);
						resolve(data);
					} else {
						if (data.message === 'Login first!') {
							resolve(data);
						}
						httpClient.printError(data);
						reject(data);
					}
				});
			});
		});
	},
	doIntegratedLogin: () => {
		return new Promise((resolve, reject) => {
			httpClient.sendPost('/api/integratedLoginConfirm', {}).then(data => {
				if (data.result) {
					localStorage.setItem('doc_user', '_integratedLoginConfirm');
					localStorage.setItem('two_auth_flag', data.two_factor_authentication_flag);
					localStorage.setItem('login', 'true');
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	}
};

export const { loginSuccess, loginError, test } = loginSlice.actions;
export const {
	doLogin,
	getTOTPSecretKey,
	registerTOTP,
	directRegisterTOTP,
	authTOTP,
	requestPaperPassword,
	paperPasswordAuth,
	doLogout,
	doIntegratedLogin
} = actions;
export default loginSlice.reducer;
