//  폴더 종류
const FOLDER_TYPE_UNKNOWN = 0; // 알 수 없는 폴더 종류
const FOLDER_TYPE_ORGANIZE = 1; // 조직 폴더
const FOLDER_TYPE_SHARED = 2; // 공유 폴더
const FOLDER_TYPE_PROJECT = 3; // 프로젝트 폴더
const FOLDER_TYPE_PRIVATE = 4; // 개인 폴더
const FOLDER_TYPE_TRASH = 5; // 휴지통 폴더

class CommonUtils {
	containsCharsOnly = (input, chars) => {
		for (let inx = 0; inx < input.length; inx++) {
			if (chars.indexOf(input.charAt(inx)) === -1) return false;
		}
		return true;
	};

	isNumber = input => {
		const chars = '0123456789';
		return this.containsCharsOnly(input, chars);
	};

	getLastWeek = () => {
		const d = new Date();
		d.setDate(d.getDate() - 7);
		return d;
	};

	/**
	 * date를 format의 문자열로 돌려준다.
	 * format 공백시 'yyyy-mm-dd'로 변경.
	 * @param \ 년: yyyy 월: mm, 일: dd
	 */
	getDateString = (date, format) => {
		if (date.constructor.name !== 'Date') date = new Date(date);

		if (format === undefined || format.trim() === '') format = 'yyyy-mm-dd';

		const m = date.getMonth() + 1 > 9 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`;
		const d = date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`;

		return format.replace('yyyy', date.getFullYear()).replace('mm', m).replace('dd', d);
	};

	/**
	 * date를 format의 문자열로 돌려준다.
	 * format 공백시 'yyyy-mm-dd hh:mi:ss'로 변경.
	 * @param \ 년: yyyy 월: mm, 일: dd
	 */
	getDateTimeString = date => {
		if (date.constructor.name !== 'Date') {
			const spRegisterd = date.replace('T', ' ').replace('Z', '').split(' ');
			let spDate = spRegisterd[0].split('/');
			if (spDate.length <= 1) {
				spDate = spRegisterd[0].split('-');
			}
			const spTime = spRegisterd[1].split(':');
			date = new Date(spDate[0], Number(spDate[1]) - 1, spDate[2], spTime[0], spTime[1], spTime[2]);
		}

		const h = date.getHours() > 9 ? `${date.getHours()}` : `0${date.getHours()}`;
		const m = date.getMinutes() > 9 ? `${date.getMinutes()}` : `0${date.getMinutes()}`;
		const s = date.getSeconds() > 9 ? `${date.getSeconds()}` : `0${date.getSeconds()}`;

		return `${this.getDateString(date)} ${h}:${m}:${s}`;
	};

	/**
	 * UTC date를 브라우저 지역에 맞는 시간로로 변경하여 출력한다.
	 * format 공백시 'yyyy-mm-dd'로 변경.
	 *
	 * @param {*} strUTC 2020/09/22 18:22:22
	 * @param {*} format
	 */
	getDateStringToLocalDate(strUTC, format) {
		if (!strUTC || strUTC.length === 0) {
			return '';
		}

		const spRegisterd = strUTC.replace('T', ' ').replace('Z', '').split(' ');
		if (spRegisterd.length === 1) {
			return spRegisterd[0];
		}

		let spDate = spRegisterd[0].split('/');
		if (spDate.length <= 1) {
			spDate = spRegisterd[0].split('-');
		}
		const spTime = spRegisterd[1].split(':');
		const utcDate = new Date(Date.UTC(spDate[0], Number(spDate[1]) - 1, spDate[2], spTime[0], spTime[1], spTime[2]));

		const h = utcDate.getHours() > 9 ? `${utcDate.getHours()}` : `0${utcDate.getHours()}`;
		const m = utcDate.getMinutes() > 9 ? `${utcDate.getMinutes()}` : `0${utcDate.getMinutes()}`;
		const s = utcDate.getSeconds() > 9 ? `${utcDate.getSeconds()}` : `0${utcDate.getSeconds()}`;

		return `${this.getDateString(utcDate, format)} ${h}:${m}:${s}`;
	}

	/**
	 * UTC date String을 date로 변경
	 * format 공백시 'yyyy-mm-dd'로 변경.
	 *
	 * @param {*} strUTC 2020/09/22 18:22:22
	 */
	getDateStringToDate(strUTC) {
		if (!strUTC || strUTC.length === 0) {
			return '';
		}
		const spRegisterd = strUTC.split(' ');
		let spDate = spRegisterd[0].split('/');
		if (spDate.length <= 1) {
			spDate = spRegisterd[0].split('-');
		}
		const spTime = spRegisterd[1] ? spRegisterd[1].split(':') : ['00', '00', '00'];

		return new Date(Date.UTC(spDate[0], Number(spDate[1]) - 1, spDate[2], spTime[0], spTime[1], spTime[2]));
	}

	/**
	 * 오늘 날짜에서 interval 만큼 수정된 date 를 돌려준다.
	 *
	 * @param {number} interval
	 */
	getDate(interval) {
		if (!interval || interval === 0) {
			return new Date();
		}
		const d = new Date();
		return new Date(d.getFullYear(), d.getMonth(), d.getDate() + interval);
	}

	/**
	 * yyyy-mm-dd 형식의 문자를 date로 변형해준다.
	 * @param {string} stringDate
	 */
	getStringToDate(stringDate) {
		const splitDate = stringDate.split('-');

		return new Date(splitDate[0], Number(splitDate[1]) - 1, splitDate[2]);
	}

	/**
	 * dateB - dateA 의 날짜 차이 돌려준다.
	 * @param {*} dateA
	 * @param {*} dateB
	 */
	getDateDifference(dateA, dateB) {
		const dif = dateB.getTime() - dateA.getTime();
		return parseInt(dif / (1000 * 60 * 60 * 24), 10);
	}

	/**
	 * dateA가 dateB 이후인지 확인하는 함수.
	 * @param {*} dateA
	 * @param {*} dateB
	 * @returns {boolean} dateA가 dateB 이후이면 true, 그렇지 않으면 false
	 */
	isAfter(dateA, dateB) {
		return dateA.getTime() > dateB.getTime();
	}

	/**
	 * UTC 시간을 현재시간과 비교하여 얼마나 차이나는지 알려다다. 1/1000초
	 * @param {*} strUTC
	 */
	getDateDifferenceByNow(strUTC) {
		if (!strUTC || strUTC.length === 0) {
			return 0;
		}
		const spRegisterd = strUTC.replace('T', ' ').replace('Z', '').split(' ');
		let spDate = spRegisterd[0].split('/');
		if (spDate.length <= 1) {
			spDate = spRegisterd[0].split('-');
		}
		const spTime = spRegisterd[1].split(':');
		const utcDate = new Date(Date.UTC(spDate[0], Number(spDate[1]) - 1, spDate[2], spTime[0], spTime[1], spTime[2]));
		const nowDate = new Date();

		const dif = utcDate.getTime() - nowDate.getTime();
		return dif;
	}

	/**
	 * 초를 시분초로 변경
	 * @param {*} seconds
	 * @returns
	 */
	getSecToTime(seconds) {
		const s = parseInt(seconds / 3600, 10);
		const hour = parseInt(seconds / 3600, 10) < 10 ? `0${parseInt(seconds / 3600, 10)}` : parseInt(seconds / 3600, 10);
		const min =
			parseInt((seconds % 3600) / 60, 10) < 10
				? `0${parseInt((seconds % 3600) / 60, 10)}`
				: parseInt((seconds % 3600) / 60, 10);
		const sec = Math.floor(seconds % 60) < 10 ? `0${Math.floor(seconds % 60)}` : Math.floor(seconds % 60);
		return `${hour}:${min}:${sec}`;
	}

	/**
	 * input을 delimiter로 자른 마지막 문자를 돌려줍니다.
	 * @param {string} input
	 * @param {string} delimiter
	 */
	splitLastDelimiterString(input, delimiter) {
		if (input === undefined || input.length === 0) {
			return '';
		}
		if (!delimiter) {
			delimiter = '/';
		}
		return input.substring(input.lastIndexOf(delimiter) + 1);
	}

	/**
	 * input을 delimiter로 자른 마지막 2번째까지 돌려준다.
	 * @param {string} input
	 * @param {string} delimiter
	 */
	splitSecond2LastDelimiterString(input, delimiter) {
		if (input === undefined || input.length === 0) {
			return '';
		}
		if (!delimiter) {
			delimiter = '/';
		}
		return this.splitLastDelimiterString(this.splitPreDelimiter(input, delimiter), delimiter);
	}

	/**
	 *
	 * input을 delimiter의 마지막 문자를 제거하고 돌려준다.
	 * @param {*} input
	 * @param {*} delimiter
	 */
	splitPreDelimiter(input, delimiter) {
		if (input === undefined || input.length === 0) {
			return '';
		}
		if (!delimiter) {
			delimiter = '/';
		}
		return input.substring(0, input.lastIndexOf(delimiter));
	}

	/**
	 * input을 delimiter로 자른 마지막 2번째까지 돌려준다.
	 * @param {string} input
	 * @param {string} delimiter
	 */
	splitPreSecond2LastDelimiterString(input, delimiter) {
		if (input === undefined || input.length === 0) {
			return '';
		}
		if (!delimiter) {
			delimiter = '/';
		}
		return input.substring(input.lastIndexOf(delimiter, input.lastIndexOf(delimiter) - 1) + 1);
	}

	shortenPath(path) {
		const splitPath = path.split('/');
		if (splitPath.length < 4) {
			return path;
		}
		let returnPath = splitPath[1];
		returnPath += `/${splitPath[2]}`;
		if (splitPath.length > 4) {
			returnPath += '/...';
		}
		returnPath += `/${splitPath[splitPath.length - 1]}`;
		return returnPath;
	}

	/**
	 * byte를 크기에 맞는(MB, GB) 단위로 변경하여 출력.
	 *
	 * @param {*} bytes
	 */
	changeByteToString(bytes) {
		if (bytes === undefined) {
			return '-';
		}
		if (bytes > 1099511627776) {
			return `${(bytes / 1099511627776).toFixed(2)} TB`;
		}
		if (bytes > 1073741824) {
			return `${(bytes / 1073741824).toFixed(2)} GB`;
		}
		if (bytes > 1048576) {
			return `${(bytes / 1048576).toFixed(2)} MB`;
		}
		if (bytes > 1024) {
			return `${(bytes / 1024).toFixed(2)} KB`;
		}
		return `${bytes.toFixed(0)} B`;
	}

	/**
	 * 천단위로 , 추가
	 * 천단위 이하는 소수점 밑을 살려서 돌려준다.
	 * 천단위 이상은 소수점을 짤라서 내려준다.
	 * @param {number} input
	 */
	addComma(input) {
		if (input < 1000) {
			let split = '';
			if (input.toString().indexOf('.') > -1) {
				split = input.substring(input.toString().indexOf('.'));
				input = Math.floor(input);
			}
			return input + split;
		}

		input = Math.floor(input);
		input += '';
		const len = input.length;
		let point = input.length % 3;
		let str = input.substring(0, point);

		while (point < len) {
			if (str !== '') str += ',';
			str += input.substring(point, point + 3);
			point += 3;
		}
		return str;
	}

	/**
	 * 입력한 단위 , 추가
	 *
	 * @param {number} bytes
	 * @param {string} unit 단위
	 */
	changeByteToStringWithUnit(bytes, unit) {
		if (unit === 'TB') {
			return `${this.addComma((bytes / 1099511627776).toFixed(2))} TB`;
		}
		if (unit === 'GB') {
			return `${this.addComma((bytes / 1073741824).toFixed(2))} GB`;
		}
		if (unit === 'MB') {
			return `${this.addComma((bytes / 1048576).toFixed(2))} MB`;
		}
		if (unit === 'KB') {
			return `${this.addComma((bytes / 1024).toFixed(2))} KB`;
		}
		return `${bytes.toFixed(0)} B`;
	}

	/**
	 * 입력한 단위 , 추가
	 *
	 * @param {number} bytes
	 * @param {string} unit 단위
	 */
	changeByteWithUnit(bytes, unit) {
		if (unit === 'TB') {
			return Number((bytes / 1099511627776).toFixed(2));
		}
		if (unit === 'GB') {
			return Number((bytes / 1073741824).toFixed(2));
		}
		if (unit === 'MB') {
			return Number((bytes / 1048576).toFixed(2));
		}
		if (unit === 'KB') {
			return Number((bytes / 1024).toFixed(2));
		}
		return bytes;
	}

	/**
	 * 폴더의 명칭은 path의 맨 마지막 경로를 잘라서 사용한다.
	 */
	pathToName = path => {
		if (path !== undefined && path !== '') {
			return path.substring(path.lastIndexOf('/') + 1);
		}
		return '';
	};

	/**
	 * 사용자, 폴더등 실제 폴더가 생성되는 부분에있어 예외문자 확인.
	 * @param {*} name
	 */
	checkName = name => {
		if (name.trim() === '') {
			return false;
		}

		if (/[\\/:*?\\"<>|]/.test(name.trim())) {
			return false;
		}

		return true;
	};

	/**
	 * path 앞부분의 경로를 사용자가 보기 좋게 변경해준다.
	 * @param {*} path
	 */
	changeAccessibleFolder = path => {
		//	/COODOC/users/ 를 제거
		path = path.replace('/COODOC/users/', '');
		//	사용자아이디를 제거
		path = path.substring(path.indexOf('/'));
		//	최상위폴더명 변경
		if (path.indexOf('/Organization') === 0) {
			return path.replace('/Organization', '/조직폴더');
		}
		if (path.indexOf('/Collect') === 0) {
			return path.replace('/Collect', '/수집폴더');
		}
		if (path.indexOf('/Private') === 0) {
			return path.replace('/Private', '/개인폴더');
		}
		if (path.indexOf('/Shared') === 0) {
			return path.replace('/Shared', '/공유폴더');
		}
		return path;
	};

	/**
	 * folderType으로 폴더의 종류를 구분해준다.
	 *
	 * @param {number}} folderType
	 */
	findFolderType = folderType => {
		switch (folderType) {
			case FOLDER_TYPE_UNKNOWN:
				return 'Unknown';
			case FOLDER_TYPE_ORGANIZE:
				return '조직 폴더';
			case FOLDER_TYPE_SHARED:
				return '공유 폴더';
			case FOLDER_TYPE_PROJECT:
				return '프로젝트 폴더';
			case FOLDER_TYPE_PRIVATE:
				return '개인 폴더';
			case FOLDER_TYPE_TRASH:
				return '휴지통';
			default:
				return '폴더';
		}
	};

	/**
	 * url에 있는 파라미터를 파싱한다.
	 * @param {string} urlParam
	 */
	getJSONFromURLParameter = urlParam => {
		const resultObj = {};
		if (!urlParam) {
			return resultObj;
		}

		urlParam = urlParam.substring(1);
		urlParam.split('&').forEach(s => {
			const item = s.split('=');
			resultObj[item[0]] = decodeURIComponent(item[1]);
		});
		return resultObj;
	};

	/**
	 * 재활용 하여 사용할 reader와 읽을 file 그리고 읽어올 위치를 지정한다.
	 * bytearray로 돌려준다.
	 *
	 * @param {FileReader} reader
	 * @param {File} file
	 * @param {number} start
	 * @param {number} end
	 * @returns
	 */
	loadFileArray = (reader, file, start, end) => {
		return new Promise((resolve, reject) => {
			reader.onload = e => resolve(e.target.result);
			reader.onerror = e => {
				if (e.type === 'error') {
					console.error('failed to load file');
				}
			};
			reader.readAsArrayBuffer(file.slice(start, end));
		});
	};

	/**
	 * setTimer을 사용한 슬립 함수.
	 * 사용하는 함수가 asyn여야 한다.
	 * @param {number} ms
	 * @returns
	 */
	sleep(ms) {
		return new Promise(r => setTimeout(r, ms));
	}

	getLocalStorage(key) {
		const item = localStorage.getItem(key);
		if (typeof item !== 'string') {
			return {};
		}

		try {
			const tmp = JSON.parse(item);

			if (tmp !== null && typeof tmp === 'object') {
				return tmp;
			}
			return {};
		} catch (e) {
			return {};
		}
	}

	setLocalStorage(key, value) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	/**
	 * 파일명의 확장자를 분석하여 무슨파일인지 확인한다.
	 *
	 * @param {*} fileName
	 * @returns
	 */
	isDocument(fileName) {
		const officeExtensions = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt', 'pdf'];
		const fileExtension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();

		return officeExtensions.includes(fileExtension);
	}

	isValidJSON(str) {
		if (typeof str !== 'string') {
			return false;
		}

		try {
			const tmp = JSON.parse(str);

			if (tmp !== null && typeof tmp === 'object') {
				return true;
			}
			return false;
		} catch (e) {
			return false;
		}
	}

	infoLog(msg) {
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, '0');
		const day = String(now.getDate()).padStart(2, '0');
		const hours = String(now.getHours()).padStart(2, '0');
		const minutes = String(now.getMinutes()).padStart(2, '0');
		const seconds = String(now.getSeconds()).padStart(2, '0');
		const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

		const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

		console.log(`[${timestamp}] ${msg}`);
	}
}
export default new CommonUtils();
