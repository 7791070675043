import ClientAccessLogPage from './ClientAccessLogPage';

const ClientAccessLogConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/clientAccessLogs',
			component: ClientAccessLogPage
		}
	]
};

export default ClientAccessLogConfig;
