import FuseAnimate from '@fuse/core/FuseAnimate';
import { Card, CardContent, Typography, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getClientRunningLogs, getClientRunningLogsPage } from 'app/store/docbase/admin/systemSlice';

const useStyles = makeStyles(theme => ({
	root: {
		background: `radial-gradient(${darken(theme.palette.primary.main, 0.5)} 0%, ${theme.palette.primary.main} 80%)`,
		color: theme.palette.primary.contrastText
	},
	mbLoginCardContent: { 'padding-bottom': '3.2rem!important' }
}));

function LogoutPage({ history }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation(['docbase', 'brand']);

	const [params, setParams] = React.useState({
		userID: 'soho',
		startdate: '2023-01-01 00:00:00',
		enddate: '2023-01-30 23:59:59',
		rows_per_page: 1000
	});

	const [page, setPage] = React.useState('1');
	useEffect(() => {}, [dispatch]);
	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimate animation="transition.expandIn">
					<Card className="w-full max-w-384 rounded-8">
						<CardContent className="flex flex-col items-center justify-center p-32">
							<img className="w-128" src="assets/brand/logo.svg" alt="logo" />

							<Typography variant="h6" className="mb-10 font-bold text-20 sm:text-24" color="primary">
								{t('brand:PRODUCT_NAME_ADMIN')}
							</Typography>
							<div>
								<TextField
									className="w-full"
									variant="outlined"
									type="text"
									name="name"
									fullWidth
									value={params.userID}
									onChange={e => setParams({ ...params, userID: e.target.value })}
								/>
								<TextField
									className="w-full"
									variant="outlined"
									type="text"
									name="startdate"
									fullWidth
									value={params.startdate}
									onChange={e => setParams({ ...params, startdate: e.target.value })}
								/>
								<TextField
									className="w-full"
									variant="outlined"
									type="text"
									name="enddate"
									fullWidth
									value={params.enddate}
									onChange={e => setParams({ ...params, enddate: e.target.value })}
								/>
								{/* <FormGroup row className="row">
									<FormControlLabel
										control={
											<Checkbox
												checked={params.omitServer}
												onChange={e => {
													setParams({ ...params, omitServer: !params.omitServer });
												}}
												name="omitServer"
											/>
										}
										label="서숮수집"
									/>
								</FormGroup> */}
								<Button
									onClick={e => {
										getClientRunningLogs(
											params.userID,
											params.startdate,
											params.enddate,
											params.rows_per_page
										).then(resp => {
											console.log(resp);
										});
									}}
								>
									로그요청
								</Button>
							</div>

							<Button
								onClick={e => {
									getClientRunningLogsPage().then(resp => {
										const date = new Date();
										console.log(date.toLocaleString());
										console.log(resp);
									});
								}}
							>
								상태 조회
							</Button>

							<div>
								<TextField
									className=""
									variant="outlined"
									type="text"
									name="includes"
									fullWidth
									value={page}
									onChange={e => setPage(e.target.value)}
								/>
								{/* <TextField
									className=""
									variant="outlined"
									type="text"
									name="excludes"
									fullWidth
									value={params1.excludes}
									onChange={e => setParams1({ ...params1, excludes: e.target.value })}
								/> */}
								<Button
									onClick={e => {
										getClientRunningLogsPage(page).then(resp => {
											console.log(resp);
										});
									}}
								>
									목록조회
								</Button>
							</div>
							{/* <div>
								<TextField
									className=""
									variant="outlined"
									type="text"
									name="page"
									fullWidth
									value={page}
									onChange={e => setPage(e.target.value)}
								/>
								<Button
									onClick={e => {
										getCollectedLogsPage(Number(page)).then(resp => {
											console.log(resp);
										});
									}}
								>
									데이터 호출
								</Button>
							</div> */}
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
	);
}

export default LogoutPage;
