import SetupInfoPage from './SetupInfoPage';

const SetupInfoPageConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/setup',
			component: SetupInfoPage
		}
	]
};

export default SetupInfoPageConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const UsersConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default UsersConfig;

*/
