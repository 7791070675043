/* eslint-disable no-await-in-loop */
import { makeStyles } from '@material-ui/core/styles';
import {
	Icon,
	Card,
	CardActions,
	CardContent,
	IconButton,
	Button,
	Tooltip,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Typography
} from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { getUserGroupMembers, registerUserGroupMember, removeUserGroupMember } from 'app/store/docbase/admin/dlpSlice';
import { showErrorMessage } from 'app/store/fuse/messageSlice';
import { setShowBackdrop } from 'app/store/docbase/admin/settingSlice';
import DLPGroupPermDialog from '../../component/DLPGroupPermDialog';
import PermIcon from '../../icon/PermIcon';

const useStyles = makeStyles(theme => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	cardInfo: {
		padding: '40px 30px 20px',
		'& .MuiCardActions-root': {
			padding: 0
		},
		'& .MuiCardContent-root': {
			padding: 0
		}
	},
	cardDetailInfo: {
		'margin-left': '10px',
		height: '48px',
		'line-height': '48px'
	},
	paperPerm: { marginTop: 30, padding: '20px 30px 0' },
	paperPermHeader: {
		padding: 0,
		display: 'flex',
		width: '100%',
		'&>div:first-child': {
			width: '100%',
			maxWidth: 534,
			marginRight: 10
		},
		'&>div:first-child .MuiAutocomplete-inputRoot': {
			height: 40
		},
		'&>div:last-child>button': {
			width: 140
		}
	},
	paperPermContent: { padding: 0, marginTop: 20 },
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 45,
			paddingRight: 30,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277,
		minWidth: 277
	},
	filedInput: {
		width: '100%',
		padding: '0 0 0 30px',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	},
	customInput: {
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 4,
		'&>input': {
			padding: '10.5px 14px'
		},
		'&.Mui-focused': {
			border: '2px solid rgba(0, 0, 0, 1)'
		},
		'&:hover': {
			border: '1px solid rgba(0, 0, 0, 1)'
		}
	},
	manage_type: {
		display: 'inline-flex',
		flex: '0 0 auto'
	},
	manage_priority: {
		width: '24px',
		height: '24px',
		'& .MuiSelect-root': {
			'padding-right': '0',
			'padding-bottom': '4px'
		},
		'& svg': {
			display: 'none'
		}
	},
	select: {
		width: '100%'
	},
	inputRight: {
		'& input': {
			'text-align': 'right'
		}
	},
	buttonGroup: {
		marginTop: 30,
		'& button': { marginRight: 10 }
	},
	h_29: { height: '29px' }
}));

function DetailSidebarContent(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const permList = React.useRef();
	const { t } = useTranslation('docbase');
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const [showPermDialog, setShowPermDialog] = React.useState(false);
	const [perms, setPerms] = React.useState([]);
	const [permsObj, setPermsObj] = React.useState({});
	React.useEffect(() => {
		if (innerHeight >= 754) {
			permList.current.style.height = `${innerHeight - 140}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {
		if (props.selectedID > 0) {
			getUserGroupMembers(props.selectedID).then(respObj => {
				setPerms(respObj.users);
				setPermsObj(respObj.usersObj);
			});
		} else {
			setPerms([]);
		}
	}, [props.selectedID]);

	function registerPerm(users) {
		dispatch(setShowBackdrop({ show: true }));
		(async () => {
			let isSuc = true;
			for (let i = 0; i < users.length; i++) {
				const resp = await registerUserGroupMember(props.selectedID, users[i]);
				if (!resp) {
					isSuc = resp;
				}
			}

			if (isSuc) {
				getUserGroupMembers(props.selectedID).then(respObj => {
					setPerms(respObj.users);
					setPermsObj(respObj.usersObj);
				});
			}
			setShowPermDialog(false);
			dispatch(setShowBackdrop({ show: false }));
		})();
	}

	function releasePerm(userID) {
		dispatch(setShowBackdrop({ show: true }));
		removeUserGroupMember(props.selectedID, userID).then(ret => {
			getUserGroupMembers(props.selectedID).then(respObj => {
				setPerms(respObj.users);
				setPermsObj(respObj.usersObj);
				dispatch(setShowBackdrop({ show: false }));
			});
		});
	}

	return (
		<div className="" ref={permList}>
			<DLPGroupPermDialog
				open={showPermDialog}
				permsObj={permsObj}
				fnHandleClose={() => setShowPermDialog(false)}
				fnSetPerm={users => {
					registerPerm(users);
				}}
			/>

			<Card className={classes.cardInfo}>
				<CardActions disableSpacing>
					<IconButton
						className="md:hidden"
						aria-controls="detail-back"
						onClick={e => {
							props.fnToggleRightSidebar();
						}}
					>
						<Icon>arrow_back</Icon>
					</IconButton>
					<Typography className={clsx(classes.cardDetailInfo, 'noto-bold')} variant="h6">
						<span>{t('DETAIL_INFO')}</span>
						<span>{`[${props.selectedObj.name}]`}</span>
					</Typography>
				</CardActions>
				<CardContent className={classes.infoFiled} />
				<CardActions className={clsx(classes.buttonGroup, 'block')}>
					<div className="w-full flex">
						<div className={clsx('inline-block align-middle')}>
							<Button
								variant="contained"
								className="default"
								type="button"
								fullWidth
								size="small"
								onClick={() => {
									if (props.selectedID < 0) {
										dispatch(showErrorMessage({ message: t('그룹을 선택해 주세요') }));
									} else {
										props.fnOpenDLPPolicy();
									}
								}}
							>
								{t('그룹 정책 설정')}
							</Button>
						</div>
					</div>
				</CardActions>
			</Card>

			<Card className={classes.paperPerm}>
				<CardContent className={classes.paperPermHeader}>
					<div>
						<Button
							variant="contained"
							color="secondary"
							className=""
							type="button"
							fullWidth
							onClick={() => {
								if (props.selectedID <= 0) {
									dispatch(showErrorMessage({ message: t('그룹을 선택해 주세요') }));
								} else {
									setShowPermDialog(true);
								}
							}}
						>
							{t('사용자 추가')}
						</Button>
					</div>
				</CardContent>

				<CardContent className={classes.paperPermContent}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								<TableCell padding="none" align="left" className="left title">
									{t('USER')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-1">
									{t('삭제')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{perms && perms.length > 0 ? (
								perms.map((perm, idx) => {
									return (
										<TableRow
											className="cursor-pointer"
											hover
											role="checkbox"
											tabIndex={-1}
											// key={d.user_id}
											// onClick={event => handleCheck(event, d.user_id)}
										>
											<TableCell className="left" align="left" component="td" scope="row">
												<div>
													<PermIcon type="person" className="" />
													<span className="folder-name">{`${perm.user_name}(${perm.user_id})`}</span>
												</div>
											</TableCell>
											<TableCell className="" component="td" scope="row" align="center">
												<Tooltip title={t('PERM_DELETE')} arrow>
													<IconButton aria-label="releasePerm" onClick={e => releasePerm(perm.user_id)}>
														<PermIcon type="delete" className="" />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									);
								})
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</div>
	);
}

export default DetailSidebarContent;
