import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { IconButton, Dialog, DialogContent, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import commonUtils from 'app/utils/CommonUtils';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});
const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 1208
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 60,
			paddingRight: 30,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277,
		minWidth: 277
	},
	filedInput: {
		width: '100%',
		padding: '0 0 0 30px',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	}
}));

export default function ViewDialog(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');

	let strTo = '';
	let strCC = '';
	let strSubject = '';
	let strContents = '';
	if (props.logObj.log_type === 16) {
		const logContents = JSON.parse(props.logObj.log);
		const tmp = JSON.parse(logContents.form_elements);

		if (tmp.to)
			tmp.to.forEach((obj, idx) => {
				if (idx > 0) strTo += `, ${obj}`;
				else strTo += obj;
			});
		if (tmp.toList)
			tmp.toList.forEach((obj, idx) => {
				if (idx > 0) strTo += `, ${obj.addr}`;
				else strTo += obj.addr;
			});
		if (tmp.cc)
			tmp.cc.forEach((obj, idx) => {
				if (idx > 0) strCC += `, ${obj}`;
				else strCC += obj;
			});
		if (tmp.ccList)
			tmp.ccList.forEach((obj, idx) => {
				if (idx > 0) strCC += `, ${obj.addr}`;
				else strCC += obj.addr;
			});
		if (tmp.subject) {
			if (Array.isArray(tmp.subject)) {
				tmp.subject.forEach((obj, idx) => {
					if (idx > 0) strSubject += `, ${obj}`;
					else strSubject += obj;
				});
			} else {
				strSubject = tmp.subject;
			}
		}
		if (tmp.body)
			tmp.body.forEach((obj, idx) => {
				if (idx > 0) strContents += `, ${obj}`;
				else strContents += obj;
			});
		if (tmp.contents) strContents = tmp.contents;
	}

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('DLP_CLIENT_LOG_VIEW_TITLE')}
				</DialogTitle>
				<DialogContent className={classes.infoFiled}>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_LOGGED_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.logged_at ? commonUtils.getDateStringToLocalDate(props.logObj.logged_at) : ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_USERNAME')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.user_name
								? `${props.logObj.user_name}(${props.logObj.user_id}-${props.logObj.client_id})`
								: ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_TYPE')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.tagName || ''}</div>
					</div>

					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PATH')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.list_path || ''}</div>
					</div>
					{props.logObj.log_type === 1 || props.logObj.log_type <= 10 ? (
						<>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_SIGNING_ID')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.signing_id || ''}</div>
							</div>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_HASH')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.hash || ''}</div>
							</div>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_SOURCE_PATH')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.source_path || ''}</div>
							</div>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_DEST_PATH')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.dest_path || ''}</div>
							</div>
						</>
					) : (
						<></>
					)}

					{props.logObj.log_type === 11 ? (
						<>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_LOCAL_ENDPOINT')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.local_endpoint || ''}</div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_REMOTE_ENDPOINT')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>
									{props.logObj.remote_endpoint || ''}
								</div>
							</div>
						</>
					) : (
						<></>
					)}

					{props.logObj.log_type === 12 || props.logObj.log_type === 13 || props.logObj.log_type === 16 ? (
						<>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('URL')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.url || ''}</div>
							</div>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_WEB_UPLOAD_FILE')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.files || ''}</div>
							</div>
						</>
					) : (
						<></>
					)}

					{props.logObj.log_type === 16 ? (
						<>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_MAIL_RECIEVER')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{strTo}</div>

								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_MAIL_CC')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{strCC}</div>
							</div>

							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_MAIL_TITLE')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{strSubject}</div>
							</div>
							<div>
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_MAIL_CONTENTS')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>{strContents}</div>
							</div>
						</>
					) : (
						<></>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
}
