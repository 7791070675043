import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TablePagination, TextField, Tooltip, IconButton, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CustomIcon from 'app/main/icon/CustomIcon';
import excelUtils from 'app/utils/ExcelUtils';
import commonUtils from 'app/utils/CommonUtils';
import LogsTableHead from './LogsTableHead';
import AccessTypeModal from './AccessTypeModal';

const useStyles = makeStyles(theme => ({
	datePicker: {
		'& .MuiOutlinedInput-notchedOutline': { display: 'none' },
		'& input': {
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 6,
			width: 130,
			textAlign: 'center',
			padding: '9.5px 0'
		},
		'& .MuiOutlinedInput-root.Mui-focused input': {
			boxShadow: '0 0 0 2px #000 inset'
		},
		'& .MuiButtonBase-root': {
			background: '#eceff4',
			borderRadius: 6,
			border: '1px solid #d9dee5',
			padding: 7
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	},
	contentWrapper: {
		height: `${window.innerHeight - 296}px`,
		padding: '0 20px'
	}
}));

const accessType = [
	{ type: 0, cons: 'ALL' },
	{ type: 1, cons: 'ACCESS_TYPE_CREATE' },
	{ type: 2, cons: 'ACCESS_TYPE_READ' },
	{ type: 3, cons: 'ACCESS_TYPE_MOVE' },
	{ type: 4, cons: 'ACCESS_TYPE_UPDATE' },
	{ type: 5, cons: 'ACCESS_TYPE_HOLD' },
	{ type: 6, cons: 'ACCESS_TYPE_REMOVE' },
	{ type: 7, cons: 'ACCESS_TYPE_REMOVE_TRASH' },
	{ type: 8, cons: 'ACCESS_TYPE_RECOVERY_TRASH' },
	{ type: 9, cons: 'ACCESS_TYPE_PURGE_TRASH' },
	{ type: 10, cons: 'ACCESS_TYPE_VIEWING' },
	{ type: 11, cons: 'ACCESS_TYPE_WEB_CREATE' },
	{ type: 12, cons: 'ACCESS_TYPE_WEB_DOWNLOAD' },
	{ type: 13, cons: 'ACCESS_TYPE_COLLECT_FILE' }
];

const accessTypeObj = {
	0: 'ALL',
	1: 'ACCESS_TYPE_CREATE',
	2: 'ACCESS_TYPE_READ',
	3: 'ACCESS_TYPE_MOVE',
	4: 'ACCESS_TYPE_UPDATE',
	5: 'ACCESS_TYPE_HOLD',
	6: 'ACCESS_TYPE_REMOVE',
	7: 'ACCESS_TYPE_REMOVE_TRASH',
	8: 'ACCESS_TYPE_RECOVERY_TRASH',
	9: 'ACCESS_TYPE_PURGE_TRASH',
	10: 'ACCESS_TYPE_VIEWING',
	11: 'ACCESS_TYPE_WEB_CREATE',
	12: 'ACCESS_TYPE_WEB_DOWNLOAD',
	13: 'ACCESS_TYPE_COLLECT_FILE'
};

function LogsTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const { t } = useTranslation('docbase');

	const { logs } = useSelector(({ docbase }) => docbase.admin.system.accessLogObj);

	const [order, setOrder] = React.useState({
		id: 'user_id',
		direction: 'asc'
	});

	const [rowsPerPage, setRowsPerPage] = React.useState(100);
	const [currentPage, setCurrentPage] = React.useState(0);

	const [openTypeModal, setOpenTypeModal] = React.useState(false);
	const [selectedTypes, setSelectedTypes] = React.useState('');
	const [selectedText, setSelectedText] = React.useState('');
	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 296}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {}, [dispatch]);

	if (props.loading) {
		return <FuseLoading />;
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<AccessTypeModal
				open={openTypeModal}
				accessType={accessType}
				selectedTypes={selectedTypes}
				fnHandleClose={() => setOpenTypeModal(false)}
				fnHandleOK={types => {
					let sts = '';
					let stt = '';
					if (types.indexOf('0') > -1) {
						stt = t('ALL');
						sts = '';
					} else {
						types.forEach((type, idx) => {
							if (idx !== 0) {
								sts += ',';
								stt += ',';
							}
							sts += type;
							stt += t(accessTypeObj[Number(type)]);
						});
					}
					setSelectedTypes(sts);
					setSelectedText(stt);
					props.fnSetSearchObj('accessType', sts);
					setOpenTypeModal(false);
				}}
			/>

			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="total-cnt-info">
						<span>
							{t('TOTAL')} <span className="count">{logs.length}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div className="">
							<span className="title">{t('ACCESS_TYPE')}</span>
							{/* <TextField
								select
								className="select"
								value={props.searchObj.accessType}
								fullWidth
								variant="outlined"
								size="small"
								onChange={e => {
									props.fnSetSearchObj('accessType', e.target.value);
								}}
							>
								{accessType.map(at => (
									<MenuItem value={at.type}>{t(at.cons)}</MenuItem>
								))}
							</TextField> */}
							<TextField
								onClick={e => setOpenTypeModal(true)}
								name="modal"
								className="align-middle"
								variant="outlined"
								siae="small"
								InputProps={{
									readOnly: true
								}}
								value={selectedText !== '' ? selectedText : t('ALL')}
							/>
						</div>
						<div className="date-picker-wrapper">
							<span className="title">{t('RESTORE_DAY')}</span>
							<div className="">
								<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
									<KeyboardDatePicker
										className={classes.datePicker}
										fullWidth
										disableToolbar
										invalidDateMessage=""
										autoOk
										size="small"
										variant="inline"
										inputVariant="outlined"
										format="yyyy/MM/dd"
										id="start_date"
										maxDate={new Date('2999-12-31')}
										value={props.searchObj.startDate}
										onChange={d => {
											// eslint-disable-next-line no-restricted-globals
											if (!isNaN(d.getTime())) {
												if (props.searchObj.startDate.getTime() !== d.getTime())
													props.fnSetSearchObj('startDate', d);
											}
										}}
										// onBlur={e => {
										// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
										// 	if (!isNaN(val.getTime()) && props.searchObj.startDate.getTime() !== val.getTime())
										// 		props.fnSetSearchObj('startDate', val);
										// }}
										KeyboardButtonProps={{
											'aria-label': 'change date start'
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
							<div className="tilde">~</div>
							<div className="">
								<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
									<KeyboardDatePicker
										className={classes.datePicker}
										fullWidth
										disableToolbar
										invalidDateMessage=""
										autoOk
										size="small"
										variant="inline"
										inputVariant="outlined"
										format="yyyy/MM/dd"
										id="end_date"
										maxDate={new Date('2999-12-31')}
										value={props.searchObj.endDate}
										onChange={d => {
											// eslint-disable-next-line no-restricted-globals
											if (!isNaN(d.getTime())) {
												if (props.searchObj.endDate.getTime() !== d.getTime())
													props.fnSetSearchObj('endDate', d);
											}
										}}
										// onBlur={e => {
										// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
										// 	if (!isNaN(val.getTime()) && props.searchObj.endDate.getTime() !== val.getTime())
										// 		props.fnSetSearchObj('endDate', val);
										// }}
										KeyboardButtonProps={{
											'aria-label': 'change date end'
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
						</div>
						<div className="">
							<Tooltip title={t('REFRESH')} placement="bottom">
								<IconButton
									key="refresh"
									aria-label="refresh"
									color="secondary"
									onClick={() => props.fnDoSearch()}
								>
									<CustomIcon className="small" type="refresh" fill="#333d4b" />
								</IconButton>
							</Tooltip>

							<Tooltip title={t('EXCEL_DOWNLOAD')} placement="bottom">
								<IconButton
									key="download"
									aria-label="download"
									color="secondary"
									onClick={() => {
										// props.fnDoSearch();
										const titles = [t('DIVISION'), t('EXISTING_FILE'), t('NEW_FILE'), t('USER'), t('DATE')];
										const contents = [];
										const colSizes = [{ wpx: 80 }, { wpx: 400 }, { wpx: 400 }, { wpx: 150 }, { wpx: 150 }];
										logs.forEach(log => {
											contents.push({
												access_type: props.fnCheckAccessType(log.access_type),
												path: log.path,
												dest_path: log.dest_path,
												user: `${log.user_name}(${log.user_id})`,
												accessed_at: commonUtils.getDateStringToLocalDate(log.accessed_at)
											});
										});
										excelUtils.excelDownload(
											`${t('FILE_LOG_EXCEL')}${commonUtils.getDateString(
												props.searchObj.startDate
											)}-${commonUtils.getDateString(props.searchObj.endDate)}`,
											titles,
											contents,
											colSizes
										);
									}}
								>
									<CustomIcon className="small" type="download" fill="#333d4b" />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<LogsTableHead
							order={order}
							searchObj={props.searchObj}
							rowCount={logs.length}
							fnSearch={(path, operator) => {
								if (props.searchObj.path !== path) {
									props.fnSetSearchObj('path', path);
								}
								if (props.searchObj.operator !== operator) {
									props.fnSetSearchObj('operator', operator);
								}
							}}
						/>
						<TableBody>
							{logs.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((log, idx) => {
								return (
									<TableRow className="cursor-default" role="checkbox" tabIndex={-1} key={log.id}>
										<TableCell className="left" align="left" component="td" scope="row">
											{props.fnCheckAccessType(log.access_type)}
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											<div>
												<p className="log-name">{commonUtils.splitLastDelimiterString(log.path)}</p>
												<p className="log-path">{commonUtils.splitPreDelimiter(log.path)}</p>
											</div>
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											<Tooltip title={log.dest_path} arrow>
												<span>{commonUtils.splitLastDelimiterString(log.dest_path)}</span>
											</Tooltip>
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											{`${log.user_name}(${log.user_id})`}
										</TableCell>

										<TableCell className="" align="center" component="td" scope="row">
											{commonUtils.getDateStringToLocalDate(log.accessed_at)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>
				<TablePagination
					className="flex-shrink-0"
					component="div"
					count={logs.length}
					rowsPerPage={rowsPerPage}
					page={currentPage}
					labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
					labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
					backIconButtonProps={{
						'aria-label': t('PAGE_PREV_BTN_LABEL')
					}}
					nextIconButtonProps={{
						'aria-label': t('PAGE_NEXT_BTN_LABEL')
					}}
					onChangePage={(e, value) => {
						setCurrentPage(value);
					}}
					onChangeRowsPerPage={e => {
						setRowsPerPage(Number(e.target.value));
						setCurrentPage(0);
					}}
				/>
			</Paper>
		</div>
	);
}

export default LogsTable;
