import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .user': {
			minWidth: '200px',
			width: '200px',
			maxWidth: '200px'
		},
		'& .type': {
			minWidth: '110px',
			width: '110px',
			maxWidth: '110px'
		},
		'& .path': {
			minWidth: '300px',
			width: '300px',
			maxWidth: '300px'
		},
		'& .identifier': {
			minWidth: '250px',
			width: '250px',
			maxWidth: '250px'
		}
	}
}));
function PrintLogTableHead(props) {
	const { t } = useTranslation('docbase');

	const headerCols = [
		{
			id: 'logged_at',
			align: 'center',
			className: 'table_col_date',
			disablePadding: false,
			label: t('DLP_CLIENT_LOG_LOGGED_AT'),
			sort: false
		},
		{
			id: 'user',
			align: 'left',
			className: 'left user',
			disablePadding: false,
			label: t('USER'),
			sort: false
		},
		{
			id: 'log_type',
			align: 'center',
			className: ' type',
			disablePadding: false,
			label: t('DLP_CLIENT_LOG_PRINT_DOC_FORMAT'),
			sort: false
		},
		{
			id: 'name',
			align: 'center',
			className: 'path',
			disablePadding: false,
			label: t('DLP_CLIENT_LOG_PRINT_JOB_NAME'),
			sort: false
		},
		{
			id: 'dest_path',
			align: 'center',
			className: '',
			disablePadding: false,
			label: t('DLP_CLIENT_LOG_PRINT_COPIES'),
			sort: false
		},
		{
			id: 'created_at',
			align: 'center',
			className: 'table_col_date',
			disablePadding: false,
			label: t('DLP_CLIENT_LOG_PRINT_JOB_CREATED_AT'),
			sort: false
		},
		{
			id: 'processing_at',
			align: 'center',
			className: 'table_col_date',
			disablePadding: false,
			label: t('DLP_CLIENT_LOG_PRINT_JOB_PROCESSING_AT'),
			sort: false
		},
		{
			id: 'completed_at',
			align: 'center',
			className: 'identifier',
			disablePadding: false,
			label: t('DLP_CLIENT_LOG_PRINT_JOB_COMPLETED_AT'),
			sort: false
		},
		{
			id: 'view',
			align: 'center',
			className: '',
			disablePadding: false,
			label: t('DLP_CLIENT_LOG_VIER'),
			sort: false
		}
	];

	return (
		<TableHead>
			<TableRow>
				{/* <TableCell padding="none" className="w-40 md:w-64 text-center z-99">
					<Checkbox
						color="secondary"
						size="small"
						indeterminate={numSelected > 0 && numSelected < props.rowCount}
						checked={props.rowCount !== 0 && numSelected === props.rowCount}
						onChange={props.onSelectAllClick}
					/>
				</TableCell> */}

				{headerCols.map(row => {
					return (
						<TableCell
							className={row.className}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
						>
							{row.label}
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default PrintLogTableHead;
