import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

function OfflineDriveTableHead(props) {
	const { t } = useTranslation('docbase');

	const headerCols = [
		{
			id: 'requester',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('TAKEOUT_REQUESTER'),
			sort: false
		},
		{
			id: 'requested_at',
			align: 'center',
			className: 'table_col_date',
			disablePadding: false,
			label: t('TAKEOUT_REQ_AT'),
			sort: false
		},
		{
			id: 'status',
			align: 'center',
			className: '',
			disablePadding: false,
			label: t('TAKEOUT_STATUS'),
			sort: false
		},
		{
			id: 'request_type',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('TAKEOUT_REASON_REQ'),
			sort: false
		},
		{
			id: 'status',
			align: 'center',
			className: 'table_col_date',
			disablePadding: false,
			label: t('EXPIRE_DATE'),
			sort: false
		}
	];

	return (
		<TableHead>
			<TableRow>
				{headerCols.map(row => {
					return (
						<TableCell
							className={row.className}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={false}
						>
							{row.label}
						</TableCell>
					);
				}, this)}
				<TableCell className="" padding="none" sortDirection={false} />
			</TableRow>
		</TableHead>
	);
}

export default OfflineDriveTableHead;
