/* eslint-disable jsx-a11y/label-has-associated-control */
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
	LinearProgress,
	Icon,
	Input
} from '@material-ui/core';
import _ from '@lodash';
import commonUtils from 'app/utils/CommonUtils';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import CapacityDialog from '../CapacityDialog';

const useStyles = makeStyles(theme => ({
	root: {
		boxShadow: 'none',
		'& .favorite-checkbox': {
			display: 'none'
		},

		'& .favorite-checkbox + label': {
			fontSize: '1.5em',
			cursor: 'pointer',
			color: ' #ccc'
		},

		'& .favorite-checkbox:checked + label': {
			color: 'gold'
		},

		'& .favorite-checkbox + label:before': {
			content: '"☆"'
		},

		'& .favorite-checkbox:checked + label:before': {
			content: '"★"'
		}
	},
	capacityWrapper: {
		display: 'inline-block',
		position: 'relative',
		height: '28px',
		width: '240px',
		background: '#eceff4',
		border: '1px solid #d9dee5',
		verticalAlign: 'middle',
		borderRadius: 6,
		'& .size-bar': {
			position: 'absolute',
			height: '100%',
			background: '#00cfe2',
			'border-radius': '6px'
		},
		'& .text-bar': {
			position: 'absolute',
			width: '100%',
			color: '#000000',
			'text-align': 'center',
			'line-height': '28px'
		},
		'&.warning .size-bar': {
			background: '#fa4b60'
		},
		'&.warning .text-bar': {
			color: '#ffffff'
		}
	}
}));

function UnitListWidget(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');
	const dispatch = useDispatch();

	const [capacityObj, setCapacityObj] = React.useState({ open: false, capacity: 0, title: '', path: '' });
	const [searchText, setSearchText] = React.useState('');
	const [data, setData] = React.useState(props.data.row);
	const [order, setOrder] = React.useState({
		id: '0',
		direction: 'asc'
	});

	React.useEffect(() => {
		if (searchText !== '') {
			setData(props.data.rows.filter(folder => folder.name.toLowerCase().includes(searchText.toLowerCase())));
		} else {
			setData(props.data.rows);
		}
	}, [props.data, searchText]);

	const createSortHandler = property => event => {
		const id = property;
		let direction = 'desc';

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	};

	return (
		<Paper className={clsx('mx-auto w-full print:p-8 print:shadow-none rounded-8 relative', classes.root)}>
			<div className="table-search-wrapper">
				<div className="">
					<div className="input-item">
						<Input
							placeholder="결과 내 검색"
							className="search-input"
							disableUnderline
							fullWidth
							value={searchText}
							inputProps={{
								'aria-label': 'Search'
							}}
							onChange={ev => setSearchText(ev.target.value)}
						/>
					</div>
				</div>
				<div className="total-cnt-info">
					<span className="pipe">|</span>
					<span>
						{t('TOTAL')} <span className="count">{props.data.rows.length}</span>
						{t('CNT')}
					</span>
				</div>
			</div>
			{props.isLoading ? (
				<div className="flex flex-1 flex-col items-center justify-center h-500">
					<Typography className="text-20 mb-16" color="textSecondary">
						Loading...
					</Typography>
					<LinearProgress className="w-xs max-w-full" color="secondary" />
				</div>
			) : (
				<div className="">
					<CapacityDialog
						open={capacityObj.open}
						title={capacityObj.title}
						capacity={capacityObj.capacity / 1024 / 1024}
						path={capacityObj.path}
						fnHandleClose={() => {
							setCapacityObj({ open: false, capacity: 0, title: '', path: '' });
							props.fnMakeUnitList();
						}}
					/>
					<Table stickyHeader className="w-full min-w-full">
						<TableHead>
							<TableRow>
								<TableCell className="" align="center">
									<span />
								</TableCell>
								{props.data.titles.map((title, idx) => {
									return (
										<TableCell
											className={idx === 0 || idx === 3 ? 'left' : ''}
											align={idx === 0 || idx === 3 ? 'left' : 'center'}
											sortDirection={
												idx === 3 ? false : order.id === idx.toString() ? order.direction : false
											}
										>
											{idx === 3 ? (
												<span>{title}</span>
											) : (
												<TableSortLabel
													active={order.id === idx.toString()}
													direction={order.direction}
													onClick={createSortHandler(idx.toString())}
												>
													{title}
												</TableSortLabel>
											)}
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{_.orderBy(
								data,
								[
									d => {
										switch (order.id) {
											case '0': {
												return d.name;
											}
											case '1': {
												return d.used.size;
											}
											case '2': {
												return d.used.trash_size;
											}
											default: {
												return d[order.id];
											}
										}
									}
								],
								[order.direction]
							).map((row, idx) => {
								let trashPercent = 0;
								if (row.used.trash_size > 0) {
									trashPercent = (row.used.trash_size / row.used.size) * 100;
								}
								return (
									<TableRow key={idx}>
										<TableCell align="center" style={{ width: 30 }}>
											<input
												type="checkbox"
												id={`favorite-${row.used.fid}`}
												checked={Object.keys(props.selectedInfo).includes(row.used.fid.toString())}
												onChange={() => {
													props.fnSelectedInfo(row);
												}}
												className="favorite-checkbox"
											/>
											<label htmlFor={`favorite-${row.used.fid}`} className="favorite-label" />
										</TableCell>
										<TableCell className="left">{row.name}</TableCell>
										<TableCell align="center" className="cell-size">
											<div
												className={clsx(
													classes.capacityWrapper,
													row.used.usage_percent > 90 ? 'warning' : ''
												)}
											>
												<div
													className="size-bar"
													style={{
														width: `${
															row.used.usage_percent > 1
																? row.used.usage_percent > 100
																	? 100
																	: row.used.usage_percent
																: 0
														}%`
													}}
												/>
												<div className="text-bar">
													<span>
														{`${commonUtils.changeByteToString(
															row.used.size <= 0 ? 0 : row.used.size
														)} ${
															row.used.capacity === 0
																? '(∞)'
																: ` / ${commonUtils.changeByteToString(row.used.capacity)}`
														}${
															row.used.capacity === 0
																? ''
																: `(${row.used.usage_percent.toFixed(2)}%)`
														}`}
													</span>
												</div>
											</div>
										</TableCell>
										<TableCell align="center" className="cell-size">
											<div className={clsx(classes.capacityWrapper)}>
												<div
													className="size-bar"
													style={{
														width: `${
															trashPercent > 1 ? (trashPercent > 100 ? 100 : trashPercent) : 0
														}%`
													}}
												/>
												<div className="text-bar">
													<span>
														{`${commonUtils.changeByteToString(
															row.used.trash_size <= 0 ? 0 : row.used.trash_size
														)} / ${commonUtils.changeByteToString(
															row.used.size
														)} (${trashPercent.toFixed(2)}%)
														`}
													</span>
												</div>
											</div>
										</TableCell>
										<TableCell align="center" className="cell-setting">
											{row.changed ? (
												<Icon
													className="align-middle cursor-default"
													onClick={() => {
														dispatch(showMessage({ message: t('CHANGE_CAPACITY_MESSAGE_4') }));
													}}
												>
													more_horiz
												</Icon>
											) : (
												<Icon
													className="align-middle cursor-pointer"
													onClick={() => {
														setCapacityObj({
															open: true,
															capacity: row.used.capacity,
															title: row.name,
															path: row.used.path
														});
													}}
												>
													settings
												</Icon>
											)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			)}
		</Paper>
	);
}

export default React.memo(UnitListWidget);
