import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, IconButton, Tooltip, Paper, Tabs, Tab, Button } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
	getWhiteProcessList,
	getWhiteURLList,
	unregisterWhiteProcess,
	unregisterWhiteURL,
	registerWhiteURL,
	approveRegisterWhiteProcess
} from 'app/store/docbase/admin/settingSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import commonUtils from 'app/utils/CommonUtils';
import CustomIcon from 'app/main/icon/CustomIcon';
import WhiteProcessesTableHead from './WhiteProcessesTableHead';
import WhiteURLTableHead from './WhiteURLTableHead';
import OpenDialogForm from '../component/OpenDialogForm';
import RegisterWhiteURLModal from './RegisterWhiteURLModal';

const useStyles = makeStyles(theme => ({
	addIcon: {
		top: '25px'
	},
	deleteIcon: {
		color: '#ff6565'
	},
	tabsBar: {
		height: 50,
		color: '#192141',
		background: 'none',
		boxShadow: 'none',
		'& .MuiTab-root': {
			padding: 0,
			minWidth: 136,
			fontSize: 16
		},
		'& .MuiTab-textColorInherit': { opacity: 1 },
		'& .MuiTab-textColorInherit.Mui-selected': {
			color: '#00cfe2',
			background: '#fff',
			borderRadius: '6px 6px 0 0'
		}
	},
	contentWrapper: {
		height: `${window.innerHeight - 274}px`,
		padding: '0 20px'
	},
	tr: {
		height: '53px'
	}
}));

function WhiteProcessesTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
	const { t } = useTranslation('docbase');

	const { whiteProcessList, whiteURLList } = useSelector(({ docbase }) => docbase.admin.setting);
	const [tabValue, setTabValue] = React.useState(0);
	const [showRegisterURLModal, setShowRegisterURLModal] = React.useState(false);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 274}px`;
		}

		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		window.addEventListener('resize', () => setInnerWidth(window.innerWidth));
	}, [innerWidth]);

	useEffect(() => {
		props.setLoading(true);
		dispatch(getWhiteProcessList()).then(() => {
			dispatch(getWhiteURLList());
			props.setLoading(false);
		});
	}, [dispatch]);

	function handleRowClick(procObj) {
		// props.fnToggleRightSidebar();
	}

	function deleteConfirm(e, name) {
		e.stopPropagation();
		unregisterWhiteProcess(name)
			.then(resp => {
				dispatch(getWhiteProcessList());
				dispatch(showMessage({ message: t('WHITE_PROCESS_MESSAGE_1') }));
			})
			.catch(err => {
				dispatch(showErrorMessage({ message: t('WHITE_PROCESS_ERROR_MESSAGE_1') }));
			});
	}

	function deleteWhiteURLConfirm(e, name) {
		e.stopPropagation();
		unregisterWhiteURL(name)
			.then(resp => {
				dispatch(getWhiteURLList());
				dispatch(showMessage({ message: t('WHITE_PROCESS_MESSAGE_2') }));
			})
			.catch(err => {
				dispatch(showErrorMessage({ message: t('WHITE_PROCESS_ERROR_MESSAGE_2') }));
			});
	}

	function doApprove(id, approve) {
		approveRegisterWhiteProcess(id, approve)
			.then(resp => {
				dispatch(getWhiteProcessList());
				dispatch(showMessage({ message: approve ? t('APPROVED') : t('REJECTED') }));
			})
			.catch(err => {
				dispatch(showErrorMessage({ message: t('FAILED') }));
			});
	}

	if (props.loading) {
		return <FuseLoading />;
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<RegisterWhiteURLModal
				open={showRegisterURLModal}
				fnHandleClose={() => {
					setShowRegisterURLModal(false);
				}}
				fnHandleChange={(name, type, url) => {
					registerWhiteURL(name, type, url)
						.then(resp => {
							dispatch(getWhiteURLList());
							dispatch(showMessage({ message: t('WHITE_PROCESS_MESSAGE_3') }));
							setShowRegisterURLModal(false);
						})
						.catch(e => {
							dispatch(showErrorMessage({ message: t('WHITE_PROCESS_ERROR_MESSAGE_5') }));
						});
				}}
			/>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="total-cnt-info">
						<span>
							{t('TOTAL')} <span className="count">{whiteProcessList.length}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div>
							<Button
								variant="contained"
								color="secondary"
								className=""
								type="button"
								onClick={e => {
									setShowRegisterURLModal(true);
								}}
							>
								{t('URL_REGISTRATION')}
							</Button>
						</div>
						<div className="">
							<Tooltip title={t('REFRESH')} placement="bottom">
								<IconButton
									key="refresh"
									aria-label="refresh"
									color="secondary"
									onClick={() => {
										props.setLoading(true);
										dispatch(getWhiteProcessList()).then(() => {
											props.setLoading(false);
										});
									}}
								>
									<CustomIcon className="small" type="refresh" fill="#333d4b" />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</div>
				<div className="type-tabs">
					<Tabs
						value={tabValue}
						onChange={(ev, val) => {
							setTabValue(val);
						}}
						indicatorColor="secondary"
						textColor="inherit"
						variant="scrollable"
						scrollButtons="off"
						classes={{ indicator: 'active_bar' }}
					>
						<Tab className="list-tab" disableRipple label={t('PROCESS')} />
						<Tab className="list-tab" disableRipple label={t('URL')} />
					</Tabs>
				</div>

				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					{tabValue === 0 ? (
						<Table stickyHeader className="" aria-labelledby="tableTitle">
							<WhiteProcessesTableHead />

							<TableBody>
								{whiteProcessList.map(p => {
									let processType = '';
									if (p.process_type === 1) {
										processType = t('WHITE_PROGRAM');
									} else if (p.process_type === 2) {
										processType = t('WHITE_SYSTEM_PROCESS');
									} else if (p.process_type === 3) {
										processType = t('WHITE_CHROME_BROWSER');
									} else if (p.process_type === 4) {
										processType = t('WHITE_RANSOMWARE');
									}
									return (
										<TableRow
											className={clsx(classes.tr, 'cursor-default')}
											hover
											role="checkbox"
											tabIndex={-1}
											key={p.id}
											onClick={event => handleRowClick(p)}
										>
											<TableCell className="left" align="left" component="td" scope="row">
												{p.name}
											</TableCell>

											<TableCell className="left" align="left" component="td" scope="row">
												{p.program_name}
											</TableCell>

											<TableCell className="left" component="td" scope="row">
												{p.verify_type === 1
													? t('WHITE_PROCESS_VERIFY_HASH')
													: t('WHITE_PROCESS_VERIFY_DIGITAL')}
											</TableCell>

											<TableCell className="left" align="left" component="td" scope="row">
												{processType}
											</TableCell>

											<TableCell className="" align="center" component="td" scope="row">
												{!p.approved ? (
													<div>
														<Tooltip title={t('TAKEOUT_APPROVED')} arrow>
															<IconButton
																onClick={e => {
																	dispatch(
																		openDialog({
																			children: (
																				<OpenDialogForm
																					title={t('PROCESS_APPROVE_TITLE')}
																					content={t('PROCESS_APPROVE_CONTENT').replace(
																						'%s',
																						t('TAKEOUT_APPROVED')
																					)}
																					fnHandleOK={() => {
																						doApprove(p.id, true);
																					}}
																				/>
																			)
																		})
																	);
																}}
															>
																<CustomIcon type="stamper" className="" fill="#575757" />
															</IconButton>
														</Tooltip>
														<Tooltip title={t('TAKEOUT_REJECTED')} arrow>
															<IconButton
																onClick={e => {
																	dispatch(
																		openDialog({
																			children: (
																				<OpenDialogForm
																					title={t('PROCESS_APPROVE_TITLE')}
																					content={t('PROCESS_APPROVE_CONTENT').replace(
																						'%s',
																						t('TAKEOUT_REJECTED')
																					)}
																					fnHandleOK={() => {
																						doApprove(p.id, false);
																					}}
																				/>
																			)
																		})
																	);
																}}
															>
																<CustomIcon type="reject" className="" fill="#575757" />
															</IconButton>
														</Tooltip>
													</div>
												) : (
													<>{p.approved ? t('TAKEOUT_APPROVED') : t('TAKEOUT_REJECTED')}</>
												)}
											</TableCell>

											<TableCell className="relative" component="td" scope="row">
												{commonUtils.getDateTimeString(p.registered_at)}
											</TableCell>
											<TableCell className=" " component="td" scope="row" align="center">
												<div>
													<Tooltip title={t('DELETE')} arrow>
														<IconButton
															onClick={e => {
																dispatch(
																	openDialog({
																		children: (
																			<OpenDialogForm
																				title={t('PROCESS_DELETE_TITLE')}
																				content={t('PROCESS_DELETE_CONTENT').replace(
																					'%s',
																					p.name
																				)}
																				fnHandleOK={() => {
																					deleteConfirm(e, p.name);
																				}}
																			/>
																		)
																	})
																);
															}}
														>
															<CustomIcon type="delete" className="" fill="#575757" />
														</IconButton>
													</Tooltip>
												</div>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					) : (
						<Table stickyHeader className="" aria-labelledby="tableTitle">
							<WhiteURLTableHead />

							<TableBody>
								{whiteURLList.map(p => {
									return (
										<TableRow
											className={clsx(classes.tr, 'cursor-default')}
											hover
											role="checkbox"
											tabIndex={-1}
											key={p.id}
											onClick={event => handleRowClick(p)}
										>
											<TableCell className="left" align="left" component="td" scope="row">
												{p.name}
											</TableCell>
											<TableCell className="left" component="td" scope="row">
												{p.set_type === 'regex' ? t('WHITE_URL_REGEX') : t('WHITE_URL_PLAIN')}
											</TableCell>
											<TableCell className="left" align="left" component="td" scope="row">
												{p.url}
											</TableCell>
											<TableCell className=" " component="td" scope="row" align="center">
												<div>
													<Tooltip title={t('DELETE')} arrow>
														<IconButton
															onClick={e => {
																dispatch(
																	openDialog({
																		children: (
																			<OpenDialogForm
																				title={t('URL_DELETE_TITLE')}
																				content={t('URL_DELETE_CONTENT').replace(
																					'%s',
																					p.url
																				)}
																				fnHandleOK={() => {
																					deleteWhiteURLConfirm(e, p.name);
																				}}
																			/>
																		)
																	})
																);
															}}
														>
															<CustomIcon type="delete" className="" fill="#575757" />
														</IconButton>
													</Tooltip>
												</div>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					)}
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

export default WhiteProcessesTable;
